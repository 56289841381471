import 'date-fns';
import React from 'react';

import {useInput, useLocale, useTranslate} from 'react-admin';
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
/*import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';*/
import DateFnsUtils from '@date-io/date-fns';
import JalaalUtils from '@date-io/jalaali';

import jMoment from "moment-jalaali";

jMoment.loadPersian({ dialect: "persian-modern", usePersianDigits: true });

const MyDateInput = (props ) => {
    const locale = useLocale();
    const translate = useTranslate()
    const {
        input: { name, onChange, ...rest },
        meta: { touched, error },
        isRequired
    } = useInput(props);
    return (
        <MuiPickersUtilsProvider utils={locale === "fa-IR" ? JalaalUtils : DateFnsUtils}  >
        <DateTimePicker
            name={name}
            label= { translate(props.label)}
            onChange={onChange}
            error={!!(touched && error)}
            helperText={touched && error}
            required={isRequired}

            labelFunc={ locale === "fa-IR" ?  date => (date  ?  date.format("jYYYY/jMM/jDD HH:mm ") : "") : undefined}
            format="yyyy/MM/dd HH:mm"
            disablePast
            ampm={false}

            {...rest}

        />
        </MuiPickersUtilsProvider>
    );
};
export default MyDateInput