import React, {useEffect, useState} from "react";

import {useTranslate} from "react-admin";

import {useForm, useFormState} from "react-final-form";
import {ThemedSlider} from "../../shared/ThemedSlider";
import {makeStyles} from "@material-ui/core/styles";
import {Typography, withStyles} from "@material-ui/core";
import {darken, lighten} from "@material-ui/core/styles/colorManipulator";
import {rawProfitInPercent} from "../VendorSignalCalculations";
import Box from "@material-ui/core/Box";

Array.min = function (array) {
	return Math.min.apply(Math, array);
};
Array.max = function (array) {
	return Math.max.apply(Math, array);
};

const SummarySlider = withStyles((theme) => ({
	thumb: {
		height: 12,
		width: 12,

		marginTop: -6,
		marginLeft: -6,
	},
	thumbError: {
		backgroundColor: theme.palette.error.main,
	},
	thumbSuccess: {
		backgroundColor: theme.palette.success.main,
	},
	thumbNormal: {
		backgroundColor: "#fff",
	},
	track: {
		display: "block",
		position: "absolute",
		height: 2,
		borderRadius: 1,
		backgroundColor: theme.palette.grey[300],
		"$vertical &": {
			width: 2,
		},
	},
	rail: {
		display: "block",
		position: "absolute",
		width: "100%",
		height: 2,
		borderRadius: 1,
		backgroundColor: theme.palette.success.main,
		opacity: 0.38,
		"$vertical &": {
			height: "100%",
			width: 2,
		},
	},
	/* Styles applied to the track element if `track={false}`. */
	trackFalse: {
		"& $track": {
			display: "none",
		},
	},
	valueLabel: {
		thumbName: {
			position: "relative",
			top: 35,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: 50,
			left: -8,
			fontSize: "2em",
			textAlign: "center",
		},
	},
	/* Styles applied to the track element if `track="inverted"`. */
	trackInverted: {
		"& $track": {
			backgroundColor:
				// Same logic as the LinearProgress track color
				theme.palette.type === "light" ? lighten(theme.palette.success.main, 0.62) : darken(theme.palette.success.main, 0.5),
		},
		"& $rail": {
			opacity: 1,
		},
	},
}))(ThemedSlider);

const useStyles = makeStyles((theme) => ({
	formInput: {
		//    maxWidth:'100%'
		width: "100%",
		maxWidth: "500px",
		//  minWidth:'250px'
	},
	formInputSlider: {
		width: "85%",
		maxWidth: "500px",
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
	},
	withSliderForm: {
		width: "100%",
		maxWidth: "500px",
		marginTop: 5,
	},
	margin: {
		height: theme.spacing(5),
	},
	seperate: {
		height: theme.spacing(2),
	},
	lossPercent: {
		marginTop: theme.spacing(1),
		color: theme.palette.error.light,
	},
	risk: {
		marginTop: theme.spacing(1),
		color: theme.palette.warning.main,
	},
	fullTargetProfit: {
		color: theme.palette.success.dark,
		marginTop: theme.spacing(1),
		//fontSize:"1.05em"
	},
}));
const SummaryForm = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const { values } = useFormState();

	const form = useForm();
	let { pairObject, entryPrice, stopLoss, positionType, targetPoints, activeStep, markettradetype, leverage, tradetype, entryReset, fundPercent } =
		values;

	const [summaryValues, setSummaryValues] = useState([{ name: "stopLoss", value: 0, color: "thumbNormal" }]);

	useEffect(() => {
		let _smAllValues = [
			{ name: "enterPriceStart", value: entryPrice.low, color: "thumbNormal" },
			{ name: "enterPriceEnd", value: entryPrice.high, color: "thumbNormal" },
			{ name: "stopLoss", value: stopLoss.valuenumber, color: "thumbError" },
		];
		targetPoints.forEach((tp, index) => {
			_smAllValues.push({ name: "targetPoint", value: tp.valuenumber, index: index, percent: tp.percent, color: "thumbSuccess" });
		});
		_smAllValues.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
		setSummaryValues(_smAllValues);
	}, [leverage, entryPrice, targetPoints, stopLoss, entryReset]);

	const getSliderValues = () => {
		return summaryValues.map((smv) => {
			return smv.value;
		});
	};
	const getMin = () => {
		const min = Array.min(getSliderValues());
		return min;
	};
	const getMax = () => {
		const max = Array.max(getSliderValues());
		return max;
	};

	const getTrackLabelText = (indx) => {
		return summaryValues[indx].name === "targetPoint"
			? "TP" + (summaryValues[indx].index + 1) + ":%" + summaryValues[indx].percent
			: translate("resources.signalalerts." + summaryValues[indx].name);
	};
	const getThumbColor = (index) => {
		return summaryValues[index].color;
	};
	const getProfitBasedOnEntryAvg = (tpIndex) => {
		if (!targetPoints[tpIndex] || !targetPoints[tpIndex].valuenumber) {
			return 0;
		}
		let avrg = Number(((entryPrice.low + entryPrice.high) / 2).toFixed(countDecimals(pairObject.tickSize)));
		let tpVal = targetPoints[tpIndex].valuenumber;

		let profitPercent = rawProfitInPercent(avrg, tpVal, positionType);
		return profitPercent;
	};
	const getProfitBasedOnEntryLow = (tpIndex) => {
		if (!targetPoints[tpIndex] || !targetPoints[tpIndex].valuenumber) {
			return 0;
		}
		let avrg = Number(((entryPrice.low + entryPrice.high) / 2).toFixed(countDecimals(pairObject.tickSize)));
		let tpVal = targetPoints[tpIndex].valuenumber;

		let profitPercent = rawProfitInPercent(entryPrice.low, tpVal, positionType);
		return profitPercent;
	};
	const getProfitBasedOnEntryHigh = (tpIndex) => {
		if (!targetPoints[tpIndex] || !targetPoints[tpIndex].valuenumber) {
			return 0;
		}

		let tpVal = targetPoints[tpIndex].valuenumber;

		let profitPercent = rawProfitInPercent(entryPrice.high, tpVal, positionType);
		return profitPercent;
	};
	const getProfitWithAppliedExtraction = (tpIndex, tp) => {
		if (!tp || !tp.percent || !tp.valuenumber) {
			return 0;
		}
		return Number((getProfitBasedOnEntryAvg(tpIndex) * (tp.percent / 100)).toFixed(2));
	};
	const getProfitWithAppliedExtractionLow = (tpIndex, tp) => {
		if (!tp || !tp.percent || !tp.valuenumber) {
			return 0;
		}
		return Number((getProfitBasedOnEntryLow(tpIndex) * (tp.percent / 100)).toFixed(2));
	};
	const getProfitWithAppliedExtractionHigh = (tpIndex, tp) => {
		if (!tp || !tp.percent || !tp.valuenumber) {
			return 0;
		}
		return Number((getProfitBasedOnEntryHigh(tpIndex) * (tp.percent / 100)).toFixed(2));
	};
	const getLossBasedOnEntryAvg = () => {
		let avrg = Number(((entryPrice.low + entryPrice.high) / 2).toFixed(countDecimals(pairObject.tickSize)));
		let lossPercent = rawProfitInPercent(avrg, stopLoss.valuenumber, positionType);
		return lossPercent;
	};
	const getFullTargetProfit = () => {
		if (!targetPoints || targetPoints.length === 0) {
			return 0;
		}
		return Number(targetPoints.map((tp, index) => getProfitWithAppliedExtraction(index, tp)).reduce((total, num) => total + num)).toFixed(2);
	};
	const getFullTargetProfitLow = () => {
		if (!targetPoints || targetPoints.length === 0) {
			return 0;
		}
		return Number(targetPoints.map((tp, index) => getProfitWithAppliedExtractionLow(index, tp)).reduce((total, num) => total + num)).toFixed(2);
	};
	const getFullTargetProfitHigh = () => {
		if (!targetPoints || targetPoints.length === 0) {
			return 0;
		}
		return Number(targetPoints.map((tp, index) => getProfitWithAppliedExtractionHigh(index, tp)).reduce((total, num) => total + num)).toFixed(2);
	};
	const getLossBasedOnEntryLow = () => {
		let low = Number(entryPrice.low.toFixed(countDecimals(pairObject.tickSize)));
		let lossPercent = rawProfitInPercent(low, stopLoss.valuenumber, positionType);

		return lossPercent;
	};
	const getLossBasedOnEntryHigh = () => {
		let high = Number(entryPrice.high.toFixed(countDecimals(pairObject.tickSize)));
		let lossPercent = rawProfitInPercent(high, stopLoss.valuenumber, positionType);

		return lossPercent;
	};
	const liquidationValidation = () => {
		if (stopLoss && pairObject && entryPrice && entryPrice.high && stopLoss.valuenumber !== undefined && markettradetype === 1) {
			if (Math.abs(getLossBasedOnEntryHigh() * leverage) >= 100 || Math.abs(getLossBasedOnEntryLow() * leverage) >= 100) {
				return true;
			}
		}

		return false;
	};
	const handleChangeSliderCommitedFundPercent = (event, newValue) => {
		form.change("fundPercent", newValue);
	};
	return (
		<div className={"fullWidth maxWidth400"}>
			<Typography gutterBottom className={classes.formInput}>
				{translate("resources.signalalerts.signalSummaryHint")}
			</Typography>
			{!!pairObject && (
				<div className={" no-cursor "} id={"targetPointsDiv"}>
					<div className={classes.margin} />
					<SummarySlider
						value={getSliderValues()}
						labelRotated
						className={classes.formInputSlider}
						track={"false"}
						getAriaLabel={getTrackLabelText}
						//getAriaLabel={()=>translate("resources.signalalerts.stopLoss")}
						getThumbColor={getThumbColor}
						min={getMin()}
						max={getMax()}
						// marks={getMarks()}

						//getAriaValueText={getEnterPriceStopLossSliderText}

						// max={enterPriceEnd * 2}
						valueLabelDisplay="on"

						//valueLabelFormat={getTrackValueText}

						//getAriaValueText={valuetext}
					/>
					<div className={classes.seperate} />
					<div className={classes.margin} />
					<Typography className={classes.formInput} variant={"caption"}>
						{translate("resources.signalalerts.signalSummaryHint2")}
					</Typography>

					<div className={classes.fullTargetProfit}>
						{translate("resources.signalalerts.fulltargetProfit", {
							profit: "(" + getFullTargetProfitLow() + " ~ " + getFullTargetProfitHigh() + ")",
						})}
						{markettradetype === 1 && (
							<span>
								<span>
									{"    x " +
										leverage +
										" = " +
										(getFullTargetProfitLow() * leverage).toFixed(2) +
										"%" +
										" ~ " +
										(getFullTargetProfitHigh() * leverage).toFixed(2) +
										"%"}
								</span>
							</span>
						)}
					</div>
					<div className={classes.lossPercent}>
						{translate("resources.vendorsignals.loss", { loss: "(" + getLossBasedOnEntryLow() + " ~ " + getLossBasedOnEntryHigh() + ")" })}

						{markettradetype === 1 && (
							<span>
								<span>
									{"    x " +
										leverage +
										" = " +
										(getLossBasedOnEntryLow() * leverage).toFixed(2) +
										"%" +
										" ~ " +
										(getLossBasedOnEntryHigh() * leverage).toFixed(2) +
										"%"}
								</span>
							</span>
						)}
						{liquidationValidation() && (
							<Box color={"red"} fontSize={"1.5em"}>
								Liquidation Possibility
							</Box>
						)}
					</div>
					<Box className={classes.risk}>
						{!markettradetype &&
							"Risk : " +
								"(" +
								((getLossBasedOnEntryLow() * fundPercent) / 100).toFixed(2) +
								" ~ " +
								((getLossBasedOnEntryHigh() * fundPercent) / 100).toFixed(2) +
								")%"}
						{markettradetype === 1 &&
							"Risk : " +
								"(" +
								((getLossBasedOnEntryLow() * fundPercent * leverage) / 100).toFixed(2) +
								" ~ " +
								((getLossBasedOnEntryHigh() * fundPercent * leverage) / 100).toFixed(2) +
								")%"}
					</Box>
					<div className={classes.margin} />
					<ThemedSlider
						value={fundPercent}
						getAriaLabel={() => "Fund"}
						step={1}
						min={1}
						max={33}
						valueLabelDisplay="on"
						onChangeCommitted={handleChangeSliderCommitedFundPercent}
						valueLabelFormat={(val) => val + "%"}

						//getAriaValueText={valuetext}
					/>
					<Box height={3} />
				</div>
			)}
		</div>
	);
};

var countDecimals = function (value) {
	let text = value.toString();
	// verify if number 0.000005 is represented as "5e-6"
	if (text.indexOf("e-") > -1) {
		let [base, trail] = text.split("e-");
		let deg = parseInt(trail, 10);
		return deg;
	}
	// count decimals for number in representation like "0.123456"
	if (Math.floor(value) !== value) {
		return value.toString().split(".")[1].length || 0;
	}
	return 0;
};
export default SummaryForm;
