import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import SignalAlertsEdit from './edit/VendorSignalsEdit';
import {VendorSignalsList} from './VendorSignalsList';
import VendorSignalsCreateWizard from './wizard/VendorSignalsCreateWizard';

export default {
     create : VendorSignalsCreateWizard,
     edit : SignalAlertsEdit,
    list:VendorSignalsList,
    icon: NotificationsActiveIcon,

};
