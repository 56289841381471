import React from 'react';
import {
    AutocompleteArrayInput,
    BooleanField,
    DateField,
    Filter,
    List,
    NumberField,
    ReferenceArrayInput,
    ReferenceField,
    TextField,
    useLocale,
    useTranslate
} from 'react-admin';
import CustomizableDatagrid from 'ra-customizable-datagrid';

const StatusFieldColored = ({record}) => <TextField source="name" record={record} style={{color:record.color ? record.color : "unset"}}/>
const ListFilter = (props) => {
    const translate = useTranslate();
    return(<Filter {...props}>
        <ReferenceArrayInput  alwaysOn label={"resources.candles.pair"} source="pair" perPage={10}
                         reference="pairs" allowEmpty={true}

                         filterToQuery={(searchText) => searchText.trim() !== '' ? ({
                             symbol:{$search:searchText}
                         }) : null}>
            <AutocompleteArrayInput optionText={'symbol'} allowEmpty={true} optionValue={'_id'}   emptyText={"resources.pairs.allpairs"} alwaysOn
            />
        </ReferenceArrayInput>


    </Filter>)
}

const PttChannelsListItems =(props) =>{
    const locale = useLocale();

    return(
        <CustomizableDatagrid   {...props} >

            <ReferenceField   source="pair" label={"resources.pairs.market"} reference="pairs">
                <ReferenceField   source="market"  reference="markets">
                    <TextField source="name"   />
                </ReferenceField>
            </ReferenceField>
            <ReferenceField   source="pair" label={"resources.candles.pair"} reference="pairs">
                <TextField source="symbol"   />
            </ReferenceField>



            <NumberField label={"resources.candles.op"}  source="op"/>
            <NumberField label={"resources.candles.cp"}  source="cp"/>
            <NumberField label={"resources.candles.hp"}  source="hp"/>
            <NumberField label={"resources.candles.lp"}  source="lp"/>
            <NumberField label={"resources.candles.bv"}  source="bv"/>
            <NumberField label={"resources.candles.qv"}  source="qv"/>

            <DateField source="ot" label={"resources.candles.ot"}  locales={locale} showTime/>
            <DateField source="ct"   label={"resources.candles.ct"} locales={locale} showTime/>
            <DateField source="createdAt"    locales={locale} showTime/>
            <DateField source="updatedAt"   locales={locale} showTime/>
            <BooleanField label={"resources.candles.closed"}  source="x"/>

        </CustomizableDatagrid>);

};
const CandlesList = (props) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            filters={<ListFilter/>}
            title={translate('resources.candles.listtitle')}
            perPage={25}
            exporter={false}
            sort={{ field: 'id', order: 'DESC' }}
            bulkActionButtons={false}
        >

            <PttChannelsListItems/>
        </List>
    );
};


export  {PttChannelsListItems,CandlesList};
