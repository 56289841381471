import * as React from "react";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
	thumb: {
		"&$open": {
			"& $offset": {
				transform: "scale(1) translateY(-10px)",
			},
		},
	},
	open: {},
	offset: {
		zIndex: 1,
		...theme.typography.body2,
		fontSize: theme.typography.pxToRem(12),
		lineHeight: 1.2,
		transition: theme.transitions.create(["transform"], {
			duration: theme.transitions.duration.shortest,
		}),
		top: -34,
		transformOrigin: "bottom center",
		transform: "scale(0)",
		position: "absolute",
	},
	circle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 32,
		height: 32,
		borderRadius: "50% 50% 50% 0",
		backgroundColor: "currentColor",
		transform: "rotate(-45deg)",
	},
	label: {
		color: theme.palette.text.seconray,
		fontSize: "0.9em",
		transform: "rotate(0deg)",
	},
	thumbName: {
		position: "relative",
		top: 30,
		display: "flex",
		alignItems: "center",
		//justifyContent: "center",
		width: 50,
		//left: -8,
		fontSize: "0.6rem",
		//textAlign: "center",
	},
	thumbNameRotated: {
		position: "relative",
		top: 40,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 50,
		left: -25,
		transform: "rotate(-45deg)",
		fontSize: "0.6rem",
		textAlign: "right",
	},
});

/**
 * @ignore - internal component.
 */
function ValueLabel(props) {
	const { children, classes, className, open, value, valueLabelDisplay, thumbLabel, labelRotated, Content } = props;

	if (valueLabelDisplay === "off") {
		return children;
	}

	return React.cloneElement(
		children,
		{
			className: clsx(
				children.props.className,
				{
					[classes.open]: open || valueLabelDisplay === "on",
				},
				classes.thumb
			),
		},
		Content,

		<span className={clsx(classes.offset, className)}>
			<span className={classes.circle}>
				<span className={classes.label}>{value}</span>
			</span>
			<span className={labelRotated ? classes.thumbNameRotated : classes.thumbName}>{thumbLabel}</span>
		</span>
	);
}

export default withStyles(styles, { name: "PrivateValueLabel" })(ValueLabel);
