import React from "react";
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    EditButton,
    Filter,
    FunctionField,
    List,
    NullableBooleanInput,
    ReferenceField,
    ReferenceInput,
    SelectField,
    SelectInput,
    useLocale,
    useTranslate,
} from "react-admin";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";

import {ServiceTypes} from "./VendorSubscriptionsEnums.js";
import Box from "@material-ui/core/Box";

const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<ReferenceInput
				alwaysOn
				emptyText={"resources.vendorsignals.allvendors"}
				label={"resources.vendorsignals.vendor"}
				source="vendor"
				reference="users|usertype|VENDOR"
				perPage={200}
			>
				<SelectInput optionValue={"_id"} optionText={<NickNameWithAvatarField />} name={"fakename"} />
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				label={"resources.signalalerts.client"}
				source="user"
				perPage={25}
				reference="users|usertype|CLIENT"
				allowEmpty={true}
				filter={{
					usertype: "CLIENT",
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
						  }
						: { fake: true }
				}
			>
				<AutocompleteInput
					optionText={(record) =>
						record && record.firstName
							? record.firstName + " " + record.lastName
							: translate("resources.observes.allusers")
					}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
			<SelectInput
				label={"resources.vendorsubscriptions.serviceType"}
				source={"servicetype"}
				choices={ServiceTypes}
				allowEmpty={true}
				alwaysOn
			/>
			<NullableBooleanInput source={"expired"} alwaysOn />
		</Filter>
	);
};

function getDaysRemaining(expiresAt) {
	const _start = new Date();
	const _expire = new Date(expiresAt);

	const result = Math.round((_expire - _start) / (1000 * 60 * 60 * 24));
	return result > 0 ? result : 0;
}

const DaysRemaining = ({ record }) => {
	if (record && record.expiresAt) {
		let remainingDays = getDaysRemaining(record.expiresAt);
		if (remainingDays) {
			return <Box marginLeft={2}>{remainingDays} Days remaining </Box>;
		}
		return (
			<Box marginLeft={2} color={"#f50057"}>
				Expired
			</Box>
		);
	}

	return <span />;
};
const rowStyle = (record) => {
	return {
		backgroundColor: record && record.disabled ? "lightgrey" : "transparent",
	};
};

const VendorSubscriptionsListItems = (props) => {
	const locale = useLocale();

	return (
		<Datagrid {...props} rowStyle={rowStyle}>
			<ReferenceField
				source="vendor"
				label={"resources.vendorsubscriptions.vendor"}
				reference="users|usertype|VENDOR"
			>
				<NickNameWithAvatarField />
			</ReferenceField>
			<ReferenceField source="user" label={"resources.vendorsubscriptions.client"} reference="users|usertype|CLIENT">
				<NameWithAvatarField />
			</ReferenceField>
			<SelectField
				label={"resources.vendorsubscriptions.serviceType"}
				source={"servicetype"}
				choices={ServiceTypes}
			/>
			<DateField source="startedAt" label="resources.vendorsubscriptions.startedAt" locales={locale} showTime />
			<DateField source="expiresAt" label="resources.vendorsubscriptions.expiresAt" locales={locale} showTime />
			<FunctionField
				label="resources.vendorsubscriptions.notifications"
				render={(record) => {
					record.notification = !record.disabledNotifications;
					return <BooleanField record={record} source="notification" />;
				}}
			/>
			<FunctionField
				label="resources.vendorsubscriptions.autoTrade"
				render={(record) => {
					record.trades = record.servicetype === "AUTOTRADE" && !record.disabledTrades;
					return <BooleanField record={record} source="trades" />;
				}}
			/>

			<DaysRemaining />
			<BooleanField label="resources.vendorsubscriptions.expired" source="expired" />

			<EditButton />
		</Datagrid>
	);
};
const VendorSubscriptionsList = (props) => {
	const translate = useTranslate();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.vendorsubscriptions.listtitle")}
			perPage={25}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			bulkActionButtons={false}
		>
			<VendorSubscriptionsListItems />
		</List>
	);
};

export { VendorSubscriptionsListItems, VendorSubscriptionsList };
