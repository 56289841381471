import React, {useEffect, useState} from 'react';

import {ReferenceInput, required, SelectInput, useTranslate} from "react-admin";

import {useForm, useFormState} from 'react-final-form';
import {ThemedSlider} from '../../shared/ThemedSlider';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography, withStyles} from "@material-ui/core";
import {darken, lighten} from '@material-ui/core/styles/colorManipulator';
import {rawProfitInPercent} from '../VendorSignalCalculations';
import NickNameWithAvatarField from "../../shared/NickNameWithAvatarField";

Array.min = function( array ){
    return Math.min.apply( Math, array );
};
Array.max = function( array ){
    return Math.max.apply( Math, array );
};

const TargetPointSlider = withStyles(theme =>({

    thumb: {

        backgroundColor: theme.palette.success.main

    },
    track: {
        display: 'block',
        position: 'absolute',
        height: 2,
        borderRadius: 1,
        backgroundColor: theme.palette.success.main,
        '$vertical &': {
            width: 2,
        },
    },
    rail: {
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: 2,
        borderRadius: 1,
        backgroundColor:  theme.palette.success.main,
        opacity: 0.38,
        '$vertical &': {
            height: '100%',
            width: 2,
        },
    },
    /* Styles applied to the track element if `track={false}`. */
    trackFalse: {
        '& $track': {
            display: 'none',
        },
    },
    /* Styles applied to the track element if `track="inverted"`. */
    trackInverted: {
        '& $track': {
            backgroundColor:
            // Same logic as the LinearProgress track color
                theme.palette.type === 'light'
                    ? lighten(theme.palette.success.main, 0.62)
                    : darken(theme.palette.success.main, 0.5),
        },
        '& $rail': {
            opacity: 1,
        },
    },

}))(ThemedSlider);

const useStyles = makeStyles(theme=>({

    formInput: {
        //    maxWidth:'100%'
        width: '100%',
        maxWidth: "500px"
        //  minWidth:'250px'
    },
    withMarginSlider:{
      margin:10
    },
    withSliderForm: {

      //  width: '100%',
        //maxWidth: "500px",
        marginTop: 5,



    },
    margin: {
        height: theme.spacing(5),
    },
    seperate:{
        height: theme.spacing(2),
    },
    tpSummaryRoot:{
      marginTop:  theme.spacing(2),
      borderBottomStyle:"solid",
      borderBottomColor:theme.palette.grey[500],
      borderBottomWidth:1,
        padding:2
    },
    summaryProfit:{
        color:theme.palette.success.light
    },
    summaryDifference:{
        color:theme.palette.grey[600]
    },
    summaryExtraction:{
        color:theme.palette.grey[900]
    },
    fullTargetProfit:{
        color:theme.palette.success.dark,
        marginTop:theme.spacing(1),
        fontSize:"1.1em"
    }

}));
const PercentsForm = (props) =>{
    const classes = useStyles();
    const translate = useTranslate();
    const {values} = useFormState();

    const form = useForm();
    let {pairObject,entryPrice,positionType,targetPoints,activeStep,markettradetype,leverage,entryReset} = values;

    const [localPercents,setlocalPercents] = useState([100]);
    const [realPercents,setRealPercents] = useState([100]);
    const [mustReset,setMustReset] = useState(true);
  /*  if(!targetPoints){
        targetPoints = []
    }*/
    useEffect(()=>{
        setMustReset(true)

    },[targetPoints.length,entryReset])

   /* useEffect(()=>{
        if(activeStep === 5){
            setTimeout(()=>{
                window.swv && window.swv.updateHeight();
            })

        }
    },[activeStep])*/
    useEffect(() => {

        if( true) {


            let shownPercents = []
            if (targetPoints.length === 1) {
                form.change('targetPoints[0].percent', 100);
                setlocalPercents([100]);
                setRealPercents([100]);
            }
            else if (targetPoints.length > 1) {
                let realPercents = [50];
                shownPercents = [50]
                form.change('targetPoints[0].percent', 50);

                for (let i = 1; i < targetPoints.length; i++) {
                    let calculatedTPP = 0;
                    if (i === targetPoints.length - 1) {
                        calculatedTPP = 100 - (realPercents.reduce(function (a, b) {
                            return a + b;
                        }, 0));
                    }
                    else {
                        calculatedTPP = 50 / (targetPoints.length - 1);
                        if (calculatedTPP.toFixed(2) * (targetPoints.length - 1) !== 50) {
                            if (i % 2) {
                                calculatedTPP = Math.floor(calculatedTPP);
                            }
                            else {
                                calculatedTPP = Math.ceil(calculatedTPP);
                            }
                        }
                    }

                    realPercents.push(calculatedTPP);
                    form.change('targetPoints[' + i + '].percent', calculatedTPP)
                    shownPercents.push(calculatedTPP + shownPercents[shownPercents.length - 1]);
                }
                setRealPercents(realPercents)
                setlocalPercents(shownPercents);


            }
            setMustReset(false);
        }


    }, [targetPoints.length,entryReset]);


    const handleChangeSlider = (event, newValue) => {
        if(findDuplicates(newValue).length >0){
            return;
        }
        newValue[newValue.length-1] = 100;

        setlocalPercents(newValue);
        let realPercents = newValue.map((lp,index)=>{
            return index === 0 ? lp : lp - newValue[index-1]
        })
        setRealPercents(realPercents);
    };
    const handleChangeSliderCommited = (event, newValue) => {

        realPercents.forEach((val,indx)=>{
           /* if(val===0){
                val = 1;
                let newRP = realPercents;
                newRP[indx] = 1
                 setRealPercents(newRP);
                let newLP = localPercents;
                newLP[indx]++;
                setlocalPercents(newLP);
            }*/
            form.change('targetPoints['+indx+'].percent',val)
        });

    };
    const getPercentName = (val,indx)=>{
         return  realPercents[indx] + "%" ;
    }
    const getProfitBasedOnEntryAvg = (tpIndex)=>{
        if(!targetPoints[tpIndex] ||  !targetPoints[tpIndex].valuenumber){
            return 0;
        }
        let avrg = Number(((entryPrice.low + entryPrice.high) / 2).toFixed(countDecimals(pairObject.tickSize)));
        let tpVal = targetPoints[tpIndex].valuenumber;

        let profitPercent = rawProfitInPercent(avrg,tpVal,positionType);
        return profitPercent;
    }
    const getProfitWithAppliedExtraction = (tpIndex,tp) => {
        if(!tp || !tp.percent || !tp.valuenumber){
            return 0;
        }
        return Number((getProfitBasedOnEntryAvg(tpIndex) * (tp.percent  / 100)).toFixed(2));
    }
    const TargetPointSummary =({index,tp}) =>{
        if(!tp || !tp.valuenumber || !tp.percent){
            return <div/>
        }
        return <div className={classes.tpSummaryRoot}>
            <Box>
            {translate("resources.signalalerts.targetPoint",{index:index+1}) + ": " + tp.valuenumber}
            </Box>
            <Box fontSize={"0.9em"} className={classes.summaryExtraction}>
            {translate("resources.signalalerts.extraction") + ": " + tp.percent + "%"}
            </Box>
            <Box fontSize={"0.9em"} className={classes.summaryDifference}>
                {translate("resources.signalalerts.differenceBasedOnEntyAvg",{profit:getProfitBasedOnEntryAvg(index)})}
                {markettradetype === 1 &&
                <span>
                        <span>{"    x " + leverage  + " = " +(getProfitBasedOnEntryAvg(index)*leverage).toFixed(2) + "%"}</span>

                    </span>

                }
            </Box>
            <Box fontSize={"0.9em"}  className={classes.summaryProfit}>
                {translate("resources.signalalerts.profitWithAppliedExtraction",{profit:  getProfitWithAppliedExtraction(index,tp)})}
                {markettradetype === 1 &&
                <span>
                        <span>{"    x " + leverage  + " = " +(getProfitWithAppliedExtraction(index,tp)*leverage).toFixed(2) + "%"}</span>

                    </span>

                }
            </Box>
        </div>
    }
    const getFullTargetProfit = ()=>{
        if(!targetPoints || targetPoints.length === 0){
            return 0;
        }
        return  Number(targetPoints.map((tp,index)=>getProfitWithAppliedExtraction(index,tp)).reduce((total,num)=>total+num)).toFixed(2);

    }
    return (
        <div   >
            <ReferenceInput validate={requiredValidate}
                            label={"resources.vendorsignals.vendor"} source="vendor" reference="users|usertype|VENDOR" perPage={100}
                            className={classes.formInput}>
                <SelectInput optionText={<NickNameWithAvatarField/>}
                             perPage={100}
                             name={"fakename"}
                />
            </ReferenceInput>
        <Typography id="enterPricesliderLabel" gutterBottom  >
            Extractions
        </Typography>
        <div className={" no-cursor "} id={"targetPointsDiv"}>

            <div className={classes.margin} />
            <Box marginRight={2} marginLeft={2} marginBottom={1}>
                <TargetPointSlider
                    value={localPercents}
                    onChange={handleChangeSlider}
                    //className={classes.withMarginSlider}
                    track={"false"}
                    getAriaLabel={(index)=>{return "TP"+(index+1)}}
                    //getAriaLabel={()=>translate("resources.signalalerts.stopLoss")}
                    step={1}
                    min={1}
                    max = {100}

                    // marks={getMarks()}

                    //getAriaValueText={getEnterPriceStopLossSliderText}

                    // max={enterPriceEnd * 2}
                    valueLabelDisplay="on"
                    onChangeCommitted={handleChangeSliderCommited}

                    valueLabelFormat={getPercentName}


                    //getAriaValueText={valuetext}
                />
            </Box>



        </div>
        </div>
    )
}
const requiredValidate = [required()];
const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort(); // You can define the comparing function here.

    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] == sorted_arr[i]) {
            results.push(sorted_arr[i]);
        }
    }
    return results;
}
var countDecimals = function(value) {
    let text = value.toString()
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
        let [base, trail] = text.split('e-');
        let deg = parseInt(trail, 10);
        return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
        return value.toString().split(".")[1].length || 0;
    }
    return 0;
}
export default PercentsForm;