import React, {Fragment, useState} from "react";
import {
    ArrayInput,
    AutocompleteInput,
    CheckboxGroupInput,
    Create,
    email,
    Loading,
    maxValue,
    minLength,
    minValue,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    useGetList,
    useLocale,
    useTranslate,
} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography, withStyles} from "@material-ui/core";
import {useForm, useFormState} from "react-final-form";
import {NotifTypes} from "../SignalAlertsEnum";
import Wizard from "../../shared/Wizard";
import StopLossForm from "./SignalAlertsCreateWizardStopLossForm";
import EnterPriceForm from "./SignalAlertsCreateWizardEntryPriceForm";
import TargetPointsForm from "./SignalAlertsCreateWizardTargetPointsForm";
import PercentsForm from "./SignalAlertsCreateWizardPercentsForm";
import SummaryForm from "./SignalAlertsWizardSignalSummary";
import Avatar from "@material-ui/core/Avatar";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import WizardChart from "./SignalAlertsCreateWizardChart";

const useStyles = makeStyles((theme) => ({
  normalfield: { width: 544 },
  fullWidth: { width: "100% !important" },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  wizardPage: {
    /*        display: 'flex',
                flexDirection: "column",
                justifyContent: "Space-around",
                alignItems: 'center',*/
    //    height:"100%",

    //  flex:1
    marginLeft: 15,
    marginRight: 15,
    padding: 10,
    overflowY: "auto",
    overflowX: "auto",
    maxHeight: "60vh",
  },
  formInput: {
    //    maxWidth:'100%'
    width: "100%",
    maxWidth: "450px",
    //  minWidth:'250px'
  },
  withSliderForm: {
    width: "100%",
    maxWidth: "500px",
    marginTop: 5,
  },
  margin: {
    height: theme.spacing(5),
  },
  seperate: {
    height: theme.spacing(2),
  },
  positionTypeSelect: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  positionTypeavatarRoot: {
    width: 30,
    height: 30,
    marginRight: 5,
  },
  positionTypeAvatarError: {
    backgroundColor: theme.palette.error.main,
  },
  positionTypeAvatarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  panelClassName: {
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "100vw",
      bottom: 0,
      left: 0,
    },
    bottom: 65,
    backgroundColor: "rgba(255,255,255,0.7)",
    left: 60,

    width: 320,
    maxWidth: "100vw",
    borderStyle: "solid",
    borderColor: "purple",
    borderWidth: 1,
    borderRadius: 5,
  },
  /*tradeAccountItem: {
    "& section": {
      display: "flex",
    },
  },*/
  exchangeAccountPicker: {
    maxWidth: 200,
  },
}));
const wizardSteps = [
  /* {
         title: "resources.signalalerts.wizardselectmarketPositionType",
         fields: ['market', 'positionType']
     },
     {
         title: "resources.signalalerts.wizardselectpair",
         fields: ['pair']
     },*/
  {
    title: "resources.signalalerts.wizardenterPricetitle",
    fields: ["entryPrice.low", "entryPrice.high"],
  },
  {
    title: "resources.signalalerts.stopLoss",
    fields: ["stopLoss.valuenumber", "targetPoints"],
  },
];

const MarketSelect = ({ formData, ...rest }) => {
  const form = useForm();
  const classes = useStyles();
  return (
    <ReferenceInput
      validate={requiredValidate}
      label={"resources.observes.market"}
      source="market"
      reference="markets"
      perPage={0}
      onChange={(event, value) => {
        form.change("pair", null);
        // console.log(formData)

        //  form.change('marketText', JSON.stringify(value));
      }}
      className={classes.formInput}
    >
      <SelectInput
        optionText={"name"}
        name={"fakename"}
        helperText={"resources.alerts.wizard.markethelper"}
      />
    </ReferenceInput>
  );
};
const AutocompleteInputInDialog = withStyles({
  container: {
    width: "100%",
    maxWidth: "500px",
  },
  suggestionsContainer: { zIndex: 2000 },
})(AutocompleteInput);

function getMarketName() {
  const containerDiv = document.getElementById("market");

  return containerDiv !== null && containerDiv !== undefined
    ? containerDiv.innerText
    : "";
}

function getPairName() {
  const containerDiv = document.getElementById("pair");

  return containerDiv !== null && containerDiv !== undefined
    ? containerDiv.getAttribute("value")
    : "";
}

const FakeMarketSpy = ({ formData, record, ...rest }) => {
  const form = useForm();
  const classes = useStyles();

  if (record && record.tradetype !== undefined) {
    form.change("markettradetype", record.tradetype);
  }
  return <Fragment />;
};

const PositionTypeRenderer = ({ record, classes }) => (
  <span className={classes.positionTypeSelect}>
    <Avatar
      className={[
        classes.positionTypeavatarRoot,
        record.id === "buy"
          ? classes.positionTypeAvatarSuccess
          : classes.positionTypeAvatarError,
      ]}
    >
      {record.id === "buy" && <TrendingUpIcon />}
      {record.id === "sell" && <TrendingDownIcon />}
    </Avatar>

    {record.name}
  </span>
);

const getlastPair = (tradetype) => {
  let result = "";
  result = localStorage.getItem(tradetype + "lastPair");
  if (!result) {
    result = "BTCUSDT";
  }
  return result;
};
const SignalAlertsCreateWizard = (props) => {
  const { basePath, location } = props;
  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();
  let typedPair = "";

  const tradetype = basePath && basePath.includes("1") ? 1 : 0;
  let record =
    location &&
    location.state &&
    location.state.record &&
    location.state.record.market
      ? location.state.record
      : false;
  let [lastSymbol, setLastSymbol] = useState(getlastPair(tradetype));

  const {
    data: marketObject,
    ids: marketIds,
    total,
    loading,
    loaded,
    error,
  } = useGetList(
    "markets",
    {
      page: 1,
      perPage: 1,
    },
    { field: "createdAt", order: "DESC" },
    record ? { _id: record.market } : { tradetype: tradetype }
  );
  let {
    data: pairObject,
    ids: pairIds,
    loading: pairLoading,
    loaded: pairLoaded,
    error: pairError,
  } = useGetList(
    "pairs",
    { page: 1, perPage: 10 },
    {
      field: "createdAt",
      order: "DESC",
    },
    record ? { _id: record.pair } : { symbol: lastSymbol }
  );
  let { total: totalExAccounts } = useGetList(
    "exchangeaccounts",
    { page: 1, perPage: 1 },
    {
      field: "createdAt",
      order: "DESC",
    },
    { disabled: { $ne: true }, isValid: true, tradetype: tradetype }
  );
  if (loading || !loaded || pairLoading || !pairLoaded) {
    return <Loading />;
  }
  if (
    error ||
    !marketIds ||
    !marketObject ||
    marketIds.length < 1 ||
    pairError ||
    !pairIds ||
    pairIds.length < 1
  ) {
    return <p>ERROR</p>;
  }
  let selectedPair = pairIds.find(
    (pid) => pairObject[pid].market === marketIds[0]
  );
  let selectedPairObj = pairObject[selectedPair];
  return (
    <Create
      {...props}
      title={
        tradetype === 0
          ? "resources.signalalerts.createtitleSpot"
          : "resources.signalalerts.createtitleFutures"
      }
    >
      <Wizard
        initialValues={{
          positionType: record ? record.positionType : "buy",
          marketObject: marketObject[marketIds[0]],
          market: marketIds[0],
          cloned: !!record,

          pairObject: selectedPairObj,
          pair: selectedPair,
          markettradetype: tradetype,
          tradetype,
          leverage: tradetype === 1 ? (record ? record.leverage : 3) : 0,
          targetPoints: record ? record.targetPoints : [],
        }}
        panelClassName={classes.panelClassName}
        steps={wizardSteps}
        {...props}
        redirect={"list"}
        hasFixedComponent
        fixedComponentVisibleSteps={[0, 1, 2, 3, 4, 5, 6]}
        FixedComponent={WizardChart}
        hideStepper
      >
        {/*<div className={classes.wizardPage}>

                    <FormDataConsumer>
                        {formDataProps => (
                            <Fragment>
                                <MarketSelect {...formDataProps} />
                                {formDataProps.formData && formDataProps.formData.market &&
                                <ReferenceField record={formDataProps.formData} source={"market"}  reference={"markets"}  {...props} >
                                  <FakeMarketSpy/>
                                </ReferenceField>}
                            </Fragment>
                        )}
                    </FormDataConsumer>
                    <SelectInput className={classes.formInput} source="positionType"
                                 label="resources.signalalerts.positionType"
                                 choices={positionTypes} allowEmpty={false} validate={requiredValidate} optionText={<PositionTypeRenderer classes={classes}/>}/>
                </div>

                <div className={classes.wizardPage}>

                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) =>

                            <Fragment>

                                <ReferenceInput validate={requiredValidate} label={"resources.alerts.pair"}
                                                source="pair"
                                                perPage={100}
                                                className={classes.formInput}
                                                reference="pairs"
                                                filter={{market: formData.market,
                                                    disbaled:{$ne:true},
                                                    status:{$ne:false},
                                                    removedfrommarket:{$ne:true}
                                                }}
                                                onChange={value => {

                                                   // console.log("1 ; " + value)
                                                }}
                                                filterToQuery={(searchText) => searchText && searchText.trim() !== '' ? ({
                                                    // symbol: {$search: "^" + searchText.trim()}

                                                    $or: [
                                                        {symbol: {$search: "^" + searchText.trim()}},

                                                    ]

                                                }) : ({fake: true})}
                                    //fullWidth={true}

                                >

                                    <AutocompleteInputInDialog
                                        optionText={(record) => <MarkText typedVal={typedPair} record={record}/>}
                                        // optionText={'symbol'}
                                        optionValue={'_id'}
                                        // className={classes.formInput}
                                        //   fullWidth={true}
                                        matchSuggestion={(filter, choice) => {
                                            return true
                                        }}


                                        inputText={(choice) => {
                                            formData.pairObject = choice

                                            return choice.symbol;
                                        }
                                        }
                                        shouldRenderSuggestions={(value) => {
                                            typedPair = value;
                                            return value && value.trim().length > 0;
                                        }}
                                        helperText={translate("resources.alerts.wizard.pairhelper", {marketname: getMarketName()})}
                                        options={{
                                            suggestionsContainerProps: {

                                                modifiers: {
                                                    preventOverflow: {
                                                        enabled: false
                                                    }
                                                },

                                            }
                                        }

                                        }
                                    />

                                </ReferenceInput>
                            </Fragment>
                        }
                    </FormDataConsumer>
                    <div className={classes.margin} />
                    <div className={classes.margin} />

                </div>*/}

        <div className={classes.wizardPage}>
          <EnterPriceForm />

          <TargetPointsForm />
          <PercentsForm />
          <StopLossForm />
        </div>

        <div className={classes.wizardPage}>
          <SummaryForm />
          {!!totalExAccounts && selectedPairObj && <TradeAccounts />}
          <CheckboxGroupInput
            style={{ display: "none" }}
            className={["no-cursor", classes.formInput]}
            label="resources.signalalerts.notiftypes"
            source="notiftypes"
            choices={NotifTypes}
            row={false}
            initialValue={["SMS"]}
            disabled
          />
        </div>
      </Wizard>
    </Create>
  );
};
const TradeAccounts = (props) => {
  const { values } = useFormState();
  const classes = useStyles();
  const form = useForm();
  let {
    pairObject,
    entryPrice,
    stopLoss,
    positionType,
    targetPoints,
    activeStep,
    markettradetype,
    leverage,
    tradetype,
    entryReset,
  } = values;
  return (
    <ArrayInput source="tradeAccounts" {...props}>
      <SimpleFormIterator className={classes.tradeAccountItem}>
        <ReferenceInput
          className={classes.exchangeAccountPicker}
          label="Exchange Account"
          source="exchangeAccount"
          validate={requiredValidate}
          reference="exchangeaccounts"
          filter={{
            disabled: { $ne: true },
            isValid: true,
            tradetype: tradetype,
            // market: marketIds[0], todo
          }}
        >
          <SelectInput
            optionText={(record) => {
              let result = record.name;

              if (tradetype === 0) {
                if (record.balance && record.balance[pairObject.quoteAsset]) {
                  result +=
                    " : " +
                    (record.balance[pairObject.quoteAsset].available
                      ? Number(
                          record.balance[pairObject.quoteAsset].available
                        ).toFixed(4)
                      : "") +
                    " " +
                    pairObject.quoteAsset;
                }
              } else if (tradetype === 1) {
                if (record.balance && record.balance.availableBalance) {
                  result +=
                    " : $" + Number(record.balance.availableBalance).toFixed(4);
                }
              }
              return result;
            }}
          />
        </ReferenceInput>
        <NumberInput
          className={classes.exchangeAccountPicker}
          label="Trade Percent"
          source="percent"
          initialValue={10}
          validate={[minValue(1), maxValue(100)]}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
/* <TextInput
                        source="name"
                        className={["no-cursor" , classes.formInput]}

                        validate={[required(),maxLength(10)]}
                        // fullWidth={true}
                        label={"resources.signalalerts.signalName"}
                        helperText={"resources.signalalerts.namehelper"}
                    />*/
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];
const valueValidate = [required(), minValue(0)];
const SectionTitle = (label) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export { SignalAlertsCreateWizard, PositionTypeRenderer };
