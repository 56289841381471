import React from "react";
import {
    Create,
    maxLength,
    minLength,
    regex,
    required,
    SimpleForm,
    TextInput,
    useLocale,
    useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import mobileValidate from "../shared/MobileValidate";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const STCategoriesCreate = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	return (
		<Create {...props} label={"resources.sms.createnew"}>
			<SimpleForm redirect={"list"}>
				<TextInput source="title" label="title" fullWidth={true} formClassName={classes.normalfield} />

				<TextInput
					source="desc"
					formClassName={classes.address}
					multiline={true}
					fullWidth={true}
					label="resources.sms.text"
				/>
			</SimpleForm>
		</Create>
	);
};

const mobileValidatation = [
	required(),
	mobileValidate,
	regex(/^([+]\d{1,3})?\d{10}$/, "Invalid mobile format"),
	minLength(11),
	maxLength(15),
];
const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default STCategoriesCreate;
