const MarketTypes = [
    {
        id : 0,
        name : "Exchange"
    },
    {
        id : 1,
        name : "Stocks"
    }
];
const TradeTypes = [
    {
        id : 0,
        name : "Spot"
    },
    {
        id : 1,
        name : "Futures"
    }
];
export {MarketTypes,TradeTypes}
