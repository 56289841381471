import React from "react";

import {useTranslate} from "react-admin";

import {useForm, useFormState} from "react-final-form";
import {makeStyles} from "@material-ui/core/styles";
import SignalCreateChart from "../../shared/tvchartinglibrary/TVChartContainer/SignalCreateChart";
import ChartActions from "./ChartActions";

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: "90vh",
		height: "100%",
		display: "flex",
	},
	formInput: {
		//    maxWidth:'100%'
		width: "100%",
		maxWidth: "500px",
		//  minWidth:'250px'
	},
	withSliderForm: {
		/*  width: '100%',
        maxWidth: "500px",
        marginTop: 5,*/
	},
	margin: {
		height: theme.spacing(5),
	},
	seperate: {
		height: theme.spacing(2),
	},
	lossPercent: {
		marginTop: theme.spacing(3),
		color: theme.palette.error.light,
	},
}));
const WizardChart = (props) => {
	const translate = useTranslate();
	const { values } = useFormState();

	const { pairObject, entryPrice, positionType } = values;
	const form = useForm();
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{!!entryPrice && !!pairObject && <SignalCreateChart form={form} record={values} pairObject={pairObject} />}
			<ChartActions />
		</div>
	);
};

export default WizardChart;
