import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import {Divider} from '@material-ui/core/';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {DateField, EditButton, Labeled, ReferenceField, TextField, useLocale, useTranslate} from 'react-admin';
import SignalTimeLine from './SignalAlertTimeLine';
import {EntryRangeField, LastStatusField, PositionTypeField, ProfitField, StopLossField} from './SignalAlertsList';
//import AvatarField from './AvatarField';
//import ColoredNumberField from './ColoredNumberField';

const useStyles = makeStyles(theme => ({
    root: {margin: '1em'},
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        lineHeight:"1.8",
        flexDirection: 'column',
    },
    titleTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    titleAvatar: {},
    titleAvatarStopLossed: {
        backgroundColor: theme.palette.error.main
    },
    titleAvatarTrending: {
        backgroundColor: theme.palette.success.main
    },
    pairTitleBold: {
        ...theme.typography.h6,
    },
    pairTitleSmall: {},
    pairMarket: {
        ...theme.typography.caption,
        color: theme.palette.grey[500]
    },
    signalName:{
        ...theme.typography.body1,
        color: theme.palette.grey[800]
    },
    disabledAlert:{
        ...theme.typography.subtitle1,

    },
    priceText:{

    },
    priceDate:{
        ...theme.typography.caption,
        color: theme.palette.grey[500]
    },
    createdAt:{
        ...theme.typography.caption,
        color: theme.palette.grey[400]
    },
    divider:{
        marginTop:5,
        marginBottom:5
    },
    triggeredAt:{
        ...theme.typography.body2,
    },
    notifby:{
        ...theme.typography.body2,
    },
    positionTypeRoot:{
        display:'flex',
        flexDirection:"row",
        alignItems:"center"
    },
    avatarRoot:{
        width:30,
        height:30,
        marginRight:5
    },
    positionTypeAvatarError: {
        backgroundColor: theme.palette.error.main
    },
    positionTypeAvatarSuccess: {
        backgroundColor: theme.palette.success.main
    },
    entryPriceRoot:{
        display:'flex',
        flexDirection:"row",
        alignItems:"center"
    },
    stopLossRoot:{
        display:'flex',
        flexDirection:"row",
        alignItems:"center"
    },
    normalAvatar: {},
    entryAvatarTriggered: {
        backgroundColor: theme.palette.primary.main
    },
    stopLossAvatarTriggered: {
        backgroundColor: theme.palette.error.main
    },
    noPadding:{
        padding:0
    },
    profitGreen:{
        color:theme.palette.success.main
    },
    profitRed:{
        color:theme.palette.error.main
    }
}));
const SimpleArrayField = ({ record,source }) => record ? (
    <span>
        {record[source].map((item,index) => (
            <span key={index}>{item}</span>
        ))}
    </span>
) : <span/>
const SymbolPriceInfo = ({record})=>{
    const locale = useLocale();
    const translate = useTranslate();
    const classes = useStyles();
    const {baseAsset, quoteAsset, latestPrice} = record;
return(
    <div>
        <div className={classes.priceText}>
    {translate('resources.alerts.mobileGrid.pairpricetext',{baseAsset, quoteAsset, latestPrice})}

    </div>
        <div className={classes.priceDate}>
            {translate('resources.alerts.mobileGrid.priceUpdatedAt')}
            <DateField source="latestPriceTime" record={record} locales={locale} showTime />
        </div>
    </div>
    )
}
const AlertsListItem = ({record, basePath}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const locale = useLocale();
    return (
        <Card className={classes.card}>
            <CardHeader
                title={
                    <div className={classes.cardTitleContent}>
                        <div className={classes.titleTextContainer}>
                            <ReferenceField basePath={basePath} record={record} source="pair"
                                            label={"resources.alerts.pair"} reference="pairs" link={false}>
                                <TextField source="symbol" className={classes.pairTitleBold}/>
                            </ReferenceField>

                            <ReferenceField basePath={basePath} record={record} source="market"
                                            label={"resources.alerts.market"} reference="markets" link={false}>
                                <TextField source="name" className={classes.pairMarket}/>

                            </ReferenceField>
                            {record.disabled &&
                            <div className={classes.disabledAlert}>
                                { translate(
                                    'resources.alerts.disabled'
                                )}

                            </div>}
                            <div className={classes.signalName}>{record.name}</div>

                        </div>
                        <EditButton

                            basePath={basePath}
                            record={record}
                        />
                    </div>
                }
                /*avatar={<Avatar
                    className={!record.entryPrice.triggeredAt ? classes.titleAvatar : record.stopLoss.triggeredAt ? classes.titleAvatarStopLossed : classes.titleAvatarTrending}>
                    {record.positionType === "buy" &&
                    <TrendingUpIcon/>
                    }
                    {record.positionType === "sell" &&
                    <TrendingDownIcon/>
                    }

                </Avatar>}*/
            />
            <CardContent className={classes.cardContent}>



                <Divider className={classes.divider}/>
                <div>

                    <ReferenceField basePath={basePath} record={record} source="pair" label={"resources.signalalerts.pair"}
                                    reference="pairs" link={false}>
                       <SymbolPriceInfo />
                    </ReferenceField>



                </div>
                <Divider className={classes.divider}/>

                <div>
                    <Labeled label="resources.signalalerts.positionType">
                   <PositionTypeField record={record} classes={classes}/>
                    </Labeled>

                </div>

                <Divider className={classes.divider}/>
                <div>
                    <Labeled label="resources.signalalerts.totalProfit">
                        <ProfitField    record={record}  classes={classes} />
                    </Labeled>

                </div>

                <Divider className={classes.divider}/>
                <div>
                    <Labeled label="resources.signalalerts.entryrange">
                        <EntryRangeField    record={record}  classes={classes} />
                    </Labeled>

                </div>

                <Divider className={classes.divider}/>
                <div>
                    <Labeled label="resources.signalalerts.stopLoss">
                        <StopLossField record={record}  classes={classes} />
                    </Labeled>

                </div>

                <Divider className={classes.divider}/>
                <div>
                    <Labeled label="resources.signalalerts.lastStatus">
                        <LastStatusField record={record} classes={classes} translate={translate} locale={locale} />
                    </Labeled>

                </div>

                <Divider className={classes.divider}/>
                <div className={classes.notifby}>
                    {translate(
                        record.triggered ?'resources.alerts.mobileGrid.notifedby':  'resources.alerts.mobileGrid.notifby'
                        ,)}
                    <SimpleArrayField record={record} source="notiftypes" link={false} />
                </div>

            </CardContent>
            <CardActions>

                <div className={classes.createdAt}>

                    {translate(
                        'resources.alerts.mobileGrid.createdAt'
                    )}
                    &nbsp;
                    <DateField record={record} source="createdAt" showTime locales={locale}/>
                </div>
            </CardActions>
            <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.noPadding} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{translate("resources.signalalerts.detailstimeline")}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.noPadding}>
                    <SignalTimeLine record={record} id={record._id} />
                </AccordionDetails>
            </Accordion>

        </Card>
    )
}
const SignalAlertsListMobileGrid = ({ids, data, basePath}) => {
    const translate = useTranslate();
    const classes = useStyles();

    if (!ids || !data) {
        return null;
    }

    return (
        <div className={classes.root}>
            {ids.map(id => (
                <AlertsListItem record={data[id]} basePath={basePath} key={id}/>
            ))}
        </div>
    );
};

SignalAlertsListMobileGrid.defaultProps = {
    data: {},
    ids: [],
};


export default SignalAlertsListMobileGrid;