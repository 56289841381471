import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import VendorSubscriptionsCreate from './VendorSubscriptionsCreate';
import VendorSubscriptionsEdit from './VendorSubscriptionsEdit';
import {VendorSubscriptionsList} from './VendorSubscriptionsList';

export default {
    create:VendorSubscriptionsCreate,
    edit:VendorSubscriptionsEdit,
    list:VendorSubscriptionsList,
    icon: AddShoppingCartIcon,
};
