import React, {memo, useState} from "react";
import {Box} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/CancelRounded";
import TuneIcon from "@material-ui/icons/Tune";
import {Link as RouterLink} from "react-router-dom";
import CloseSignalDialog from "./CloseSignalDialog";
import DeleteSignalBtn from "./DeleteSignalDialog";

const styles = (theme) => ({
	iconButtonRoot: {},
	icon: {
		fontSize: "1.1em",
	},
	root: { display: "flex", flexDirection: "column" },
});
const SignalActions = (props) => {
	const { classes, record = {} } = props;
	const { _id, positionType, closed, opened, tradetype, vendor } = record;

	const linkResource = !!vendor
		? "vendorsignals|tradetype|" + tradetype + "/" + _id + "/show"
		: "signalalerts|tradetype|" + tradetype + "/" + _id + "/";
	const [closeDialog, setCloseDialog] = useState(false);
	return (
		<Box className={classes.root}>
			<Tooltip title="Manage" enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000}>
				<IconButton
					className={classes.iconButtonRoot}
					aria-label="Manage"
					color={"primary"}
					component={RouterLink}
					to={linkResource}
				>
					<TuneIcon className={classes.icon} />
				</IconButton>
			</Tooltip>
			{!!opened && !closed && !vendor && (
				<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000} title="Close Signal & All Trades">
					<IconButton
						className={classes.iconButtonRoot}
						aria-label="Close"
						color={"secondary"}
						size={"medium"}

					>
						<CancelIcon className={classes.icon} onClick={() => setCloseDialog(true)} />
						<CloseSignalDialog signal={record} openDialog={closeDialog} setOpenDialog={setCloseDialog} />
					</IconButton>
				</Tooltip>
			)}
			{!opened && !vendor && (
				<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000} title="Delete Signal">
					<IconButton className={classes.iconButtonRoot} aria-label="delete"  >
						<DeleteIcon className={classes.icon} color={"error"} onClick={() => setCloseDialog(true)} />
						<DeleteSignalBtn signal={record} openDialog={closeDialog} setOpenDialog={setCloseDialog} />
					</IconButton>
				</Tooltip>
			)}
		</Box>
	);
};
export default memo(withStyles(styles, { name: "SignalActions" })(SignalActions));
