import React, {memo} from "react";
import {Box, Tooltip, Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import MarketAvatarField from "../shared/MarketAvatarField";
import FuturesMetaField from "./FuturesMetaField";

const styles = (theme) => ({
	root: { display: "flex", alignItems: "center" },
});
const SignalInfoField = (props) => {
	const { classes, record } = props;

	return (
		<Box className={classes.root}>
			{!!record?.name && (
				<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000} title={"Signal unique code"}>
					<Box minWidth={46}>
						<Typography color={"textSecondary"} variant={"subtitle2"}>
							{record?.name}
						</Typography>
					</Box>
				</Tooltip>
			)}
			<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000} title={record?.market?.name}>
				<Box ml={1}>
					<MarketAvatarField size={20} addLabel={false} record={record?.market} />
				</Box>
			</Tooltip>

			{record?.tradetype === 1 && (
				<Box ml={1}>
					<FuturesMetaField record={record} />
				</Box>
			)}
		</Box>
	);
};
export default memo(withStyles(styles, { name: "VendorOrUserAvatar" })(SignalInfoField));
