import React, {Fragment} from "react";

import {DateField, ReferenceField, TextField, useLocale, useTranslate} from "react-admin";

import {useForm} from "react-final-form";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import SignalEditChart from "../../shared/tvchartinglibrary/TVChartContainer/SignalEditChart";

const useStyles = makeStyles((theme) => ({
	formInput: {
		//    maxWidth:'100%'
		width: "100%",
		maxWidth: "500px",
		//  minWidth:'250px'
	},
	withSliderForm: {
		width: "100%",
		maxWidth: "500px",
		marginTop: 5,
	},
	margin: {
		height: theme.spacing(5),
	},
	seperate: {
		height: theme.spacing(2),
	},
	avatarSuccess: {
		backgroundColor: theme.palette.success.main,
	},
	avatarError: {
		backgroundColor: theme.palette.error.main,
	},
	avatarGrey: {
		backgroundColor: theme.palette.grey[500],
	},
	avatarPrimary: {
		backgroundColor: theme.palette.primary.main,
	},
	thumbContent: {
		color: "white",
		fontSize: "1em",
	},
	addTPButton: {
		margin: theme.spacing(2),
	},
	positionTypeRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	avatarRoot: {
		width: 30,
		height: 30,
		marginRight: 5,
	},
}));
const SignalInformationEdit = ({ record, pairObject, ...props }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const form = useForm();

	const getTitle = () => {
		return pairObject.symbol;
	};
	const GetSubTitle = () => {
		return (
			<ReferenceField record={record} {...props} source="market" reference="markets" link={false}>
				<TextField source="name" />
			</ReferenceField>
		);
	};
	const GetPairPrice = () => {
		const { baseAsset, quoteAsset, latestPrice } = pairObject;
		const locale = useLocale();
		return (
			<Fragment>
				{translate("resources.alerts.wizard.pairpricetext", { baseAsset, quoteAsset, latestPrice })}
				<DateField source="latestPriceTime" record={pairObject} locales={locale} showTime />
			</Fragment>
		);
	};
	return (
		<Card>
			<CardContent>
				<Box height={800}>
					<SignalEditChart form={form} record={record} pairObject={pairObject} />
				</Box>
			</CardContent>
		</Card>
	);
};
const PairField = ({ record }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const locale = useLocale();

	if (!record) {
		return <Fragment />;
	}
	let pair = record;
	let baseAsset,
		quoteAsset,
		latestPrice,
		latestPriceTime,
		symbol = "";

	baseAsset = pair.baseAsset;
	quoteAsset = pair.quoteAsset;
	latestPrice = pair.latestPrice;
	latestPriceTime = pair.latestPriceTime;
	symbol = pair.symbol;

	return (
		<Fragment>
			<Typography variant="subtitle1">{symbol}</Typography>
			<Typography variant="caption" color={"secondary"}>
				{translate("resources.alerts.wizard.pairpricetext", { baseAsset, quoteAsset, latestPrice })}
				<DateField source="latestPriceTime" record={pair} locales={locale} showTime />
			</Typography>
		</Fragment>
	);
};
export default SignalInformationEdit;
