import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles, ThemeOptions, ThemeProvider} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import BackIcon from "@material-ui/icons/ChevronLeftOutlined";
import {createForm} from "final-form";
import parsePhoneNumber, {
	isValidPhoneNumber,
	parseIncompletePhoneNumber,
	validatePhoneNumberLength,
} from "libphonenumber-js";
import PropTypes from "prop-types";
import React, {useEffect, useRef, useState} from "react";
import {createMuiTheme, Notification, useLogin, useNotify, useRedirect, useTranslate} from "react-admin";
import {Field, withTypes} from "react-final-form";
import {Link as RouterLink, useLocation} from "react-router-dom";
import bgImage from "../assets/images/dealerifyConcept.svg";

import MuiPhoneNumber from "../shared/phoneInput";

import dealerifyDark from "../themes/dealerifyDark";

const logoWhite = "./images/dealerifyWhite.svg";
const useStyles = makeStyles((theme) => ({
	commentText: {
		//marginTop: '0.2em',
		marginBottom: "0.2em",
		display: "flex",
		justifyContent: "center",
		color: theme.palette.grey[500],
	},
	middleText: {
		marginTop: "1em",
		marginBottom: "0.2em",
		display: "flex",
		justifyContent: "center",
		color: theme.palette.grey[500],
	},
	main: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		alignItems: "center",
		justifyContent: "flex-start",
		backgroundImage: "url(" + bgImage + ")",
		backgroundColor: theme.palette.background.default,
		backgroundRepeat: "no-repeat",
		backgroundSize: "auto",
		backgroundPosition: "140% top",
		backgroundOrigin: "border-box",
		backgroundBlendMode: "overlay",
	},
	card: {
		minWidth: 350,
		minHeight: 400,
		marginTop: "6em",
		display: "flex",
		flexDirection: "column",
	},
	avatar: {
		margin: "1em",
		display: "flex",
		justifyContent: "center",
	},
	icon: {
		backgroundColor: theme.palette.secondary.main,
	},
	hint: {
		marginTop: "1em",
		display: "flex",
		justifyContent: "center",
		color: theme.palette.text.hint,
	},
	form: {
		padding: "0 1em 1em 1em",
	},
	input: {
		marginTop: "1em",
		minHeight: 100,
	},
	actions: {
		padding: "0 1em .5em 1em",
	},
	countryBtn: {
		fontSize: 16,
		fontFamily: "unset",
		color: "inherit",
	},
}));

const renderInput = ({
	meta: { touched, error } = { touched: false, error: undefined },
	input: { ...inputProps },
	...props
}) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />;
const renderPhoneInput = ({
	meta: { touched, error } = { touched: false, error: undefined },
	input: { ...inputProps },
	...props
}) => (
	<MuiPhoneNumber error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
);
interface FormValues {
	phonenumber?: string;
	password?: string;
	uniqueId?: string;
}

const { Form } = withTypes<FormValues>();

const getDefaultPhoneNumber = () => {
	return localStorage.getItem("defaultPhoneNumber");
};
const setDefaultPhoneNumber = (phonenumber: any) => {
	localStorage.setItem("defaultPhoneNumber", phonenumber);
};
function useQuery() {
	return new URLSearchParams(useLocation().search.toLowerCase());
}
const Login = () => {
	const [loading, setLoading] = useState(false);

	const [step, setStep] = useState(0);
	const translate = useTranslate();
	const classes = useStyles();
	const notify = useNotify();
	const login = useLogin();
	const location = useLocation<{ nextPathname: string } | null>();
	const redirect = useRedirect();
	const query = useQuery();
	const pnInQuery = query.get("pn");
	useEffect(() => {
		if (!!pnInQuery && parsePhoneNumber(pnInQuery)?.isValid()) {
			formRef.current.change("phonenumber", pnInQuery);
			setStep(1);
		} else {
			formRef.current.change("password", "");
			setStep(0);
		}
	}, [pnInQuery]);
	useEffect(() => {
		if (!pnInQuery) {
			let defaultPhoneNumber = getDefaultPhoneNumber();
			if (defaultPhoneNumber && parsePhoneNumber(defaultPhoneNumber)?.isValid()) {
				let parsedNumber = encodeURIComponent(parsePhoneNumber(defaultPhoneNumber)?.number?.toString());
				redirect("/login?pn=" + parsedNumber);
			}
		}
	}, []);
	//  const currentItem = find(FLAG_SELECTOR_OPTION_LIST, { id: areaCode })[0];
	const handleSubmit = (auth: FormValues) => {
		//  console.log(location)
		if (step === 0) {
			if (parsePhoneNumber(auth.phonenumber)?.isValid()) {
				let parsedNumber = encodeURIComponent(parsePhoneNumber(auth.phonenumber)?.number?.toString());
				setDefaultPhoneNumber(parsePhoneNumber(auth.phonenumber)?.number?.toString());
				redirect("/login?pn=" + parsedNumber);
			}
			return;
		}
		setLoading(true);
		auth.uniqueId = parsePhoneNumber(auth.phonenumber)?.number + "@ADMIN";
		login(
			{ username: auth.uniqueId, password: auth.password },
			location.state ? location.state.nextPathname : "/"
		).catch((error: Error) => {
			setLoading(false);
			notify(
				typeof error === "string"
					? error
					: typeof error === "undefined" || !error.message
					? "ra.auth.sign_in_error"
					: error.message,
				"warning"
			);
		});
	};

	const validate = (values: FormValues) => {
		const errors: FormValues = {};

		switch (step) {
			case 0:
				if (!values.phonenumber) {
					errors.phonenumber = translate("ra.validation.required");
					return errors;
				} else if (
					validatePhoneNumberLength(values.phonenumber) === "TOO_SHORT" ||
					parseIncompletePhoneNumber(values.phonenumber)?.length < 10
				) {
					errors.phonenumber = "Enter phone number completely";
					return errors;
				} else if (!isValidPhoneNumber(values.phonenumber) || validatePhoneNumberLength(values.phonenumber)) {
					errors.phonenumber = "Invalid mobile number";
				}

				break;
			case 1:
				if (!values.phonenumber) {
					errors.phonenumber = translate("ra.validation.required");
					return errors;
				} else if (
					validatePhoneNumberLength(values.phonenumber) === "TOO_SHORT" ||
					parseIncompletePhoneNumber(values.phonenumber)?.length < 10
				) {
					errors.phonenumber = "Enter phone number completely";
					return errors;
				} else if (!isValidPhoneNumber(values.phonenumber) || validatePhoneNumberLength(values.phonenumber)) {
					errors.phonenumber = "Invalid mobile number";
				}
				if (!values.password) {
					errors.password = translate("ra.validation.required");
				}
				break;
		}

		return errors;
	};

	const formRef = useRef(
		createForm({
			onSubmit: handleSubmit,
			// validate: validate
		})
	);
	return (
		<Form
			form={formRef.current}
			onSubmit={handleSubmit}
			validate={validate}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<div className={classes.main}>
						<Card className={classes.card}>
							<div className={classes.avatar}>
								<Box>
									<img src={logoWhite} style={{ height: 35 }} alt={"Dealerify"} />
								</Box>
							</div>

							{step === 0 && (
								<Box px={1} display={"flex"} alignItems={"center"} height={"50px"}>
									<Typography align={"center"} style={{ flexGrow: 1 }}>
										Enter your mobile number
									</Typography>
								</Box>
							)}
							{step === 1 && (
								<Box px={1} display={"flex"} alignItems={"center"} height={"50px"}>
									<IconButton component={RouterLink} to={"/login"}>
										<BackIcon />
									</IconButton>
									<Typography style={{ fontWeight: 800 }} align={"center"}>
										<Typography component={"span"} color={"textSecondary"}>
											{" (" +
												parsePhoneNumber(formRef.current?.getState()?.values["phonenumber"])?.country +
												") "}
										</Typography>
										{parsePhoneNumber(
											formRef.current?.getState()?.values["phonenumber"]
										)?.formatInternational()}
									</Typography>
								</Box>
							)}
							<div className={classes.form}>
								<Box display={step === 1 && "none"} className={classes.input}>
									<Field
										name="phonenumber"
										id={"username"}
										autoFocus={true}
										// @ts-ignore
										component={renderPhoneInput}
										label={"International phone number"}
										disabled={loading}
										placeholder={translate("loginPage.examplephonenumber")}
										type="tel"
										defaultCountry={"us"}
										disableAreaCodes={true}
									/>
								</Box>
								{step === 1 && (
									<div className={classes.input}>
										<Field
											name="password"
											autoFocus={true}
											// @ts-ignore
											component={renderInput}
											label={translate("ra.auth.password")}
											type="password"
											disabled={loading}
											id={"passwordfield"}
											autoComplete={"password"}
										/>
									</div>
								)}
							</div>

							<CardActions className={classes.actions}>
								<Button
									variant="contained"
									type="submit"
									color="primary"
									disabled={loading || formRef.current?.getState()?.hasValidationErrors}
									fullWidth
								>
									{loading && <CircularProgress size={25} thickness={2} />}
									{step === 0 && <Typography>Next</Typography>}
									{step === 1 && <Typography>Login</Typography>}
								</Button>
							</CardActions>
							<Box flexGrow={1} />
						</Card>
						<Notification />
					</div>
				</form>
			)}
		/>
	);
};

Login.propTypes = {
	authProvider: PropTypes.func,
	previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
/*
const LoginWithTheme = (props: any) => (
	<ThemeProvider theme={createMuiTheme(lightTheme)}>
		<Login {...props} />
	</ThemeProvider>
);
*/
const LoginWithTheme = (props: any) => {
	const theme = dealerifyDark as ThemeOptions;
	return (
		<ThemeProvider theme={createMuiTheme(theme)}>
			<Login {...props} />
		</ThemeProvider>
	);
};
export default LoginWithTheme;
