import {useSelector} from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import themes from "./index";
import {AUTO_THEME_ID} from "../consts";
import config from "../config";

const useCurrentTheme = () => {
	const prefersLightMode = useMediaQuery("(prefers-color-scheme: light)");
	const theme = useSelector((state) => {
		if (state.theme === AUTO_THEME_ID) {
			return prefersLightMode ? themes["dealerifyLight"] : themes["dealerifyDark"];
		}
		const themeName = state.theme || Object.keys(themes).find((t) => themes[t].themeName === config.defaultTheme) || "dealerifyDark";
		return themes[themeName];
	});

	return theme;
};

export default useCurrentTheme;
