import React from "react";
import {
	BooleanField,
	BooleanInput,
	Edit,
	email,
	ImageField,
	ImageInput,
	maxValue,
	minLength,
	minValue,
	Pagination,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
	TopToolbar,
	useLocale,
	useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {MarketTypes, TradeTypes} from "./MarketsEnum";

import AddUsersToChannelByChannelBtn from "../shared/AddUsersToChannelByChannelBtn";
import {JsonField} from "@bb-tech/ra-components";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);
const Title = ({ record }) => {
	const translate = useTranslate();
	return (
		<span>
			{" "}
			{translate("resources.markets.edittitle")} {record ? `${record.name}` : ""}
		</span>
	);
};

const MarketsEdit = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const translate = useTranslate();
	return (
		<Edit {...props} title={<Title />}>
			<SimpleForm>
				<TextInput source="name" label="resources.markets.name" validate={requiredValidate} />
				<TextInput source="ccxtId" validate={requiredValidate} />
				<TextInput source="websiteURL" label="resources.markets.websiteURL" validate={requiredValidate} />
				<TextInput source="tvDataURL" label="resources.markets.tvDataURL" validate={requiredValidate} />
				<TextInput source="desc" label="resources.markets.desc" />
				<TextInput source="helpUrl" />
				<TextInput source="className" label="resources.markets.className" validate={requiredValidate} />
				<ImageInput
					source="avatar"
					label={"resources.vendors.avatar"}
					maxSize={1000000}
					accept=".jpg,.png,.jpeg"
					placeholder={<p>{translate("resources.vendors.dropimages")}</p>}
				>
					<ImageField source="src" title="title" />
				</ImageInput>
				<SelectInput
					label="resources.markets.markettype"
					source="markettype"
					choices={MarketTypes}
					validate={requiredValidate}
				/>
				<SelectInput
					label="resources.markets.tradetype"
					source="tradetype"
					choices={TradeTypes}
					validate={requiredValidate}
				/>
				<BooleanInput source="canTrade" />
				<BooleanInput source="canCopyTrade" />
				<BooleanInput source="passwordRequired" />
				<BooleanField source={"wsConnected"} />
				<JsonField source={"wsSubscriptions"} />
			</SimpleForm>
		</Edit>
	);
};
const totValidate = [required(), minValue(10), maxValue(600)];
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];
const EditActions = ({ basePath, data, resource }) => (
	<TopToolbar>
		<AddUsersToChannelByChannelBtn record={data} basePath={basePath} />
	</TopToolbar>
);

const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const RFPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const Separator = () => <Box pt="1em" />;

export default MarketsEdit;
