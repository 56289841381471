import React from "react";
import {
	AutocompleteInput,
	Create,
	email,
	maxValue,
	minLength,
	minValue,
	ReferenceInput,
	required,
	SimpleForm,
	TextField,
	TextInput,
	useLocale,
	useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const PairsCreate = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	return (
		<Create {...props} label={"resources.pairs.createnew"}>
			<SimpleForm redirect={"list"}>
				<ReferenceInput
					validate={requiredValidate}
					label={"resources.pairs.market"}
					source="market"
					perPage={10}
					reference="markets"
					allowEmpty={false}
					filterToQuery={(searchText) =>
						searchText.trim() !== ""
							? {
									name: { $search: searchText },
							  }
							: null
					}
				>
					<AutocompleteInput optionText={"name"} allowEmpty={false} optionValue={"_id"} />
				</ReferenceInput>
				<ReferenceInput source="market" label={"resources.pairs.market"} reference="markets">
					<TextField source="name" />
				</ReferenceInput>
				<TextInput source="symbol" label="resources.pairs.symbol" validate={requiredValidate} />
				<TextInput source="baseAsset" label="resources.pairs.baseAsset" validate={requiredValidate} />
				<TextInput source="quoteAsset" label="resources.pairs.quoteAsset" validate={requiredValidate} />
			</SimpleForm>
		</Create>
	);
};
const totValidate = [required(), minValue(10), maxValue(600)];
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];

const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default PairsCreate;
