import React, {Fragment, useCallback, useEffect, useState} from "react";
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    Filter,
    List,
    ListContextProvider,
    ReferenceField,
    ReferenceInput,
    SelectField,
    ShowButton,
    TextField,
    useGetList,
    useListContext,
    useLocale,
    useTranslate,
} from "react-admin";
import {Divider, makeStyles, Tab, Tabs, useMediaQuery} from "@material-ui/core";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import {ConditionTypes} from "./ATUFPositionsEnum";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";
import {LastStatus} from "./ATUFPositionsShow";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import {AvatarGroup} from "@material-ui/lab";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import SignalStatus from "../vendorsignals/SignalStatus";
import MarketAvatarField from "../shared/MarketAvatarField";

const StatusFieldColored = ({ record }) => (
	<TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);
const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<ReferenceInput
				alwaysOn
				label={"resources.observes.pair"}
				source="pair"
				perPage={10}
				reference="pairs"
				allowEmpty={true}
				shouldRenderSuggestions={(value) => {
					return value && value.trim().length > 0;
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								symbol: { $search: searchText },
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={"symbol"}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.pairs.allpairs"}
					alwaysOn
				/>
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				label={"resources.signalalerts.client"}
				source="user"
				perPage={10}
				reference="users"
				allowEmpty={true}
				filter={{
					usertype: { $ne: "ADMIN" },
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={(record) =>
						record && record.firstName
							? record.firstName + " " + record.lastName
							: translate("resources.observes.allusers")
					}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
		</Filter>
	);
};
const SimpleArrayField = ({ record, source }) =>
	record ? (
		<div>
			{record[source].map((item, index) => (
				<span key={index}>{item}</span>
			))}
		</div>
	) : (
		<div />
	);
const ConditionField = ({ record }) => {
	if (!record) {
		return <Fragment></Fragment>;
	}
	return (
		record && (
			<Fragment>
				<SelectField record={record} source="condition.conditionType" choices={ConditionTypes} />
				{record.condition.args}
			</Fragment>
		)
	);
};
const useStyles = makeStyles((theme) => ({
	entryPriceRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	stopLossRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	positionTypeRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	avatarRoot: {
		width: 30,
		height: 30,
		marginRight: 5,
	},
	iconRoot: {
		fontSize: "1.1rem",
	},
	normalAvatar: {},
	entryAvatarTriggered: {
		backgroundColor: theme.palette.primary.main,
	},
	stopLossAvatarTriggered: {
		backgroundColor: theme.palette.error.main,
	},

	positionTypeAvatarError: {
		backgroundColor: theme.palette.error.main,
		borderRadius: 5,
	},
	positionTypeAvatarSuccess: {
		backgroundColor: theme.palette.success.main,
		borderRadius: 5,
	},
	profitGreen: {
		color: theme.palette.success.main,
	},
	profitRed: {
		color: theme.palette.error.main,
	},
	maxWidth100: {
		width: 120,
	},
}));
const tabs = [
	{ id: "open", name: "open" },
	{ id: "pending", name: "pending" },
	{ id: "closed", name: "closed" },
	{ id: "cancelled", name: "cancelled" },
];

const getStatusObject = (status) => {
	switch (status) {
		case "closed":
			return { opened: true, closed: true };

		case "open":
			return { opened: true, closed: false };

		case "cancelled":
			return { opened: false, closed: true };

		case "pending":
			return { opened: false, closed: false };
	}
};
const getStatusFilter = (filter) => {
	if (filter.opened && filter.closed) {
		return "closed";
	}
	if (filter.opened && !filter.closed) {
		return "open";
	}
	if (!filter.opened && !filter.closed) {
		return "pending";
	}
	if (!filter.opened && filter.closed) {
		return "cancelled";
	}
};
const useGetTotals = (filterValues) => {
	const { total: totalOpen } = useGetList(
		"atufuturespositions",
		{ perPage: 1, page: 1 },
		{ field: "id", order: "ASC" },
		{ ...filterValues, opened: true, closed: false }
	);
	const { total: totalPending } = useGetList(
		"atufuturespositions",
		{ perPage: 1, page: 1 },
		{ field: "id", order: "ASC" },
		{ ...filterValues, opened: false, closed: false }
	);
	const { total: totalCancelled } = useGetList(
		"atufuturespositions",
		{ perPage: 1, page: 1 },
		{ field: "id", order: "ASC" },
		{ ...filterValues, opened: false, closed: true }
	);
	const { total: totalClosed } = useGetList(
		"atufuturespositions",
		{ perPage: 1, page: 1 },
		{ field: "id", order: "ASC" },
		{ ...filterValues, opened: true, closed: true }
	);

	return {
		open: totalOpen ? totalOpen : 0,
		pending: totalPending ? totalPending : 0,
		cancelled: totalCancelled ? totalCancelled : 0,
		closed: totalClosed ? totalClosed : 0,
	};
};
const useDatagridStyles = makeStyles({
	total: { fontWeight: "bold" },
});
const SignalStatusExpand = ({ record, ...props }) => {
	if (!record || !record.vendorSignal) {
		return <span />;
	}
	return (
		<Box m={3}>
			<ReferenceField
				record={record}
				{...props}
				source="vendorSignal"
				label={"resources.atspositions.vendorSignal"}
				reference="vendorsignals"
				link={false}
			>
				<SignalStatus />
			</ReferenceField>
		</Box>
	);
};
const ATUFPositionsTabs = (props) => {
	const listContext = useListContext();
	const { ids, filterValues, setFilters, displayedFilters } = listContext;
	const translate = useTranslate();
	const locale = useLocale();
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	const classes = useDatagridStyles();
	const isSmall = false; // useMediaQuery(theme => theme.breakpoints.down('sm'));
	const [pendingOrders, setPendingOrders] = useState([]);
	const [openOrders, setOpenOrders] = useState([]);
	const [closedOrders, setClosedOrders] = useState([]);
	const [canceledOrders, setCanceledOrders] = useState([]);

	const totals = useGetTotals(filterValues);
	const handleChange = useCallback(
		(event, value) => {
			setFilters && setFilters({ ...filterValues, ...getStatusObject(value) }, displayedFilters);
		},
		[displayedFilters, filterValues, setFilters]
	);
	useEffect(() => {
		if (ids && ids !== getStatusFilter(filterValues)) {
			switch (getStatusFilter(filterValues)) {
				case "closed":
					setClosedOrders(ids);
					break;
				case "open":
					setOpenOrders(ids);
					break;
				case "cancelled":
					setCanceledOrders(ids);
					break;
				case "pending":
					setPendingOrders(ids);
					break;
			}
		}
	}, [ids, filterValues.opened, filterValues.closed]);
	return (
		<Fragment>
			<Tabs
				variant="fullWidth"
				centered
				value={getStatusFilter(filterValues)}
				indicatorColor="primary"
				onChange={handleChange}
			>
				{tabs.map((choice) => (
					<Tab key={choice.id} label={`${choice.name} (${totals[choice.name]})`} value={choice.id} />
				))}
			</Tabs>
			<Divider />
			{getStatusFilter(filterValues) === "closed" && (
				<ListContextProvider value={{ ...listContext, ids: closedOrders }}>
					<ClosedOrdersDataGrid />
				</ListContextProvider>
			)}
			{getStatusFilter(filterValues) === "cancelled" && (
				<ListContextProvider value={{ ...listContext, ids: canceledOrders }}>
					<ClosedOrdersDataGrid />
				</ListContextProvider>
			)}
			{getStatusFilter(filterValues) === "pending" && (
				<ListContextProvider value={{ ...listContext, ids: pendingOrders }}>
					<PendingOrdersDataGrid />
				</ListContextProvider>
			)}
			{getStatusFilter(filterValues) === "open" && (
				<ListContextProvider value={{ ...listContext, ids: openOrders }}>
					<OpenedOrdersDataGrid />
				</ListContextProvider>
			)}
		</Fragment>
	);
};
const ATUFPositionsList = (props) => {
	const translate = useTranslate();
	return (
		<List
			{...props}
			filterDefaultValues={{ opened: true, closed: false }}
			title={translate("resources.atufuturespositions.listtitle")}
			perPage={25}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			bulkActionButtons={false}
			filters={<ListFilter />}
		>
			<ATUFPositionsTabs />
		</List>
	);
};
const PairWithIconField = ({ record, classes }) => {
	if (!record || !record.baseAsset) {
		return <div />;
	}
	return (
		<Box display={"flex"} alignItems={"center"} flexDirection={"Column"}>
			<AvatarGroup>
				<Avatar
					className={classes.avatarRoot}
					alt={record.baseAsset}
					src={"https://cdn.dealerify.io/asseticons/svg/color/" + record.baseAsset.toLowerCase() + ".svg"}
				/>
				<Avatar
					className={classes.avatarRoot}
					alt={record.quoteAsset}
					src={"https://cdn.dealerify.io/asseticons/svg/color/" + record.quoteAsset.toLowerCase() + ".svg"}
				/>
			</AvatarGroup>
			<Box
				display={"flex"}
				alignItems={"center"}
				flexDirection={"row"}
				justifyContent={"space-evenly"}
				width={"100%"}
				fontSize={"0.9em"}
			>
				<Typography variant={"caption"}>{record.baseAsset}</Typography>
				<Typography variant={"caption"}>/</Typography>
				<Typography variant={"caption"}>{record.quoteAsset}</Typography>
			</Box>
		</Box>
	);
};
const PositionField = ({ record, classes, ...props }) => {
	if (!record || !record.positionType) {
		return <div />;
	}
	return (
		<Box display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"} minWidth={110}>
			<PositionTypeField classes={classes} record={record} />
			<ReferenceField
				record={record}
				{...props}
				source="pair"
				label={"resources.signalalerts.pair"}
				reference="pairs"
				link={false}
			>
				<PairWithIconField classes={classes} />
			</ReferenceField>
		</Box>
	);
};

const PositionTypeField = ({ record, classes }) => {
	if (!record || !record.positionType) {
		return <div />;
	}

	return (
		<Badge
			overlap="circle"
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			// badgeContent={record.leverage ? <Box className={classes.leverageBadge}> <Typography variant={"caption"}>{record.leverage}x</Typography></Box>:null}
			color="error"
			badgeContent={record.leverage ? record.leverage + "x" : null}
		>
			<Avatar
				className={[
					record.positionType === "buy" ? classes.positionTypeAvatarSuccess : classes.positionTypeAvatarError,
				]}
			>
				{record.positionType === "buy" ? "B" : "S"}
			</Avatar>
		</Badge>
	);
};
const OpenedOrdersDataGrid = (props) => {
	const locale = useLocale();
	const classes = useStyles();

	return (
		<Datagrid optimized expand={<SignalStatusExpand />} {...props}>
			<ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
				<NameWithAvatarField />
			</ReferenceField>
			<DateField source="createdAt" label="resources.alerts.createdAt" locales={locale} showTime />
			<ReferenceField source="market" label={"Exchange"} reference="markets" link={false}>
				<MarketAvatarField />
			</ReferenceField>
			<PositionField classes={classes} label={"Position"} cellClassName={classes.maxWidth100} />
			<LastStatus label={"resources.atufuturespositions.lastStatus"} />

			<ReferenceField
				source="vendorSignal"
				label={"resources.atufuturespositions.vendorSignal"}
				reference="vendorsignals"
			>
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField
				source="vendorSignal"
				label={"resources.atufuturespositions.vendorName"}
				reference="vendorsignals"
				link={false}
			>
				<ReferenceField
					source="vendor"
					label={"resources.vendorsubscriptions.vendor"}
					reference="users|usertype|VENDOR"
					link={false}
				>
					<NickNameWithAvatarField />
				</ReferenceField>
			</ReferenceField>
			<ReferenceField
				source="exchangeAccount"
				label={"resources.atufuturespositions.exchangeAccount"}
				reference="exchangeaccounts"
			>
				<TextField source="name" />
			</ReferenceField>

			<DateField source="openedAt" showTime locales={locale} />

			<ShowButton label={"resources.alerts.manage"} />
		</Datagrid>
	);
};
/**/
const ClosedOrdersDataGrid = (props) => {
	const locale = useLocale();
	const classes = useStyles();

	return (
		<Datagrid optimized expand={<SignalStatusExpand />} {...props}>
			<ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
				<NameWithAvatarField />
			</ReferenceField>
			<DateField source="createdAt" label="resources.alerts.createdAt" locales={locale} showTime />
			<ReferenceField source="market" label={"Exchange"} reference="markets" link={false}>
				<MarketAvatarField />
			</ReferenceField>
			<PositionField classes={classes} label={"Position"} cellClassName={classes.maxWidth100} />
			<LastStatus label={"resources.atufuturespositions.lastStatus"} />

			<ReferenceField
				source="vendorSignal"
				label={"resources.atufuturespositions.vendorSignal"}
				reference="vendorsignals"
			>
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField
				source="vendorSignal"
				label={"resources.atufuturespositions.vendorName"}
				reference="vendorsignals"
				link={false}
			>
				<ReferenceField
					source="vendor"
					label={"resources.vendorsubscriptions.vendor"}
					reference="users|usertype|VENDOR"
					link={false}
				>
					<NickNameWithAvatarField />
				</ReferenceField>
			</ReferenceField>
			<ReferenceField
				source="exchangeAccount"
				label={"resources.atufuturespositions.exchangeAccount"}
				reference="exchangeaccounts"
			>
				<TextField source="name" />
			</ReferenceField>

			<DateField source="closedAt" showTime locales={locale} />

			<ShowButton label={"resources.alerts.manage"} />
		</Datagrid>
	);
};
const PendingOrdersDataGrid = (props) => {
	const locale = useLocale();
	const classes = useStyles();

	return (
		<Datagrid optimized expand={<SignalStatusExpand />} {...props}>
			<ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
				<NameWithAvatarField />
			</ReferenceField>
			<DateField source="createdAt" label="resources.alerts.createdAt" locales={locale} showTime />
			<ReferenceField source="market" label={"Exchange"} reference="markets" link={false}>
				<MarketAvatarField />
			</ReferenceField>
			<PositionField classes={classes} label={"Position"} cellClassName={classes.maxWidth100} />
			<LastStatus label={"resources.atufuturespositions.lastStatus"} />

			<ReferenceField
				source="vendorSignal"
				label={"resources.atufuturespositions.vendorSignal"}
				reference="vendorsignals"
			>
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField
				source="vendorSignal"
				label={"resources.atufuturespositions.vendorName"}
				reference="vendorsignals"
				link={false}
			>
				<ReferenceField
					source="vendor"
					label={"resources.vendorsubscriptions.vendor"}
					reference="users|usertype|VENDOR"
					link={false}
				>
					<NickNameWithAvatarField />
				</ReferenceField>
			</ReferenceField>
			<ReferenceField
				source="exchangeAccount"
				label={"resources.atufuturespositions.exchangeAccount"}
				reference="exchangeaccounts"
			>
				<TextField source="name" />
			</ReferenceField>

			<ShowButton label={"resources.alerts.manage"} />
		</Datagrid>
	);
};

export { ATUFPositionsList };
