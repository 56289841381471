import React, {useCallback} from "react";
import {Avatar, Box, Typography, useMediaQuery} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/CancelRounded";
import {ReferenceManyField} from "react-admin";

import DoneIcon from "@material-ui/icons/Done";
import AlarmIcon from "@material-ui/icons/Alarm";
import Grid from "@material-ui/core/Grid";
import PositionProfitField from "./PositionProfit";
import {getUnrelizedProfit} from "./ProfitField";

const styles = (theme) => ({
	iconButtonRoot: {},
	icon: {
		fontSize: "1.1em",
	},
	root: {
		display: "flex",
		flexDirection: "row",
		/*[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},*/
		backgroundColor: "transparent",
	},
	orderChipRoot: {
		display: "flex",
		flexDirection: "row",
		borderRadius: "22px",
		borderStyle: "solid",
		borderWidth: 1,
		alignItems: "center",
		padding: 5,
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		height: 40,
		marginLeft: theme.spacing(2),
		backgroundColor: "#1c1c1c",
		margin: theme.spacing(1),
	},
	orderChipAvatar: {
		height: 22,
		backgroundColor: "transparent",
		borderWidth: 1,
		borderStyle: "solid",
		fontSize: "0.9em",
		lineHeight: 22,
		color: theme.palette.text.secondary,
	},
	orderChipTitle: {
		marginLeft: 5,
		marginRight: 5,
		fontSize: "1em",
	},
	orderChipEntry: {
		borderColor: theme.palette.text.primary,
		//	color: theme.palette.background.default,
	},
	orderChipTargetPoint: {
		borderColor: theme.palette.success.main,
	},
	orderChipStopLoss: {
		borderColor: theme.palette.error.main,
	},
});
const PositionOrders = (props) => {
	const { classes, signal = {}, position = {}, exchangeAccountObject = {} } = props;
	const { positionType, tradetype, vendor, user } = signal;

	const {
		exchangeAccount,
		opened,
		closed,
		stopLossed,
		canceled,
		status,
		minRequiredBalance,
		entryBalancePercent,
		paper,
	} = position;
	const getRelatedService = useCallback(() => {
		if (!!vendor) {
			if (tradetype === 0) {
				return ["atspotorders", "atSpotPosition"];
			}
			if (tradetype === 1) {
				return ["atufuturesorders", "atuFuturesPosition"];
			}
		} else if (!!user) {
			if (tradetype === 0) {
				return ["clspotorders", "clSpotPosition"];
			}
			if (tradetype === 1) {
				return ["clufuturesorders", "cluFuturesPosition"];
			}
		}
	}, [tradetype, vendor]);
	const relatedService = getRelatedService();

	return (
		<Box>
			{!opened && !closed && (
				<OrderChip
					classes={classes}
					signal={signal}
					exchangeAccountObject={exchangeAccountObject}
					position={position}
					record={{ orderRole: "Entry", side: positionType }}
				/>
			)}
			{(opened || closed) && !paper && (
				<ReferenceManyField
					reference={relatedService[0]}
					target={relatedService[1]}
					record={position}
					sort={{ field: "createdAt" }}
					link={false}
				>
					<OrdersIterator
						signal={signal}
						classes={classes}
						exchangeAccountObject={exchangeAccountObject}
						position={position}
					/>
				</ReferenceManyField>
			)}
			{(opened || closed) && paper && (
				<OrdersIterator
					ids={position.orders.map((order) => order.id)}
					signal={signal}
					classes={classes}
					exchangeAccountObject={exchangeAccountObject}
					position={position}
				/>
			)}
		</Box>
	);
};
const OrdersIterator = ({ ids, data, basePath, signal, classes, exchangeAccountObject, position }) => {
	const { positionType, tradetype, pair, leverage } = signal;
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const {
		exchangeAccount,
		opened,
		closed,
		stopLossed,
		canceled,
		status,
		minRequiredBalance,
		entryBalancePercent,
		orders,
	} = position;
	if (!data) {
		data = {};
		orders.map((order) => (data[order.id] = order));
	}
	const getRealizedPnl = () => {
		const dataArray = ids.map((id) => data[id]);

		if (!opened) {
			return 0;
		}
		const entryOrder = dataArray?.find((order) => order?.orderRole === "Entry");
		if (!entryOrder || !entryOrder?.orderId) {
			return 0;
		}
		const _price = entryOrder?.average || entryOrder?.pairLatestPrice;
		let _amount = entryOrder?.amount || entryOrder?.quantity;
		if (pair?.multiplier && entryOrder?.amount) {
			_amount *= pair?.multiplier;
		}
		let entryAmount = _amount ? _amount * _price : entryOrder?.quoteOrderQty;
		if (tradetype === 1) {
			entryAmount /= leverage;
		}
		const placedTargetPoints = dataArray.filter((order) => order?.orderId && order?.orderRole === "TargetPoint");
		const stopLossOrder = dataArray.find(
			(order) => order?.orderId && order?.orderRole === "StopLoss" && order?.average
		);

		const slicedSignalTargetPoints = signal?.targetPoints?.slice(0, placedTargetPoints?.length);

		const replacedTargetPoints = slicedSignalTargetPoints.map((tp, index) => {
			return {
				...tp,
				percent: placedTargetPoints[index].balancePercent,
				valuenumber: placedTargetPoints[index]?.average || tp.valuenumber,
			};
		});
		let signalRecord = signal;
		if (replacedTargetPoints && replacedTargetPoints?.length) {
			signalRecord = { ...signal, targetPoints: replacedTargetPoints };
			if (entryOrder?.average) {
				signalRecord.entryAverage = entryOrder?.average;
			}
		}
		if (stopLossOrder?.orderId) {
			signalRecord.stopLoss = { ...signalRecord.stopLoss, valuenumber: stopLossOrder.average };
		}

		const unRealizedPnlPercent = getUnrelizedProfit(signalRecord);

		const pnl = entryAmount * (unRealizedPnlPercent / 100);
		return pnl.toFixed(2);
	};
	return (
		<Grid container display={"flex"} alignItems={isSmall ? "stretch" : "center"}>
			{
				<Grid item>
					<PositionProfitField profit={getRealizedPnl()} pair={pair} />
				</Grid>
			}
			{ids.map((id) => (
				<Grid key={id}>
					<OrderChip
						record={data[id]}
						key={id}
						signal={signal}
						classes={classes}
						exchangeAccountObject={exchangeAccountObject}
						position={position}
					/>
				</Grid>
			))}
		</Grid>
	);
};
const OrderChip = (props) => {
	const { classes, record = {}, position = {}, exchangeAccountObject = {}, signal = {} } = props;
	const { opened, closed, stopLossed, canceled, status, minRequiredBalance, entryBalancePercent } = position;
	const {
		side,
		byQuote,
		balancePercent,
		orderRole,
		quantity,
		quoteOrderQty,
		tpPercent,
		orderId,
		error,
		pairLatestPrice,
		price,
		average,
		cost,
		amount,
	} = record;
	const { balance, tradetype, copyTradePercent } = exchangeAccountObject;
	const { pair, vendor, fundPercent } = signal;
	const percent = !!vendor ? (fundPercent ? fundPercent : copyTradePercent) : entryBalancePercent;
	const title = () => {
		let result = "";
		let quoteAssetBalance = 0;
		let targetAmount = 0;
		if (!opened && !closed) {
			if (balance && Array.isArray(balance) && balance.length) {
				let lastBalance = balance[0].balance;

				if (tradetype === 0) {
					quoteAssetBalance = Number(lastBalance[pair?.quote]?.free).toFixed(pair?.quote === "USDT" ? 2 : 5);
					targetAmount = Number(quoteAssetBalance * (percent / 100)).toFixed(pair?.quote === "USDT" ? 2 : 5);
				} else if (tradetype === 1) {
					quoteAssetBalance = Number(lastBalance["availableBalance"]).toFixed(pair?.quote === "USDT" ? 2 : 5);
					targetAmount = Number(quoteAssetBalance * (percent / 100)).toFixed(pair?.quote === "USDT" ? 2 : 5);
				}
			}
			let formula = quoteAssetBalance + " x " + percent + "% ≈ ";
			result = targetAmount;
			if (pair?.quote === "USDT") {
				result += "$";
			} else {
				result += pair?.quote;
			}
			return (
				<Typography style={{ fontSize: "0.8em" }} color={"textSecondary"}>
					{formula}
					<Typography style={{ fontSize: "1.2em" }} component={"span"} color={"textPrimary"}>
						{result}
					</Typography>
				</Typography>
			);
		} else {
			let _amount = amount || quantity;
			if (pair?.multiplier && amount) {
				_amount *= pair?.multiplier;
			}
			if (!_amount) {
				result = quoteOrderQty?.toFixed(pair?.quote === "USDT" ? 2 : 5);
				if (pair?.quote === "USDT") {
					result += "$";
				} else {
					result += pair?.quote;
				}
			} else {
				result = _amount?.toFixed(5);
				if (pair?.base === "USDT") {
					result += "$";
				} else {
					result += " " + pair?.base;
				}
			}
		}

		return result;
	};
	const StatusIcon = useCallback(() => {
		if (orderId) {
			return <DoneIcon color={"action"} />;
		}
		if (!opened && !closed) {
			return <AlarmIcon color={"secondary"} />;
		}
		return <CancelIcon color={"error"} />;
	}, [orderId, opened, closed, error])();
	const getPairQuote = () => {
		if (pair?.quote === "USDT") {
			return "$";
		}
		return pair?.quote;
	};
	const ChipToolTipText = () => {
		const _price = average || price || pairLatestPrice;
		let _amount = amount || quantity;
		if (pair?.multiplier && amount) {
			_amount *= pair?.multiplier;
		}
		/*return <Typography variant={"caption"}>{JSON.stringify(record)}</Typography>;*/
		return (
			<Typography variant={"caption"}>
				{orderRole + " Order"}
				{_price && quantity && (
					<Typography variant={"caption"} component={"div"}>
						{_amount.toFixed(5) +
							" x " +
							_price.toFixed(5) +
							" " +
							getPairQuote() +
							" = " +
							(_price * _amount).toFixed(5) +
							" " +
							getPairQuote()}
					</Typography>
				)}
				{!!error && (
					<Typography component={"div"} color={"secondary"} variant={"caption"}>
						{error}
					</Typography>
				)}
			</Typography>
		);
	};
	return (
		<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000} title={<ChipToolTipText />}>
			<Box className={classNames(classes.orderChipRoot, classes["orderChip" + orderRole])}>
				<Avatar variant={"rounded"} className={classes.orderChipAvatar}>
					{side?.toUpperCase()}
				</Avatar>
				<Typography color={"textPrimary"} className={classes.orderChipTitle}>
					{title()}
				</Typography>
				{StatusIcon}
			</Box>
		</Tooltip>
	);
};
export default withStyles(styles, { name: "PositionOrders" })(PositionOrders);
