const ConditionTypes = [
    {
        id : "egr",
        name : "Greater than or equal >= "
    },
    {
        id : "elr",
        name : "Less than or equal <= "
    }
];
const NotifTypes =[
    {
        id:'SMS',
        name:"SMS"

    },
    {
        id:'Dastan',
        name:"Dastan"

    }
]
export {ConditionTypes,NotifTypes}
