import Icon from '@material-ui/icons/MonetizationOn';
import PairsCreate from './PairsCreate';
import PairsEdit from './PairsEdit';
import {PairsList} from './PairsList';

export default {
    create : PairsCreate,
    edit : PairsEdit,
    list:PairsList,
    icon: Icon,
};
