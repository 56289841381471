import Icon from "@material-ui/icons/MessageRounded";
import LinksCreate from "./LinksCreate";

import {LinksList} from "./LinksList";

export default {
	create: LinksCreate,

	list: LinksList,
	icon: Icon,
};
