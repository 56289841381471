import {Box, Theme, useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import React, {forwardRef} from "react";
import {AppBar, MenuItemLink, usePermissions, UserMenu, useTranslate} from "react-admin";
import NameWithAvatarField from "../shared/NameWithAvatarField";

import useCurrentTheme from "../themes/useCurrentTheme";

const logoWhite = "./images/dealerifyWhite.svg";
const logoDark = "./images/dealerifyDark.svg";
const useStyles = makeStyles({
	title: {
		flex: 1,
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
	},
	logoType: {
		flex: 1,
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textAlign: "center",
	},
	spacer: {
		flex: 1,
	},
	logo: {
		height: 30,
	},
	iconstyle: {
		fontSize: "0.875rem",
	},
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
	const translate = useTranslate();
	return (
		<MenuItemLink
			ref={ref}
			to="/configuration"
			primaryText={translate("pos.configuration")}
			leftIcon={<SettingsIcon />}
			onClick={props.onClick}
		/>
	);
});

const CustomUserMenu = (props: any) => {
	const { permissions } = usePermissions();
	const classes = useStyles();
	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
	//return (<UserMenu {...props} icon={<AvatarField record={permissions}/>}>
	return (
		<UserMenu {...props} icon={<NameWithAvatarField record={permissions} addLabel={!isXSmall} />}>
			<ConfigurationMenu />
		</UserMenu>
	);
};

const CustomAppBar = (props: any) => {
	const classes = useStyles();
	const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
	const theme = useCurrentTheme();
	return (
		<AppBar elevation={0} color={"primary"} {...props} userMenu={<CustomUserMenu />}>
			<Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />

			{!isXSmall && (
				<Box mx={"auto"} flex={1}>
					<img src={theme?.palette?.type === "dark" ? logoDark : logoWhite} style={{ height: 35 }} />
				</Box>
			)}
		</AppBar>
	);
};

export default CustomAppBar;
