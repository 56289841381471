import React from "react";
import {BooleanField, DateField, Filter, List, SearchInput, TextField, useLocale, useTranslate} from "react-admin";
import CustomizableDatagrid from "ra-customizable-datagrid";
import {TrimField} from "@bb-tech/ra-components";

const StatusFieldColored = ({ record }) => (
	<TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);
const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<SearchInput
				InputLabelProps={{ shrink: true }}
				source="phonenumber.$search"
				alwaysOn
				placeholder={translate("resources.sms.phonenumber")}
			/>
		</Filter>
	);
};
const PhoneNumberCountField = ({ record }) => {
	if (!record || !record.phonenumber) {
		return <div />;
	}
	return <div>{Array.from(record.phonenumber.split(",")).length}</div>;
};

const PttChannelsListItems = (props) => {
	const locale = useLocale();

	return (
		<CustomizableDatagrid {...props}>
			<PhoneNumberCountField label="Count" />
			<TrimField source={"phonenumber"} />
			{/* <TextField source="phonenumber" label="resources.sms.phonenumber" />*/}
			<TextField source="text" label="resources.sms.text" />

			<BooleanField label="resources.sms.success" source="success" />
			<BooleanField source="isValidation" />

			<DateField source="createdAt" label="resources.sms.createdAt" locales={locale} showTime />
		</CustomizableDatagrid>
	);
};
const SmsList = (props) => {
	const translate = useTranslate();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.sms.listtitle")}
			perPage={10}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			bulkActionButtons={false}
		>
			<PttChannelsListItems />
		</List>
	);
};

export { PttChannelsListItems, SmsList };
