import React, {Fragment, useEffect} from "react";
import {useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {useSnackbar} from "notistack";
import {useAuthState, useRedirect, useRefresh, useTranslate} from "react-admin";
import feathersClient from "../feathers/feathersClient";

const CustomNotifications = (props) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { loading, authenticated } = useAuthState();
	const redirect = useRedirect();
	const history = useHistory();
	const refresh = useRefresh();
	const translate = useTranslate();
	useEffect(() => {
		console.log(history?.location?.pathname);
	}, [history?.location?.pathname]);
	const shouldRefresh = (service, data, notiflink) => {
		if (history?.location?.pathname?.startsWith(notiflink) || history?.location?.pathname === "/") {
			return true;
		}
		let refreshablePaths = [];
		switch (service) {
			case "invoices":
				refreshablePaths = ["/invoices", "/subscriptionplans"];
				break;
			case "alerts":
				refreshablePaths = ["/alerts"];
				break;
			case "vendorsignals":
				refreshablePaths = ["/spot", "/futures", "/history"];
				break;
			case "signalalerts":
				refreshablePaths = ["/spot", "/futures", "/history"];
				break;
		}
		console.log();
		return refreshablePaths.indexOf(history?.location?.pathname) !== -1;
	};
	const MakeSnack = (service, data) => {
		const notiflink = getSnackLinkPath(service, data);

		const isInCorrentPath = history?.location?.pathname?.startsWith(notiflink);
		let title = "";
		let variant = "info";
		switch (service) {
			case "stmessages":
				title = "New support message received";
				break;
			case "invoices":
				title = translate("resources.invoices.statusChanged") + " : " + capitalize(data.newPaymentStatus);
				break;
			case "alerts":
				title = translate("resources.alerts.alerttriggered");
				break;
			case "signalalerts":
				switch (data.happening) {
					case "entryPrice":
						title = translate("resources.signalalerts.reachedEntryPrice");
						break;
					case "stopLoss":
						title = translate("resources.signalalerts.reachedStopLoss");
						variant = "error";
						break;
					case "targetPoints":
						title = translate("resources.signalalerts.signalreachedTargetPoint", { index: data.tpIndex });
						variant = "success";
						break;
					case "forceClosed":
						title = "Signal Closed";

						break;
				}

				break;
			case "vendorsignals":
				switch (data.happening) {
					case "entryPrice":
						title = translate("resources.signalalerts.reachedEntryPrice");
						break;
					case "stopLoss":
						title = translate("resources.signalalerts.reachedStopLoss");
						variant = "error";
						break;
					case "targetPoints":
						title = translate("resources.signalalerts.signalreachedTargetPoint", { index: data.tpIndex });
						variant = "success";
						break;
					case "forceClosed":
						title = "Signal Closed";

						break;
				}

				break;
		}
		let randkey = getRandomInt(9999999);

		enqueueSnackbar(title, {
			persist: true,
			variant: variant,
			key: randkey,
			action: () => (
				<GetSnackActions
					refresh={refresh}
					translate={translate}
					isInCorrentPath={isInCorrentPath}
					redirect={redirect}
					closeSnackbar={closeSnackbar}
					mykey={randkey}
					service={service}
					data={data}
				/>
			),
		});
		if (shouldRefresh(service, data, notiflink)) {
			refresh(true);
		}
		// return title;
	};
	useEffect(() => {
		feathersClient.service("alerts").removeListener("occurred");
		feathersClient.service("stmessages").removeListener("created");
		feathersClient.service("signalalerts").removeListener("occurred");
		feathersClient.service("invoices").removeListener("statusChanged");
		feathersClient.service("stmessages").on("created", (data) => {
			MakeSnack("stmessages", data);
		});
		feathersClient.service("alerts").on("occurred", (data) => {
			MakeSnack("alerts", data);
		});
		feathersClient.service("signalalerts").on("occurred", (data) => {
			MakeSnack("signalalerts", data);
		});
		feathersClient.service("invoices").on("statusChanged", (data) => {
			MakeSnack("invoices", data);
		});
		return () => {
			feathersClient.service("alerts").removeListener("occurred");
			feathersClient.service("stmessages").removeListener("created");
			feathersClient.service("signalalerts").removeListener("occurred");
			feathersClient.service("invoices").removeListener("statusChanged");
		};
	}, [authenticated]);
	return <span />;
};
function capitalize(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}
const getSnackLinkPath = (service, data) => {
	switch (service) {
		case "stmessages":
			return "/" + "supporttickets" + "/" + data.supportTicket;
		case "invoices":
			return "/" + service + "/" + data._id + "/show";
		case "alerts":
			return "/" + service + "/" + data._id;

		case "vendorsignals":
			switch (data.tradetype) {
				case 1:
					return "/" + service + "|tradetype|1/" + data._id;

				default:
					return "/" + service + "|tradetype|0/" + data._id;
			}
		case "signalalerts":
			switch (data.tradetype) {
				case 1:
					return "/" + service + "|tradetype|1/" + data._id;

				default:
					return "/" + service + "|tradetype|0/" + data._id;
			}
	}
	return "";
};
const getServiceListPath = (service, data) => {
	switch (service) {
		case "invoices":
			return "/" + service;
		case "alerts":
			return "/" + service;

		case "vendorsignals":
			switch (data.tradetype) {
				case 1:
					return "/futures";

				default:
					return "/spot";
			}
		case "signalalerts":
			switch (data.tradetype) {
				case 1:
					return "/futures";

				default:
					return "/spot";
			}
	}
	return "/history";
};
const GetSnackActions = (props) => {
	const notiflink = getSnackLinkPath(props.service, props.data);

	// console.log(props);

	const { translate } = props;
	return (
		<Fragment>
			{!props.isInCorrentPath && (
				<Button
					onClick={() => {
						props.redirect(notiflink);
						props.refresh();
					}}
				>
					View
				</Button>
			)}

			<Button
				onClick={() => {
					props.closeSnackbar(props.mykey);
				}}
			>
				Dismiss
			</Button>
		</Fragment>
	);
};

function getRandomInt(max) {
	return Math.floor(Math.random() * Math.floor(max));
}
export default CustomNotifications;
