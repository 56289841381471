import React from "react";
import {
    AutocompleteInput,
    Create,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    useLocale,
    useTranslate,
} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";
import MyDateInput from "../shared/MyDatePicker";
import {ServiceTypes} from "./VendorSubscriptionsEnums";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);
const Title = ({ record }) => {
	const translate = useTranslate();
	return <span> {translate("resources.vendorsubscriptions.edittitle")}</span>;
};

const VendorSubscriptionsCreate = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const translate = useTranslate();
	return (
		<Create {...props} title={"resources.vendorsubscriptions.createtitle"}>
			<SimpleForm redirect={"list"}>
				<ReferenceInput
					validate={requiredValidate}
					allowEmpty={false}
					label={"resources.vendorsignals.vendor"}
					source="vendor"
					reference="users|usertype|VENDOR"
					perPage={500}
				>
					<SelectInput optionText={<NickNameWithAvatarField />} name={"fakename"} />
				</ReferenceInput>
				<ReferenceInput
					validate={requiredValidate}
					label={"resources.signalalerts.client"}
					source="user"
					perPage={25}
					reference="users|usertype|CLIENT"
					allowEmpty={false}
					filter={{
						usertype: "CLIENT",
					}}
					filterToQuery={(searchText) =>
						searchText.trim() !== ""
							? {
									$or: [
										{ firstName: { $search: searchText } },
										{ lastName: { $search: searchText } },
										{ telegramId: { $search: searchText } },
									],
							  }
							: { fake: true }
					}
				>
					<AutocompleteInput
						matchSuggestion={() => true}
						optionText={(record) =>
							record && record.firstName
								? record.firstName + " " + record.lastName + (record.telegramId ? " " + record.telegramId : "")
								: translate("resources.observes.allusers")
						}
						optionValue={"_id"}
					/>
				</ReferenceInput>

				<SelectInput
					label={"resources.vendorsubscriptions.serviceType"}
					source={"servicetype"}
					choices={ServiceTypes}
				/>
				<MyDateInput
					validate={requiredValidate}
					source="startedAt"
					label="resources.vendorsubscriptions.startedAt"
					defaultValue={new Date()}
				/>
				<MyDateInput
					validate={requiredValidate}
					source="expiresAt"
					label="resources.vendorsubscriptions.expiresAt"
					locales={locale}
					defaultValue={addMonths(new Date(), 1)}
				/>
			</SimpleForm>
		</Create>
	);
};
function addMonths(date, months) {
	var d = date.getDate();
	date.setMonth(date.getMonth() + +months);
	if (date.getDate() !== d) {
		date.setDate(0);
	}
	return date;
}

const requiredValidate = [required()];

export default VendorSubscriptionsCreate;
