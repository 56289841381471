import Icon from "@material-ui/icons/MessageRounded";
import EmailsCreate from "./EmailsCreate";

import {EmailsList} from "./EmailsList";

export default {
	create: EmailsCreate,

	list: EmailsList,
	icon: Icon,
};
