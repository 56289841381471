import React, {Fragment, useCallback, useEffect, useState} from "react";
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    Filter,
    List,
    ListContextProvider,
    NullableBooleanInput,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField,
    useGetList,
    useListContext,
    useLocale,
    useTranslate,
} from "react-admin";
import {Divider, makeStyles, Tab, Tabs, useMediaQuery} from "@material-ui/core";
import VendorSignalsListMobileGrid from "./SignalAlertsListMobileGrid";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import AlarmIcon from "@material-ui/icons/Alarm";
import CheckIcon from "@material-ui/icons/Check";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {rawProfitInPercent} from "./SignalCalculations";
import SignalStatus from "./SignalStatus";
import Box from "@material-ui/core/Box";
import {AvatarGroup} from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import MarketAvatarField from "../shared/MarketAvatarField";
import SignalPositionsExpand from "./SignalPositionsExpand";

const useStyles = makeStyles((theme) => ({
	entryPriceRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	stopLossRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	positionTypeRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	avatarRoot: {
		width: 30,
		height: 30,

		fontSize: "1.2em",
	},
	iconRoot: {
		fontSize: "1.1rem",
	},
	normalAvatar: {},
	entryAvatarTriggered: {
		backgroundColor: theme.palette.primary.main,
	},
	stopLossAvatarTriggered: {
		backgroundColor: theme.palette.error.main,
	},

	positionTypeAvatarError: {
		backgroundColor: theme.palette.error.main,
		borderRadius: 5,
	},
	positionTypeAvatarSuccess: {
		backgroundColor: theme.palette.success.main,
		borderRadius: 5,
	},
	profitGreen: {
		color: theme.palette.success.main,
	},
	profitRed: {
		color: theme.palette.error.main,
	},
	maxWidth100: {},
	minWidth100: {},
	maxWidth99P: {
		width: "99%",
	},
}));
const StatusFieldColored = ({ record }) => (
	<TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);
const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			{/*<ReferenceInput  alwaysOn label={"resources.pairs.market"} source="market" perPage={10}
                         reference="markets" allowEmpty={true}

                         filterToQuery={(searchText) => searchText.trim() !== '' ? ({
                             name:{$search:searchText}
                         }) : null}>
            <AutocompleteInput optionText={'name'} allowEmpty={true} optionValue={'_id'}   emptyText={"resources.pairs.allmarkets"} alwaysOn
            />
        </ReferenceInput>*/}
			<ReferenceInput
				alwaysOn
				label={"resources.signalalerts.client"}
				source="user"
				perPage={25}
				reference="users|usertype|CLIENT"
				allowEmpty={true}
				filter={{
					usertype: "CLIENT",
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
						  }
						: { fake: true }
				}
			>
				<AutocompleteInput
					optionText={(record) =>
						record && record.firstName
							? record.firstName + " " + record.lastName
							: translate("resources.observes.allusers")
					}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				label={"resources.observes.pair"}
				source="pair"
				perPage={10}
				reference="pairs"
				allowEmpty={true}
				shouldRenderSuggestions={(value) => {
					return value && value.trim().length > 0;
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								symbol: { $search: searchText },
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={"symbol"}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.pairs.allpairs"}
					alwaysOn
				/>
			</ReferenceInput>

			<SearchInput
				InputLabelProps={{ shrink: true }}
				source="name.$search"
				alwaysOn
				placeholder={translate("resources.vendorsignals.namehelper")}
			/>

			<NullableBooleanInput label="resources.vendorsignals.fullTargeted" source="fullTargeted" nullable alwaysOn />
		</Filter>
	);
};
const SimpleArrayField = ({ record, source }) =>
	record ? (
		<div>
			{record[source].map((item, index) => (
				<span key={index}>{item}</span>
			))}
		</div>
	) : (
		<div />
	);

const EntryRangeField = ({ record, classes }) => {
	if (!record || !record.entryPrice) {
		return <div />;
	}
	return (
		<div className={classes.entryPriceRoot}>
			<Avatar
				className={[
					classes.avatarRoot,
					record.entryPrice.triggeredAt ? classes.entryAvatarTriggered : classes.normalAvatar,
				]}
			>
				{record.entryPrice.triggeredAt && <CheckIcon className={[classes.iconRoot]} />}
				{!record.entryPrice.triggeredAt && <AlarmIcon className={[classes.iconRoot]} />}
			</Avatar>

			{record.entryPrice.low + " ~ " + record.entryPrice.high}
		</div>
	);
};
const PositionTypeField = ({ record, classes }) => {
	if (!record || !record.positionType || !record.entryPrice || !record.stopLoss) {
		return <div />;
	}
	return (
		<Badge
			overlap="circle"
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			// badgeContent={record.leverage ? <Box className={classes.leverageBadge}> <Typography variant={"caption"}>{record.leverage}x</Typography></Box>:null}
			color="error"
			badgeContent={record.leverage ? record.leverage + "x" : null}
		>
			<Avatar
				className={[
					record.positionType === "buy" ? classes.positionTypeAvatarSuccess : classes.positionTypeAvatarError,
				]}
			>
				{record.positionType === "buy" ? "B" : "S"}
			</Avatar>
		</Badge>
	);
};

const StopLossField = ({ record, classes }) => {
	if (!record || !record.stopLoss) {
		return <div />;
	}
	return (
		<div className={classes.stopLossRoot}>
			<Avatar
				className={[
					classes.avatarRoot,
					record.stopLoss.triggeredAt ? classes.stopLossAvatarTriggered : classes.normalAvatar,
				]}
			>
				{record.stopLoss.disabled && <MoreHorizIcon className={[classes.iconRoot]} />}
				{!record.stopLoss.disabled && record.stopLoss.triggeredAt && <CheckIcon className={[classes.iconRoot]} />}
				{!record.stopLoss.disabled && !record.stopLoss.triggeredAt && <AlarmIcon className={[classes.iconRoot]} />}
			</Avatar>

			{record.stopLoss.valuenumber}
		</div>
	);
};
const LastStatusField = ({ record, classes, translate, locale }) => {
	if (!record || !record.stopLoss || !record.entryPrice || !record.targetPoints) {
		return <div />;
	}
	const { stopLoss, entryPrice, targetPoints } = record;
	if (!record.entryPrice.triggeredAt) {
		return translate("resources.signalalerts.waitingForEntry");
	}
	const someThingHappend = record.stopLoss.triggeredAt || record.targetPoints.find((tp) => tp.triggeredAt);
	if (!someThingHappend) {
		return (
			<span>
				{translate("resources.signalalerts.entryTriggeredAt")}
				<DateField source={"triggeredAt"} record={entryPrice} locales={locale} showTime />
			</span>
		);
	}
	if (stopLoss.triggeredAt) {
		return (
			<span>
				{translate("resources.signalalerts.stopLossTriggeredAt")}
				<DateField source={"triggeredAt"} record={stopLoss} locales={locale} showTime />
			</span>
		);
	}
	let indexedTargetPoints = targetPoints.map((tp, index) => {
		tp.index = index;
		return tp;
	});
	let triggeredTps = indexedTargetPoints.filter((tp) => {
		return tp.triggeredAt;
	});
	let sortedTps = triggeredTps.sort((a, b) => new Date(b.triggeredAt).getTime() - new Date(a.triggeredAt).getTime());

	if (sortedTps && sortedTps.length > 0) {
		return (
			<span>
				{translate("resources.signalalerts.targetPointTriggered", { tpindex: sortedTps[0].index + 1 })}

				<DateField source={"triggeredAt"} record={sortedTps[0]} locales={locale} showTime />
			</span>
		);
	}
	return <div />;
};
const getNotExtractedLoss = (record) => {
	const { stopLoss, entryPrice, targetPoints } = record;
	if (!stopLoss.triggeredAt) {
		return 0;
	}
	const notExtractedTargetPoints = targetPoints.filter((tp) => !tp.triggeredAt);
	let notExtractedPercent = 0;
	if (notExtractedTargetPoints.length > 0) {
		notExtractedPercent = notExtractedTargetPoints.map((tp) => tp.percent).reduce((total, num) => total + num);
	}
	let lossPercent = getLossBasedOnEntryAvg(record);
	let NotExtractedLossPercent = Number((lossPercent * (notExtractedPercent / 100)).toFixed(3));
	return NotExtractedLossPercent;
};
const getExtractedProfit = (record) => {
	const { stopLoss, entryPrice, targetPoints } = record;
	if (!targetPoints || targetPoints.length === 0) {
		return 0;
	}
	const extractedTargetPoints = targetPoints.filter((tp) => tp.triggeredAt);
	if (extractedTargetPoints.length === 0) {
		return 0;
	}
	let targetPointsProfits = extractedTargetPoints
		.map((tp) => getProfitWithAppliedExtraction(tp, record))
		.reduce((num, total) => total + num);
	return targetPointsProfits;
};
const getLossBasedOnEntryAvg = (record) => {
	const { stopLoss, entryPrice, positionType, entryAverage, leverage } = record;
	let avrg = entryAverage ? entryAverage : Number((entryPrice.low + entryPrice.high) / 2);
	let lossPercent = rawProfitInPercent(avrg, stopLoss.valuenumber, positionType);
	if (leverage) {
		lossPercent *= leverage;
	}
	return lossPercent;
};
const getProfitBasedOnEntryAvg = (tp, record) => {
	const { entryPrice, positionType, entryAverage, leverage } = record;

	let avrg = entryAverage ? entryAverage : Number((entryPrice.low + entryPrice.high) / 2);
	let tpVal = tp.valuenumber;

	let profitPercent = rawProfitInPercent(avrg, tpVal, positionType);
	if (leverage) {
		profitPercent *= leverage;
	}
	return profitPercent;
};
const getProfitWithAppliedExtraction = (tp, record) => {
	if (!tp || !tp.percent || !tp.valuenumber) {
		return 0;
	}
	return Number((getProfitBasedOnEntryAvg(tp, record) * (tp.percent / 100)).toFixed(3));
};
const getSignalProfit = (record) => {
	let profit = 0;
	if (!record || !record.entryPrice || !record.entryPrice || !record.entryPrice.triggeredAt) {
		return 0;
	}
	profit = getExtractedProfit(record) + getNotExtractedLoss(record);

	return Number(profit.toFixed(3));
};
const ProfitField = ({ record, classes }) => {
	let profit = getSignalProfit(record);
	if (profit <= -100) {
		return (
			<div className={profit === 0 ? undefined : profit > 0 ? classes.profitGreen : classes.profitRed}>
				{profit + "%"} 💩
			</div>
		);
	} else if (profit >= 100) {
		return (
			<div className={profit === 0 ? undefined : profit > 0 ? classes.profitGreen : classes.profitRed}>
				{profit + "%"} 💰
			</div>
		);
	}
	return (
		<div className={profit === 0 ? undefined : profit > 0 ? classes.profitGreen : classes.profitRed}>
			{profit + "%"}
		</div>
	);
};

const PositionField = ({ record, classes, ...props }) => {
	if (!record || !record.positionType || !record.entryPrice || !record.stopLoss) {
		return <div />;
	}
	return (
		<Box display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"} minWidth={110}>
			<PositionTypeField classes={classes} record={record} />
			<ReferenceField
				record={record}
				{...props}
				source="pair"
				label={"resources.signalalerts.pair"}
				reference="pairs"
				link={false}
			>
				<PairWithIconField classes={classes} />
			</ReferenceField>
		</Box>
	);
};
const PairWithIconField = ({ record, classes }) => {
	if (!record || !record.baseAsset) {
		return <div />;
	}
	return (
		<Box display={"flex"} alignItems={"center"} flexDirection={"Column"}>
			<AvatarGroup>
				<Avatar
					className={classes.avatarRoot}
					alt={record.baseAsset}
					src={"https://cdn.dealerify.io/asseticons/svg/color/" + record.baseAsset.toLowerCase() + ".svg"}
				/>
				<Avatar
					className={classes.avatarRoot}
					alt={record.quoteAsset}
					src={"https://cdn.dealerify.io/asseticons/svg/color/" + record.quoteAsset.toLowerCase() + ".svg"}
				/>
			</AvatarGroup>
			<Box
				display={"flex"}
				alignItems={"center"}
				flexDirection={"row"}
				justifyContent={"space-evenly"}
				width={"100%"}
				fontSize={"0.9em"}
			>
				<Typography variant={"caption"}>{record.baseAsset}</Typography>
				<Typography variant={"caption"}>/</Typography>
				<Typography variant={"caption"}>{record.quoteAsset}</Typography>
			</Box>
		</Box>
	);
};

const tabs = [
	{ id: "open", name: "open" },
	{ id: "pending", name: "pending" },
	{ id: "closed", name: "closed" },
];
const useGetTotals = (filterValues, tradetype) => {
	const { total: totalOpen } = useGetList(
		"signalalerts|tradetype|" + tradetype,
		{ perPage: 1, page: 1 },
		{ field: "id", order: "ASC" },
		{ ...filterValues, opened: true, closed: false }
	);

	const { total: totalPending } = useGetList(
		"signalalerts|tradetype|" + tradetype,
		{ perPage: 1, page: 1 },
		{ field: "id", order: "ASC" },
		{ ...filterValues, opened: false, closed: false }
	);
	const { total: totalClosed } = useGetList(
		"signalalerts|tradetype|" + tradetype,
		{ perPage: 1, page: 1 },
		{ field: "id", order: "ASC" },
		{ ...filterValues, closed: true, opened: true }
	);

	return {
		open: totalOpen ? totalOpen : 0,

		pending: totalPending ? totalPending : 0,
		closed: totalClosed ? totalClosed : 0,
	};
};
const getStatusObject = (status) => {
	switch (status) {
		case "closed":
			return { closed: true, opened: true };

		case "pending":
			return { closed: false, opened: false };

		case "open":
			return { closed: false, opened: true };
	}
};
const getStatusFilter = (filter) => {
	if (filter.closed && filter.opened) {
		return "closed";
	}
	if (!filter.closed && filter.opened) {
		return "open";
	}
	if (!filter.closed && !filter.opened) {
		return "pending";
	}
};
const VendorSignalsTabs = (props) => {
	const { basePath } = props;
	const tradetype = basePath && basePath.includes("1") ? 1 : 0;
	const listContext = useListContext();
	const { ids, filterValues, setFilters, displayedFilters } = listContext;
	const translate = useTranslate();
	const locale = useLocale();

	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

	const [openOrders, setOpenOrders] = useState([]);
	const [closedOrders, setClosedOrders] = useState([]);
	const [pendingOrders, setPendingOrders] = useState([]);
	const [activeTab, setActiveTab] = useState("open");
	const statusFilter = getStatusFilter(filterValues);
	let selectedIds = [];
	switch (statusFilter) {
		case "open":
			selectedIds = openOrders;
			break;
		case "pending":
			selectedIds = pendingOrders;
			break;
		case "closed":
			selectedIds = closedOrders;
			break;
	}

	const totals = useGetTotals(filterValues, tradetype);

	const handleChange = useCallback(
		(event, value) => {
			// setActiveTab(value)

			if (setFilters) {
				setFilters({ ...filterValues, ...getStatusObject(value) }, displayedFilters, false);
			}
		},
		[displayedFilters, filterValues, setFilters]
	);

	useEffect(() => {
		if (ids && ids !== getStatusFilter(filterValues)) {
			switch (getStatusFilter(filterValues)) {
				case "closed":
					setClosedOrders(ids);
					break;
				case "open":
					setOpenOrders(ids);
					break;
				case "pending":
					setPendingOrders(ids);
			}
		}
	}, [ids]);
	return (
		<Fragment>
			<Tabs
				variant="fullWidth"
				centered
				value={getStatusFilter(filterValues)}
				indicatorColor="primary"
				onChange={handleChange}
			>
				{tabs.map((choice) => (
					<Tab key={choice.id} label={`${choice.name} (${totals[choice.name]})`} value={choice.id} />
				))}
			</Tabs>
			<Divider />
			{isXsmall ? (
				<ListContextProvider value={{ ...listContext, ids: selectedIds }}>
					<VendorSignalsListMobileGrid {...props} ids={selectedIds} />
				</ListContextProvider>
			) : (
				<div>
					{getStatusFilter(filterValues) === "closed" && (
						<ListContextProvider value={{ ...listContext, ids: closedOrders }}>
							<SignalDataGridClosed tradetype={tradetype} />
						</ListContextProvider>
					)}

					{getStatusFilter(filterValues) === "open" && (
						<ListContextProvider value={{ ...listContext, ids: openOrders }}>
							<SignalDataGridOpen tradetype={tradetype} />
						</ListContextProvider>
					)}
					{getStatusFilter(filterValues) === "pending" && (
						<ListContextProvider value={{ ...listContext, ids: pendingOrders }}>
							<SignalDataGridPending tradetype={tradetype} />
						</ListContextProvider>
					)}
				</div>
			)}
		</Fragment>
	);
};

const SignalDataGridOpen = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	const classes = useStyles();

	return (
		<Datagrid optimized expand={<SignalPositionsExpand />} {...props}>
			<ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
				<NameWithAvatarField />
			</ReferenceField>
			<ReferenceField source="market" label={"Exchange"} reference="markets" link={false}>
				<MarketAvatarField />
			</ReferenceField>
			<PositionField classes={classes} label={"Position"} cellClassName={classes.maxWidth100} />
			<ProfitField
				classes={classes}
				label={"resources.signalalerts.totalProfit"}
				cellClassName={classes.maxWidth100}
			/>
			<SignalStatus label={"Status"} cellClassName={classes.maxWidth99P} />
			<TextField source="name" label="resources.signalalerts.signalName" cellClassName={classes.maxWidth100} />
			<DateField
				source="createdAt"
				label="resources.signalalerts.createdAt"
				cellClassName={classes.maxWidth100}
				locales={locale}
				showTime
			/>
		</Datagrid>
	);
};
const SignalDataGridClosed = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	const classes = useStyles();

	return (
		<Datagrid optimized expand={<SignalPositionsExpand />} {...props}>
			<ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
				<NameWithAvatarField />
			</ReferenceField>
			<ReferenceField source="market" label={"Exchange"} reference="markets" link={false}>
				<MarketAvatarField />
			</ReferenceField>
			<PositionField classes={classes} label={"Position"} cellClassName={classes.maxWidth100} />
			<ProfitField
				classes={classes}
				label={"resources.signalalerts.totalProfit"}
				cellClassName={classes.maxWidth100}
			/>

			<SignalStatus label={"Status"} component="th" scope={"row"} cellClassName={classes.maxWidth99P} />

			<TextField source="name" label="resources.signalalerts.signalName" cellClassName={classes.maxWidth100} />
			<DateField
				source="createdAt"
				label="resources.signalalerts.createdAt"
				cellClassName={classes.maxWidth100}
				locales={locale}
				showTime
			/>
		</Datagrid>
	);
};
const SignalDataGridPending = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	const classes = useStyles();

	return (
		<Datagrid optimized expand={<SignalPositionsExpand />} {...props}>
			<ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
				<NameWithAvatarField />
			</ReferenceField>
			<ReferenceField source="market" label={"Exchange"} reference="markets" link={false}>
				<MarketAvatarField />
			</ReferenceField>
			<PositionField classes={classes} label={"Position"} cellClassName={classes.maxWidth100} />

			<SignalStatus label={"Status"} component="th" scope={"row"} cellClassName={classes.maxWidth99P} />

			<TextField source="name" label="resources.signalalerts.signalName" cellClassName={classes.maxWidth100} />
			<DateField
				source="createdAt"
				label="resources.signalalerts.createdAt"
				cellClassName={classes.maxWidth100}
				locales={locale}
				showTime
			/>
		</Datagrid>
	);
};
const SignalAlertsList = (props) => {
	const translate = useTranslate();
	const { basePath } = props;
	const tradetype = basePath && basePath.includes("1") ? 1 : 0;

	return (
		<List
			{...props}
			filterDefaultValues={{ closed: false, opened: true }}
			filters={<ListFilter />}
			title={
				tradetype === 0
					? translate("resources.vendorsignals.listtitleSpot")
					: translate("resources.vendorsignals.listtitleFutures")
			}
			perPage={25}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			bulkActionButtons={false}
		>
			<VendorSignalsTabs />
		</List>
	);
};

export {
	SignalAlertsList,
	EntryRangeField,
	LastStatusField,
	StopLossField,
	PositionTypeField,
	ProfitField,
	PositionField,
};
