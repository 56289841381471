import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {
	BooleanInput,
	Edit,
	email,
	ImageField,
	ImageInput,
	maxLength,
	minLength,
	PasswordInput,
	regex,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
	TopToolbar,
	useTranslate,
} from "react-admin";
import BulkEditBtn from "../shared/BulkEditBtn";
import mobileValidate from "../shared/MobileValidate";

import NameWithAvatarField from "../shared/NameWithAvatarField";
import {validatePassword} from "../shared/ScorePassword";
import {usertypes} from "./AdminsEnum";

const useStyles = makeStyles({
	normalfield: { width: 544 },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
});
const Title = ({ record }: any) => {
	const translate = useTranslate();
	return (
		<span>
			{" "}
			{translate("resources.admins.edittitle")} {record ? `${record.name}` : ""}
		</span>
	);
};
const ADMINsEdit = (props: any) => {
	const classes = useStyles();
	const translate = useTranslate();
	return (
		<Edit {...props} title={<NameWithAvatarField />} actions={<EditActions />} undoable={false}>
			<SimpleForm>
				<TextInput
					autoFocus
					source="firstName"
					formClassName={classes.normalfield}
					validate={requiredValidate}
					label={"resources.admins.firstName"}
				/>
				<TextInput
					autoFocus
					source="lastName"
					formClassName={classes.normalfield}
					validate={requiredValidate}
					label={"resources.admins.lastName"}
				/>

				<TextInput
					source="phonenumber"
					label={"resources.admins.phonenumber"}
					fullWidth={true}
					formClassName={classes.normalfield}
					validate={mobileValidatation}
				/>
				<TextInput
					label={"resources.admins.email"}
					type="email"
					source="email"
					validation={{ email: true }}
					fullWidth={true}
					formClassName={classes.email}
					validate={emailValidate}
				/>
				<SelectInput
					label="resources.admins.acceessType"
					source="accessType"
					choices={usertypes}
					initialValue={0}
				/>
				<ImageInput
					source="avatar"
					label={"resources.vendors.avatar"}
					maxSize={1000000}
					accept=".jpg,.png,.jpeg"
					placeholder={<p>{translate("resources.vendors.dropimages")}</p>}
				>
					<ImageField source="src" title="title" />
				</ImageInput>
				<TextInput
					source="desc"
					formClassName={classes.address}
					multiline={true}
					fullWidth={true}
					label={"resources.admins.desc"}
				/>

				<BooleanInput source="disabled" label={"resources.admins.disabled"} />
			</SimpleForm>
		</Edit>
	);
};

const requiredValidate = [required()];
const mobileValidatation = [
	required(),
	mobileValidate,
	regex(/^([+]\d{1,3})?\d{10}$/, "Invalid mobile format"),
	minLength(11),
	maxLength(15),
];
const emailValidate = [required(), email()];
const passwordValidate = [required(), minLength(8), validatePassword];
const EditActions = ({ basePath, data, resource }: any) => (
	<TopToolbar>
		<BulkEditBtn
			data={data}
			resource={resource}
			field={"password"}
			label="resources.clients.changepassword"
			idField="_id"
			inputComponent={<PasswordInput source="password" validate={passwordValidate} />}
		/>
	</TopToolbar>
);
const SectionTitle = ({ label }: { label: string }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default ADMINsEdit;
