import React, {memo, useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Slider from "@material-ui/core/Slider";
import {useMediaQuery} from "@material-ui/core";
import {useForm} from "react-final-form";

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);
const OrderSlider = withStyles({
	root: {
		color: "#3880ff",
		height: 2,
		padding: "15px 0",
	},

	active: {},
	valueLabel: {
		left: 25,
		top: 0,
		"& *": {
			background: "transparent",
			color: "#fff",
			fontSize: "1em",
		},
	},
})(Slider);
const ChartOrderModifier = (props) => {
	const { open, setOpen, order = {}, pairObject } = props;
	const { point, tpIndex, pointType, min, max } = order;
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const [value, setValue] = useState(point?.valuenumber);
	useEffect(() => {
		setValue(point?.valuenumber);
	}, [point, tpIndex]);
	const form = useForm();
	const handleClose = () => {
		setOpen(false);
	};
	const handleChange = () => {
		switch (pointType) {
			case "TargetPoint":
				form.change("targetPoints[" + tpIndex + "].valuenumber", value);
				break;
			case "StopLoss":
				form.change("stopLoss.valuenumber", value);
				break;
			case "Entry Price High":
				form.change("entryPrice.high", value);
				break;
			case "Entry Price Low":
				form.change("entryPrice.low", value);
				break;
		}
		handleClose();
	};
	return (
		<Dialog onClose={handleClose} open={open} maxWidth={"xs"} fullWidth={true}>
			<DialogTitle id="customized-dialog-title" onClose={handleClose}>
				Modify {pointType}
			</DialogTitle>
			<DialogContent dividers>
				<Box height={200} p={1} m={1} display={"flex"}>
					<OrderSlider
						step={pairObject?.tickSize}
						orientation="vertical"
						min={min < point?.valuenumber - 0.1 * point?.valuenumber ? point?.valuenumber - 0.1 * point?.valuenumber : min}
						max={max > point?.valuenumber + 0.1 * point?.valuenumber ? point?.valuenumber + 0.1 * point?.valuenumber : max}
						defaultValue={point?.valuenumber}
						valueLabelDisplay="on"
						getAriaValueText={(value) => value}
						onChangeCommitted={(e, v) => setValue(v)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleChange} color="primary">
					Save changes
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default memo(ChartOrderModifier);
