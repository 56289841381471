import React, {memo, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useLocale, useTranslate} from "react-admin";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AlarmIcon from "@material-ui/icons/Alarm";
import {useMediaQuery, withStyles} from "@material-ui/core";
import {rawProfitInPercent} from "./VendorSignalCalculations";
import {darken, lighten} from "@material-ui/core/styles/colorManipulator";
import {ThemedSlider} from "../shared/ThemedSlider";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: "6px 16px",
	},
	paperSmall: {
		padding: "6px 6px",
	},

	secondaryTail: {
		backgroundColor: theme.palette.secondary.main,
	},
	topMarginDate: {
		marginTop: "10px",
		color: theme.palette.primary.light,
	},
	smallRoot: {
		padding: "6px 0px",
	},
	profitText: {
		color: theme.palette.success.light,
	},
	lossText: {
		color: theme.palette.error.light,
	},
	lossTextNotExtracted: {
		color: theme.palette.error.main,
	},
	thumbContent: {
		color: "white",
		fontSize: "1em",
	},
	thumbContentContrast: {
		color: "Black",
		fontSize: "1em",
	},
	assetAvatar: {
		width: 22,
		height: 22,
		backgroundColor: "white",
		color: "black",
	},
}));
const SignalStatusSlider = memo(
	withStyles((theme) => ({
		disabled: {},
		thumb: {
			height: 22,
			width: 22,
			borderColor: theme.palette.text.hint,
			borderWidth: 1,
			borderStyle: "solid",
			marginTop: -11,
			marginLeft: -11,
			"&$disabled": {
				opacity: 0.8,
				backgroundColor: "transparent",
				borderColor: theme.palette.text.disabled,
				borderWidth: 1,
				borderStyle: "dashed",
			},
		},
		thumbError: {
			backgroundColor: theme.palette.error.main,
		},
		thumbSuccess: {
			backgroundColor: theme.palette.success.main,
		},
		thumbNormal: {
			backgroundColor: "#fff",
		},
		track: {
			display: "block",
			position: "absolute",
			height: 2,
			borderRadius: 1,
			backgroundColor: theme.palette.grey[300],
			"$vertical &": {
				width: 2,
			},
		},
		rail: {
			display: "block",
			position: "absolute",
			width: "100%",
			height: 2,
			borderRadius: 1,
			backgroundColor: theme.palette.success.main,
			opacity: 0.38,
			"$vertical &": {
				height: "100%",
				width: 2,
			},
		},
		/* Styles applied to the track element if `track={false}`. */
		trackFalse: {
			"& $track": {
				display: "none",
			},
		},

		/*valueLabel: {
		"& $thumbName": {
			position: "relative",
			top: 20,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: 10,
			left: -10,
			fontSize: "2em",
			textAlign: "center",
		},
	},*/
		/* Styles applied to the track element if `track="inverted"`. */
		trackInverted: {
			"& $track": {
				backgroundColor:
					// Same logic as the LinearProgress track color
					theme.palette.type === "light"
						? lighten(theme.palette.success.main, 0.62)
						: darken(theme.palette.success.main, 0.5),
			},
			"& $rail": {
				opacity: 1,
			},
		},
	}))(ThemedSlider)
);
const getNotExtractedLoss = (record) => {
	const { stopLoss, entryPrice, targetPoints } = record;
	if (!stopLoss.triggeredAt) {
		return 0;
	}
	const notExtractedTargetPoints = targetPoints.filter((tp) => !tp.triggeredAt);
	let notExtractedPercent = 0;
	if (notExtractedTargetPoints.length > 0) {
		notExtractedPercent = notExtractedTargetPoints.map((tp) => tp.percent).reduce((total, num) => total + num);
	}
	let lossPercent = getLossBasedOnEntryAvg(record);
	let NotExtractedLossPercent = Number(((lossPercent * notExtractedPercent) / 100).toFixed(3));
	return NotExtractedLossPercent;
};
const getLossBasedOnEntryAvg = (record) => {
	const { stopLoss, entryPrice, positionType, entryAverage, leverage } = record;
	let avrg = entryAverage ? entryAverage : Number((entryPrice.low + entryPrice.high) / 2);
	let lossPercent = rawProfitInPercent(avrg, stopLoss.valuenumber, positionType);
	if (leverage) {
		lossPercent *= leverage;
		lossPercent = Number(lossPercent.toFixed(3));
	}
	return lossPercent;
};
const getProfitBasedOnEntryAvg = (tp, record) => {
	const { entryPrice, positionType, entryAverage, leverage } = record;

	let avrg = entryAverage ? entryAverage : Number((entryPrice.low + entryPrice.high) / 2);
	let tpVal = tp.valuenumber;

	let profitPercent = rawProfitInPercent(avrg, tpVal, positionType);
	if (leverage) {
		profitPercent *= leverage;
		profitPercent = Number(profitPercent.toFixed(3));
	}
	return profitPercent;
};
const getProfitWithAppliedExtraction = (tp, record) => {
	if (!tp || !tp.percent || !tp.valuenumber) {
		return 0;
	}
	return Number((getProfitBasedOnEntryAvg(tp, record) * (tp.percent / 100)).toFixed(3));
};
function getTimelineArray(record, pair) {
	let tlArray = [];
	tlArray.push({
		price: record.stopLoss.valuenumber,
		color: "error",
		name: "stopLoss",
		NotExtractedLossPercent: getNotExtractedLoss(record),
		LossBasedOnEntryAvg: getLossBasedOnEntryAvg(record),
		...record.stopLoss,
	});
	tlArray.push({
		price: record.entryPrice.low,
		color: "primary",
		name: "enterPriceStart",
		...record.entryPrice,
	});
	tlArray.push({
		price: record.entryPrice.high,
		color: "primary",
		name: "enterPriceEnd",
		...record.entryPrice,
	});
	tlArray.push({
		price: pair.latestPrice,
		color: "grey",
		name: "latestPrice",
		...pair,
	});
	record.targetPoints.forEach((tp, index) => {
		tlArray.push({
			price: tp.valuenumber,
			color: "success",
			name: "targetPoint",
			differencefromAvg: getProfitBasedOnEntryAvg(tp, record),
			profitWithExtraction: getProfitWithAppliedExtraction(tp, record),
			index,
			...tp,
		});
	});
	//todo add current price;

	tlArray.sort((a, b) => b.price - a.price);
	return tlArray;
}
function getTitleForDate(item, translate) {
	if (["enterPriceStart", "enterPriceEnd"].indexOf(item.name) !== -1) {
		return translate("resources.signalalerts.entryrange");
	}
	if (item.name === "latestPrice") {
		return translate("resources.signalalerts.pairpriceUpdatedAt");
	} else {
		return getTitle(item, translate);
	}
}
function getTitle(item, translate) {
	if (item.name === "targetPoint") {
		return translate("resources.signalalerts." + item.name, {
			index: item.index + 1,
		});
	} else if (item.name === "latestPrice") {
		return item.symbol;
	} else {
		return translate("resources.signalalerts." + item.name);
	}
}

const SignalStatusContainer = (props) =>
	!!props && !!props.record && !!props.record.pair ? <SignalStatus {...props} /> : <span />;
const SignalStatus = ({ id, record = {}, resource }) => {
	const classes = useStyles();
	const translate = useTranslate();

	const locale = useLocale();
	//const { data: pair, loading, error } = useGetOne("pairs", record.pair);
	const [summaryValues, setSummaryValues] = useState([{ name: "SL", value: 0, color: "thumbNormal" }]);

	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	let { entryPrice, stopLoss, targetPoints, leverage, entryAverage, closed, opened, pair: pairInRecord } = record;
	const [pair, setPair] = useState(
		pairInRecord?._id ? pairInRecord : { tickSize: 0.01, baseAsset: "", latestPrice: 0, fake: true }
	);
	//const { loading, loaded, referenceRecord } = useReference({ id: pairInRecord?._id ? pairInRecord?._id : pairInRecord, reference: "pairs" });
	useEffect(() => {
		if (record?.pair?._id) {
			setPair(record?.pair);
		}
	}, [record]);
	useEffect(() => {
		if (pair) {
			let _smAllValues = [
				{
					name: "SL",
					value: stopLoss.valuenumber,
					color: "thumbError",
					triggeredAt: stopLoss.triggeredAt,
					differencefromAvg: getProfitBasedOnEntryAvg(stopLoss, record),
					NotExtractedLossPercent: getNotExtractedLoss(record),
					LossBasedOnEntryAvg: getLossBasedOnEntryAvg(record),
				},
			];
			if (!pair.fake) {
				_smAllValues.push({
					name: "",
					value: pair.latestPrice,
					color: "thumbNormal",
					baseAsset: pair.baseAsset,
				});
			}
			if (!opened) {
				_smAllValues.push({
					name: "Low",
					value: entryPrice.low,
					color: "thumbNormal",
					triggeredAt: entryPrice.triggeredAt,
				});
				_smAllValues.push({
					name: "High",
					value: entryPrice.high,
					color: "thumbNormal",
					triggeredAt: entryPrice.triggeredAt,
				});
			} else if (entryAverage) {
				_smAllValues.push({
					name: "Entry",
					value: entryAverage,
					color: "thumbNormal",
					triggeredAt: true,
				});
			} else if (!entryAverage) {
				_smAllValues.push({
					name: "Entry",
					value: ((entryPrice.low + entryPrice.high) / 2).toFixed(countDecimals(pair.tickSize)),
					color: "thumbNormal",
					triggeredAt: true,
				});
			}
			targetPoints.forEach((tp, index) => {
				_smAllValues.push({
					name: "TP",
					value: tp.valuenumber,
					index: index,
					percent: tp.percent,
					color: "thumbSuccess",
					differencefromAvg: getProfitBasedOnEntryAvg(tp, record),
					profitWithExtraction: getProfitWithAppliedExtraction(tp, record),
					triggeredAt: tp.triggeredAt,
				});
			});
			_smAllValues.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
			setSummaryValues(_smAllValues);
		}
	}, [pair, closed, opened, targetPoints]);

	const getSliderValues = () => {
		return summaryValues.map((smv) => {
			return smv.value;
		});
	};
	const getMin = () => {
		const min = Array.min(getSliderValues());
		return min;
	};
	const getMax = () => {
		const max = Array.max(getSliderValues());
		return max;
	};

	const getTrackLabelText = (indx) => {
		return summaryValues[indx].name === "TP" ? "TP" + (summaryValues[indx].index + 1) : summaryValues[indx].name;
	};
	const getThumbColor = (index) => {
		return summaryValues[index].color;
	};
	const getThumbContent = (index) => {
		const item = summaryValues[index];
		let res = <span></span>;
		switch (item.name) {
			case "TP":
				if (item.triggeredAt) {
					res = <CheckIcon className={classes.thumbContent} />;
				} else if (!item.triggeredAt && closed) {
					res = <CloseIcon className={classes.thumbContent} />;
				}

				break;
			case "SL":
				if (item.triggeredAt) {
					res = <CheckIcon className={classes.thumbContent} />;
				}
				break;
			case "High":
				if (item.triggeredAt) {
					res = <CheckIcon className={classes.thumbContentContrast} />;
				} else if (!item.triggeredAt && !opened) {
					res = <AlarmIcon className={classes.thumbContentContrast} />;
				}
				break;
			case "Low":
				if (item.triggeredAt) {
					res = <CheckIcon className={classes.thumbContentContrast} />;
				} else if (!item.triggeredAt && !opened) {
					res = <AlarmIcon className={classes.thumbContentContrast} />;
				}
				break;
			case "Entry":
				res = <CheckIcon className={classes.thumbContentContrast} />;
				break;

			default:
				res = (
					<Avatar
						alt={item.baseAsset}
						src={"https://cdn.dealerify.io/asseticons/svg/color/" + item.baseAsset.toLowerCase() + ".svg"}
						className={classes.assetAvatar}
						//style={{ width: parseInt(size, 10),backgroundColor:borderColor === 'transparent' ? 'lightgray' : borderColor, height: parseInt(size, 10),borderColor:borderColor,borderStyle:'solid',borderWidth:'1px' }}
						//   className={className ? className : styles.avatar}
					></Avatar>
				);
		}
		return res;
	};
	const getTooltip = (index) => {
		const item = summaryValues[index];
		let res = "";
		switch (item.name) {
			case "TP":
				res = (
					<Box>
						<Typography>{"Distance: " + item.differencefromAvg + "%"}</Typography>
						<Typography>{"Extraction: " + item.percent + "%"}</Typography>
						<Typography>{"Pnl: " + item.profitWithExtraction + "%"}</Typography>
					</Box>
				);

				break;
			case "SL":
				res = (
					<Box>
						<Typography>{"Distance: " + item.differencefromAvg + "%"}</Typography>
					</Box>
				);

				break;
			case "High":
				res = "Waiting for entry";
				break;
			case "Low":
				res = "Waiting for entry";
				break;
			case "Entry":
				res = "Signal Entry Average";
				break;

			default:
				res = item.baseAsset + " Price";
		}
		return res;
	};
	return (
		<Box minHeight={115} display={"flex"} alignItems={"center"}>
			<SignalStatusSlider
				value={getSliderValues()}
				smallValueLabel
				className={classes.formInputSlider}
				track={"false"}
				getAriaLabel={getTrackLabelText}
				//getAriaLabel={()=>translate("resources.signalalerts.stopLoss")}
				getThumbColor={getThumbColor}
				min={getMin()}
				max={getMax()}
				getContent={(index) => getThumbContent(index)}
				getTooltip={(index) => getTooltip(index)}
				marks={
					!!pairInRecord
						? [
								{
									value: pair.latestPrice,
									label: pair.baseAsset + "\n" + pair.latestPrice,
								},
						  ]
						: undefined
				}
				getDisabled={(index) => {
					return ["TP", "SL"].indexOf(summaryValues[index].name) !== -1 && !summaryValues[index].triggeredAt;
				}}
				//getAriaValueText={getEnterPriceStopLossSliderText}

				// max={enterPriceEnd * 2}
				valueLabelDisplay="on"
				valueLabelFormat={(val, index) => (summaryValues[index].name ? summaryValues[index].value : "")}

				//valueLabelFormat={getTrackValueText}

				//getAriaValueText={valuetext}
			/>
		</Box>
	);
};
var countDecimals = function (value) {
	let text = value.toString();
	// verify if number 0.000005 is represented as "5e-6"
	if (text.indexOf("e-") > -1) {
		let [base, trail] = text.split("e-");
		let deg = parseInt(trail, 10);
		return deg;
	}
	// count decimals for number in representation like "0.123456"
	if (Math.floor(value) !== value) {
		return value.toString().split(".")[1].length || 0;
	}
	return 0;
};
export default SignalStatusContainer;
