import React from "react";
import {
	BooleanInput,
	Create,
	email,
	FormDataConsumer,
	maxLength,
	maxValue,
	minLength,
	minValue,
	regex,
	required,
	SimpleForm,
	TextInput,
	useLocale,
	usePermissions,
	useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import mobileValidate from "../shared/MobileValidate";
import {JsonInput} from "react-admin-json-view";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const EmailsCreate = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const { permissions } = usePermissions();
	return (
		<Create {...props} label={"resources.sms.createnew"}>
			<SimpleForm redirect={"list"}>
				{permissions?._id === "5fc928bde690dc5ffc675ef6" && <BooleanInput source="sendToAll" />}

				<FormDataConsumer>
					{({ formData, ...rest }) =>
						!formData.sendToAll && (
							<TextInput source="to" formClassName={classes.normalfield} validate={emailValidate} />
						)
					}
				</FormDataConsumer>

				<TextInput source="subject" formClassName={classes.address} />
				<TextInput source="template" formClassName={classes.address} />
				<JsonInput
					source="templateArgs"
					reactJsonOptions={{
						// Props passed to react-json-view

						name: null,
						collapsed: true,
						enableClipboard: false,
						displayDataTypes: false,
					}}
				/>
				<TextInput source="templateArgs" format={(v) => JSON.stringify(v)} parse={(v) => JSON.parse(v)} multiline />
			</SimpleForm>
		</Create>
	);
};
const totValidate = [required(), minValue(10), maxValue(600)];
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];
const mobileValidatation = [
	required(),
	mobileValidate,
	regex(/^([+]\d{1,3})?\d{10}$/, "Invalid mobile format"),
	minLength(11),
	maxLength(15),
];
const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default EmailsCreate;
