import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import {Divider} from "@material-ui/core/";
import {makeStyles} from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Labeled, Loading, useListContext, useTranslate} from "react-admin";
import ProfitField from "./ProfitField";
import SignalStatus from "../vendorsignals/SignalStatus";
import Box from "@material-ui/core/Box";
import SignalInfoField from "./SignalInfoField";
import VendorOrUserAvatar from "./VendorOrUserAvatar";
import SignalActions from "./SignalActions";
import PositionsCards from "./PositionsCards";
//import AvatarField from './AvatarField';
//import ColoredNumberField from './ColoredNumberField';

const useStyles = makeStyles((theme) => ({
	root: { margin: "1em" },
	card: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		margin: "0.5rem 0",
	},
	cardTitleContent: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	cardContent: {
		...theme.typography.body1,
		display: "flex",
		lineHeight: "1.8",
		flexDirection: "column",
	},
	titleTextContainer: {
		display: "flex",
		flexDirection: "column",
	},
	titleAvatar: {},
	titleAvatarStopLossed: {
		backgroundColor: theme.palette.error.main,
	},
	titleAvatarTrending: {
		backgroundColor: theme.palette.success.main,
	},
	pairTitleBold: {
		...theme.typography.h6,
	},
	pairTitleSmall: {},
	pairMarket: {
		...theme.typography.caption,
		color: theme.palette.grey[500],
	},
	signalName: {
		...theme.typography.body1,
		color: theme.palette.grey[800],
	},
	disabledAlert: {
		...theme.typography.subtitle1,
	},
	priceText: {},
	priceDate: {
		...theme.typography.caption,
		color: theme.palette.grey[500],
	},
	createdAt: {
		...theme.typography.caption,
		color: theme.palette.grey[400],
	},
	divider: {
		marginTop: 5,
		marginBottom: 5,
	},
	triggeredAt: {
		...theme.typography.body2,
	},
	notifby: {
		...theme.typography.body2,
	},
	positionTypeRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	avatarRoot: {
		width: 30,
		height: 30,
		marginRight: 5,
	},
	positionTypeAvatarError: {
		backgroundColor: theme.palette.error.main,
	},
	positionTypeAvatarSuccess: {
		backgroundColor: theme.palette.success.main,
	},
	entryPriceRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	stopLossRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	normalAvatar: {},
	entryAvatarTriggered: {
		backgroundColor: theme.palette.primary.main,
	},
	stopLossAvatarTriggered: {
		backgroundColor: theme.palette.error.main,
	},
	noPadding: {
		padding: 0,
	},
	profitRoot: {
		minWidth: 100,
		textAlign: "center",
	},
	profitZero: {
		fontWeight: "bold",
		fontSize: "1.1em",
		backgroundColor: "#1c1c1c",
		borderRadius: 24,
		padding: 12,
	},
	profitGreen: {
		fontWeight: "bold",
		fontSize: "1.1em",
		color: theme.palette.success.main,
		backgroundColor: "#1c1c1c",
		borderRadius: 24,
		padding: 12,
	},
	profitRed: {
		fontWeight: "bold",
		fontSize: "1.1em",
		color: theme.palette.warning.main,
		backgroundColor: "#1c1c1c",
		borderRadius: 24,
		padding: 12,
	},
}));
const SimpleArrayField = ({ record, source }) =>
	record ? (
		<span>
			{record[source].map((item, index) => (
				<span key={index}>{item}</span>
			))}
		</span>
	) : (
		<span />
	);

const BatchSignalListMobileGrid = (props = {}) => {
	const classes = useStyles();
	const { total, resource, basePath, loaded } = useListContext();
	const { record = {} } = props;
	if (!record) {
		return <Loading />;
	}

	return (
		<Card className={classes.card} variant={"outlined"}>
			<CardHeader
				disableTypography={true}
				title={
					<Box display={"flex"}>
						<Box fontSize={"0.7em"}>
							<VendorOrUserAvatar label={"Vendor"} record={record} />
						</Box>

						<SignalInfoField label={"Signal"} record={record} />
					</Box>
				}
				action={<SignalActions label={"Actions"} record={record} />}
			/>
			<CardContent className={classes.cardContent}>
				{resource === "history" && <Box>{record?.pair?._symbol}</Box>}
				<Labeled label={resource === "history" ? "PnL" : "Unrealized PnL"}>
					<ProfitField record={record} classes={classes} label={resource === "history" ? "PnL" : "Unrealized PnL"} />
				</Labeled>

				<Box marginRight={1} marginLeft={1} marginBottom={1}>
					<SignalStatus label={"Status"} record={record} basePath={basePath} />
				</Box>
			</CardContent>

			<Accordion TransitionProps={{ unmountOnExit: true }} className={classes.noPadding}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
					<Typography className={classes.heading}>Positions</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.noPadding}>
					<PositionsCards record={record} id={record._id} />
				</AccordionDetails>
			</Accordion>
		</Card>
	);
};
const BatchSignalListMobileGridIterator = ({ ids, data, basePath }) => {
	const translate = useTranslate();
	const classes = useStyles();

	if (!ids || !data) {
		return null;
	}

	return (
		<div className={classes.root}>
			{ids.map((id) => (
				<BatchSignalListMobileGrid record={data[id]} basePath={basePath} key={id} />
			))}
		</div>
	);
};

BatchSignalListMobileGridIterator.defaultProps = {
	data: {},
	ids: [],
};

export default BatchSignalListMobileGridIterator;
