import {TranslationMessages} from "ra-core";
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
	...englishMessages,
	loginPage: {
		loginHint: "Sign in with Mobile number & password.",
		or: "Or",
		register: "Sign up",
		ifnotregisterd: "If not registered yet",
		phonenumber: "Mobile Number",
		alreadyregistered: "If already have an account",
		examplephonenumber: "Mobile number without country code",
		mobilenotnumbererror: "Use only numbers in phone number",
		registerHintphonenumber: "Enter your mobile phone number to register",
		step0ButtonText: "Send Verification Code",
		step1ButtonText: "Confirm",
		step2ButtonText: "Register",
		mobilestartswith09Errpr: "Mobile Number MUST start with 09",
		mobilelengthError: "Mobile Number Must have 11 digits",
		mobileNumberError: "Only Numbers are supported",
		verificationHint: "Enter verification code sent to:\n%{phonenumber}",
		verificationCode: "Verification Code",
		verificationHelper: "6 digits number",
		verificationLengthError: "Verification Must have 6 digits",
		wrongnumber: "Entered wrong phone number?",
		back: "Back",
		smsError: "Error While sending SMS",
		wrongcode: "Wrong verification code entered, Please try again!",
		lastFormHint: "Fill this form to complete registration",
		firstName: "First Name",
		lastName: "Last Name",
		email: "Email",
		password: "Password",
		passwordConfirmation: "Confirm Password",
		successRegister: "Congratulation , you have been successfully registered.",
		weakpassword:
			"Your choosen password is very weak, Please use combination of Upper/Lower Case alphabets and symbols",
		errors: {
			alreadyregisteredMobile:
				"You have already registered this Mobile Number, Try 'Sign In' or change Mobile Number",

			wait2minutes: "You have to wait 2 minutes before your next try",
			smserror: "Error while sending message, Please contact us",
			alreadyregisteredEmail: "You havee alreay registered with this email address",
			wrongtoken: "Wrong verification code entered, Please try again!",
			weakpassword: "Password is weak",
		},
	},
	pos: {
		search: "Search",
		configuration: "Configuration",
		apply: "Apply",
		actionDone: "Done",
		language: "Language",
		theme: {
			name: "Theme",
			light: "Light",
			dark: "Dark",
		},
		dashboard: {
			pttuserscount: "PTT Users Quantity",
			daysremaining: "Remaining Days",
			serviceexpiredate: "Expire Date",
			servicestartedat: "Starting Date",
			clientname: "Client Name",
			servicestatus: "Service Status",
		},
	},
	resources: {
		"users|usertype|vendors": {
			Name: "Vendors",
		},
		admins: {
			menutitle: "Admins",
			listtitle: "Admins List",
			createnew: "Create New Admin",
			name: "Name",
			firstName: "First Name",
			lastName: "Last Name",
			phonenumber: "Mobile Number",
			email: "Email",
			createdat: "Created At",
			desc: "Description",
			lastlogin: "Last Login",
			password: "Password",
			color: "Color",
			avatar: "Avatar",
			disabled: "Disabled",
			identity: "Identity",
			dropimages: "Drop Images here (Max Size: 1MB)",
			acceessType: "Access Type",
		},
		referralcodes: {
			menutitle: "Referral Codes",
			listtitle: "Referral Codes List",
			createnew: "Create New Referral Code",
			name: "Referral Code",
			owner: "Owner",
			code: "Code",
			disabled: "Disabled",
			limit: "Limit",
		},
		vendors: {
			menutitle: "Vendors",
			listtitle: "Vendors List",
			createnew: "Create New Vendor",
			Name: "Vendors",
			nickName: "Nick Name",
			allowedToWork: "Allowed to work",
			active: "Active",
			firstName: "First Name",
			lastName: "Last Name",
			phonenumber: "Mobile Number",
			email: "Email",
			createdat: "Created At",
			desc: "Description",
			lastlogin: "Last Login",
			password: "Password",
			color: "Color",
			avatar: "Avatar",
			disabled: "Disabled",
			identity: "Identity",
			dropimages: "Drop Images here (Max Size: 1MB)",
			acceessType: "Access Type",
			monthlyPrice: "Monthly Price",
			discountPercent: "Discount %",
			annuallyPaidDiscountPercent: "Annually Paid Discount %",
			quarterlyPaidDiscountPercent: "Quarterly Paid Discount %",
			semiAnnualPaidDiscountPercent: "Semi-Annual Paid Discount %",
			updatedat: "Updated At",
			totalSignals: "Total Signals",
			lastWeekProfitSpot: "7D Profit Spot",
			lastMonthProfitSpot: "1M Profit Spot",
			totalProfitSpot: "Total Profit Spot",
			totalSpotSignals: "Total Spot",
			totalFuturesSignals: "Total Futures",
			totalProfitFutures: "Total Profit Futures",
			lastWeekProfitFutures: "7D Profit Futures",
			lastMonthProfitFutures: "1M Profit Futures",
			autoTradePrice: "Auto Trade Price",
			currentBalance: "Current Balance",
			totalIncome: "Total Income",
		},
		clients: {
			firstName: "First Name",
			lastName: "Last Name",
			changeFirstName: "Change First Name",
			changeLastName: "Change Last Name",
			vendorsubscriptions: "Vendor Subscriptions",
			phonenumber: "Phone number",
			menutitle: "Clients",
			listtitle: "Clients List",
			createnew: "Create New Client",
			name: "Name",
			changepassword: "Change Password",
			not_mobile_country: "Should start with country code: +99...",
			telegramId: "Telegram ID",
			email: "Email",
			createdat: "Created At",
			desc: "Description",
			prefix: "Prefix",
			lastlogin: "Last Login",
			updatedat: "Updated At",
			createdby: "Created By",
			color: "Color",
			avatar: "Avatar",
			pttusersenabled: "PTT Users Allowed",
			password_weak: "Entered Password is too weak, enter stronger password!",
			not_mobile: "Mobile Number must start with 09",
			invalid_char: "Spaces and Invalid Characters not allowed",
			serviceNotInitiated: "Not Initiated",
			serviceExpired: "Expired",
			serviceRunning: "Running",
			people: "Users",
			remainingDays: "Days remained",
			serviceStatus: "Service Status",
			status: "Status",
			serviceExpiresAt: "Service Expires At",
			serviceStartedAt: "Service Started At",
			serviceQuantity: "Service Quantity",
			identity: "Identity",
			information: "Information",
			disabled: "Disabled",
			password: "Password",
			dropimages: "Drop Images here (Max Size: 1MB)",
		},
		markets: {
			tradetype: "Trade Type",
			menutitle: "Markets",
			listtitle: "Markets List",
			showtitle: "Market",
			createtitle: "Create Market",
			edittitle: "Edit Market",
			allusers: "All users",
			name: "Name",
			desc: "Description",
			markettype: "Market Type",
			websiteURL: "Website",
			disabled: "Disabled",
			createdAt: "Created At",
			updatedAt: "Updated At",
			className: "Class Name",
			tvDataURL: "Trading View Data URL",
			priceTickerReceivedAt: "Price Ticker Time",
			candlesReceivedAt: "Candles Time",
		},
		subscriptionplans: {
			tradetype: "Trade Type",
			menutitle: "Plans",
			listtitle: "Plans List",
			showtitle: "Plan",
			createtitle: "Create Plan",
			edittitle: "Edit Plan",
			allusers: "All users",
			name: "Name",
			desc: "Description",
			monthlyPrice: "Monthly Price",
			monthlyOnSale: "Monthly On Sale",
			monthlyOnSalePrice: "Monthly On Sale Price",
			quarterlyOnSale: "Quarterly On Sale",
			quarterlyOnSalePrice: "Quarterly On Sale Price",
			annuallyOnSale: "Annually On Sale",
			annuallyOnSalePrice: "Annually On Sale Price",
			disabled: "Disabled",
			createdAt: "Created At",
			updatedAt: "Updated At",
		},
		invoices: {
			menutitle: "Invoices",
			listtitle: "Invoices List",
			showtitle: "Invoice",
			status: "Status",
			name: "Name",
			desc: "Description",
			statusChanged: "Invoice Status Changed",
			websiteURL: "Website",
			disabled: "Disabled",
			createdAt: "Created At",
			updatedAt: "Updated At",
			className: "Class Name",
			paymentaddress: "Payment Address",
			addresscoppied: "Payment Address copied to clipboard",
			invoiceNumber: "Invoice number",
			expiresAt: "Expires At",
			expiresIn: "Address Expires In",
			purchasable: "Purchasable",
		},
		accountingdocuments: {
			menutitle: "Accounting",
			listtitle: "Accounting Docs List",
			edittitle: "Accounting Doc Edit",
			status: "Status",
			type: "Source",
			desc: "Description",
			amount: "Amount",
			disabled: "Disabled",
			createdAt: "Created At",
			updatedAt: "Updated At",
			className: "Class Name",
			paymentaddress: "Payment Address",
			addresscoppied: "Payment Address copied to clipboard",
			invoiceNumber: "Invoice number",
			expiresAt: "Expires At",
			expiresIn: "Address Expires In",
			purchasable: "Purchasable",
			side: "Side",
		},
		pairs: {
			listtitle: "Pairs List",
			menutitle: "Pairs",
			edittitle: "Pair",
			createtitle: "Create Pair",
			market: "Market",
			symbol: "Symbol",
			baseAsset: "Base Asset",
			quoteAsset: "Quote Asset",
			status: "Is trading",
			removedfrommarket: "Removed",
			disabled: "Disabled",
			createdAt: "Created At",
			updatedAt: "Updated At",
			allmarkets: "All Markets",
			allpairs: "All Pairs",
			latestPrice: "Latest Price",
			latestPriceTime: "Latest Price Time",
		},
		candles: {
			listtitle: "Candles List",
			menutitle: "Candles",
			pair: "Pair",
			intv: "Interval",
			ot: "Open Time",
			ct: "Close Time",
			op: "Open Price",
			cp: "Close Price",
			hp: "High Price",
			lp: "Low Price",
			bv: "Base Vol",
			qv: "Quote Vol",
			closed: "Closed",
			createdAt: "Created At",
			updatedAt: "Updated At",
		},
		observes: {
			listtitle: "Observes List",
			menutitle: "Observes",
			edittitle: "Edit Observe",
			createtitle: "Create Observe",
			triggered: "Triggered",
			condition: "Condition",
			createdAt: "Created At",
			updatedAt: "Updated At",
			triggeredAt: "Triggered At",
			disabled: "Disabled",
			deleted: "Deleted",
			client: "Client",
			conditionType: "Condition Type",
			conditionValue: "Condition Value",
			pair: "Pair",
			market: "Market",
			allusers: "All Users",
			actionType: "Action Type",
		},
		alerts: {
			cantedittriggred: "This alert has been triggered and can not be edited",
			pairinfo: "Pair Information",
			properties: "Properties",
			manage: "Manage",
			listtitle: "Alerts List",
			menutitle: "Alerts",
			edittitle: "Manage Alert",
			createtitle: "Create Alert",
			triggered: "Triggered",
			condition: "Condition",
			createdAt: "Created At",
			updatedAt: "Updated At",
			triggeredAt: "Triggered At",
			disabled: "Disabled",
			deleted: "Deleted",
			client: "Client",
			conditionType: "Condition Type",
			conditionValue: "Condition Value",
			notifby: "Notification by",
			pair: "Pair",
			market: "Market",
			allusers: "All Users",
			notiftypes: "Notification Types",
			desc: "Description",

			mobileGrid: {
				notifywhenText: "Notifies when ",
				triggeredAt: "Alert triggered at:",
				createdAt: "Alert created at:",
				notifby: "You will be notifed by: ",
				notifedby: "You have been notifed by: ",
				priceUpdatedAt: "Price updated at:",
				pairpricetext: "Every %{baseAsset} is  %{latestPrice} %{quoteAsset}",
			},
			wizardselectmarket: "Market",
			wizardselectpair: "Pair",
			wizardcondition: "Condition",
			wizardselectnotif: "Notification",
			wizard: {
				markethelper: "Please choose your market ",
				pairhelper: "Start typing to find your pair in %{marketname}",
				wizardconditionheadertext: "Notify me when %{pairname} from %{marketname} is",
				conditionValue: "value",
				to: "To",
				pairpricetext: "1 %{baseAsset} is  %{latestPrice} %{quoteAsset}   updated at :",
			},
		},
		signalalerts: {
			cantedittriggred: "This alert has been triggered and can not be edited",
			pairinfo: "Pair Information",
			properties: "Properties",
			manage: "Manage",
			listtitle: "Signal Alerts List",
			menutitle: "Signal Alerts",
			edittitle: "Manage Signal",
			createtitle: "Create Signal Alert",
			createtitleSpot: "Create Spot Signal",
			createtitleFutures: "Create Futures Signal",
			menutitleSpot: "Client Spot Signals",
			menutitleFutures: "Client Futures Signals",
			entryrange: "Entry Price Range",
			latestPrice: "Latest Price",
			enterPriceStart: "Entry Low",
			enterPriceEnd: "Entry High",
			name: "Signal Alert",
			signalName: "Name",
			namehelper: "Signal Name",
			entry: "Entry",
			low: "Low",
			targetPoints: "Target Points",
			high: "High",
			stopLossHint: "Set Stop Loss price to handle risks",
			targetPointsHint: "Add target points",
			capitalManagementHit: "Set target points Extraction percents to save profit",
			capitalManagement: "Capital Management",
			targetprice: "Target Price",
			triggered: "Triggered",
			condition: "Condition",
			createdAt: "Created At",
			updatedAt: "Updated At",
			triggeredAt: "Triggered At",
			disabled: "Disabled",
			deleted: "Deleted",
			client: "Client",
			conditionType: "Condition Type",
			conditionValue: "Condition Value",
			notifby: "Notification by",
			pair: "Pair",
			market: "Market",
			allusers: "All Users",
			notiftypes: "Notification Types",
			desc: "Description",
			positionType: "Position Type",
			stopLoss: "Stop Loss",
			signalSummaryHint: "Signal Summary",
			signalSummaryHint2: "Please check all values very carefully",
			options: "Options",
			mobileGrid: {
				notifywhenText: "Notifies when ",
				triggeredAt: "Alert triggered at:",
				createdAt: "Alert created at:",
				notifby: "You will be notified by: ",
				notifedby: "You have been notified by: ",
				priceUpdatedAt: "Price updated at:",
				pairpricetext: "Every %{baseAsset} is  %{latestPrice} %{quoteAsset}",
			},
			wizardselectmarketPositionType: "Market & Position Type",
			wizardenterPriceStopLoss: "Enter Price & Stop Loss",
			wizardenterPricetitle: "Entry Price",
			wizardselectmarket: "Market",
			wizardselectpair: "Pair",
			wizardcondition: "Condition",
			wizardselectnotif: "Notification",
			wizard: {
				markethelper: "Please choose your market ",
				pairhelper: "Start typing to find your pair in %{marketname}",
				wizardconditionheadertext: "Notify me when %{pairname} from %{marketname} is",
				conditionValue: "value",
				to: "To",
				pairpricetext: "1 %{baseAsset} is  %{latestPrice} %{quoteAsset}   updated at :",
			},
			waitingForEntry: "Waiting for Entry Price",
			entryTriggeredAt: "Reached EntryPrice: ",
			stopLossTriggeredAt: "Reached StopLoss: ",
			targetPointTriggered: "Reached TP %{tpindex}: ",
			lastStatus: "Last Status",
			targetPoint: "Target Point %{index}",
			tp: "TP%{index}",
			price: "Price",
			reachedAt: "Reached at: ",
			pairpriceUpdatedAt: "Pair price updated at: ",
			extraction: "Extraction",
			detailstimeline: "Details Timeline",
			notReached: "Not reached",
			notReachedYet: "Not reached yet",
			willnotReachDuetoStopLoss: "Will not reach due to StopLoss",
			removeTargetPoint: "Remove Target Point",
			areyousuretoRemoveTP: "Are you sure to remove Target Point %{index}?",
			addTP: "Add Target Point",
			targetPointsExtraction: "Target Points Extraction percents",
			extractedYet: "%{percent}% of your stcok Extracted till now",
			reachedTargetPoints: "Reached %{r} of %{a} Target Point(s)",
			from: "From",
			latestprice: "Latest Price",
			editErrorConflict: "Conflict with latest signal updates: %{message}",
			reachedEntryPrice: "Signal reached Entry Price",
			reachedStopLoss: "Signal reached Stop Loss",
			signalreachedTargetPoint: "Signal reached Target Point %{index}",
			entryAverage: "Entry Average",
			lossBasedOnEntyAvg: "Loss (based on Entry Average): %{loss}%",
			differenceBasedOnEntyAvg: "Difference form Entry Average: %{profit}%",
			profitWithAppliedExtraction: "Profit with applied extraction: %{profit}%",
			fulltargetProfit: "Full targets profit: %{profit}%",
			notextractedLoss: "Not Extracted Loss: %{loss}%",
			totalProfit: "Total Profit",
		},
		clspositions: {
			accountname: "Account Name",
			menutitle: "Client Spot Positions",
			listtitle: "Client Spot Positions List",
			edittitle: "Manage Client Spot Position",
			showtitle: "Client Spot Position",
			market: "Market",
			exchange: "Exchange",
			apikey: "API Key",
			secretkey: "Secret Key",
			apicredentials: "API Credentials",
			validationCheckedAt: "Validation Time",
			isValid: "Is Valid",
			information: "Information",
			balance: "balance",
			vendorName: "Vendor",
			signalAlert: "Signal Code",
			exchangeAccount: "Exchange Account",
			baseAssetBalanceBefore: "%{base} Balance Before",
			quoteAssetBalanceBefore: "%{quote} Balance Before",
			minRequiredAsset: "Min Required Amount",
			orderAmount: "Order Amount",
			executedQty: "Executed Quantity",
			cummulativeQuoteQty: "Cumulative Quote Quantity",
			averagePrice: "Average Price",
			openedAt: "Opened At",
			closedAt: "Closed At",
			stopLossed: "StopLoss Triggered",
			stopLossedAt: "StopLoss Triggered At",
			status: "Status",
			orderRole: "Order Role",
			side: "Side",
			lastStatus: "Last Status",
			positionType: "Position Type",
			minRequiredBalance: "Min required quote to open",
			positionStatus: {
				"0": "Waiting for entry",
				"100": "Opened Successfully",
				"101": "Failed to open (Exchange Account Error)",
				"102": "Failed to open (Low balance)",
				"103": "Failed to open (Exchange API Error)",
				"104": "Failed to open (Exchange Order Error)",
				"105": "Signal Deleted Before open",
				"200": "StopLoss Triggered Successfully",
				"201": "Failed to StopLoss (Exchange Account Error)",
				"202": "Failed to StopLoss (Low balance)",
				"203": "Failed to StopLoss (Exchange API Error)",
				"204": "Failed to StopLoss (Exchange Order Error)",
				"3i0": "TP %{i} Triggered Successfully",
				"3i1": "TP %{i} Failed (Exchange Account Error)",
				"3i2": "TP %{i} Failed (Low balance)",
				"3i3": "TP %{i} Failed (Exchange API Error)",
				"3i4": "TP %{i} Failed (Exchange Order Error)",
			},
		},
		clufuturespositions: {
			accountname: "Account Name",
			menutitle: "Personal Futures Positions",
			listtitle: "Personal Futures Positions List",
			positionType: "Position Type",
			edittitle: "Manage Personal Futures Position",
			showtitle: "Personal Futures Position",
			market: "Market",
			exchange: "Exchange",
			apikey: "API Key",
			secretkey: "Secret Key",
			apicredentials: "API Credentials",
			validationCheckedAt: "Validation Time",
			isValid: "Is Valid",
			information: "Information",
			balance: "balance",
			vendorName: "Vendor",
			signalAlert: "Signal Code",
			exchangeAccount: "Exchange Account",
			baseAssetBalanceBefore: "%{base} Balance Before",
			quoteAssetBalanceBefore: "%{quote} Balance Before",
			minRequiredAsset: "Min Required Amount",
			orderAmount: "Order Amount",
			executedQty: "Executed Quantity",
			cummulativeQuoteQty: "Cumulative Quote Quantity",
			averagePrice: "Average Price",
			openedAt: "Opened At",
			closedAt: "Closed At",
			stopLossed: "StopLoss Triggered",
			stopLossedAt: "StopLoss Triggered At",
			status: "Status",
			orderRole: "Order Role",
			side: "Side",
			lastStatus: "Last Status",
			minRequiredBalance: "Min required quote to open",
			positionStatus: {
				"0": "Waiting for entry",
				"100": "Opened Successfully",
				"101": "Failed to open (Exchange Account Error)",
				"102": "Failed to open (Low balance)",
				"103": "Failed to open (Exchange API Error)",
				"104": "Failed to open (Exchange Order Error)",
				"105": "Signal Deleted Before open",
				"200": "StopLoss Triggered Successfully",
				"201": "Failed to StopLoss (Exchange Account Error)",
				"202": "Failed to StopLoss (Low balance)",
				"203": "Failed to StopLoss (Exchange API Error)",
				"204": "Failed to StopLoss (Exchange Order Error)",
				"3i0": "TP %{i} Triggered Successfully",
				"3i1": "TP %{i} Failed (Exchange Account Error)",
				"3i2": "TP %{i} Failed (Low balance)",
				"3i3": "TP %{i} Failed (Exchange API Error)",
				"3i4": "TP %{i} Failed (Exchange Order Error)",
			},
		},
		vendorsignals: {
			allvendors: "All Vendors",
			cantedittriggred: "This signal has been triggered and can not be edited",
			pairinfo: "Pair Information",
			properties: "Properties",
			manage: "Manage",
			delete: "Delete",
			closePositionMarket: "Market Close Position Now",
			closePosition: "Close Position",
			positionClosed: "Position Closed",
			menutitleSpot: "Vendor Spot Signals",
			menutitleFutures: "Vendor Futures Signals",
			listtitle: "Vendor Signals List",
			createtitleSpot: "Create Spot Signal",
			createtitleFutures: "Create Futures Signal",
			listtitleSpot: "Spot Signals List",
			listtitleFutures: "Futures Signals List",
			menutitle: "Vendor Signals",
			edittitle: "Manage Vendor Signal",
			createtitle: "Create Vendor Signal",
			entryrange: "Entry Price Range",
			latestPrice: "Latest Price",
			enterPriceStart: "Entry Low",
			enterPriceEnd: "Entry High",
			name: "Vendor Signal",
			signalName: "Code",
			namehelper: "Signal Code",
			entry: "Entry",
			low: "Low",
			targetPoints: "Target Points",
			high: "High",
			stopLossHint: "Set Stop Loss price to handle risks",
			targetPointsHint: "Add target points",
			capitalManagementHit: "Set target points Extraction percents to save profit",
			capitalManagement: "Capital Management",
			targetprice: "Target Price",
			triggered: "Triggered",
			condition: "Condition",
			createdAt: "Created At",
			updatedAt: "Updated At",
			triggeredAt: "Triggered At",
			disabled: "Disabled",
			deleted: "Deleted",
			client: "Client",
			conditionType: "Condition Type",
			conditionValue: "Condition Value",
			notifby: "Notification by",
			pair: "Pair",
			market: "Market",
			allusers: "All Users",
			notiftypes: "Notification Types",
			desc: "Description",
			positionType: "Position Type",
			stopLoss: "Stop Loss",
			signalSummaryHint: "Signal Summary",
			signalSummaryHint2: "Please check all values very carefully",
			options: "Options",
			mobileGrid: {
				notifywhenText: "Notifies when ",
				triggeredAt: "Alert triggered at:",
				createdAt: "Alert created at:",
				notifby: "You will be notifed by: ",
				notifedby: "You have been notifed by: ",
				priceUpdatedAt: "Price updated at:",
				pairpricetext: "Every %{baseAsset} is  %{latestPrice} %{quoteAsset}",
			},
			wizardselectmarketPositionType: "Market & Position Type",
			wizardenterPriceStopLoss: "Enter Price & Stop Loss",
			wizardenterPricetitle: "Entry Price",
			wizardselectmarket: "Market",
			wizardselectpair: "Pair",
			wizardcondition: "Condition",
			wizardselectnotif: "Notification",
			wizard: {
				markethelper: "Please choose your market ",
				pairhelper: "Start typing to find your pair in %{marketname}",
				wizardconditionheadertext: "Notify me when %{pairname} from %{marketname} is",
				conditionValue: "value",
				to: "To",
				pairpricetext: "1 %{baseAsset} is  %{latestPrice} %{quoteAsset}   updated at :",
			},
			waitingForEntry: "Waiting for Entry Price",
			entryTriggeredAt: "Reached EntryPrice: ",
			stopLossTriggeredAt: "Reached StopLoss: ",
			targetPointTriggered: "Reached TP %{tpindex}: ",
			lastStatus: "Last Status",
			targetPoint: "Target Point %{index}",
			tp: "TP%{index}",
			price: "Price",
			reachedAt: "Reached at: ",
			pairpriceUpdatedAt: "Pair price updated at: ",
			extraction: "Extraction",
			detailstimeline: "Details Timeline",
			notReached: "Not reached",
			notReachedYet: "Not reached yet",
			willnotReachDuetoStopLoss: "Will not reach due to StopLoss",
			removeTargetPoint: "Remove Target Point",
			areyousuretoRemoveTP: "Are you sure to remove Target Point %{index}?",
			addTP: "Add Target Point",
			targetPointsExtraction: "Target Points Extraction percents",
			extractedYet: "%{percent}% of your stcok Extracted till now",
			reachedTargetPoints: "Reached %{r} of %{a} Target Point(s)",
			from: "From",
			latestprice: "Latest Price",
			editErrorConflict: "Conflict with latest signal updates: %{message}",
			reachedEntryPrice: "Signal reached Entry Price",
			reachedStopLoss: "Signal reached Stop Loss",
			signalreachedTargetPoint: "Signal reached Target Point %{index}",
			entryAverage: "Entry Average",
			lossBasedOnEntyAvg: "Loss (based on Entry Average): %{loss}%",
			loss: "Loss: %{loss}%",
			differenceBasedOnEntyAvg: "Difference form Entry : %{profit}%",
			profitWithAppliedExtraction: "Profit with  extraction: %{profit}%",
			fulltargetProfit: "Full profit: %{profit}%",
			notextractedLoss: "Not Extracted Loss: %{loss}%",
			totalProfit: "Total Profit",
			vendor: "Vendor",
			view: "View",
			fullTargeted: "Full Target",
			closed: "Closed",
			profit: "Profit",
			leverage: "Leverage",
			setPositionLeverage: "Set Position Leverage",
		},
		sms: {
			listtitle: "SMS List",
			menutitle: "SMS",
			createtitle: "Send New SMS",
			phonenumber: "Phone Number",
			text: "Text",
			success: "Sent",
			createdAt: "Sent At",
			updatedAt: "Updated At",
			phonenumberCount: "PhoneNumbers Count",
		},
		emails: {
			listtitle: "Emails List",
			menutitle: "Emails",
			createtitle: "Send New Email",
			phonenumber: "Phone Number",
			text: "Text",
			success: "Sent",
			createdAt: "Sent At",
			updatedAt: "Updated At",
			phonenumberCount: "PhoneNumbers Count",
		},
		vendorsubscriptions: {
			listtitle: "Vendor Subscriptions List",
			menutitle: "Vendor Subscriptions",
			expired: "Expired",
			disabled: "Disabled",
			vendor: "Vendor",
			client: "Client",
			createdAt: "Created At",
			startedAt: "Started At",
			expiresAt: "Expires At",
			updatedAt: "Updated At",
			disabledNotifications: "Disable notifications (SMS,...)",
			notifications: "Notifications",
			autoTrade: "Auto trade",
			disabledTrades: "Disable auto trades",
			serviceType: "Service Type",
		},
		appsubscriptions: {
			listtitle: "App Subscriptions List",
			menutitle: "App Subscriptions",
			expired: "Expired",
			disabled: "Disabled",
			vendor: "Vendor",
			client: "Client",
			createdAt: "Created At",
			startedAt: "Started At",
			expiresAt: "Expires At",
			updatedAt: "Updated At",
			disabledNotifications: "Disable notifications (SMS,...)",
			notifications: "Notifications",
			autoTrade: "Auto trade",
			disabledTrades: "Disable auto trades",
			serviceType: "Service Type",
		},
		exchangeaccounts: {
			accountname: "Account Name",
			menutitle: "Exchange Accounts",
			createtitle: "Create Exchange Account",
			market: "Market",
			exchange: "Exchange",
			apikey: "API Key",
			secretkey: "Secret Key",
			apicredentials: "API Credentials",
			validationCheckedAt: "Validation Time",
			isValid: "Is Valid",
			information: "Information",
			balance: "balance",
			listtitle: "Exchange Accounts List",
		},

		atspositions: {
			accountname: "Account Name",
			menutitle: "Vendor Spot Positions",
			listtitle: "Auto Trade Spot Positions List",

			edittitle: "Manage AutoTrade Spot Position",
			showtitle: "AutoTrade Spot Position",
			market: "Market",
			exchange: "Exchange",
			apikey: "API Key",
			secretkey: "Secret Key",
			apicredentials: "API Credentials",
			validationCheckedAt: "Validation Time",
			isValid: "Is Valid",
			information: "Information",
			balance: "balance",
			vendorName: "Vendor",
			vendorSignal: "Signal Code",
			exchangeAccount: "Exchange Account",
			baseAssetBalanceBefore: "%{base} Balance Before",
			quoteAssetBalanceBefore: "%{quote} Balance Before",
			minRequiredAsset: "Min Required Amount",
			orderAmount: "Order Amount",
			executedQty: "Executed Quantity",
			cummulativeQuoteQty: "Cumulative Quote Quantity",
			averagePrice: "Average Price",
			openedAt: "Opened At",
			closedAt: "Closed At",
			stopLossed: "StopLoss Triggered",
			stopLossedAt: "StopLoss Triggered At",
			status: "Status",
			orderRole: "Order Role",
			side: "Side",
			lastStatus: "Last Status",
			positionType: "Position Type",
			minRequiredBalance: "Min required quote to open",
			positionStatus: {
				"0": "Waiting for entry",
				"100": "Opened Successfully",
				"101": "Failed to open (Exchange Account Error)",
				"102": "Failed to open (Low balance)",
				"103": "Failed to open (Exchange API Error)",
				"104": "Failed to open (Exchange Order Error)",
				"105": "Signal Deleted Before open",
				"200": "StopLoss Triggered Successfully",
				"201": "Failed to StopLoss (Exchange Account Error)",
				"202": "Failed to StopLoss (Low balance)",
				"203": "Failed to StopLoss (Exchange API Error)",
				"204": "Failed to StopLoss (Exchange Order Error)",

				"3i0": "TP %{i} Triggered Successfully",
				"3i1": "TP %{i} Failed (Exchange Account Error)",
				"3i2": "TP %{i} Failed (Low balance)",
				"3i3": "TP %{i} Failed (Exchange API Error)",
				"3i4": "TP %{i} Failed (Exchange Order Error)",
			},
		},
		atufuturespositions: {
			accountname: "Account Name",
			menutitle: "Vendor Futures Positions",
			listtitle: "Auto Trade Futures Positions List",
			positionType: "Position Type",
			edittitle: "Manage AutoTrade Futures Position",
			showtitle: "AutoTrade Futures Position",
			market: "Market",
			exchange: "Exchange",
			apikey: "API Key",
			secretkey: "Secret Key",
			apicredentials: "API Credentials",
			validationCheckedAt: "Validation Time",
			isValid: "Is Valid",
			information: "Information",
			balance: "balance",
			vendorName: "Vendor",
			vendorSignal: "Signal Code",
			exchangeAccount: "Exchange Account",
			baseAssetBalanceBefore: "%{base} Balance Before",
			quoteAssetBalanceBefore: "%{quote} Balance Before",
			minRequiredAsset: "Min Required Amount",
			orderAmount: "Order Amount",
			executedQty: "Executed Quantity",
			cummulativeQuoteQty: "Cumulative Quote Quantity",
			averagePrice: "Average Price",
			openedAt: "Opened At",
			closedAt: "Closed At",
			stopLossed: "StopLoss Triggered",
			stopLossedAt: "StopLoss Triggered At",
			status: "Status",
			orderRole: "Order Role",
			side: "Side",
			lastStatus: "Last Status",
			minRequiredBalance: "Min required quote to open",
			positionStatus: {
				"0": "Waiting for entry",
				"100": "Opened Successfully",
				"101": "Failed to open (Exchange Account Error)",
				"102": "Failed to open (Low balance)",
				"103": "Failed to open (Exchange API Error)",
				"104": "Failed to open (Exchange Order Error)",
				"105": "Signal Deleted Before open",
				"200": "StopLoss Triggered Successfully",
				"201": "Failed to StopLoss (Exchange Account Error)",
				"202": "Failed to StopLoss (Low balance)",
				"203": "Failed to StopLoss (Exchange API Error)",
				"204": "Failed to StopLoss (Exchange Order Error)",

				"3i0": "TP %{i} Triggered Successfully",
				"3i1": "TP %{i} Failed (Exchange Account Error)",
				"3i2": "TP %{i} Failed (Low balance)",
				"3i3": "TP %{i} Failed (Exchange API Error)",
				"3i4": "TP %{i} Failed (Exchange Order Error)",
			},
		},
		cdn: {
			dropimages: "Drop Image or Click here (Max Size:5MB)",
		},
	},
};

export default customEnglishMessages;
