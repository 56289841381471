import {createHashHistory} from "history";
import polyglotI18nProvider from "ra-i18n-polyglot";
import React from "react";
import {Admin as RAAdmin, Resource,ShowGuesser } from "react-admin";
import {Provider} from "react-redux";
import accountingDocuments from "./accountingdocuments";
import ADMINs from "./admins";
import alerts from "./alerts";

import "./App.css";
import appsubscriptions from "./appsubscriptions";
import atspositions from "./atspotpositions";
import atufuturespositions from "./atufuturespositions";
import batchSignals from "./batchsignals";
import candles from "./candles";
import clients from "./clients";
import clspositions from "./clspotpositions";
import clufuturespositions from "./clufuturespositions";
import email from "./email";
import exchangeaccounts from "./exchangeaccounts";
import giftcodes from "./giftcodes";
import batchOrders from './batchorders'
import {authClient, feathersClient, restClient} from "./feathers";
import englishMessages from "./i18n/en";
import farsiMessages from "./i18n/fa";
import invoices from "./invoices";

import {Layout, Login} from "./layout";
import links from "./links";
import markets from "./markets";
import observes from "./observes";
import pairs from "./pairs";
import {themeReducer,} from "./reducers";
import referralcodes from "./referralcodes";

import customRoutes from "./routes";
import {JSONShow} from "./shared/JSONShow";
import signalalerts from "./signalalerts";
import sms from "./sms";
import stcategories from "./stcategories";
import createAdminStore from "./store/createAdminStore";
import subscriptionplans from "./subscriptionplans";
import supporttickets from "./supporttickets";
import useChangeThemeColor from "./useChangeThemeColor";
import vendors from "./vendors";
import vendorsignals from "./vendorsignals";
import vendorsubscriptions from "./vendorsubscriptions";

const getLocalStorageDefaultLang = () => {
	let res = localStorage.getItem("locale");
	return res ? res : "en";
};
const i18nProvider = polyglotI18nProvider((locale) => {
	if (locale === "fa-IR") {
		return farsiMessages;
	}

	// Always fallback on english
	return englishMessages;
}, getLocalStorageDefaultLang());

const restClientOptions = {
	id: "_id", // In this example, the database uses '_id' rather than 'id'

	usePatch: true, // Use PATCH instead of PUT for updates
};

const authClientOptions = {
	storageKey: "login-jwt", // The key in localStorage used to store the authentication token
	/*authenticate: { // Options included in calls to Feathers client.authenticate
        strategy: 'local', // The authentication strategy Feathers should use
    },*/
	permissionsKey: "meta", // The key in localStorage used to store permissions from decoded JWT
	permissionsField: "meta", // The key in the decoded JWT containing the user's role
	passwordField: "password", // The key used to provide the password to Feathers client.authenticate
	usernameField: "uniqueId", // The key used to provide the username to Feathers client.authenticate
	redirectTo: "/login", // Redirect to this path if an AUTH_CHECK fails. Uses the react-admin default of '/login' if omitted.
	//logoutOnForbidden: true, // Logout when response status code is 403
};
declare var global: any;
const RC = restClient(feathersClient, restClientOptions);
global.RC = RC;
const authP = authClient(feathersClient, authClientOptions);
global.feathers = feathersClient;
const history = createHashHistory();
const App = () => (
	<Provider
		store={createAdminStore({
			authProvider: authP,
			dataProvider: RC,
			history,
			customReducers: {
				theme: themeReducer,
			},
		})}
	>
		<Admin />
	</Provider>
);
const Admin = () => {
	useChangeThemeColor();
	return (
		<RAAdmin
			disableTelemetry
			title=""
			dataProvider={RC}
			customRoutes={customRoutes}
			authProvider={authP}
			history={history}
			/*  dashboard={ClientDashboard}*/
			loginPage={Login}
			layout={Layout}
			i18nProvider={i18nProvider}
		>
			{(permissions: any) => [
				<Resource name="supporttickets" {...supporttickets} />,
				<Resource name="users|usertype|CLIENT" {...clients} />,
				<Resource
					name="users|usertype|ADMIN"
					edit={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? ADMINs.edit
							: undefined
					}
					create={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? ADMINs.create
							: undefined
					}
					list={ADMINs.list}
				/>,

				<Resource
					name="users|usertype|VENDOR"
					edit={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? vendors.edit
							: undefined
					}
					create={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? vendors.create
							: undefined
					}
					list={vendors.list}
				/>,
				<Resource name="users" />,
				<Resource
					name="markets"
					edit={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? markets.edit
							: undefined
					}
					create={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? markets.create
							: undefined
					}
					list={markets.list}
				/>,
				<Resource
					name="pairs"
					edit={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? pairs.edit
							: undefined
					}
					create={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? pairs.create
							: undefined
					}
					list={pairs.list}
				/>,
				<Resource name="candles" {...candles} />,
				<Resource name="alerts" {...alerts} />,
				<Resource name="batchorders" {...batchOrders} />,
				<Resource name="signalalerts" />,
				<Resource name="signalalerts|tradetype|0" {...signalalerts} />,
				<Resource name="signalalerts|tradetype|1" {...signalalerts} />,
				<Resource name="observes" {...observes} />,
				<Resource name="sms" {...sms} />,
				<Resource name="links" {...links} />,
				<Resource
					name="vendorsignals"
					edit={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? vendorsignals.edit
							: undefined
					}
					create={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? vendorsignals.create
							: undefined
					}
					list={vendorsignals.list}
				/>,
				<Resource
					name="subscriptionplans"
					edit={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? subscriptionplans.edit
							: undefined
					}
					create={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? subscriptionplans.create
							: undefined
					}
					list={subscriptionplans.list}
				/>,
				<Resource
					name="vendorsignals|tradetype|0"
					edit={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? vendorsignals.edit
							: undefined
					}
					create={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? vendorsignals.create
							: undefined
					}
					list={vendorsignals.list}
				/>,
				<Resource
					name="vendorsignals|tradetype|1"
					edit={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? vendorsignals.edit
							: undefined
					}
					create={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? vendorsignals.create
							: undefined
					}
					list={vendorsignals.list}
				/>,
				<Resource
					name="vendorsubscriptions"
					list={vendorsubscriptions.list}
					edit={vendorsubscriptions.edit}
					create={permissions?._id === "5fc928bde690dc5ffc675ef6" ? vendorsubscriptions.create : undefined}
				/>,
				<Resource name="appsubscriptions" {...appsubscriptions} />,
				<Resource name="vendorsubscriptiontools" />,
				<Resource name="externaltools" />,
				<Resource name="appsubscriptiontools" />,
				<Resource
					name="exchangeaccounts"
					edit={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? exchangeaccounts.edit
							: undefined
					}
					create={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? exchangeaccounts.create
							: undefined
					}
					list={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? exchangeaccounts.list
							: undefined
					}
				/>,
				<Resource
					name="emails"
					create={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? email.create
							: undefined
					}
					list={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? email.list
							: undefined
					}
				/>,
				<Resource name="atspotpositions" {...atspositions} />,
				<Resource name="exchangebalances" />,
				<Resource name="atspotorders" />,
				<Resource name="atspotorders" />,
				<Resource name="giftcodes" {...giftcodes} />,
				<Resource name="atufuturesorders" />,
				<Resource name="atufuturespositions" {...atufuturespositions} />,
				<Resource name="referralcodes" {...referralcodes} />,
				<Resource
					name="invoices"
					list={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? invoices.list
							: undefined
					}
					show={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? invoices.show
							: undefined
					}
				/>,
				<Resource name="clspotorders" />,
				<Resource name="clufuturesorders" />,
				<Resource name="stcategories" {...stcategories} />,
				<Resource name="stmessages" />,

				<Resource name="clspotpositions" {...clspositions} />,
				<Resource name="clufuturespositions" {...clufuturespositions} />,
				<Resource name="spot" {...batchSignals} />,
				<Resource name="futures" {...batchSignals} />,
				<Resource name="history" list={batchSignals.historyList} />,
				<Resource name="cdn" />,
				<Resource
					name="accountingdocuments"
					list={accountingDocuments.list}

					create={
						permissions && permissions.usertype === "ADMIN" && permissions.accessType === 1
							? accountingDocuments.create
							: undefined
					}
					show={JSONShow}
				/>,
			]}
		</RAAdmin>
	);
};

export default App;
