import React from "react";
import Avatar from "@material-ui/core/Avatar";
import {makeStyles} from "@material-ui/core/styles";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";

const useStyles = makeStyles((theme) => ({
	avatar: {
		marginRight: theme.spacing(1),
	},
}));
const AvatarField = ({ record, size = "25", className = "", borderColor = "transparent" }) => {
	const styles = useStyles();
	if (record && record.color && record.color !== "") {
		borderColor = record.color;
	}
	if (record) {
		if (record.avatar && record.avatar.src) {
			const filePath = record.avatar.src;
			console.log(filePath);
			return (
				<Avatar
					src={`${filePath}`}
					style={{
						width: parseInt(size, 10),
						backgroundColor: borderColor === "transparent" ? "lightgray" : borderColor,
						height: parseInt(size, 10),
						borderColor: borderColor,
						borderStyle: "solid",
						borderWidth: "1px",
					}}
					className={className ? className : styles.avatar}
				/>
			);
		} else {
			return (
				<Avatar
					style={{
						width: parseInt(size, 10),
						backgroundColor: borderColor === "transparent" ? "lightgray" : borderColor,
						height: parseInt(size, 10),
						borderColor: borderColor,
						borderStyle: "solid",
						borderWidth: "1px",
					}}
					className={className ? className : styles.avatar}
				>
					{record.isMobile && <PhoneIphoneIcon color={"action"} fontSize={"small"} />}
					{!record.isMobile && <DesktopWindowsIcon color={"action"} fontSize={"small"} />}
				</Avatar>
			);
		}
	} else {
		return null;
	}
};

export default AvatarField;
