const ConditionTypes = [
    {
        id : "egr",
        name : "Greater than or equal >= ",
        shortName:">="
    },
    {
        id : "elr",
        name : "Less than or equal <= ",
        shortName:"<="
    }
];
const positionTypes = [
    {
        id : "buy",
        name : "Buy / Long",

    },
    {
        id : "sell",
        name : "Sell / Short",

    }
];
const NotifTypes =[
    {
        id:'SMS',
        name:"SMS"

    }
]
export {ConditionTypes,NotifTypes,positionTypes}
