const TicketStatus = [
	{
		id: "opened",
		name: "Opened",
		color: "primary",
	},
	{
		id: "inreview",
		name: "In review",
		color: "primary",
	},
	{
		id: "inprogress",
		name: "In progress",
		color: "secondary",
	},
	{
		id: "onhold",
		name: "On hold",
		color: "secondary",
	},
	{
		id: "answered",
		name: "Answered",
		color: "primary",
	},
	{
		id: "userreplied",
		name: "User replied",
		color: "primary",
	},
	{
		id: "closed",
		name: "Closed",
		color: "default",
	},
];

export { TicketStatus };
