import React from "react";
import {
    AutocompleteInput,
    Create,
    maxLength,
    minLength,
    ReferenceInput,
    required,
    SelectInput,
    TextInput,
} from "react-admin";

import {makeStyles} from "@material-ui/core/styles";

import {Typography} from "@material-ui/core";

import Wizard from "../shared/Wizard";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	wizardPage: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "Space-around",
		alignItems: "center",
		//    height:"100%",
		padding: "15px",
		//  flex:1
	},
	formInput: {
		//    maxWidth:'100%'
		width: "100%",
		maxWidth: "500px",
		//  minWidth:'250px'
	},
};
const useStyles = makeStyles(styles);

const SupportTicketsCreateWizard = (props) => {
	const classes = useStyles();

	const wizardSteps = [
		{
			title: "Topic",
			fields: ["category"],
		},
		{
			title: "Content",
			fields: ["title", "message"],
		},
	];
	return (
		<Create {...props} label={"resources.exchangeaccounts.createtitle"}>
			<Wizard steps={wizardSteps} {...props} redirect={"list"}>
				<div className={classes.wizardPage}>
					<ReferenceInput
						validate={required()}
						emptyText={"Select Categery"}
						label={"Category"}
						source="category"
						reference="stcategories"
						perPage={200}
						allowEmpty={false}
						className={classes.formInput}
					>
						<SelectInput
							optionText={(option) => (
								<Typography>
									{option?.title}{" "}
									<Typography variant={"caption"} color={"textSecondary"} component={"span"}>
										{option.desc}
									</Typography>
								</Typography>
							)}
						/>
					</ReferenceInput>
					<ReferenceInput
						validate={required()}
						label={"resources.signalalerts.client"}
						source="user"
						perPage={25}
						reference="users"
						allowEmpty={false}
						filterToQuery={(searchText) =>
							searchText.trim() !== ""
								? {
										$or: [
											{ firstName: { $search: searchText } },
											{ lastName: { $search: searchText } },
											{ nickName: { $search: searchText } },
											{ telegramId: { $search: searchText } },
										],
								  }
								: { fake: true }
						}
					>
						<AutocompleteInput
							matchSuggestion={() => true}
							optionText={(record) =>
								record?.firstName
									? record?.firstName +
									  " " +
									  record?.lastName +
									  (record?.telegramId ? " " + record?.telegramId : "") +
									  (record?.nickName ? " " + record?.nickName : "") +
									  " (" +
									  record.usertype +
									  ")"
									: "Select User"
							}
							optionValue={"_id"}
						/>
					</ReferenceInput>
				</div>

				<div className={classes.wizardPage}>
					<TextInput
						source="title"
						validate={[required(), maxLength(20), minLength(5)]}
						fullWidth={true}
						className={classes.formInput}
					/>
					<TextInput
						source="message"
						className={classes.formInput}
						validate={[required(), maxLength(2048), minLength(5)]}
						fullWidth={true}
						multiline={true}
					/>
				</div>
			</Wizard>
		</Create>
	);
};

export default SupportTicketsCreateWizard;
