import React, {memo} from "react";
import {makeStyles} from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {Backdrop, Fade, useMediaQuery} from "@material-ui/core";
import {AddTargetPointIcon, EditChartIcon} from "../../shared/CustomIcons";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {useForm, useFormState} from "react-final-form";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";

const actions = [
	{ icon: <FileCopyIcon />, name: "Copy" },
	{ icon: <SaveIcon />, name: "Save" },
	{ icon: <PrintIcon />, name: "Print" },
	{ icon: <ShareIcon />, name: "Share" },
	{ icon: <FavoriteIcon />, name: "Like" },
];
const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: 10,
	},
	exampleWrapper: {
		position: "relative",
		marginTop: theme.spacing(3),
		height: 380,
	},
	radioGroup: {
		margin: theme.spacing(1, 0),
	},
	speedDial: {
		zIndex: 1300,
		position: "absolute",
		top: theme.spacing(0),
		right: theme.spacing(15),
		[theme.breakpoints.down("sm")]: {
			top: theme.spacing(5),
			right: theme.spacing(1),
		},
	},
}));
const ChartActions = (props) => {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const [hidden, setHidden] = React.useState(false);
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const form = useForm();
	const { values } = useFormState();
	const { pairObject, entryPrice, positionType, targetPoints, activeStep, markettradetype, leverage, entryReset, cloned, hideOrders, hidePanel } =
		values;

	const getTargetPointDefaultInitVal = () => {
		if (!targetPoints || targetPoints.length < 1 || !pairObject) return 0;
		let defaultTP = 0;
		if (positionType === "buy") {
			const maxTarget = Array.max(targetPoints?.map((tp) => tp.valuenumber));
			defaultTP = Number((maxTarget + maxTarget * 0.02).toFixed(countDecimals(pairObject?.tickSize)));
		} else {
			const minTarget = Array.min(targetPoints?.map((tp) => tp.valuenumber));
			defaultTP = Number((minTarget - minTarget * 0.02).toFixed(countDecimals(pairObject?.tickSize)));
		}
		return defaultTP;
	};
	const handleAddTargetPoint = (price) => {
		const { entryPrice, stopLoss, targetPoints, positionType } = form.getState().values;
		let newTargetVal = Number(price.toFixed(countDecimals(pairObject.tickSize)));
		let newPercent = targetPoints[targetPoints.length - 1].percent / 2;
		let shouldCurrectPercent = false;
		if (newPercent >= 1 && countDecimals(newPercent) > 1) {
			shouldCurrectPercent = true;
		}
		let newTargetPoints = Array.from(targetPoints);
		newTargetPoints[newTargetPoints.length - 1].percent = shouldCurrectPercent ? Math.floor(newPercent) : newPercent;
		newTargetPoints.push({
			valuenumber: newTargetVal,
			percent: shouldCurrectPercent ? Math.ceil(newPercent) : newPercent,
		});
		form.change("hideOrders", false);
		form.change("targetPoints", newTargetPoints);

		form.change("tpCountChangedAt", new Date());
	};
	const addTargetPoint = () => {
		handleAddTargetPoint(getTargetPointDefaultInitVal());
		handleClose();
	};
	const toggleHideShowOrders = () => {
		form.change("hideOrders", !hideOrders);
		handleClose();
	};
	const toggleHideShowPanel = () => {
		form.change("hideOrders", false);
		form.change("hidePanel", false);
		handleClose();
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	return (
		<Box>
			<SpeedDial
				ariaLabel="SpeedDial example"
				className={classes.speedDial}
				hidden={hidden}
				icon={<SpeedDialIcon icon={<EditChartIcon />} />}
				onClose={handleClose}
				onOpen={handleOpen}
				open={open}
				direction={"down"}
				TransitionComponent={Fade}
				FabProps={{ size: "small", disabled: !pairObject }}
			>
				<SpeedDialAction
					delay={100}
					icon={<AddTargetPointIcon color={"primary"} />}
					tooltipTitle={
						<Typography noWrap={true} variant={"caption"}>
							Add TargetPoint
						</Typography>
					}
					onClick={addTargetPoint}
					tooltipOpen
				/>
				<SpeedDialAction
					delay={200}
					icon={!!hideOrders ? <VisibilityIcon color={"primary"} /> : <VisibilityOffIcon color={"secondary"} />}
					tooltipTitle={
						<Typography noWrap={true} variant={"caption"}>
							{!!hideOrders && "Show Orders"}
							{!hideOrders && "Hide Orders"}
						</Typography>
					}
					onClick={toggleHideShowOrders}
					tooltipOpen
				/>
				<SpeedDialAction
					delay={300}
					icon={<PublishRoundedIcon color={"primary"} />}
					tooltipTitle={
						<Typography noWrap={true} variant={"caption"}>
							Publish Signal
						</Typography>
					}
					onClick={toggleHideShowPanel}
					tooltipOpen
				/>
			</SpeedDial>
			<Backdrop open={open} className={classes.backdrop} />
		</Box>
	);
};
export default memo(ChartActions);
var countDecimals = function (value) {
	let text = value.toString();
	// verify if number 0.000005 is represented as "5e-6"
	if (text.indexOf("e-") > -1) {
		let [base, trail] = text.split("e-");
		let deg = parseInt(trail, 10);
		return deg;
	}
	// count decimals for number in representation like "0.123456"
	if (Math.floor(value) !== value) {
		return value.toString().split(".")[1].length || 0;
	}
	return 0;
};
