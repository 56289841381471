import React, {memo} from "react";
import {Avatar, Box, Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import config from "../config";

const styles = (theme) => ({
	avatarRoot: {
		backgroundColor: "rgba(0,0,0,0.1)",
		color: "#fff",
		padding: 1,

		fontWeight: "bolder",
	},
	baseAvatar: {},
	baseText: {
		marginLeft: theme.spacing(1),
		fontSize: "1.2em",
		fontWeight: "bold",
	},
	quoteText: {
		fontSize: "0.85em",
		fontWeight: 400,
		marginLeft: 4,
		marginRight: 3,
	},
	quoteAvatar: {
		opacity: 0.8,
		width: 25,
		height: 25,
		marginLeft: 4,
	},

	avatarGroup: {
		alignItems: "center",
	},
});
const PairAvatar = (props) => {
	const { classes, base, quote, pairObject, _symbol, textVariant = "caption" } = props;
	const baseName = base || pairObject?.base || _symbol?.split("/")?.[0];
	const quoteName = quote || pairObject?.quote || _symbol?.split("/")?.[1];
	return (
		<Box display={"flex"} alignItems={"flex-end"} flexDirection={"row"}>
			<Avatar
				className={classNames(classes.avatarRoot, classes.baseAvatar)}
				variant={"circle"}
				alt={baseName}
				src={config["AssetIconCDN"] + baseName?.toLowerCase() + ".svg"}
			/>
			<Box display={"flex"} alignItems={"flex-end"} flexDirection={"row"}>
				<Typography className={classes.baseText}>{baseName}</Typography>
				<Typography className={classes.quoteText} color={"textSecondary"}>
					{"/"}
				</Typography>
				<Typography className={classes.quoteText} color={"textSecondary"}>
					{quoteName}
				</Typography>
			</Box>
			{quoteName?.toLowerCase() !== "usdt" && (
				<Avatar
					className={classNames(classes.avatarRoot, classes.quoteAvatar)}
					alt={quoteName}
					src={config["AssetIconCDN"] + quoteName?.toLowerCase() + ".svg"}
				/>
			)}
		</Box>
	);
};
export default memo(withStyles(styles, { name: "PairAvatar" })(PairAvatar));
