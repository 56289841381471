import React from "react";
import {
    AutocompleteInput,
    BooleanField, Datagrid,
    DateField,
    EditButton,
    Filter, FunctionField,
    List, NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput, SelectField,
    TextField,
    useLocale,
    useTranslate,TextInput
} from "react-admin";

import {Box, Typography} from "@material-ui/core";
import {JsonField} from "@bb-tech/ra-components";
import {spotOrderRoles} from "../clspotpositions/CLSPositionsEnum";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";

const StatusFieldColored = ({ record }) => (
    <TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);
const ListFilter = (props) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput label={"Status"}   source="status.$search" alwaysOn />
            <ReferenceInput
                alwaysOn
                label={"resources.pairs.market"}
                source="market"
                perPage={10}
                reference="markets"
                allowEmpty={true}
                filterToQuery={(searchText) =>
                    searchText.trim() !== ""
                        ? {
                            name: { $search: searchText },
                        }
                        : null
                }
            >
                <AutocompleteInput
                    optionText={"name"}
                    allowEmpty={true}
                    optionValue={"_id"}
                    emptyText={"resources.pairs.allmarkets"}
                    alwaysOn
                />
            </ReferenceInput>
            <ReferenceInput
                alwaysOn
                source="owner"
                perPage={10}
                reference="users"
                allowEmpty={true}
                filter={{
                    usertype: { $ne: "ADMIN" },
                }}
                filterToQuery={(searchText) =>
                    searchText.trim() !== ""
                        ? {
                            $or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
                        }
                        : null
                }
            >
                <AutocompleteInput
                    optionText={(record) =>
                        record && record.firstName
                            ? record.firstName + " " + record.lastName
                            : translate("resources.observes.allusers")
                    }
                    allowEmpty={true}
                    optionValue={"_id"}
                    emptyText={"resources.observes.allusers"}
                    alwaysOn
                />
            </ReferenceInput>
        </Filter>
    );
};
const OrderIdField = ({record})=>{
    return <Box>
        {record?.orderId &&
        <Typography color={"primary"} variant={"caption"}>{record?.orderId}</Typography>}
        <Typography color={record?.orderId  ? "textPrimary" : record?.error  ? "error" : "textSecondary"}  variant={"caption"} component={"div"}>{record?._id}</Typography>
    </Box>
}
const ErrorsField = ({record})=>{
    return <Box style={{maxWidth:500,lineBreak: "anywhere"}}>
        <TextField record={record  || {}} source="error" />
        <JsonField record={record  || {}}  source={"errorDetailsOnPlacement"} />
        <JsonField record={record  || {}} source={"detailsFetchedError"} />
    </Box>
}
const OrderTypeField = ({record})=>{
    return <Box>
        <SelectField
            record={record}
            source="orderRole"
            label="resources.clspositions.orderRole"
            choices={spotOrderRoles}
            optionText={(rec) => <Box color={rec.color}>{rec.name}</Box>}
        />

        <FunctionField
            record={record}
            label="resources.clspositions.side"
            render={(record) => (
                <Box style={{ textTransform: "capitalize" }} color={record.side === "buy" ? "green" : "red"}>
                    {record.side}
                </Box>
            )}
        />
        <TextField source="orderType" />
    </Box>
}
const BatchOrdersList = (props) => {
    const translate = useTranslate();
    const locale = useLocale();
    return (
        <List
            {...props}
            filters={<ListFilter />}
            title={translate("resources.sms.listtitle")}
            perPage={25}
            exporter={false}
            sort={{ field: "createdAt", order: "DESC" }}
            bulkActionButtons={false}
        >
            <Datagrid
                expand={({ id, record, resource }) => (
                    <Box>
                        <JsonField record={{ r: record }} source={"r"} />
                    </Box>
                )}
            >
                <OrderIdField/>
                <ReferenceField source="market" label={"resources.pairs.market"} reference="markets">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="symbol" />
                <TextField source="status" />
                <ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
                    <NameWithAvatarField />
                </ReferenceField>

                <ReferenceField
                    source="exchangeAccount"
                    label={"resources.atufuturespositions.exchangeAccount"}
                    reference="exchangeaccounts"
                >
                    <TextField source="name" />
                </ReferenceField>

                <ReferenceField
                    source="vendor"
                    label={"resources.vendorsubscriptions.vendor"}
                    reference="users|usertype|VENDOR"
                    link={false}
                >
                    <NickNameWithAvatarField />
                </ReferenceField>

                <DateField source="createdAt" showTime locales={locale} />
                <ErrorsField label={"error"}/>
                <TextField source={"attempt"} />
                {/*<NumberField
                    source="baseAssetBalanceBefore"
                    label={translate("resources.clspositions.baseAssetBalanceBefore", { base: pairObject.baseAsset })}
                    options={{ maximumFractionDigits: 10 }}
                />
                <NumberField
                    source="quoteAssetBalanceBefore"
                    label={translate("resources.clspositions.quoteAssetBalanceBefore", {
                        quote: pairObject.quoteAsset,
                    })}
                    options={{ maximumFractionDigits: 10 }}
                />*/}
               {/* <OrderAmount pairObject={pairObject} label={"resources.clspositions.orderAmount"} />

                <MinRequiredAsset pairObject={pairObject} label={"resources.clspositions.minRequiredAsset"} />

                <TextField source="status" />
                <ExecutedQty pairObject={pairObject} label={"resources.clspositions.executedQty"} />
                <CummulativeQuoteQty pairObject={pairObject} label={"resources.clspositions.cummulativeQuoteQty"} />
                <AveragePrice pairObject={pairObject} label={"resources.clspositions.averagePrice"} />*/}

              <OrderTypeField/>
            </Datagrid>
        </List>
    );
};

export { BatchOrdersList };
