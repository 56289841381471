import React from "react";
import {
	AutocompleteInput,
	Create,
	email,
	maxLength, maxValue,
	minLength,
	minValue,
	NumberInput,
	ReferenceInput,
	regex,
	required,
	SimpleForm,
	TextInput,
	useLocale,
	useTranslate,
	DateInput, SelectInput, FormDataConsumer
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import mobileValidate from "../shared/MobileValidate";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const GiftCodesCreate = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const translate = useTranslate();
	return (
		<Create {...props} label={"resources.referralcodes.createnew"}>
			<SimpleForm redirect={"list"}>
				<TextInput
					source="code"
					formClassName={classes.normalfield}
					validate={codeValidate}
					helperText={"Leave empty to Auto Generate"}
				/>
				<SelectInput source="serviceType" validate={requiredValidate} initialValue={"vendorsubscriptions"} choices={[
					{ id: 'vendorsubscriptions', name: 'Vendor Subscriptions' },
					{ id: 'appsubscriptions', name: 'App Subscriptions' }
				]} />
				<FormDataConsumer>
					{({ formData, ...rest }) =>
						 formData.serviceType === "vendorsubscriptions" && (

							<ReferenceInput

								validate={requiredValidate}
								allowEmpty={false}
								label={"resources.vendorsignals.vendor"}
								source="vendor"
								reference="users|usertype|VENDOR"
								perPage={500}
							>
								<SelectInput optionText={<NickNameWithAvatarField />} name={"fakename"} />
							</ReferenceInput>
						)
					}
				</FormDataConsumer>

				<SelectInput source="amountType" validate={requiredValidate} initialValue={"fixed"} choices={[
					{ id: 'fixed', name: 'Fixed $' },
					{ id: 'percentage', name: 'Percent %' }
				]} />
				<FormDataConsumer>
					{({ formData, ...rest }) =>
						formData.amountType === "fixed" && (

							<NumberInput source={"fixedAmount"} validate={[required(), minValue(1), maxValue(500)]} initialValue={5} />
						)
					}
				</FormDataConsumer>
				<FormDataConsumer>
					{({ formData, ...rest }) =>
						formData.amountType === "percentage" && (

							<NumberInput source={"percentageAmount"} validate={[required(), minValue(1), maxValue(100)]} initialValue={10} />
						)
					}
				</FormDataConsumer>
				<NumberInput source={"limit"} validate={limitValidate} initialValue={1} />
				<DateInput source={"expiresAt"} initialValue={addMonths(new Date(), 1)}/>
			</SimpleForm>
		</Create>
	);
};
function addMonths(date, months) {
	var d = date.getDate();
	date.setMonth(date.getMonth() + +months);
	if (date.getDate() !== d) {
		date.setDate(0);
	}
	return date;
}

const limitValidate = [required(), minValue(1), maxValue(500)];
const requiredValidate = [required()];
const codeValidate = [minLength(6), maxLength(12)];


export default GiftCodesCreate;
