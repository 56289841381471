import {useTranslate} from "react-admin";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
	positionStatusGood: {
		color: theme.palette.success.main,
		fontSize: "1.2em",
	},
	positionStatusBad: {
		color: theme.palette.error.main,
		fontSize: "1.2em",
	},
});
const LastStatus = ({ record = {}, classes = {} }) => {
	const translate = useTranslate();
	if (!record) {
		return <span />;
	}
	let statusStr = record?.status?.toString();
	if (statusStr.startsWith("3")) {
		let tpIndex = statusStr.charAt(1);

		return (
			<span className={statusStr.endsWith("0") ? classes.positionStatusGood : classes.positionStatusBad}>
				{" "}
				{translate("resources.clspositions.positionStatus.3i" + statusStr.charAt(statusStr.length - 1), { i: tpIndex })}
			</span>
		);
	} else {
		return (
			<span className={statusStr.endsWith("0") ? classes.positionStatusGood : classes.positionStatusBad}>
				{translate("resources.clspositions.positionStatus." + record.status.toString())}
			</span>
		);
	}
};
export default withStyles(styles, { name: "LastStatus" })(LastStatus);
