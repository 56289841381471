import React, {memo, useState} from "react";
import {Box} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/CancelRounded";
import ClosePositionDialog from "./ClosePositionDialog";

const styles = (theme) => ({
	iconButtonRoot: {},
	icon: {
		fontSize: "1.1em",
	},
	root: { display: "flex", flexDirection: "column" },
});
const PositionActions = (props) => {
	const { classes, position = {}, signal = {}, pair = {}, exchangeAccountObject = {} } = props;
	const { _id, positionType, closed, opened, tradetype, vendor } = position;

	const linkResource = !!vendor
		? "vendorsignals|tradetype|" + tradetype + "/" + _id + "/show"
		: "signalalerts|tradetype|" + tradetype + "/" + _id + "/";
	const [closeDialog, setCloseDialog] = useState(false);
	return (
		<Box className={classes.root}>
			{!closed && (
				<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000} title="Close this position">
					<IconButton
						className={classes.iconButtonRoot}
						aria-label="Close"
						color={"secondary"}
						size={"medium"}

					>
						<CancelIcon className={classes.icon} onClick={() => setCloseDialog(true)} />
						<ClosePositionDialog
							position={position}
							signal={signal}
							pair={pair}
							openDialog={closeDialog}
							setOpenDialog={setCloseDialog}
							exchangeAccountObject={exchangeAccountObject}
						/>
					</IconButton>
				</Tooltip>
			)}
		</Box>
	);
};
export default memo(withStyles(styles, { name: "PositionActions" })(PositionActions));
