import React from "react";
import {Avatar, Box, Tooltip} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

const styles = (theme) => ({
	root: {},
	avatar: {
		fontWeight: "bold",
		fontSize: "1.1em",
		backgroundColor: "#1c1c1c",
		width: 46,
		height: 46,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	shortPosition: { color: theme.palette.warning.main },
	longPosition: { color: theme.palette.success.main },
});
const FuturesMetaField = (props) => {
	const { classes, record } = props;

	return (
		<Box
			className={classNames(classes.root, {
				[classes.shortPosition]: record?.positionType === "sell",
				[classes.longPosition]: record?.positionType === "buy",
			})}
		>
			<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000} title={record?.positionType === "sell" ? "Short" : "Long"}>
				<Avatar
					className={classNames(classes.avatar, {
						[classes.shortPosition]: record?.positionType === "sell",
						[classes.longPosition]: record?.positionType === "buy",
					})}
				>
					<Box height={"1em"}>{record?.positionType === "buy" && <KeyboardArrowUpRoundedIcon fontSize={"small"} />}</Box>
					<Box alignItems={"center"} display={"flex"} fontSize={"1.1em"}>
						{record?.leverage}x
					</Box>
					<Box height={"1em"}>{record?.positionType === "sell" && <KeyboardArrowDownRoundedIcon fontSize={"small"} />}</Box>
				</Avatar>
			</Tooltip>
		</Box>
	);
};
export default withStyles(styles, { name: "VendorOrUserAvatar" })(FuturesMetaField);
