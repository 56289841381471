import React, {cloneElement, useState} from "react";
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    EditButton,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    TextField,
    TopToolbar,
    useDataProvider,
    useLocale,
    useNotify,
    useRefresh,
    useTranslate,
} from "react-admin";
import {makeStyles, Typography, useMediaQuery} from "@material-ui/core";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import {ListRowStyle} from "./ExchangeAccountsRowStyle";
import Button from "@material-ui/core/Button";
import BalanceField from "./BalanceField";
import CreateWithMarketButton from "../shared/CreateWithMarketButton";
import MarketAvatarField from "../shared/MarketAvatarField";

const useStyles = makeStyles((theme) => ({
	balanceChipAvatar: {
		width: 25,
		height: 25,
		fontSize: "0.9em",
	},
	balanceChip: {
		margin: 1,
	},
}));
const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<ReferenceInput
				alwaysOn
				label={"resources.signalalerts.client"}
				source="user"
				perPage={25}
				reference="users"
				allowEmpty={true}
				filter={{
					usertype: "CLIENT",
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
						  }
						: { fake: true }
				}
			>
				<AutocompleteInput
					optionText={(record) =>
						record && record.firstName
							? record.firstName + " " + record.lastName
							: translate("resources.observes.allusers")
					}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
		</Filter>
	);
};
const FundRangeField = ({ record }) => {
	return <Typography> {"$" + record?.autoTradeMinFundUsd + " ~ $" + record?.autoTradeMaxFundUsd}</Typography>;
};
const ExchangeAccountsList = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	return (
		<List
			{...props}
			filters={<ListFilter />}
			filter={{ deleted: { $ne: true } }}
			title={translate("resources.exchangeaccounts.listtitle")}
			perPage={25}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			bulkActionButtons={false}
			actions={<ListActions />}
		>
			{
				<Datagrid optimized rowStyle={ListRowStyle}>
					<ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
						<NameWithAvatarField />
					</ReferenceField>
					<ReferenceField source="market" label={"Exchange"} reference="markets" link={false}>
						<MarketAvatarField />
					</ReferenceField>
					<BooleanField source={"isValid"} />
					<TextField source="name" label={"resources.exchangeaccounts.accountname"} />

					<FundRangeField label={"AutoTrade fund range"} />
					<ReloadBalanceBtn />
					<BalanceField />

					<EditButton label={"resources.alerts.manage"} />
				</Datagrid>
			}
		</List>
	);
};
const ListActions = (props) => {
	const { basePath } = props;
	const tradetype = basePath && basePath.includes("1") ? 1 : 0;

	return (
		<TopToolbar>
			{cloneElement(props.filters, { context: "button" })}
			<CreateWithMarketButton basePath={basePath} />
		</TopToolbar>
	);
};
const ReloadBalanceBtn = ({ record, basePath }) => {
	const refresh = useRefresh();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const [loading, setLoading] = useState(false);

	if (!record) {
		return <span />;
	}
	const handleClick = () => {
		setLoading(true);
		dataProvider
			.create("externaltools", {
				data: {
					action: "requestReloadBalance",
					exchangeAccount: record._id,
				},
			})
			.then(() => {
				refresh();
			})
			.catch((error) => notify(`Error: ${error.message}`, "warning"))
			.finally(() => setLoading(false));
	};
	return (
		<Button variant={"outlined"} color={"default"} onClick={handleClick} disabled={loading} disableFocusRipple={true}>
			Refresh
		</Button>
	);
};

export { ExchangeAccountsList, ReloadBalanceBtn, BalanceField };
