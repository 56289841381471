import Box from "@material-ui/core/Box";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
	maxWidth100: {},
	minWidth100: {},
	maxWidth99P: {
		width: "99%",
	},
	profitRoot: {
		minWidth: 100,
		textAlign: "center",
		display: "flex",

		flexDirection: "row",
		justifyContent: "center",
		borderWidth: 1,
		alignItems: "center",
		borderRadius: 24,
		padding: 12,
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		height: 40,
		marginLeft: theme.spacing(2),
		backgroundColor: "#1c1c1c",
		margin: theme.spacing(1),
	},
	profitZero: {
		fontWeight: "bold",
		fontSize: "1.1em",
		backgroundColor: "#1c1c1c",
	},
	profitGreen: {
		fontWeight: "bold",
		fontSize: "1.1em",
		color: theme.palette.success.main,
		backgroundColor: "#1c1c1c",
	},
	profitRed: {
		fontWeight: "bold",
		fontSize: "1.1em",
		color: theme.palette.warning.main,
		backgroundColor: "#1c1c1c",
	},
});
const PositionProfitField = ({ pair, profit, classes }) => {
	const quote = pair?.quote === "USDT" ? "$" : pair?.quote;

	return (
		<Box className={classes.profitRoot}>
			<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000} title={"Estimated Pnl"}>
				<Box className={profit === 0 ? classes.profitZero : profit > 0 ? classes.profitGreen : classes.profitRed}>
					{profit > 0 && "+"}
					{profit}
					{quote}
				</Box>
			</Tooltip>
		</Box>
	);
};
export default withStyles(styles, { name: "PositionProfitField" })(PositionProfitField);
