import React from "react";
import {
	AutocompleteInput,
	Create,
	email,
	FormDataConsumer,
	maxValue,
	minLength,
	minValue,
	NumberInput,
	ReferenceInput,
	required,
	SelectArrayInput,
	SelectInput,
	SimpleForm,
	TextInput,
	useLocale,
	useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {useForm} from "react-final-form";
import {ConditionTypes, NotifTypes} from "./AlertsEnum";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const MarketSelect = ({ formData, ...rest }) => {
	const form = useForm();

	return (
		<ReferenceInput
			label={"resources.observes.market"}
			source="market"
			reference="markets"
			perPage={0}
			onChange={(value) => form.change("pair", null)}
		>
			<SelectInput optionText="name" onChange={(value) => console.log(value)} />
		</ReferenceInput>
	);
};
const AlertsCreate = (props) => {
	const classes = useStyles();
	const locale = useLocale();

	return (
		<Create {...props} label={"resources.alerts.createtitle"}>
			<SimpleForm redirect={"list"}>
				<FormDataConsumer>{(formDataProps) => <MarketSelect {...formDataProps} />}</FormDataConsumer>

				<FormDataConsumer subscription={{ values: true }}>
					{({ formData, ...rest }) =>
						formData.market && (
							<ReferenceInput
								validate={requiredValidate}
								label={"resources.alerts.pair"}
								source="pair"
								perPage={15}
								reference="pairs"
								allowEmpty={false}
								filter={{ market: formData.market }}
								filterToQuery={(searchText) =>
									searchText.trim() !== ""
										? {
												symbol: { $search: searchText },
										  }
										: null
								}
							>
								<AutocompleteInput optionText={"symbol"} optionValue={"_id"} />
							</ReferenceInput>
						)
					}
				</FormDataConsumer>

				<ReferenceInput
					alwaysOn
					label={"resources.signalalerts.client"}
					source="user"
					perPage={25}
					reference="users|usertype|CLIENT"
					allowEmpty={true}
					filter={{
						usertype: "CLIENT",
					}}
					filterToQuery={(searchText) =>
						searchText.trim() !== ""
							? {
									$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
							  }
							: { fake: true }
					}
				>
					<AutocompleteInput
						optionText={(record) =>
							record && record.firstName ? record.firstName + " " + record.lastName : "All"
						}
						allowEmpty={true}
						optionValue={"_id"}
						emptyText={"resources.observes.allusers"}
						alwaysOn
					/>
				</ReferenceInput>
				<SelectInput
					source="condition.conditionType"
					label="resources.alerts.conditionType"
					choices={ConditionTypes}
					allowEmpty={false}
					validate={requiredValidate}
				/>
				<NumberInput source="condition.args" label="resources.alerts.conditionValue" validate={valueValidate} />
				<SelectArrayInput label="resources.alerts.notiftypes" source="notiftypes" choices={NotifTypes} />
				<TextInput source="desc" multiline={true} fullWidth={true} label={"resources.alerts.desc"} />
			</SimpleForm>
		</Create>
	);
};
const totValidate = [required(), minValue(10), maxValue(600)];
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];
const valueValidate = [required(), minValue(0)];
const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default AlertsCreate;
