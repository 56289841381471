import Icon from "@material-ui/icons/MessageRounded";
import ReferralCodesCreate from "./ReferralCodesCreate";
import ReferralCodesEdit from "./ReferralCodesEdit";

import {ReferralCodesList} from "./ReferralCodesList";

export default {
	create: ReferralCodesCreate,
	edit: ReferralCodesEdit,
	list: ReferralCodesList,
	icon: Icon,
};
