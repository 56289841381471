import React from "react";
import {
    BooleanInput,
    Edit,
    email,
    maxValue,
    minLength,
    minValue,
    NumberInput,
    Pagination,
    required,
    SimpleForm,
    TextInput,
    TopToolbar,
    useLocale,
    useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AddUsersToChannelByChannelBtn from "../shared/AddUsersToChannelByChannelBtn";

import {JsonInput} from "react-admin-json-view";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);
const Title = ({ record }) => {
	const translate = useTranslate();
	return (
		<span>
			{" "}
			{translate("resources.markets.edittitle")} {record ? `${record.name}` : ""}
		</span>
	);
};

const SubscriptionplansEdit = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	return (
		<Edit {...props} title={<Title />}>
			<SimpleForm>
				<NumberInput source={"priority"} />
				<TextInput source="name" label="resources.subscriptionplans.name" validate={requiredValidate} />
				<TextInput source="features" format={(v) => JSON.stringify(v)} parse={(v) => JSON.parse(v)} multiline />
				<JsonInput
					source="features"
					reactJsonOptions={{
						// Props passed to react-json-view

						name: null,
						collapsed: true,
						enableClipboard: false,
						displayDataTypes: false,
					}}
				/>

				<TextInput source="desc" label="resources.subscriptionplans.desc" multiline />
				<NumberInput source={"maxExchangeAccounts"} validate={[minValue(0)]} />
				<NumberInput source={"maxCopyTrade"} validate={[minValue(0)]} />
				<NumberInput source={"monthlyPrice"} validate={[minValue(10)]} />
				<BooleanInput source="monthlyOnSale" />
				<NumberInput source={"monthlyOnSalePrice"} validate={[minValue(10)]} />
				<BooleanInput source="quarterlyOnSale" />
				<NumberInput source={"quarterlyOnSalePrice"} validate={[minValue(10)]} />
				<BooleanInput source="annuallyOnSale" />
				<NumberInput source={"annuallyOnSalePrice"} validate={[minValue(10)]} />
				<BooleanInput source="disabled" />
			</SimpleForm>
		</Edit>
	);
};
const totValidate = [required(), minValue(10), maxValue(600)];
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];
const EditActions = ({ basePath, data, resource }) => (
	<TopToolbar>
		<AddUsersToChannelByChannelBtn record={data} basePath={basePath} />
	</TopToolbar>
);

const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const RFPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const Separator = () => <Box pt="1em" />;

export default SubscriptionplansEdit;
