import React from "react";
import {
	ReferenceField,
	SaveButton,
	Show,
	SimpleShowLayout,
	Toolbar,
	useLocale,
	useNotify,
	useRefresh,
	useTranslate,
} from "react-admin";
import {Box, ButtonBase, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Countdown from "react-countdown";
import CopyIcon from "@material-ui/icons/FileCopy";
import QRCode from "react-qr-code";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import BlockIcon from "@material-ui/icons/Block";
import Avatar from "@material-ui/core/Avatar";
import {InvoiceDesc} from "./InvoicesList";

const useStyles = makeStyles((theme) => ({
	ormalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	paymentDetailsRoot: {
		maxWidth: 600,
	},
	paymentDetailsContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},

	paymentDetailsHeader: {
		alignSelf: "flex-start",
	},
	payAmount: {
		//backgroundColor: "gold",
		padding: 7,
		borderColor: "gold",
		borderWidth: 1,
		borderStyle: "solid",
		borderRadius: 5,
	},
	showLayout: {
		backgroundColor: "transparent",
		border: "none",
	},
}));
const Title = ({ record }) => {
	const translate = useTranslate();
	return <span> {translate("resources.invoices.showtitle") + (record ? "#" + record.invoiceNumber : "")}</span>;
};
const vendorinvoicetypes = [
	"clients_subscribe_vendor_signal",
	"clients_subscribe_vendor_autotrade",
	"clients_subscribe_vendor_extend",
	"clients_subscribe_vendor_upgradetoautotrade",
];
function getDaysRemaining(expiresAt) {
	const _start = new Date();
	const _expire = new Date(expiresAt);

	const result = Math.round((_expire - _start) / (1000 * 60 * 60 * 24));
	return result > 0 ? result : 0;
}
const ShareInformation = ({ record }) => {
	if (vendorinvoicetypes.indexOf(record?.invoicetype) !== -1) {
		return (
			<Box m={1}>
				<Typography>
					Vendor subscription share:{" "}
					{(record?.vendorSubscriptionShare).toFixed(2) + "(%" + record?.subscriptionSharePercent} )
				</Typography>
				<Typography>
					Vendor autotrade share:{" "}
					{(record?.vendorAutoTradeShare).toFixed(2) + "(%" + record?.autoTradeSharePercent} )
				</Typography>
				<Typography>
					Total vendor share: {(record?.vendorAutoTradeShare + record?.vendorSubscriptionShare).toFixed(2)}
				</Typography>
				{!!record?.companySubscriptionShare && (
					<Box>
						<Typography>Company subscription share: {(record?.companySubscriptionShare).toFixed(2)} </Typography>
						<Typography>Company Autotrade share: {(record?.companyAutoTradeShare).toFixed(2)} </Typography>
						<Typography>
							Total company share:{" "}
							{(record?.companySubscriptionShare + record?.companyAutoTradeShare).toFixed(2)}
						</Typography>
					</Box>
				)}
			</Box>
		);
	}
	return <span />;
};
const PaymentDetailsField = ({ record }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const notify = useNotify();
	const refresh = useRefresh();
	if (!record || !record.paymentDetails) {
		return <span />;
	}
	const { payment_id, payment_status, pay_address, price_amount, pay_amount, pay_currency, actually_paid } =
		record.paymentDetails;
	const copyText = () => {
		var TempText = document.createElement("input");
		TempText.value = pay_address;
		document.body.appendChild(TempText);
		TempText.select();

		document.execCommand("copy");
		document.body.removeChild(TempText);
		notify(translate("resources.invoices.addresscoppied"), "success");
	};
	const CartAvatar = () => {
		switch (record.status) {
			case "created":
			case "waiting":
			case "partially":
				return <HourglassEmptyIcon color="primary" />;

			case "paid":
				return <CheckIcon style={{ color: "#71ac05" }} />;

			default:
				return <BlockIcon color="error" />;
		}
	};
	return (
		<Card className={classes.paymentDetailsRoot}>
			<CardHeader
				avatar={
					<Avatar style={{ backgroundColor: "#ececec" }}>
						<CartAvatar />
					</Avatar>
				}
				className={classes.paymentDetailsHeader}
				subheader={record.status ? record.status.toUpperCase() : ""}
				title={
					<Box>
						<Typography component={"span"}>{translate("resources.invoices.invoiceNumber") + " : "}</Typography>#
						{record.invoiceNumber}
					</Box>
				}
			/>
			<CardContent className={classes.paymentDetailsContent}>
				<Box>
					<Typography variant="title" color="textSecondary" component="p" style={{ textAlign: "center" }}>
						{translate("resources.invoices.paymentaddress")}
					</Typography>
					<Box m={1} display={"flex"} justifyContent={"center"}>
						<QRCode value={pay_address} size={150} />
					</Box>
					<Typography variant="title" component="p" style={{ textAlign: "center" }}>
						{pay_address}
						<IconButton onClick={copyText}>
							<CopyIcon />
						</IconButton>
					</Typography>
					{record.status === "waiting" && (
						<Typography style={{ textAlign: "center" }}>
							{translate("resources.invoices.expiresIn") + " : "}
							<Countdown date={record.expiresAt} daysInHours={true} />
						</Typography>
					)}
				</Box>
				<Box className={classes.payAmount}>
					<Typography variant="h5" component="p">
						{pay_amount.toFixed(2)} USDT (TRC20)
					</Typography>
				</Box>
				<Box
					onClick={refresh}
					m={1}
					p={1}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					component={ButtonBase}
				>
					{record.status === "waiting" && <CircularProgress size={20} color={"secondary"} />}
					<Typography
						variant="h6"
						style={{
							textTransform: "uppercase",
							margin: 5,
							color: record.status === "paid" ? "#71ac05" : "unset",
						}}
					>
						{payment_status}
					</Typography>
				</Box>

				<Box width={"100%"}>
					<Typography>Description</Typography>
					<ul>
						<li>
							<InvoiceDesc record={record} />
						</li>
					</ul>
					<Typography>Important tips!</Typography>
					<ul>
						<li>
							<Typography>
								Send only{" "}
								<Box component={"span"} color={"red"}>
									USDT
								</Box>{" "}
								to this deposit address.
							</Typography>
						</li>
						<li>
							<Typography>
								Ensure the network is{" "}
								<Box component={"span"} color={"red"}>
									TRC20
								</Box>
								{" (Tron network)"}
							</Typography>
						</li>
						<li>
							<Typography>
								<Box component={"span"} color={"red"}>
									Do NOT
								</Box>
								{" Send Money to this address after expired time"}
							</Typography>
						</li>
					</ul>
				</Box>
			</CardContent>
		</Card>
	);
};
const DaysRemaining = ({ record }) => {
	if (record && record.expiresAt) {
		let remainingDays = getDaysRemaining(record.expiresAt);
		if (remainingDays) {
			return (
				<Box marginTop={1} marginBottom={1}>
					{remainingDays} Days remaining{" "}
				</Box>
			);
		}
		return (
			<Box marginTop={1} marginBottom={1} color={"#f50057"}>
				Expired
			</Box>
		);
	}
	return <span />;
};
const Aside = ({ record }) => {
	const locale = useLocale();
	if (!record) {
		return <span />;
	}
	return (
		<div style={{ width: 300, margin: "1em" }}>
			<Typography>Shares</Typography>
			<ShareInformation record={record} />
		</div>
	);
};
const InvoicesShowLayout = (props) => {
	const { record } = props;
	const classes = useStyles();
	const locale = useLocale();
	return (
		<SimpleShowLayout toolbar={<CustomToolbar />} {...props}>
			<ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
				<NameWithAvatarField />
			</ReferenceField>
			<PaymentDetailsField />

			{/* <ReferenceField
        source="vendor"
        label={"resources.vendorsubscriptions.vendor"}
        reference="users|usertype|VENDOR"
      >
        <NickNameWithAvatarField />
      </ReferenceField>

      <DateField
        source="startedAt"
        label="resources.vendorsubscriptions.startedAt"
        locales={locale}
        showTime
      />
      <DateField
        source="expiresAt"
        label="resources.vendorsubscriptions.expiresAt"
        locales={locale}
        showTime
      />
      <Labeled label={"Days remaining"}>
        <DaysRemaining />
      </Labeled>
      <div style={{ maxWidth: 800, width: 800, overflowWrap: "anywhere" }}>
        {record ? JSON.stringify(record.paymentDetails) : ""}
      </div>*/}
		</SimpleShowLayout>
	);
};
const InvoicesShow = (props) => {
	const classes = useStyles();
	const locale = useLocale();

	return (
		<Show {...props} title={<Title />} aside={<Aside />} classes={{ card: classes.showLayout }}>
			<InvoicesShowLayout />
		</Show>
	);
};
const CustomToolbar = (props) => (
	<Toolbar {...props} classes={useStyles()}>
		<SaveButton />
	</Toolbar>
);

export default InvoicesShow;
