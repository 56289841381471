//import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import DescriptionIcon from "@material-ui/icons/Description";
import {InvoicesList} from "./InvoicesList";
import InvoicesShow from "./InvoicesShow";

export default {
  show: InvoicesShow,
  list: InvoicesList,
  icon: DescriptionIcon,
};
