import React from "react";
import {
	AutocompleteInput,
	Filter,
	List,
	ReferenceField,
	ReferenceInput,
	SelectField,
	SelectInput,
	SimpleList,
	TextField,
	useLocale,
	useTranslate,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import {TicketStatus} from "./SupportTicketsEnum";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import moment from "moment";
import {useTheme} from "@material-ui/core/styles";
import {TrimField} from "@bb-tech/ra-components";
import SupportTicketsEmpty from "./SupportTicketsEmpty";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import {Link as RouterLink} from "react-router-dom";
import {ButtonBase} from "@material-ui/core";

const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<ReferenceInput
				alwaysOn
				label={"resources.signalalerts.client"}
				source="user"
				perPage={25}
				reference="users|usertype|CLIENT"
				allowEmpty={true}
				filter={{
					usertype: "CLIENT",
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
						  }
						: { fake: true }
				}
			>
				<AutocompleteInput
					optionText={(record) =>
						record && record.firstName
							? record.firstName + " " + record.lastName
							: translate("resources.observes.allusers")
					}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
			<SelectInput source={"status"} choices={TicketStatus} alwaysOn allowEmpty={true} />
		</Filter>
	);
};
const UserWithLink = ({ record }) => {
	if (!record) {
		return <span />;
	}
	return (
		<ButtonBase component={RouterLink} to={"/users|usertype|" + record?.usertype + "/" + record?._id}>
			<NameWithAvatarField record={record} />{" "}
			{!!record?.nickName && <Typography variant={"caption"}> {" - " + record?.nickName} </Typography>}
			<Typography variant={"caption"}> {" - " + record?.usertype} </Typography>
		</ButtonBase>
	);
};
const SupportTicketsList = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	return (
		<List
			{...props}
			title={"Support Tickets"}
			perPage={100}
			empty={<SupportTicketsEmpty />}
			exporter={false}
			sort={{ field: "updatedAt", order: "DESC" }}
			bulkActionButtons={false}
			filters={<ListFilter />}
		>
			<SimpleList
				primaryText={(record) => record.title}
				leftIcon={(record) => (
					<Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} mr={2}>
						<ReferenceField source="user" label={"resources.observes.client"} reference="users">
							<UserWithLink />
						</ReferenceField>
						<Typography variant={"h6"}>{`#${record.ticketNumber}`}</Typography>
						<Typography color={"textSecondary"} variant={"caption"}>
							<ReferenceField reference={"stcategories"} source={"category"} label={"Topic"} link={false}>
								<TextField source={"title"} />
							</ReferenceField>
						</Typography>
					</Box>
				)}
				secondaryText={(record) => (
					<Box display={"flex"}>
						<Box flexGrow={1}>
							<Typography style={{ lineBreak: "anywhere" }}>
								<TrimField record={record} source="lastMessage" limit={100} trimstr="..." />
							</Typography>
							<Box>{moment(record?.updatedAt).fromNow()}</Box>
						</Box>
						<SelectField
							record={record}
							source="status"
							choices={TicketStatus}
							optionText={(option) => <Chip variant={"outlined"} label={option?.name} color={option?.color} />}
						/>
					</Box>
				)}
				linkType={"edit"}
				rowStyle={GetRowStyle}
			/>
		</List>
	);
};
const GetRowStyle = (record) => {
	const theme = useTheme();
	if (!record) {
		return null;
	}
	const color = TicketStatus.find((ts) => ts.id === record?.status)?.color;
	return {
		borderTopColor: "transparent",
		borderRightColor: "transparent",
		marginTop: 5,
		marginBottom: 5,
		borderLeftWidth: 2,
		borderBottomWidth: 1,
		borderLeftStyle: "solid",
		borderBottomStyle: "solid",
		borderBottomColor: "#232323",
		borderLeftColor: theme?.palette[color]?.main,
	};
};
export { SupportTicketsList };
