import React, {memo, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import {FileField, ImageField, useLocale, useQueryWithStore, useTranslate} from "react-admin";

import {ButtonBase, useMediaQuery, useTheme} from "@material-ui/core";

import moment from "moment";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: "6px 16px",
	},
}));

const ChatView = ({ id, record, resource }) => {
	const classes = useStyles();
	const theme = useTheme();
	const translate = useTranslate();
	const locale = useLocale();
	const { loaded, error, data, total, loading } = useQueryWithStore({
		type: "getList",
		resource: "stmessages",
		payload: {
			pagination: { page: 0, perPage: 100 },
			sort: { field: "createdAt", order: "DESC" },
			filter: { $limit: 100, supportTicket: record?.id },
		},
	});

	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	useEffect(() => {
		if (total) {
			setTimeout(() => {
				document
					.getElementById("chatview")
					?.scrollTo({ top: document.getElementById("chatview")?.scrollHeight, behavior: "smooth" });
			}, 1000);
		}
	}, [total]);
	if (error) {
		return <p>ERROR</p>;
	}

	return (
		<Box display={"flex"} flexDirection={"column"} style={{ overFlowY: "scroll" }}>
			{total &&
				data
					?.slice(0)
					?.reverse()
					?.map((messageItem) => (
						<Box
							display={"flex"}
							my={1}
							flexDirection={"column"}
							maxWidth={isXsmall ? "80%" : "55%"}
							minWidth={100}
							alignSelf={messageItem?.isAdmin ? "flex-start" : "flex-end"}
							style={{
								backgroundColor: messageItem?.isAdmin
									? theme?.palette?.secondary?.dark
									: theme?.palette?.primary?.dark,
								padding: 15,
								borderRadius: 10,
								whiteSpace: "pre-line",
							}}
						>
							{!messageItem?.isCdn && (
								<Box>
									<Typography gutterBottom={true} style={{ lineBreak: "anywhere" }}>
										{messageItem?.message}
									</Typography>
									<Typography align={"right"} variant={"caption"} color={"textSecondary"}>
										{moment(messageItem?.createdAt).fromNow()}
									</Typography>
								</Box>
							)}
							{!!messageItem?.isCdn && (
								<Box>
									{messageItem?.cdn?.fileType !== "Image" && (
										<FileField
											title={"cdn.url"}
											download={true}
											record={messageItem}
											source={"cdn.url"}
											target={"_blank"}
										/>
									)}
									{messageItem?.cdn?.fileType === "Image" && (
										<ButtonBase
											style={{ display: "block" }}
											component={"a"}
											href={messageItem?.cdn?.url}
											target={"_blank"}
										>
											<ImageField download={true} record={messageItem} source={"cdn.url"} />
										</ButtonBase>
									)}
									<Typography align={"right"} variant={"caption"} color={"textSecondary"} component={"div"}>
										{moment(messageItem?.createdAt).fromNow()}
									</Typography>
								</Box>
							)}
						</Box>
					))}
		</Box>
	);
};
const MessageContent = ({ message, ...rest }) => {
	if (message?.isAdmin) {
		return <TimelineOppositeContent {...rest} />;
	}
	return <TimelineContent {...rest} />;
};
export default memo(ChatView);
