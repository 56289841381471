import React from "react";
import {
	Create,
	email,
	maxValue,
	minLength,
	minValue,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
	useLocale,
	useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {MarketTypes} from "./MarketsEnum";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const MarketsCreate = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	return (
		<Create {...props} label={"resources.markets.createnew"}>
			<SimpleForm redirect={"list"}>
				<TextInput source="name" label="resources.markets.name" validate={requiredValidate} />
				<TextInput source="websiteURL" label="resources.markets.websiteURL" validate={requiredValidate} />
				<TextInput source="desc" label="resources.markets.desc" />
				<TextInput source="className" label="resources.markets.className" validate={requiredValidate} />

				<SelectInput
					label="resources.markets.markettype"
					source="markettype"
					choices={MarketTypes}
					validate={requiredValidate}
				/>
			</SimpleForm>
		</Create>
	);
};
const totValidate = [required(), minValue(10), maxValue(600)];
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];

const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default MarketsCreate;
