import React, {useCallback, useEffect, useState} from "react";
import {Box, ButtonBase} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {ReferenceManyField, useDataProvider} from "react-admin";
import Grid from "@material-ui/core/Grid";
import PositionCard from "./PositionCard";
import PaperPositionCard from "./PaperPositionCard";
import {Link as RouterLink} from "react-router-dom";

const styles = (theme) => ({
	iconButtonRoot: {},
	icon: {
		fontSize: "1.1em",
	},
	root: { display: "flex", flexDirection: "column" },
});
const PositionsCards = (props) => {
	const { classes, record = {} } = props;
	const { positionType, closed, opened, tradetype, vendor, user, pair } = record;
	const [pairObject, setPairObject] = useState(pair);
	const dataProvider = useDataProvider();
	const getRelatedService = useCallback(() => {
		if (!!vendor) {
			if (tradetype === 0) {
				return "atspotpositions";
			}
			if (tradetype === 1) {
				return "atufuturespositions";
			}
		} else if (!!user) {
			if (tradetype === 0) {
				return "clspotpositions";
			}
			if (tradetype === 1) {
				return "clufuturespositions";
			}
		}
	}, [tradetype, vendor]);
	const relatedService = getRelatedService();
	useEffect(() => {
		if (!record?.pair?._id) {
			dataProvider.getOne("pairs", { id: record?.pair }).then((pairResult) => {
				setPairObject(pairResult.data);
			});
		}
	}, []);
	useEffect(() => {
		if (pair?._id) {
			setPairObject(pair);
		}
	}, [pair]);
	return (
		<Box>
			<ReferenceManyField
				reference={relatedService}
				target={!!vendor ? "vendorSignal" : "signalAlert"}
				record={record}
				l
			>
				<PositionsCardsIterator signal={{ ...record, pair: pairObject }} />
			</ReferenceManyField>
		</Box>
	);
};
const PositionsCardsIterator = ({ ids, data, basePath, signal }) => {
	return (
		<Grid container spacing={2}>
			<Grid key={"paper"} item xs={12} sm={12} md={12} lg={12}>
				<PaperPositionCard signal={signal} record={getPaperPosition(signal)} />
			</Grid>

			{ids.map((id) => (
				<Grid key={id} item xs={12} sm={12} md={12} lg={12}>
					<ButtonBase component={RouterLink} to={basePath + "/" + id + "/show"}>
						<PositionCard signal={signal} record={data[id]} />
					</ButtonBase>
				</Grid>
			))}
		</Grid>
	);
};
const getPaperPosition = (signal) => {
	const { positionType, closed, opened, tradetype, vendor, user, stopLoss, targetPoints, entryAverage } = signal;
	//const { side, byQuote, balancePercent, orderRole, quantity, quoteOrderQty, tpPercent, orderId, error } = record;
	const orders = [];
	const fund = 100;
	let paperPosition = {
		_id: "paper",
		paper: true,
		fund: fund,
		opened: opened,
		closed,
		positionType,
		tradetype,
		entryBalancePercent: 100,
	};
	if (opened) {
		const entryOrder = {
			orderRole: "Entry",
			id: "Entry",
			orderId: true,
			byQuote: true,
			side: positionType,
			quoteOrderQty: fund,
			quantity: fund / entryAverage,
			pairLatestPrice: entryAverage,
		};
		orders.push(entryOrder);

		targetPoints.forEach((tp, index) => {
			if (!!tp.triggeredAt) {
				const tpOrder = {
					orderRole: "TargetPoint",
					id: "tp" + index,
					orderId: true,
					byQuote: false,
					side: positionType === "buy" ? "sell" : "buy",
					balancePercent: tp.percent,
					quantity: (fund / entryAverage) * (tp.percent / 100),
					pairLatestPrice: tp.valuenumber,
				};
				orders.push(tpOrder);
			}
		});
		if (stopLoss?.triggeredAt) {
			const notExtractedTargetPoints = targetPoints.filter((tp) => !tp.triggeredAt);
			let notExtractedPercent = 0;
			if (notExtractedTargetPoints.length > 0) {
				notExtractedPercent = notExtractedTargetPoints.map((tp) => tp.percent).reduce((total, num) => total + num);
			}

			const stopOrder = {
				orderRole: "StopLoss",
				id: "sl",
				orderId: true,
				byQuote: false,
				side: positionType === "buy" ? "sell" : "buy",
				pairLatestPrice: stopLoss.valuenumber,
				quantity: (fund / entryAverage) * (notExtractedPercent / 100),
			};
			orders.push(stopOrder);
		}
	}
	paperPosition.orders = orders;
	return paperPosition;
};
export default withStyles(styles, { name: "PositionsCards" })(PositionsCards);
