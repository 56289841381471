import React from "react";
import {
	AutocompleteInput,
	BooleanField,
	Datagrid,
	DateField,
	Filter,
	List,
	NumberField,
	ReferenceField,
	ReferenceInput,
	SelectField,
	SelectInput, ShowButton,
	TextField,
	useLocale,
	useTranslate,
} from "react-admin";
import {AccountDocsTypes} from "./AccountingDocsEnum";
import {ButtonBase, makeStyles, Typography} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import NameWithAvatarField from "../shared/NameWithAvatarField";

const useStyles = makeStyles((theme) => ({
	positiveAmount: {
		fontSize: "1.1em",
		color: theme.palette.success.main,
	},
	negativeAmount: {
		fontSize: "1.1em",
		color: theme.palette.error.main,
	},
}));

const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<ReferenceInput
				alwaysOn
				source="user"
				perPage={25}
				reference="users"
				allowEmpty={true}
				filter={{
					usertype: { $ne: "ADMIN" },
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [
									{ firstName: { $search: searchText } },
									{ lastName: { $search: searchText } },
									{ nickName: { $search: searchText } },
									{ userName: { $search: searchText } },
								],
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={(record) =>
						record && record.firstName
							? record.firstName + " " + record.lastName + " " + (record.nickName || "")
							: translate("resources.observes.allusers")
					}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				source="invoice"
				perPage={25}
				reference="invoices"
				allowEmpty={true}
				filter={{
					status: "paid",
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								invoiceNumber: { $search: searchText },
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={(record) => record?.invoiceNumber}
					allowEmpty={true}
					optionValue={"_id"}
					alwaysOn
				/>
			</ReferenceInput>
			<SelectInput label="resources.accountingdocuments.type" source="type" choices={AccountDocsTypes} alwaysOn />
		</Filter>
	);
};
const UserWithLink = ({ record }) => {
	if (!record) {
		return <span />;
	}
	return (
		<ButtonBase component={RouterLink} to={"/users|usertype|" + record?.usertype + "/" + record?._id}>
			<NameWithAvatarField record={record} />
		</ButtonBase>
	);
};
const AmountField = ({ record }) => {
	const classes = useStyles();
	return (
		<Typography className={record?.amount > 0 ? classes.positiveAmount : classes.negativeAmount}>
			{record?.amount > 0 && "+"}

			<NumberField
				record={record}
				source={"amount"}
				options={{ style: "currency", currency: "USD" }}
				className={record?.amount > 0 ? classes.positiveAmount : classes.negativeAmount}
			/>
		</Typography>
	);
};
const AccountingDocumentsList = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.accountingdocuments.listtitle")}
			perPage={10}
			sort={{ field: "createdAt", order: "DESC" }}
			exporter={false}
			bulkActionButtons={false}
		>
			<Datagrid>
				<ReferenceField source="user" label={"resources.observes.client"} reference="users">
					<UserWithLink />
				</ReferenceField>
				<AmountField label={"resources.accountingdocuments.amount"} />
				<SelectField label="resources.accountingdocuments.type" source="type" choices={AccountDocsTypes} />
				<ReferenceField source="invoice" reference="invoices" link={"show"}>
					<TextField source={"invoiceNumber"} />
				</ReferenceField>
				<NumberField source={"referralLevel"} />
				<NumberField source={"referralPercent"} />
				<NumberField source={"vendorSharePercent"} />
				<BooleanField source={"userIsReferredByVendor"} />
				<DateField source="createdAt" label="resources.markets.createdAt" locales={locale} showTime />

				<TextField source={"desc"} />
				<ShowButton/>
			</Datagrid>
		</List>
	);
};

export { AccountingDocumentsList };
