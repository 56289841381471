import React from "react";
import {
	AutocompleteInput,
	BooleanField,
	BooleanInput,
	Edit,
	email,
	FunctionField,
	maxValue,
	minLength,
	minValue,
	Pagination,
	ReferenceInput,
	required,
	SimpleForm,
	TextField,
	TextInput,
	TopToolbar,
	useLocale,
	useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import AddUsersToChannelByChannelBtn from "../shared/AddUsersToChannelByChannelBtn";
import {JsonField} from "@bb-tech/ra-components";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);
const Title = ({ record }) => {
	const translate = useTranslate();
	return (
		<span>
			{" "}
			{translate("resources.pairs.edittitle")} {record ? `${record.symbol}` : ""}
		</span>
	);
};

const PairsEdit = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	return (
		<Edit {...props} title={<Title />}>
			<SimpleForm>
				<ReferenceInput
					validate={requiredValidate}
					label={"resources.pairs.market"}
					source="market"
					perPage={10}
					reference="markets"
					allowEmpty={false}
					filterToQuery={(searchText) =>
						searchText.trim() !== ""
							? {
									name: { $search: searchText },
							  }
							: null
					}
				>
					<AutocompleteInput optionText={"name"} allowEmpty={false} optionValue={"_id"} />
				</ReferenceInput>
				<ReferenceInput source="market" label={"resources.pairs.market"} reference="markets">
					<TextField source="name" />
				</ReferenceInput>
				<TextInput source="symbol" label="resources.pairs.symbol" validate={requiredValidate} />
				<TextInput source="baseAsset" label="resources.pairs.baseAsset" validate={requiredValidate} />
				<TextInput source="quoteAsset" label="resources.pairs.quoteAsset" validate={requiredValidate} />
				<BooleanField label="resources.pairs.status" source="status" />
				<BooleanField label="resources.pairs.removedfrommarket" source="removedfrommarket" />
				<BooleanInput label="resources.pairs.disabled" source="disabled" />
				<FunctionField
					label={"details"}
					render={(record) => (
						<Box>
							<JsonField record={{ r: record }} source={"r"} />
						</Box>
					)}
				/>
			</SimpleForm>
		</Edit>
	);
};
const totValidate = [required(), minValue(10), maxValue(600)];
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];
const EditActions = ({ basePath, data, resource }) => (
	<TopToolbar>
		<AddUsersToChannelByChannelBtn record={data} basePath={basePath} />
	</TopToolbar>
);

const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const RFPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const Separator = () => <Box pt="1em" />;

export default PairsEdit;
