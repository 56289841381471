const ConditionTypes = [
    {
        id : "egr",
        name : "Greater than or equal >= "
    },
    {
        id : "elr",
        name : "Less than or equal <= "
    },
    {
        id : "range",
        name : "Inside range"
    },
];

export {ConditionTypes}
