import React from "react";
import {
	AutocompleteInput,
	BooleanField,
	DateField,
	EditButton,
	Filter,
	List, NumberField,
	ReferenceField,
	ReferenceInput,
	SearchInput,
	TextField,
	useLocale,
	useTranslate,
} from "react-admin";
import CustomizableDatagrid from "ra-customizable-datagrid";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";

const StatusFieldColored = ({ record }) => (
	<TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);
const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<SearchInput InputLabelProps={{ shrink: true }} source="code.$search" alwaysOn />
			<ReferenceInput
				alwaysOn
				source="owner"
				perPage={25}
				reference="users"
				allowEmpty={true}
				filter={{
					usertype: { $ne: "ADMIN" },
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={(record) =>
						record && record.firstName
							? record.firstName + " " + record.lastName
							: translate("resources.observes.allusers")
					}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
		</Filter>
	);
};

const GiftCodesList = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.sms.listtitle")}
			perPage={25}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			bulkActionButtons={false}
		>
			<CustomizableDatagrid {...props}>
				<DateField source="createdAt" label="Created At" locales={locale} showTime />
				<DateField source="expiresAt" label="Expires At" locales={locale} showTime />
				<TextField source="code" />
				<TextField source="limit" />
				<ReferenceField source="vendor" reference={"users"}>
					<NickNameWithAvatarField />
				</ReferenceField>
				<NumberField source={`used`} />
				<BooleanField source="disabled" />
				<EditButton />
			</CustomizableDatagrid>
		</List>
	);
};

export { GiftCodesList };
