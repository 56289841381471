import Icon from '@material-ui/icons/Alarm';
import AlertsCreate from './AlertsCreate';
import {AlertsList} from './AlertsList';

export default {
    create : AlertsCreate,
    //edit : AlertsEdit,
    list:AlertsList,
    icon: Icon,
};
