import React from "react";

import {DateField, NumberInput, useLocale, useTranslate} from "react-admin";

import {useForm, useFormState} from "react-final-form";
import {ThemedSlider} from "../../shared/ThemedSlider";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import AlarmIcon from "@material-ui/icons/Alarm";
import Card from "@material-ui/core/Card";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";

import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";

Array.min = function (array) {
	return Math.min.apply(Math, array);
};
Array.max = function (array) {
	return Math.max.apply(Math, array);
};
const useStyles = makeStyles((theme) => ({
	formInputSlider: {
		paddingLeft: "5px",
		paddingRight: "5px",
		width: "100%",
		maxWidth: "500px",
	},
	formInput: {
		//    maxWidth:'100%'
		//  minWidth:'250px'
	},
	withSliderForm: {
		width: "100%",
		maxWidth: "500px",
		marginTop: 5,
	},
	margin: {
		height: theme.spacing(5),
	},
	seperate: {
		height: theme.spacing(2),
	},
	thumbContent: {
		color: theme.palette.primary.main,
		fontSize: "1em",
	},
	iconStyle: {
		color: theme.palette.primary.main,
	},
	cardContent: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	avatarClass: {
		backgroundColor: theme.palette.grey[100],
	},
}));
const EntryPriceEdit = ({ record, pairObject, ...props }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const { values } = useFormState();
	const { entryPrice, stopLoss, targetPoints } = values;
	const form = useForm();
	const locale = useLocale();
	const { positionType, entryAverage } = record;

	const getMin = () => {
		const result = entryPrice.low - entryPrice.low * 0.075;

		return result < pairObject.latestPrice ? result : pairObject.latestPrice;
	};
	const getMax = () => {
		const result = entryPrice.high + entryPrice.high * 0.075;

		return result > pairObject.latestPrice ? result : pairObject.latestPrice;
	};

	const getThumbContent = () => {
		if (entryPrice.triggeredAt) {
			return <CheckIcon className={classes.thumbContent} />;
		} else {
			return <AlarmIcon className={classes.thumbContent} />;
		}
	};

	const handleChangeSlider = (event, newValue) => {
		if (entryPrice.triggeredAt) {
			return;
		}
		form.change("entryPrice.low", Array.min(newValue));
		form.change("entryPrice.high", Array.max(newValue));
		//enterPriceStartInput.input.onChange(newValue)
		//form.change('enterPriceStartvaluenumber', newValue)
	};

	const handleChangeSliderCommited = (event, newValue) => {
		afterFormEdit();
	};

	const afterFormEdit = () => {
		form.batch(() => {
			const arr = [entryPrice.low, entryPrice.high];
			form.change("entryPrice.low", Array.min(arr));
			form.change("entryPrice.high", Array.max(arr));
		});
		setTimeout(() => {
			form.resetFieldState("stopLoss.valuenumber");
			form.blur("stopLoss.valuenumber");
			targetPoints.forEach((tp, index) => {
				form.resetFieldState("targetPoints[" + index + "].valuenumber");
				form.blur("targetPoints[" + index + "].valuenumber");
			});
		}, 100);
	};
	const getStatusText = () => {
		if (entryPrice.triggeredAt) {
			return (
				<>
					{" "}
					{translate("resources.signalalerts.reachedAt")} <DateField source={"triggeredAt"} record={entryPrice} locales={locale} showTime />{" "}
				</>
			);
		} else {
			return translate("resources.signalalerts.waitingForEntry");
		}
	};

	// console.log(enterPriceStartInput);
	return (
		<Card>
			<CardHeader
				action={
					<IconButton aria-label="remove">
						<HelpIcon color={"action"} />
					</IconButton>
				}
				avatar={
					<Avatar aria-label="recipe" className={classes.avatarClass}>
						{entryPrice.triggeredAt && <CheckIcon className={classes.iconStyle} />}

						{!entryPrice.triggeredAt && <AlarmIcon className={classes.iconStyle} />}
					</Avatar>
				}
				title={translate("resources.signalalerts.entryrange")}
				subheader={getStatusText()}
			/>
			<CardContent>
				<Box margin={2} marginBottom={9}>
					<ThemedSlider
						value={[entryPrice.low, entryPrice.high]}
						onChange={handleChangeSlider}
						// className={classes.formInput}
						getAriaLabel={(index) => translate(getEntrpPriceThumLabel(index))}
						step={pairObject.tickSize}
						min={getMin()}
						max={getMax()}
						getDisabled={() => entryPrice.triggeredAt}
						marks={entryAverage ? [{ value: entryAverage, label: translate("resources.signalalerts.entry") + "\n" + entryAverage }] : false}
						getContent={() => getThumbContent()}
						//getAriaValueText={getEnterPriceStopLossSliderText}

						// max={enterPriceEnd * 2}
						valueLabelDisplay="on"
						onChangeCommitted={handleChangeSliderCommited}

						//valueLabelFormat={getEnterPriceStopLossSliderText}

						//getAriaValueText={valuetext}
					/>
				</Box>
				<Grid direction={"row"} container spacing={1} justify={"center"}>
					<Grid item>
						<NumberInput
							label={"resources.signalalerts.low"}
							source="entryPrice.low"
							onBlur={afterFormEdit}
							disabled={entryPrice.triggeredAt}
						/>
					</Grid>
					<Grid item>
						<NumberInput
							label={"resources.signalalerts.high"}
							source="entryPrice.high"
							onBlur={afterFormEdit}
							disabled={entryPrice.triggeredAt}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
var countDecimals = function (value) {
	let text = value.toString();
	// verify if number 0.000005 is represented as "5e-6"
	if (text.indexOf("e-") > -1) {
		let [base, trail] = text.split("e-");
		let deg = parseInt(trail, 10);
		return deg;
	}
	// count decimals for number in representation like "0.123456"
	if (Math.floor(value) !== value) {
		return value.toString().split(".")[1].length || 0;
	}
	return 0;
};
const notEqualHighLow = (value, allValues) => {
	if (
		allValues &&
		allValues.enterPriceStart &&
		allValues.entryPrice.low !== undefined &&
		allValues.enterPriceEnd &&
		allValues.entryPrice.high !== undefined
	) {
		if (allValues.entryPrice.high === allValues.entryPrice.low) {
			return { message: "shoud not be equal" };
		}
	}

	return null;
};
function getEntrpPriceThumLabel(index) {
	switch (index) {
		case 0:
			return "resources.signalalerts.low";
		case 1:
			return "resources.signalalerts.high";
	}
}
export default EntryPriceEdit;
