import React, {Fragment} from "react";
import {
    AutocompleteInput,
    Create,
    DateField,
    email,
    FormDataConsumer,
    maxLength,
    maxValue,
    minLength,
    minValue,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput,
    useLocale,
    useTranslate,
} from "react-admin";

import {makeStyles} from "@material-ui/core/styles";

import {Box, Typography, withStyles} from "@material-ui/core";
import {useForm} from "react-final-form";
import {ConditionTypes} from "./ExchangeAccountsEnum";

import Wizard from "../shared/Wizard";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	wizardPage: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "Space-around",
		alignItems: "center",
		//    height:"100%",
		padding: "15px",
		//  flex:1
	},
	formInput: {
		//    maxWidth:'100%'
		width: "100%",
		maxWidth: "500px",
		//  minWidth:'250px'
	},
};
const useStyles = makeStyles(styles);
const wizardSteps = [
	{
		title: "resources.exchangeaccounts.exchange",
		fields: ["market", "name"],
	},
	{
		title: "resources.exchangeaccounts.apicredentials",
		fields: ["apikey", "secretkey"],
	},
];
let formInputedDataTexts = {
	marketNameText: "asb",
	pairNameText: "",
};
const MarketSelect = ({ formData, ...rest }) => {
	const form = useForm();
	const classes = useStyles();
	return (
		<ReferenceInput
			validate={requiredValidate}
			label={"resources.observes.market"}
			source="market"
			reference="markets"
			perPage={0}
			filter={{ canCopyTrade: true }}
			className={classes.formInput}
		>
			<SelectInput optionText={"name"} name={"fakename"} helperText={"resources.alerts.wizard.markethelper"} />
		</ReferenceInput>
	);
};
const AutocompleteInputInDialog = withStyles({
	container: styles.formInput,
	suggestionsContainer: { zIndex: 2000 },
})(AutocompleteInput);

function getMarketName() {
	const containerDiv = document.getElementById("market");

	return containerDiv !== null && containerDiv !== undefined ? containerDiv.innerText : "";
}

function getPairName() {
	const containerDiv = document.getElementById("pair");

	return containerDiv !== null && containerDiv !== undefined ? containerDiv.getAttribute("value") : "";
}

const ConditionForm = ({ formData, ...rest }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const locale = useLocale();
	let baseAsset,
		quoteAsset,
		latestPrice,
		latestPriceTime = "";
	if (formData.pairObject) {
		baseAsset = formData.pairObject.baseAsset;
		quoteAsset = formData.pairObject.quoteAsset;
		latestPrice = formData.pairObject.latestPrice;
		latestPriceTime = formData.pairObject.latestPriceTime;
	}

	return (
		<Fragment>
			<Typography className={classes.formInput} variant="caption">
				{translate("resources.alerts.wizard.wizardconditionheadertext", {
					pairname: getPairName(),
					marketname: getMarketName(),
				})}
			</Typography>
			<SelectInput
				className={classes.formInput}
				source="condition.conditionType"
				label="resources.alerts.condition"
				choices={ConditionTypes}
				allowEmpty={false}
				validate={requiredValidate}
			/>
			<Typography className={classes.formInput} variant="caption">
				{translate("resources.alerts.wizard.to")}
			</Typography>
			<NumberInput
				className={classes.formInput}
				source="condition.args"
				label="resources.alerts.wizard.conditionValue"
				validate={valueValidate}
			/>
			<Typography className={classes.formInput} variant="caption">
				{translate("resources.alerts.wizard.pairpricetext", { baseAsset, quoteAsset, latestPrice })}
				<DateField source="latestPriceTime" record={formData.pairObject} locales={locale} showTime />
			</Typography>
		</Fragment>
	);
};
const ExchangeAccountsCreateWizard = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const translate = useTranslate();
	let typedPair = "";
	return (
		<Create {...props} label={"resources.exchangeaccounts.createtitle"}>
			<Wizard steps={wizardSteps} {...props} redirect={"list"}>
				<div className={classes.wizardPage}>
					<FormDataConsumer>
						{(formDataProps) => (
							<Fragment>
								<TextInput
									source="name"
									className={classes.formInput}
									validate={[required(), maxLength(100), minLength(2)]}
									// fullWidth={true}
									label={"resources.exchangeaccounts.accountname"}
								/>
								<MarketSelect {...formDataProps} />
							</Fragment>
						)}
					</FormDataConsumer>
				</div>

				<div className={classes.wizardPage}>
					<TextInput
						source="apikey"
						className={classes.formInput}
						validate={[required(), maxLength(1024), minLength(2)]}
						fullWidth={true}
						label={"resources.exchangeaccounts.apikey"}
					/>
					<TextInput
						source="secretkey"
						className={classes.formInput}
						validate={[required(), maxLength(1024), minLength(2)]}
						fullWidth={true}
						label={"resources.exchangeaccounts.secretkey"}
					/>
				</div>
			</Wizard>
		</Create>
	);
};
const totValidate = [required(), minValue(10), maxValue(600)];
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];
const valueValidate = [required(), minValue(0)];
const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default ExchangeAccountsCreateWizard;
