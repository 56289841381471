import Box from "@material-ui/core/Box";
import React from "react";
import {rawProfitInPercent} from "../vendorsignals/VendorSignalCalculations";
import Tooltip from "@material-ui/core/Tooltip";

const getNotExtractedLoss = (record) => {
	const { stopLoss, entryPrice, targetPoints } = record;
	if (!stopLoss.triggeredAt) {
		return 0;
	}
	const extractedTargetPoints = targetPoints.filter((tp) => tp.triggeredAt);

	let notExtractedPercent = 100;
	if (extractedTargetPoints.length > 0) {
		notExtractedPercent = 100 - extractedTargetPoints.map((tp) => tp.percent).reduce((total, num) => total + num);
	}
	let lossPercent = getLossBasedOnEntryAvg(record);
	let NotExtractedLossPercent = Number((lossPercent * (notExtractedPercent / 100)).toFixed(3));
	return NotExtractedLossPercent;
};
const getExtractedProfit = (record) => {
	const { stopLoss, entryPrice, targetPoints } = record;
	if (!targetPoints || targetPoints.length === 0) {
		return 0;
	}
	const extractedTargetPoints = targetPoints.filter((tp) => tp.triggeredAt);
	if (extractedTargetPoints.length === 0) {
		return 0;
	}
	let targetPointsProfits = extractedTargetPoints.map((tp) => getProfitWithAppliedExtraction(tp, record)).reduce((num, total) => total + num);
	return targetPointsProfits;
};
const getLossBasedOnEntryAvg = (record) => {
	const { stopLoss, entryPrice, positionType, entryAverage, leverage } = record;
	let avrg = entryAverage ? entryAverage : Number((entryPrice.low + entryPrice.high) / 2);
	let lossPercent = rawProfitInPercent(avrg, stopLoss.valuenumber, positionType);
	if (leverage) {
		lossPercent *= leverage;
	}
	return lossPercent;
};
const getProfitBasedOnEntryAvg = (tp, record) => {
	const { entryPrice, positionType, entryAverage, leverage } = record;

	let avrg = entryAverage ? entryAverage : Number((entryPrice.low + entryPrice.high) / 2);
	let tpVal = tp.valuenumber;

	let profitPercent = rawProfitInPercent(avrg, tpVal, positionType);
	if (leverage) {
		profitPercent *= leverage;
	}
	return profitPercent;
};
const getProfitWithAppliedExtraction = (tp, record) => {
	if (!tp || !tp.percent || !tp.valuenumber) {
		return 0;
	}
	return Number((getProfitBasedOnEntryAvg(tp, record) * (tp.percent / 100)).toFixed(3));
};
const getSignalProfit = (record) => {
	let profit = 0;
	if (!record || !record.entryPrice || !record.entryPrice || !record.entryPrice.triggeredAt) {
		return 0;
	}
	profit = getExtractedProfit(record) + getNotExtractedLoss(record);

	return Number(profit.toFixed(1));
};
const ProfitField = ({ record, classes, forceUnrealized = false }) => {
	let profit = !forceUnrealized && record?.closed ? record?.profit?.toFixed(2) : getUnrelizedProfit(record);
	let profitRealized = !forceUnrealized && record?.closed ? record?.profit?.toFixed(2) : getSignalProfit(record);
	if (record?.leverage && !forceUnrealized && record?.closed) {
		profit *= record?.leverage;
		profit = profit.toFixed(2);
		profitRealized *= record?.leverage;
		profitRealized = profitRealized.toFixed(2);
	}

	return (
		<Tooltip
			enterDelay={0}
			enterTouchDelay={0}
			leaveTouchDelay={3000}
			placement={"top"}
			title={
				<Box className={classes.profitRoot}>
					Realized Pnl:
					<Box className={profitRealized === 0 ? classes.profitZero : profitRealized > 0 ? classes.profitGreen : classes.profitRed}>
						{profitRealized > 0 && "+"}
						{profitRealized + "%"}
					</Box>
				</Box>
			}
		>
			<Box className={classes.profitRoot}>
				<Box className={profit === 0 ? classes.profitZero : profit > 0 ? classes.profitGreen : classes.profitRed}>
					{profit > 0 && "+"}
					{profit + "%"}
				</Box>
			</Box>
		</Tooltip>
	);
};
const getUnrelizedProfit = (record) => {
	if (record?.closed || record?.stopLoss?.triggeredAt) {
		return getSignalProfit(record);
	}
	const unRealizedRecord = { ...record, stopLoss: { triggeredAt: true, valuenumber: record?.pair?.latestPrice } };
	return getSignalProfit(unRealizedRecord);
};
export { getUnrelizedProfit };
export default ProfitField;
