import React from "react";
import Badge from "@material-ui/core/Badge";
import AvatarField from "./AvatarField";
import {makeStyles, withStyles} from "@material-ui/core/styles";

const StyledBadge = withStyles((theme) => ({
	badge: {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "$ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}))(Badge);
const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",

		flexWrap: "nowrap",
		alignItems: "center",
	},
	avatar: {
		marginRight: theme.spacing(1),
	},
}));

const NameWithAvatarField = ({ record = false, size = "25", addLabel = true }) => {
	const classes = useStyles();
	return record ? (
		<div className={classes.root}>
			<StyledBadge
				overlap="circle"
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				invisible={!record.isOnline}
				variant="dot"
			>
				<AvatarField className={classes.avatar} record={record} size={size} />
			</StyledBadge>

			{addLabel ? record.firstName + " " + record.lastName : ""}
		</div>
	) : null;
};

export default NameWithAvatarField;
