import React, {Fragment, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TVChartContainer from "./TvWidget";
import {useFormState} from "react-final-form";
import {useDataProvider, usePermissions} from "react-admin";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
	avatar: {
		marginRight: theme.spacing(1),
	},
}));
//let tvWidget = null;
let stopLossOrderLine = null;
let stopLossOrderExLine = null;
let entryPriceLowLine = null;
let entryPriceHighLine = null;
let entryAverageLine = null;
let entryAverageExLine = null;
let targetPointsLines = [];
let targetPointsExLines = [];
let liquidLine = false;
const SignalEditChart = ({ form, record, pairObject }) => {
	const styles = useStyles();
	const dataProvider = useDataProvider();
	const [widgetLoaded, setWidgetLoaded] = useState(false);

	const [marketObject, setMarketObject] = useState();
	const chartRef = useRef(null);
	const theme = useSelector((state) => state.theme);
	const { values } = useFormState();
	const { symbol } = pairObject;
	const { closed, positionType, market, leverage, name, entryAverage,user,vendor } = record;
	const { entryPrice, stopLoss, targetPoints } = values;
	const { permissions } = usePermissions();

	useEffect(() => {
		if (!marketObject) {
			dataProvider
				.getOne("markets", { id: market })
				.then(({ data }) => {
					setMarketObject(data);
				})
				.catch((e) => {
					//todo refresh
					//	console.log(e);
				});
		}
	}, []);
	const getEmoji = (obj, closed) => {
		if (obj.triggeredAt) {
			return "✔";
		} else {
			if (closed) {
				return "✖";
			}
			return "⏰";
		}
	};
	const getStopLossMin = () => {
		const { entryPrice, stopLoss, targetPoints } = form.getState().values;
		if (positionType === "buy") {
			if (leverage) {
				return getLiquidPrice();
			}
			return 0;
		} else {
			if (!entryPrice.triggeredAt) {
				return Number((entryPrice.high + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			} else {
				let notTriggeredTargetPoints = targetPoints.filter((tp) => !tp.triggeredAt);
				if (notTriggeredTargetPoints.length === 0) {
					return stopLoss.valuenumber - stopLoss.valuenumber * 0.1;
				} else {
					let res = Number(Array.max(notTriggeredTargetPoints.map((tp) => tp.valuenumber)));

					return Number((res + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
				}
			}
		}
	};
	const getStopLossMax = () => {
		const { entryPrice, stopLoss, targetPoints } = form.getState().values;
		if (positionType === "sell") {
			if (leverage) {
				return getLiquidPrice();
			}
			return pairObject.latestPrice * 1000;
		} else {
			if (!entryPrice.triggeredAt) {
				//	console.log(entryPrice);
				return Number((entryPrice.low - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			} else {
				let notTriggeredTargetPoints = targetPoints.filter((tp) => !tp.triggeredAt);
				if (notTriggeredTargetPoints.length === 0) {
					return stopLoss.valuenumber + stopLoss.valuenumber * 0.1;
				} else {
					let res = Number(Array.min(notTriggeredTargetPoints.map((tp) => tp.valuenumber)));

					return Number((res - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
				}
			}
		}
	};
	const onStopLossMoved = () => {
		let stopLossPrice = stopLossOrderLine.getPrice();
		if (stopLossPrice > getStopLossMax()) {
			stopLossPrice = form.getState().values.stopLoss.valuenumber;
			stopLossOrderLine?.setPrice(stopLossPrice);
		} else if (stopLossPrice < getStopLossMin()) {
			stopLossPrice = form.getState().values.stopLoss.valuenumber;
			stopLossOrderLine?.setPrice(stopLossPrice);
		}

		form.change("stopLoss.valuenumber", stopLossPrice);
	};
	const addLiquidLine = (tvWidget) => {
		const { hideOrders } = form.getState().values;
		if (stopLoss && stopLoss.valuenumber && !hideOrders && leverage) {
			//console.log("adding liquid line");
			let that = this;
			liquidLine = tvWidget.activeChart().createOrderLine();
			liquidLine
				.setTooltip("Liquidation Zone")

				.setText("Liquidation Zone")
				.setPrice(getLiquidPrice())
				.setQuantity(getLiquidPrice())

				.setEditable(false)
				.setLineColor("#500740")
				.setBodyBorderColor("#500740")
				.setBodyTextColor("#500740")
				.setQuantityBackgroundColor("#500740")
				.setQuantityBorderColor("#500740")
				.setLineLength(25)
				.setLineStyle(2)
				.setLineWidth(1.3);
		}
	};
	const addStopLoss = (tvWidget) => {
		if (stopLoss) {
			let that = this;
			stopLossOrderLine = tvWidget.activeChart().createOrderLine();
			stopLossOrderLine
				.setTooltip("Stop Loss")
				/*.setModifyTooltip("Modify order")
                .setCancelTooltip("Cancel order")*/
				.onMove(() => {
					onStopLossMoved();
				})

				.setText(getEmoji(stopLoss, closed) + " Stop Loss")
				.setPrice(stopLoss.valuenumber)
				.setQuantity(false)
				.setEditable(!stopLoss.triggeredAt && !closed)
				.setLineColor("#FF0000")
				.setBodyBorderColor("#FF0000")
				.setBodyTextColor("#FF0000")
				.setLineLength(25)
				.setLineStyle(2)
				.setLineWidth(1.3);

			if (stopLoss?.triggeredAt) {
				stopLossOrderExLine = tvWidget?.activeChart()?.createExecutionShape();

				stopLossOrderExLine
					.setText("Stop Loss" + " : " + stopLoss.valuenumber)
					.setTime(new Date(stopLoss.triggeredAt).getTime() / 1000)
					.setDirection(positionType === "buy" ? "sell" : "buy")
					.setPrice(stopLoss.valuenumber)
					.setTooltip(stopLoss.triggeredAt)
					.setTextColor("#ff0000")
					.setFont("16px Verdana");
			}
		}
	};

	const getTargetPointMin = (index) => {
		const { entryPrice, stopLoss, targetPoints } = form.getState().values;
		if (positionType === "buy") {
			if (index === 0) {
				return Number((entryPrice.high + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			} else {
				return Number((targetPoints[index - 1].valuenumber + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			}
		} else {
			if (index === targetPoints.length - 1 || targetPoints.length === 1) {
				return Number(pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize));
			}
			return Number((targetPoints[index + 1].valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		}
	};
	const getEntryLowMin = () => {
		const { entryPrice, stopLoss, targetPoints } = form.getState().values;
		if (positionType === "buy") {
			return Number((stopLoss.valuenumber + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		} else {
			return Number((targetPoints[0].valuenumber + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		}
	};
	const getEntryLowMax = () => {
		const { entryPrice, stopLoss, targetPoints } = form.getState().values;
		return Number((entryPrice.high - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
	};
	const getEntryHighMin = () => {
		const { entryPrice, stopLoss, targetPoints } = form.getState().values;
		return Number((entryPrice.low + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
	};
	const getEntryHighMax = () => {
		const { entryPrice, stopLoss, targetPoints } = form.getState().values;
		if (positionType === "buy") {
			return Number((targetPoints[0].valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		} else {
			return Number((stopLoss.valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		}
	};
	const getTargetPointMax = (index) => {
		const { entryPrice, stopLoss, targetPoints } = form.getState().values;
		if (positionType === "sell") {
			if (index === 0) {
				return Number((entryPrice.low + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			} else {
				return Number((targetPoints[index - 1].valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			}
		} else {
			if (index === targetPoints.length - 1 || targetPoints.length === 1) {
				return Number.MAX_SAFE_INTEGER;
			}
			return Number((targetPoints[index + 1].valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		}
	};
	const getLiquidPrice = () => {
		const { entryPrice, stopLoss, targetPoints, positionType, leverage, entryAverage } = form.getState().values;
		if (leverage) {
			let _entryPrice = entryAverage ? entryAverage : positionType === "buy" ? entryPrice.high : entryPrice.low;
			if (positionType === "buy") {
				return ((_entryPrice * leverage) / (leverage + 1)).toFixed(countDecimals(pairObject.tickSize));
			} else {
				return ((_entryPrice * leverage) / (leverage - 1)).toFixed(countDecimals(pairObject.tickSize));
			}
		}
		return 0;
	};
	const addTargetPoints = (tvWidget) => {
		if (targetPoints && targetPoints.length > 0) {
			targetPoints.forEach((tp, index) => {
				targetPointsLines[index] = tvWidget
					.activeChart()
					.createOrderLine()
					.onMove(function () {
						let tpPrice = this.getPrice();
						if (tpPrice > getTargetPointMax(index)) {
							tpPrice = form.getState().values.targetPoints[index].valuenumber;
							targetPointsLines[index]?.setPrice(tpPrice);
						} else if (tpPrice < getTargetPointMin(index)) {
							tpPrice = form.getState().values.targetPoints[index].valuenumber;
							targetPointsLines[index]?.setPrice(tpPrice);
						}

						form.change("targetPoints[" + index + "].valuenumber", tpPrice);
					})

					.setText(getEmoji(targetPoints[index], closed) + " TP" + (index + 1) + " : " + targetPoints[index].percent + "%")
					.setPrice(targetPoints[index].valuenumber)
					.setQuantity(false)
					.setEditable(!targetPoints[index].triggeredAt && !closed)
					.setLineColor("#00bc00")
					.setBodyBorderColor("#00bc00")
					.setBodyTextColor("#00bc00")
					.setLineLength(20 - (20 / targetPoints.length) * index)
					.setLineStyle(2)
					.setLineWidth(1.3);
				if (targetPoints[index].triggeredAt) {
					targetPointsExLines[index] = tvWidget?.activeChart()?.createExecutionShape();

					targetPointsExLines[index]
						.setText(" TP" + (index + 1) + " : " + targetPoints[index].valuenumber)
						.setTime(new Date(targetPoints[index].triggeredAt).getTime() / 1000)
						.setDirection(positionType === "buy" ? "sell" : "buy")
						.setPrice(targetPoints[index].valuenumber)
						.setTooltip(targetPoints[index].triggeredAt)
						.setTextColor("#00bc00")
						.setFont("16px Verdana");
				}
			});
		}
	};
	const addEntryHigh = (tvWidget) => {
		if (entryPrice.high) {
			entryPriceHighLine = tvWidget.activeChart().createOrderLine();
			entryPriceHighLine
				.setTooltip("Entry Price High")

				.onMove(function () {
					let entryHighPrice = this.getPrice();
					if (entryHighPrice > getEntryHighMax()) {
						entryHighPrice = form.getState().values.entryPrice.high;
						entryPriceHighLine?.setPrice(entryHighPrice);
					} else if (entryHighPrice < getEntryHighMin()) {
						entryHighPrice = form.getState().values.entryPrice.high;
						entryPriceHighLine?.setPrice(entryHighPrice);
					}
					form.change("entryPrice.high", entryHighPrice);
				})

				.setText(getEmoji(entryPrice, closed) + " Entry High")
				.setPrice(entryPrice.high)
				.setQuantity(false)
				.setEditable(!entryPrice.triggeredAt && !closed)
				.setLineLength(35)
				.setLineStyle(2)
				.setLineWidth(1.4);
		}
	};
	const addEntryLow = (tvWidget) => {
		if (entryPrice.low) {
			entryPriceLowLine = tvWidget.activeChart().createOrderLine();
			entryPriceLowLine
				.setTooltip("Entry Price Low")

				.onMove(function () {
					let entryLowPrice = this.getPrice();
					if (entryLowPrice > getEntryLowMax()) {
						entryLowPrice = form.getState().values.entryPrice.low;
						entryPriceLowLine?.setPrice(entryLowPrice);
					} else if (entryLowPrice < getEntryLowMin()) {
						entryLowPrice = form.getState().values.entryPrice.low;
						entryPriceLowLine?.setPrice(entryLowPrice);
					}
					form.change("entryPrice.low", entryLowPrice);
				})

				.setText(getEmoji(entryPrice, closed) + " Entry Low")
				.setPrice(entryPrice.low)
				.setQuantity(false)
				.setEditable(!entryPrice.triggeredAt && !closed)
				.setLineLength(35)
				.setLineStyle(2)
				.setLineWidth(1.4);
		}
	};
	const addEntryAverage = (tvWidget) => {
		if (entryAverage) {
			entryAverageLine = tvWidget?.activeChart()?.createOrderLine();
			entryAverageLine
				.setTooltip("Entry Average")
				.setText(getEmoji(entryPrice, closed) + " Entry Average")
				.setPrice(entryAverage)
				.setQuantity(false)
				.setEditable(false)
				.setLineLength(35)
				.setLineStyle(2)
				.setLineWidth(1.4);
			entryAverageExLine = tvWidget?.activeChart()?.createExecutionShape();
			entryAverageExLine
				.setText("Entry : " + entryAverage)
				.setTime(new Date(entryPrice.triggeredAt).getTime() / 1000)
				.setDirection(positionType)
				.setPrice(entryAverage)
				.setTooltip(entryPrice.triggeredAt)
				.setTextColor("#0099ce")
				.setFont("18px Verdana");
		}
	};
	useEffect(() => {
		if (widgetLoaded && chartRef) {
			stopLossOrderLine?.setPrice(stopLoss.valuenumber);
			stopLossOrderLine?.setText(getEmoji(stopLoss, closed) + " Stop Loss");
			stopLossOrderLine?.setEditable(!stopLoss.triggeredAt && !closed);
			// const tvWidget = chartRef.current.getTVWidget();
			// addStopLoss(tvWidget)
		}
	}, [stopLoss]);
	useEffect(() => {
		if (widgetLoaded && chartRef) {
			const tvWidget = chartRef.current.getTVWidget();
			targetPointsLines.forEach((tpLine) => {
				tpLine.remove(tvWidget);
			});
			targetPointsExLines.forEach((tpExLine) => {
				tpExLine.remove(tvWidget);
			});
			targetPointsLines = [];
			targetPointsExLines = [];
			addTargetPoints(tvWidget);
			// const tvWidget = chartRef.current.getTVWidget();
			// addStopLoss(tvWidget)
		}
	}, [targetPoints.length]);
	useEffect(() => {
		if (widgetLoaded && chartRef) {
			targetPoints.forEach((tp, index) => {
				targetPointsLines[index]?.setPrice(targetPoints[index].valuenumber);
				targetPointsLines[index]?.setText(
					getEmoji(targetPoints[index], closed) + " TP" + (index + 1) + " : " + targetPoints[index].percent + "%"
				);
				targetPointsLines[index]?.setEditable(!targetPoints[index].triggeredAt && !closed);
			});

			// const tvWidget = chartRef.current.getTVWidget();
			// addStopLoss(tvWidget)
		}
	}, [targetPoints]);
	useEffect(() => {
		if (widgetLoaded && chartRef && entryPriceHighLine && entryPriceLowLine) {
			entryPriceHighLine?.setPrice(entryPrice.high);
			entryPriceHighLine?.setText(getEmoji(entryPrice, closed) + " Entry High");
			entryPriceHighLine?.setEditable(!entryPrice.triggeredAt && !closed);
			entryPriceLowLine?.setPrice(entryPrice.low);
			entryPriceLowLine?.setText(getEmoji(entryPrice, closed) + " Entry Low");
			entryPriceLowLine?.setEditable(!entryPrice.triggeredAt && !closed);
			if (liquidLine && leverage) {
				liquidLine?.setPrice(getLiquidPrice());
				liquidLine?.setQuantity(getLiquidPrice());
			}
			// const tvWidget = chartRef.current.getTVWidget();
			// addStopLoss(tvWidget)
		}
	}, [entryPrice]);
	useEffect(() => {
		if (widgetLoaded && chartRef) {
			//initial stopLoss and ...
			//set listeners
			const tvWidget = chartRef.current.getTVWidget();
			tvWidget.onChartReady(() => {
				if (stopLoss) {
					addStopLoss(tvWidget);
				}

				if (entryPrice) {
					if (entryPrice?.triggeredAt) {
						addEntryAverage(tvWidget);
					} else {
						addEntryHigh(tvWidget);
						addEntryLow(tvWidget);
					}
				}
				if (targetPoints) {
					addTargetPoints(tvWidget);
				}
				if (leverage) {
					addLiquidLine(tvWidget);
				}
				tvWidget.saveChartToServer(null, null, { defaultChartName: symbol + "_chart" });
				tvWidget.subscribe("onAutoSaveNeeded", () => {
					tvWidget.saveChartToServer(null, null, { defaultChartName: symbol + "_chart" });
				});
				tvWidget.headerReady().then(() => {
					//buttonPosition.setAttribute('title', 'Click to show a notification popup');

					/*buttonPosition.addEventListener('click', () =>  tvWidget.showNoticeDialog({
                        title: 'Notification',
                        body: 'TradingView Charting Library API works correctly',
                        callback: () => {
                            console.log('Noticed!');
                        },
                    }));*/

					const buttonInfo = tvWidget.createButton();

					buttonInfo.innerHTML = name;

					buttonInfo.style.height = "100%";
					buttonInfo.style.display = "flex";
					buttonInfo.style.alignItems = "center";

					const buttonPosition = tvWidget.createButton();
					buttonPosition.innerHTML = positionType === "buy" ? "Position: Buy/Long" : "Position: Sell/Short";
					buttonPosition.style.backgroundColor = positionType === "buy" ? "green" : "red";
					buttonPosition.style.color = "#fff";
					buttonPosition.style.height = "100%";
					buttonPosition.style.display = "flex";
					buttonPosition.style.alignItems = "center";

					if (leverage) {
						const buttonLev = tvWidget.createButton();
						buttonLev.innerHTML = leverage + "x ";
						buttonLev.style.backgroundColor = "orange";
						buttonLev.style.color = "#fff";
						buttonLev.style.height = "100%";
						buttonLev.style.display = "flex";
						buttonLev.style.alignItems = "center";
					}
				});
			});
		}
	}, [widgetLoaded]);

	return (
		<Fragment>
			{marketObject && permissions && (
				<TVChartContainer
					chartStyle={{ height: "100%" }}
					//  userId={symbol+market}

					disabled_features={[
						"header_symbol_search",
						"symbol_search_hot_key",
						"header_saveload",
						"border_around_the_chart",
						"header_undo_redo",
						"disable_resolution_rebuild",
					]}
					userId={vendor || user}
					clientId={vendor || user}
					datafeedUrl={"https://udf2.dealerify.io/udf"}
					ref={chartRef}
					symbol={symbol + "@" + marketObject?.ccxtId}
					theme={theme && theme.includes("Dark") ? "dark" : "light"}
					setWidgetLoaded={setWidgetLoaded}
				/>
			)}
		</Fragment>
	);
};
const PositionType = () => <div>Hello</div>;
var countDecimals = function (value) {
	let text = value.toString();
	// verify if number 0.000005 is represented as "5e-6"
	if (text.indexOf("e-") > -1) {
		let [base, trail] = text.split("e-");
		let deg = parseInt(trail, 10);
		return deg;
	}
	// count decimals for number in representation like "0.123456"
	if (Math.floor(value) !== value) {
		return value.toString().split(".")[1].length || 0;
	}
	return 0;
};
export default SignalEditChart;
