import React from "react";
import {
	BooleanInput,
	Datagrid, DateInput,
	Edit,
	email, FormDataConsumer,
	FormTab,
	maxLength, maxValue,
	minLength,
	minValue,
	NumberInput,
	Pagination,
	ReferenceField, ReferenceInput,
	ReferenceManyField,
	regex,
	required, SelectField, SelectInput,
	TabbedForm,
	TextField, TextInput,
	useLocale,
	useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import mobileValidate from "../shared/MobileValidate";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const GiftCodesEdit = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const translate = useTranslate();
	return (
		<Edit {...props} label={"resources.referralcodes.edit"}>
			<TabbedForm redirect={false}>
				<FormTab label="Information">
					<TextField
						source="code"

					/>
					<SelectField source="serviceType" validate={requiredValidate} initialValue={"vendorsubscriptions"} choices={[
						{ id: 'vendorsubscriptions', name: 'Vendor Subscriptions' },
						{ id: 'appsubscriptions', name: 'App Subscriptions' }
					]} />
					<ReferenceField source="vendor" reference={"users"}>
						<NickNameWithAvatarField />
					</ReferenceField>

					<SelectField source="amountType" validate={requiredValidate} initialValue={"fixed"} choices={[
						{ id: 'fixed', name: 'Fixed $' },
						{ id: 'percentage', name: 'Percent %' }
					]} />
					<FormDataConsumer>
						{({ formData, ...rest }) =>
							formData.amountType === "fixed" && (

								<NumberInput source={"fixedAmount"} validate={[required(), minValue(1), maxValue(500)]} initialValue={5} />
							)
						}
					</FormDataConsumer>
					<FormDataConsumer>
						{({ formData, ...rest }) =>
							formData.amountType === "percentage" && (

								<NumberInput source={"percentageAmount"} validate={[required(), minValue(1), maxValue(100)]} initialValue={10} />
							)
						}
					</FormDataConsumer>
					<NumberInput source={"limit"} validate={limitValidate}  />
					<DateInput source={"expiresAt"} />
				</FormTab>
				<FormTab label="Referred Users">
					<ReferenceManyField
						label="Users"
						reference="users"
						target="registeredWithReferralCode"
						sortable={false}
						pagination={<Pagination />}
					>
						<Datagrid label="Users">
							<NameWithAvatarField label={"User"} />
						</Datagrid>
					</ReferenceManyField>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
const limitValidate = [required(), minValue(0)];
const requiredValidate = [required()];
const codeValidate = [minLength(6), maxLength(10)];
const emailValidate = [email()];
const mobileValidatation = [
	required(),
	mobileValidate,
	regex(/^([+]\d{1,3})?\d{10}$/, "Invalid mobile format"),
	minLength(11),
	maxLength(15),
];
const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default GiftCodesEdit;
