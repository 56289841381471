import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import VendorsCreate from './VendorsCreate';
import VendorsEdit from './VendorsEdit';
import VendorsList from './VendorsList';

export default {
    edit : VendorsEdit,
    create: VendorsCreate,
    list:VendorsList,
    icon: RecordVoiceOverIcon,
};
