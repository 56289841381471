const AccountDocsTypes = [
	{
		id: "referral",
		name: "Referral Program",
	},
	{
		id: "settlement",
		name: "Settlement",
	},
	{
		id: "vendorsubscription",
		name: "Vendor Signal Subscription",
	},
	{
		id: "autotrade",
		name: "Vendor Signal AutoTrade",
	},
	{
		id: "giftcode",
		name: "Gift code redemption",
	},
];
const AccountDocsSides = [
	{
		id: "in",
		name: "In",
	},
	{
		id: "out",
		name: "Out",
	},
];
export { AccountDocsTypes, AccountDocsSides };
