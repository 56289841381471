import AdminIcon from '@material-ui/icons/VerifiedUserTwoTone';
import ADMINsCreate from './AdminsCreate';
import ADMINsEdit from './AdminsEdit';

import ADMINsList from './AdminsList';
/*import VisitorCreate from './VisitorCreate';
import VisitorEdit from './VisitorEdit';*!/*/

export default {

    edit: ADMINsEdit,
    create: ADMINsCreate,
    list: ADMINsList,
    icon: AdminIcon,
};
