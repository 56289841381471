import Icon from '@material-ui/icons/MessageRounded';
import SmsCreate from './SmsCreate';

import {SmsList} from './SmsList';

export default {
    create : SmsCreate,

    list:SmsList,
    icon: Icon,
};
