import React from "react";
import {
    BooleanInput,
    Datagrid,
    Edit,
    email,
    FormTab,
    maxLength,
    minLength,
    minValue,
    NumberInput,
    Pagination,
    ReferenceField,
    ReferenceManyField,
    regex,
    required,
    TabbedForm,
    TextField,
    useLocale,
    useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import mobileValidate from "../shared/MobileValidate";
import NameWithAvatarField from "../shared/NameWithAvatarField";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const ReferralCodesEdit = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const translate = useTranslate();
	return (
		<Edit {...props} label={"resources.referralcodes.edit"}>
			<TabbedForm redirect={false}>
				<FormTab label="Information">
					<TextField source="code" />

					<ReferenceField source="owner" reference={"users"}>
						<NameWithAvatarField />
					</ReferenceField>
					<NumberInput source={"limit"} validate={limitValidate} initialValue={0} />
					<BooleanInput source="disabled" />
				</FormTab>
				<FormTab label="Used By Users">
					<ReferenceManyField
						label="Users"
						reference="users"
						target="registeredWithReferralCode"
						sortable={false}
						pagination={<Pagination />}
					>
						<Datagrid label="Users">
							<NameWithAvatarField label={"User"} />
						</Datagrid>
					</ReferenceManyField>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
const limitValidate = [required(), minValue(0)];
const requiredValidate = [required()];
const codeValidate = [minLength(6), maxLength(10)];
const emailValidate = [email()];
const mobileValidatation = [
	required(),
	mobileValidate,
	regex(/^([+]\d{1,3})?\d{10}$/, "Invalid mobile format"),
	minLength(11),
	maxLength(15),
];
const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default ReferralCodesEdit;
