import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {
    minLength,
    PasswordInput,
    required,
    SimpleForm,
    Title,
    useLocale,
    useSetLocale,
    useTranslate,
} from "react-admin";
import {useDispatch, useSelector} from "react-redux";
import BulkEditBtn from "../shared/BulkEditBtn";
import {validatePassword} from "../shared/ScorePassword";
import {AppState} from "../types";
import {SelectTheme} from "./SelectTheme";

const useStyles = makeStyles({
	label: { width: "10em", display: "inline-block" },
	button: { margin: "1em" },
	inputFullWidth: {
		width: "100%",
	},
});

const Configuration = () => {
	const translate = useTranslate();
	const locale = useLocale();
	const setLocale = useSetLocale();
	const classes = useStyles();
	const theme = useSelector((state: AppState) => state.theme);
	const dispatch = useDispatch();
	return (
		<Card>
			<SimpleForm toolbar={<span />} variant={"outlined"}>
				<Title title={translate("pos.configuration")} />
				<CardContent className={classes.inputFullWidth}>
					<SelectTheme />
				</CardContent>

				<CardContent className={classes.inputFullWidth}>
					{/*  <div className={classes.label}>{translate('pos.language')}</div>*/}
					<div className={classes.label}>Dates Format</div>
					<Button
						variant="contained"
						className={classes.button}
						color={locale === "en" ? "primary" : "default"}
						onClick={() => {
							setLocale("en");
							localStorage.setItem("locale", "en");
						}}
					>
						en
					</Button>
					<Button
						variant="contained"
						className={classes.button}
						color={locale === "fa-IR" ? "primary" : "default"}
						onClick={() => {
							setLocale("fa-IR");
							localStorage.setItem("locale", "fa-IR");
						}}
					>
						fa
					</Button>
				</CardContent>
				<CardContent className={classes.inputFullWidth}>
					<div className={classes.label}>{translate("resources.admins.password")}</div>
					<BulkEditBtn
						data={{ _id: null }}
						resource={"admins"}
						field={"password"}
						label="resources.clients.changepassword"
						idField="_id"
						inputComponent={<PasswordInput source="password" validate={passwordValidate} />}
					/>
				</CardContent>
			</SimpleForm>
		</Card>
	);
};
const passwordValidate = [required(), minLength(8), validatePassword];
export default Configuration;
