const ConditionTypes = [
    {
        id : "egr",
        name : "Greater than or equal >= ",
        shortName:">="
    },
    {
        id : "elr",
        name : "Less than or equal <= ",
        shortName:"<="
    }
];
const NotifTypes =[
    {
        id:'SMS',
        name:"SMS"

    }
]
const spotOrderRoles = [
    {
        id:'Entry',
        name:"Position Entry",
        color:"Blue"
    },
    {
        id:'TargetPoint',
        name:"Target Point",
        color:"Green"
    },
    {
        id:'StopLoss',
        name:"Stop Loss",
        color:"Red"

    }
]
export {ConditionTypes,NotifTypes,spotOrderRoles}
