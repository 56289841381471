import React, {useState} from "react";
import {
    Edit,
    ReferenceField,
    ReferenceManyField,
    SaveButton,
    SelectField,
    SelectInput,
    SimpleForm,
    SimpleList,
    TextField,
    TextInput,
    Toolbar,
    useDataProvider,
    useLocale,
    useNotify,
    useRefresh,
    useTranslate,
} from "react-admin";
import {Box, ButtonBase, Card, Dialog, DialogTitle, Typography} from "@material-ui/core";

import {makeStyles, useTheme} from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import ChatView from "./ChatView";
import IconButton from "@material-ui/core/IconButton";
import {SendTwoTone} from "@material-ui/icons";
import {TicketStatus} from "./SupportTicketsEnum";
import Chip from "@material-ui/core/Chip";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import {TrimField} from "@bb-tech/ra-components";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import {Link as RouterLink} from "react-router-dom";
import CreateCDN from "../cdnservice/cdnCreate";
import {useForm} from "react-final-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
	messageInput: {
		minHeight: 35,
	},
	attachmentBtn: {
		margin: 1,
	},
	inputAd: {
		height: "unset",
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));
const CloseTicketBtn = ({ record, basePath }) => {
	const refresh = useRefresh();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const [loading, setLoading] = useState(false);

	if (!record) {
		return <span />;
	}
	const handleClick = () => {
		setLoading(true);
		dataProvider
			.update("supporttickets", {
				id: record?._id,
				data: {
					status: "closed",
				},
			})
			.then(() => {
				refresh();
			})
			.catch((error) => notify(`Error: ${error.message}`, "warning"))
			.finally(() => setLoading(false));
	};
	return (
		<Tooltip title={"Click to mark ticket as closed"}>
			<IconButton
				color={"secondary"}
				onClick={handleClick}
				disabled={loading || record?.status === "closed"}
				disableFocusRipple={true}
			>
				<CancelTwoToneIcon fontSize={"large"} />
			</IconButton>
		</Tooltip>
	);
};
const ChangeTicketStatus = ({ record, basePath }) => {
	const refresh = useRefresh();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const [loading, setLoading] = useState(false);

	if (!record) {
		return <span />;
	}
	const handleChange = (event) => {
		setLoading(true);
		dataProvider
			.update("supporttickets", {
				id: record?._id,
				data: {
					status: event.target.value,
				},
			})
			.then(() => {
				refresh();
			})
			.catch((error) => notify(`Error: ${error.message}`, "warning"))
			.finally(() => setLoading(false));
	};
	return <SelectInput record={record} source="status" choices={TicketStatus} onChange={handleChange} />;
};
const FormToolbar = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	return (
		<Toolbar {...props} className={classes.toolbar}>
			<SaveButton icon={<SendTwoTone />} disabled={props.pristine} label={"Send"} />
		</Toolbar>
	);
};
const SupportTicketsEdit = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const notify = useNotify();
	const refresh = useRefresh();
	const onSuccess = () => {
		notify("Message sent");
		setTimeout(() => {
			refresh();
		}, 200);
	};
	return (
		<Edit
			{...props}
			label={"resources.sms.createnew"}
			aside={<Aside />}
			undoable={false}
			mutationMode={"pessimistic"}
			onSuccess={onSuccess}
		>
			<SimpleForm redirect={false} toolbar={<FormToolbar />}>
				<TicketEditLayout />
			</SimpleForm>
		</Edit>
	);
};
const UserWithLink = ({ record }) => {
	if (!record) {
		return <span />;
	}
	return (
		<ButtonBase component={RouterLink} to={"/users|usertype|" + record?.usertype + "/" + record?._id}>
			<NameWithAvatarField record={record} />{" "}
			{!!record?.nickName && <Typography variant={"caption"}> {" - " + record?.nickName} </Typography>}
			<Typography variant={"caption"}> {" - " + record?.usertype} </Typography>
		</ButtonBase>
	);
};
const TicketEditLayout = ({ record = {} }) => {
	const locale = useLocale();
	const form = useForm();
	const classes = useStyles();
	const [uploadDialog, setUploadDialog] = useState(false);
	const fileUploaded = ({ data }) => {
		if (data?._id) {
			form.change("isCdn", true);
			form.change("message", data?._id);

			form.submit();
		}
		setUploadDialog(false);
	};
	return (
		<Card
			style={{
				maxWidth: 1280,
				maxHeight: "calc(100vh - 150px)",
				display: "flex",
				flexDirection: "column",
				backgroundColor: "#232323",
			}}
		>
			<CardHeader
				action={<CloseTicketBtn record={record} />}
				title={`Ticket Number: #${record?.ticketNumber}`}
				subheader={
					<Typography color={"textSecondary"} variant={"caption"}>
						<ReferenceField
							record={record}
							reference={"stcategories"}
							source={"category"}
							label={"Topic"}
							link={false}
						>
							<TextField source={"title"} />
						</ReferenceField>
					</Typography>
				}
			/>
			<Box px={2} display={"flex"} justifyContent={"space-between"}>
				<Box>
					<ReferenceField source="user" label={"resources.observes.client"} reference="users">
						<UserWithLink />
					</ReferenceField>
					<Typography variant={"h6"}>{record?.title}</Typography>
				</Box>
				<Box display={"flex"} flexDirection={"column"} justifyContent={"flex-end"}>
					<ChangeTicketStatus record={record} />
					<Typography style={{ marginTop: 5 }} color={"textSecondary"} align={"right"} variant={"caption"}>
						{moment(record?.updatedAt).fromNow()}
					</Typography>
				</Box>
			</Box>
			<CardContent style={{ overflowY: "scroll" }} id={"chatview"}>
				<ChatView record={record} />
			</CardContent>
			<CardActions>
				<TextInput
					record={record}
					source="message"
					multiline={true}
					fullWidth={true}
					autoFocus={true}
					className={classes.messageInput}
					variant={"standard"}
					label="Reply Message"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end" className={classes.inputAd}>
								<IconButton
									color={"primary"}
									className={classes.attachmentBtn}
									onClick={() => setUploadDialog(true)}
								>
									<AttachFileIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</CardActions>
			<Dialog open={uploadDialog} keepMounted={false} fullWidth={true} onClose={() => setUploadDialog(false)}>
				<DialogTitle disableTypography>
					<IconButton aria-label="close" className={classes.closeButton} onClick={() => setUploadDialog(false)}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<CreateCDN basePath={"/cdn"} resource={"cdn"} onSuccess={fileUploaded} />
			</Dialog>
		</Card>
	);
};
const Aside = ({ record }) => {
	const locale = useLocale();
	if (!record) {
		return <span />;
	}
	return (
		<div style={{ margin: "1em", maxWidth: 300 }}>
			<Typography>All Tickets</Typography>
			<ReferenceManyField
				label="Tickets"
				reference="supporttickets"
				target="user"
				record={{ id: record.user, _id: record.user }}
			>
				<SimpleList
					primaryText={(record) => record.title}
					leftIcon={(record) => (
						<Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} mr={2}>
							<Typography variant={"h6"}>{`#${record?.ticketNumber}`}</Typography>
							<Typography color={"textSecondary"} variant={"caption"}>
								<ReferenceField reference={"stcategories"} source={"category"} label={"Topic"} link={false}>
									<TextField source={"title"} />
								</ReferenceField>
							</Typography>
						</Box>
					)}
					secondaryText={(record) => (
						<Box display={"flex"}>
							<Box flexGrow={1}>
								<Typography>
									<TrimField record={record} source="lastMessage" limit={200} trimstr="..." />
								</Typography>
								<Box>{moment(record?.updatedAt).fromNow()}</Box>
							</Box>
							<SelectField
								record={record}
								source="status"
								choices={TicketStatus}
								optionText={(option) => (
									<Chip variant={"outlined"} label={option?.name} color={option?.color} />
								)}
							/>
						</Box>
					)}
					linkType={"edit"}
					rowStyle={GetRowStyle}
				/>
			</ReferenceManyField>
		</div>
	);
};
const GetRowStyle = (record) => {
	const theme = useTheme();
	if (!record) {
		return null;
	}
	const color = TicketStatus.find((ts) => ts.id === record?.status)?.color;
	return {
		borderTopColor: "transparent",
		borderRightColor: "transparent",
		marginTop: 5,
		marginBottom: 5,
		borderLeftWidth: 2,
		borderBottomWidth: 1,
		borderLeftStyle: "solid",
		borderBottomStyle: "solid",
		borderBottomColor: "#232323",
		borderLeftColor: theme?.palette[color]?.main,
	};
};
export default SupportTicketsEdit;
