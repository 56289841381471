import React from "react";
import {
    AutocompleteInput,
    Create,
    email,
    maxLength,
    minLength,
    minValue,
    NumberInput,
    ReferenceInput,
    regex,
    required,
    SimpleForm,
    TextInput,
    useLocale,
    useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import mobileValidate from "../shared/MobileValidate";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const ReferralCodesCreate = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const translate = useTranslate();
	return (
		<Create {...props} label={"resources.referralcodes.createnew"}>
			<SimpleForm redirect={"list"}>
				<TextInput
					source="code"
					formClassName={classes.normalfield}
					validate={codeValidate}
					helperText={"Leave empty to Auto Generate"}
				/>

				<ReferenceInput
					validate={requiredValidate}
					source="owner"
					perPage={25}
					reference="users"
					allowEmpty={false}
					filter={{
						usertype: { $ne: "ADMIN" },
					}}
					filterToQuery={(searchText) =>
						searchText.trim() !== ""
							? {
									$or: [
										{ firstName: { $search: searchText } },
										{ lastName: { $search: searchText } },
										{ telegramId: { $search: searchText } },
									],
							  }
							: { fake: true }
					}
				>
					<AutocompleteInput
						matchSuggestion={() => true}
						optionText={(record) =>
							record && record.firstName
								? record.firstName + " " + record.lastName + (record.telegramId ? " " + record.telegramId : "")
								: translate("resources.observes.allusers")
						}
						optionValue={"_id"}
					/>
				</ReferenceInput>
				<NumberInput source={"limit"} validate={limitValidate} initialValue={0} />
			</SimpleForm>
		</Create>
	);
};
const limitValidate = [required(), minValue(0)];
const requiredValidate = [required()];
const codeValidate = [minLength(6), maxLength(10)];
const emailValidate = [email()];
const mobileValidatation = [
	required(),
	mobileValidate,
	regex(/^([+]\d{1,3})?\d{10}$/, "Invalid mobile format"),
	minLength(11),
	maxLength(15),
];
const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default ReferralCodesCreate;
