import {withStyles} from "@material-ui/core";
import MySlider from "./mySlider/MySlider";

const iOSBoxShadow = "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
const ThemedSlider = withStyles((theme) => ({
	root: {
		color: theme.palette.text.primary,

		height: 2,
		padding: "15px 0",
	},
	thumb: {
		height: 28,
		width: 28,
		backgroundColor: "#fff",
		//boxShadow: iOSBoxShadow,
		marginTop: -14,
		marginLeft: -14,
		"&:focus, &:hover, &$active": {
			boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
			// Reset on touch devices, it doesn't add specificity
			/*"@media (hover: none)": {
				boxShadow: iOSBoxShadow,
			},*/
		},
		"&$disabled": {
			opacity: 0.1,
			height: 35,
			width: 35,
			marginLeft: -4,
			marginTop: -3,

			"&:hover": {
				boxShadow: "none",
			},
		},
	},
	active: {},
	valueLabel: {
		left: "calc(-50% + 12px)",
		top: -22,
		"& *": {
			background: "transparent",
			//           color: '#000',
		},
	},
	track: {
		height: 2,
	},
	rail: {
		display: "block",
		position: "absolute",
		width: "100%",
		height: 2,
		borderRadius: 1,
		backgroundColor: "currentColor",
		opacity: 0.38,
		"$vertical &": {
			height: "100%",
			width: 2,
		},
	},
	mark: {
		backgroundColor: "#bfbfbf",
		height: 30,
		width: 1,
		marginTop: -3,
	},
	markActive: {
		opacity: 1,
		backgroundColor: "currentColor",
	},
}))(MySlider);

export { ThemedSlider };
