import React from "react";
import {Create, required, SimpleForm, TextInput, useLocale, usePermissions,} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const LinksCreate = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const { permissions } = usePermissions();
	return (
		<Create {...props} label={"resources.sms.createnew"}>
			<SimpleForm redirect={"list"}>
				<TextInput source="link" validate={[required()]} />
				<TextInput source="hash" />
				<TextInput source="meta" />
			</SimpleForm>
		</Create>
	);
};

export default LinksCreate;
