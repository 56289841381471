import Icon from "@material-ui/icons/MessageRounded";
import STCategories from "./STCategoriesCreate";

import {STCategoriesList} from "./STCategoriesList";

export default {
	create: STCategories,

	list: STCategoriesList,
	icon: Icon,
};
