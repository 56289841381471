import io from "socket.io-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";

const rest = require("@feathersjs/rest-client");
const client = feathers();
//console.log("ServerURL:" + window.location.hostname)
//client.set("serverurl", window.location.hostname);
//client.set("serverurl", "https://localhost");
client.set("serverurl", "https://api10.dealerify.io");
client.set("serverurl2", "https://publicapi.dealerify.io");
client.set("cdnurl", "https://cdnapi.dealerify.io");
client.set("linkmanagerurl", "https://l.dealerify.io/");
const convertFileToBase64 = (rawFile) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;

		reader.readAsDataURL(rawFile);
	});
const getAccessTokenHeader = async () => {
	const auth = await client.get("authentication");
	console.log(auth);
	if (auth?.accessToken) {
		return "Bearer " + auth?.accessToken;
	}
	return undefined;
};
const socketOptions = {
	transports: ["websocket"],
	upgrade: false,
	timeout: 30000,
};
const socketIO = io(client.get("serverurl"), socketOptions);
const mainapi = socketio(socketIO, socketOptions);
client.configure(mainapi);

client.configure(
	auth({
		jwtStrategy: "jwt",
		storage: window.localStorage,
		timeout: 40000,
		storageKey: "login-jwt",
	})
);
const publicapi = rest(client.get("serverurl2")).fetch(window.fetch);
const cdnapi = rest(client.get("cdnurl")).fetch(window.fetch);
const linkmanagerapi = rest(client.get("linkmanagerurl")).fetch(window.fetch);
client.use("accounts", publicapi.service("accounts"));
client.use("cdn", cdnapi.service("cdn"));
client.use("links", linkmanagerapi.service("links"));
client.service("links").hooks({
	before: {
		all: [
			async (context) => {
				const at = await getAccessTokenHeader();
				if (!!at) {
					context.params.headers = { ...context?.params?.headers, Authorization: at };
				}

				return Promise.resolve(context);
			},
		],
	},
});
client.service("accounts").hooks({
	before: {
		all: [
			async (context) => {
				const at = await getAccessTokenHeader();
				if (!!at) {
					context.params.headers = { ...context?.params?.headers, Authorization: at };
				}

				return Promise.resolve(context);
			},
		],
	},
});
client.service("cdn").hooks({
	before: {
		create: [
			async (context) => {
				const at = await getAccessTokenHeader();
				if (!!at) {
					context.params.headers = {
						...context?.params?.headers,
						Authorization: at,
					};
				}
				if (context.data?.fileToUpload?.rawFile instanceof File) {
					context.data.fileToUpload.rawFile = await convertFileToBase64(context.data.fileToUpload.rawFile);
				}
				return Promise.resolve(context);
			},
		],
	},
});
export default client;
