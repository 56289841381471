export default {
	themeName: "Light",
	typography: {
		fontFamily: "Helvetica",
		h6: {
			fontSize: "1rem",
		},
	},
	palette: {
		primary: {
			light: "#2ae8aa",
			dark: "#001064",
			main: "#1d1b43",
			contrastText: "#fff",
		},
		secondary: {
			light: "#7175d8",
			dark: "#001064",
			main: "#7175d8",
			contrastText: "#fff",
		},
	},
	overrides: {
		RaAppBar: {
			color: "primary",
		},
		MuiFilledInput: {
			root: {
				backgroundColor: "rgba(0, 0, 0, 0.04)",
				"&$disabled": {
					backgroundColor: "rgba(0, 0, 0, 0.04)",
				},
			},
		},
		NDLogin: {
			main: {
				"& .MuiFormLabel-root": {
					color: "#000000",
				},
				"& .MuiFormLabel-root.Mui-focused": {
					color: "#0085ff",
				},
				"& .MuiFormLabel-root.Mui-error": {
					color: "#f44336",
				},
				"& .MuiInput-underline:after": {
					borderBottom: "2px solid #0085ff",
				},
			},
			card: {
				minWidth: 300,
				marginTop: "6em",
				backgroundColor: "#ffffffe6",
			},
			avatar: {},
			icon: {},
			button: {
				boxShadow: "3px 3px 5px #000000a3",
			},
			systemNameLink: {
				color: "#0085ff",
			},
		},
	},
};
