import Box from "@material-ui/core/Box";
import React, {memo} from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
	root: {
		display: "flex",
		maxWidth: 700,
		flexDirection: "column",
		marginLeft: "auto",
		marginRight: "auto",
		justifyContent: "center",
		alignItems: "center",
	},
	container: {
		padding: theme.spacing(10),
	},
});
const SupportTicketsEmpty = ({ classes = {} }) => {
	return (
		<Box className={classes.container}>
			<Box className={classes.root}>
				<Box width={"40%"} mx={"auto"} my={3} display={"flex"} justifyContent={"center"} fontSize={"5rem"}>
					<LiveHelpIcon fontSize={"inherit"} color={"primary"} />
				</Box>
				<Typography gutterBottom style={{ fontWeight: 800 }}>
					Submit a new support ticket if you
				</Typography>
				<Typography color={"textSecondary"} gutterBottom variant={"body2"}>
					have questions ❓
				</Typography>
				<Typography color={"textSecondary"} gutterBottom variant={"body2"}>
					need help 🆘
				</Typography>
				<Typography color={"textSecondary"} gutterBottom variant={"body2"}>
					are angry 😡
				</Typography>
				<Typography color={"textSecondary"} gutterBottom variant={"body2"}>
					have suggestions 🤓
				</Typography>
				<Typography color={"textSecondary"} gutterBottom variant={"body2"}>
					want to report a bug 🐞
				</Typography>
				<Typography color={"textSecondary"} gutterBottom variant={"body2"}>
					want to join us as a vendor
				</Typography>

				<Box m={2} />
				<Button
					variant={"outlined"}
					component={RouterLink}
					color={"primary"}
					size={"large"}
					to={"/supporttickets/create"}
				>
					Submit support ticket
				</Button>
			</Box>
		</Box>
	);
};
export default memo(withStyles(styles, { name: "SupportTicketsEmpty" })(SupportTicketsEmpty));
