import React from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    EditButton,
    Filter,
    List,
    SearchInput,
    TextField,
    useLocale,
    useTranslate,
} from "react-admin";

const StatusFieldColored = ({ record }) => (
	<TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);
const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<SearchInput
				source="name.$search"
				alwaysOn
				placeholder={translate("resources.markets.name")}
				InputLabelProps={{ shrink: true }}
			/>
		</Filter>
	);
};

const SubscriptionplansList = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.markets.listtitle")}
			perPage={10}
			exporter={false}
			bulkActionButtons={false}
		>
			<Datagrid>
				<TextField source="name" />

				<TextField source="maxExchangeAccounts" />
				<TextField source="monthlyPrice" />
				<BooleanField source={"monthlyOnSale"} />
				<TextField source="monthlyOnSalePrice" />
				<BooleanField source={"quarterlyOnSale"} />
				<TextField source="quarterlyOnSalePrice" />
				<BooleanField source={"annuallyOnSale"} />
				<TextField source="annuallyOnSalePrice" />

				<DateField source="updatedAt" label="resources.markets.updatedAt" locales={locale} showTime />

				{props &&
					props.permissions &&
					props.permissions.usertype === "ADMIN" &&
					props.permissions.accessType === 1 && <EditButton />}
			</Datagrid>
		</List>
	);
};

export { SubscriptionplansList };
