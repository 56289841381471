import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import SubscriptionplansCreate from "./SubscriptionplansCreate";
import SubscriptionplansEdit from "./SubscriptionplansEdit";
import {SubscriptionplansList} from "./SubscriptionplansList";

export default {
	create: SubscriptionplansCreate,
	edit: SubscriptionplansEdit,
	list: SubscriptionplansList,
	icon: SubscriptionsIcon,
};
