import TimelineIcon from "@material-ui/icons/Timeline";

import {CLUFPositionsList} from "./CLUFPositionsList";
import {CLUFPositionsShow} from "./CLUFPositionsShow";

export default {
  show: CLUFPositionsShow,
  list: CLUFPositionsList,
  icon: TimelineIcon,
};
