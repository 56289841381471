import React from "react";
import {
    DeleteButton,
    Edit,
    email,
    FormTab,
    Loading,
    maxValue,
    minLength,
    minValue,
    Pagination,
    ReferenceField,
    ReferenceManyField,
    required,
    SaveButton,
    TabbedForm,
    TabbedFormTabs,
    Toolbar,
    TopToolbar,
    useGetList,
    useGetOne,
    useLocale,
    useNotify,
    useRedirect,
    useRefresh,
    useTranslate,
} from "react-admin";
import {Box, Typography, useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SignalTimeLine from "../VendorSignalTimeLine";
//import {DumbList} from '../shared/DumbList';
import {OpenedOrdersDataGrid,} from "../../atspotpositions/ATSPositionsList";
import AddUsersToChannelByChannelBtn from "../../shared/AddUsersToChannelByChannelBtn";

import VendorSignalsEntryPriceEdit from "./VendorSignalsEntryPriceEdit";
import VendorSignalsStopLossEdit from "./VendorSignalsStopLossEdit";
import VendorSignalsTargetPointsEdit from "./VendorSignalsTargetPointsEdit";
import VendorSignalsPercentsEdit from "./VendorSignalsPercentsEdit";
import VendorSignalsSignalInformationEdit from "./VendorSignalsSignalInformationEditWithChart";

import Grid from "@material-ui/core/Grid";
import NickNameWithAvatarField from "../../shared/NickNameWithAvatarField";

export const styles = {
	// formInput:{flexDirection:"column",display:"flex",maxWidth:500 },
	//normalfield: {width: 544},
	//fullWidth: {width: '100% !important'},
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	toolbar: { justifyContent: "space-between" },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);
const Title = ({ record, ...props }) => {
	const translate = useTranslate();
	if (!record) {
		return <span>Loading...</span>;
	}
	return (
		<Box display={"flex"} alignItems={"center"}>
			<span style={{ marginRight: 5 }}> {record.name} </span>
			<ReferenceField
				record={record}
				{...props}
				source="vendor"
				label={"resources.vendorsubscriptions.vendor"}
				reference="users|usertype|VENDOR"
				link={false}
			>
				<NickNameWithAvatarField />
			</ReferenceField>
		</Box>
	);
};

const FormToolbar = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	return (
		<Toolbar {...props} className={classes.toolbar}>
			{!props.record.triggered && <SaveButton disabled={props.record.triggered} />}
			{props.record.triggered && (
				<Typography variant="caption">{translate("resources.alerts.cantedittriggred")}</Typography>
			)}
			<DeleteButton />
		</Toolbar>
	);
};
const GriddedComponents = ({ record, pairObject, ...other }) => {
	return (
		<Grid container spacing={1}>
			<Grid item sm={12} xs={12}>
				<VendorSignalsSignalInformationEdit record={record} pairObject={pairObject} {...other} />
			</Grid>
			<Grid item sm={12} md={6} xs={12}>
				<VendorSignalsEntryPriceEdit record={record} pairObject={pairObject} />
			</Grid>
			<Grid item sm={12} md={6} xs={12}>
				<VendorSignalsStopLossEdit record={record} pairObject={pairObject} />
			</Grid>
			<Grid item sm={12} md={12} xs={12}>
				<VendorSignalsTargetPointsEdit record={record} pairObject={pairObject} />
			</Grid>
			<Grid item sm={12} md={12} xs={12}>
				<VendorSignalsPercentsEdit record={record} pairObject={pairObject} />
			</Grid>
		</Grid>
	);
};
const useGetTotals = (positionReference, record) => {
	const { total: totalOpen } = useGetList(
		positionReference,
		{ perPage: 1, page: 1 },
		{ field: "id", order: "ASC" },
		{ vendorSignal: record && record._id ? record._id : false, opened: true, closed: false }
	);
	const { total: totalPending } = useGetList(
		positionReference,
		{ perPage: 1, page: 1 },
		{ field: "id", order: "ASC" },
		{ vendorSignal: record && record._id ? record._id : false, opened: false, closed: false }
	);
	const { total: totalCancelled } = useGetList(
		positionReference,
		{ perPage: 1, page: 1 },
		{ field: "id", order: "ASC" },
		{ vendorSignal: record && record._id ? record._id : false, opened: false, closed: true }
	);
	const { total: totalClosed } = useGetList(
		positionReference,
		{ perPage: 1, page: 1 },
		{ field: "id", order: "ASC" },
		{ vendorSignal: record && record._id ? record._id : false, opened: true, closed: true }
	);

	return {
		open: totalOpen ? totalOpen : 0,
		pending: totalPending ? totalPending : 0,
		cancelled: totalCancelled ? totalCancelled : 0,
		closed: totalClosed ? totalClosed : 0,
	};
};
const EditInnerForm = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const { record } = props;
	const { data, loading, error } = useGetOne("pairs", record.pair);
	let pairObject = data;
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	const positionReference = record && record.tradetype ? "atufuturespositions" : "atspotpositions";
	const totals = useGetTotals(positionReference, record);
	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <p>ERROR</p>;
	}

	return (
		<TabbedForm tabs={<TabbedFormTabs scrollable={false} />} {...props} redirect={false}>
			<FormTab label="main">
				<GriddedComponents pairObject={pairObject} />
			</FormTab>
			<FormTab label={"Pending (" + totals.pending + ")"}>
				<ReferenceManyField
					cellClassName={classes.maxWidth100}
					reference={positionReference}
					target="vendorSignal"
					filter={{ opened: false, closed: false }}
					perPage={50}
					pagination={<Pagination />}
				>
					<OpenedOrdersDataGrid />
				</ReferenceManyField>
			</FormTab>
			<FormTab label={"Open (" + totals.open + ")"}>
				<ReferenceManyField
					cellClassName={classes.maxWidth100}
					reference={positionReference}
					target="vendorSignal"
					filter={{ opened: true, closed: false }}
					perPage={50}
					pagination={<Pagination />}
				>
					<OpenedOrdersDataGrid />
				</ReferenceManyField>
			</FormTab>
			<FormTab label={"Closed (" + totals.closed + ")"}>
				<ReferenceManyField
					cellClassName={classes.maxWidth100}
					reference={positionReference}
					target="vendorSignal"
					filter={{ opened: true, closed: true }}
					perPage={50}
					pagination={<Pagination />}
				>
					<OpenedOrdersDataGrid />
				</ReferenceManyField>
			</FormTab>
			<FormTab label={"Canceled (" + totals.cancelled + ")"}>
				<ReferenceManyField
					cellClassName={classes.maxWidth100}
					reference={positionReference}
					target="vendorSignal"
					filter={{ opened: false, closed: true }}
					perPage={50}
					pagination={<Pagination />}
				>
					<OpenedOrdersDataGrid />
				</ReferenceManyField>
			</FormTab>
			<FormTab label="resources.signalalerts.detailstimeline">
				<SignalTimeLine />
			</FormTab>
		</TabbedForm>
	);
};
const SignalAlertsEdit = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	//console.log(record)
	const translate = useTranslate();
	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();

	const onFailure = (error) => {
		notify("resources.signalalerts.editErrorConflict", "warning", { message: error.message });

		refresh();
	};

	return (
		<Edit {...props} onFailure={onFailure} title={<Title {...props} />} undoable={false}>
			<EditInnerForm />
		</Edit>
	);
};
const totValidate = [required(), minValue(10), maxValue(600)];
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];
const valueValidate = [required(), minValue(0)];
const EditActions = ({ basePath, data, resource }) => (
	<TopToolbar>
		<AddUsersToChannelByChannelBtn record={data} basePath={basePath} />
	</TopToolbar>
);

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom gutterTop>
			{translate(label)}
		</Typography>
	);
};

const RFPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const Separator = () => <Box pt="1em" />;

export default SignalAlertsEdit;
