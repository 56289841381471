import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	balanceChipAvatar: {
		width: 25,
		height: 25,
		fontSize: "0.9em",
	},
	balanceChip: {
		margin: 1,
	},
}));
const BalanceField = ({ record }) => {
	if (!record) {
		return <span />;
	}
	if (Array.isArray(record?.balance)) {
		record = record?.balance[0];
	}
	if (!record) {
		return <span />;
	}
	let { tradetype, balance, prices, v2 } = record;
	if (!prices && !balance) {
		return <span />;
	}
	if (!prices && balance && balance.prices) {
		prices = balance.prices;
	}

	if (tradetype === 0) {
		return record.v2 ? (
			<SpotBalance_V2 balance={record.balance} prices={prices} />
		) : (
			<SpotBalance_V1 balance={record.balance} prices={prices} />
		);
	} else if (record.tradetype === 1) {
		return record.v2 ? (
			<FuturesBalance_v2 balance={record.balance} />
		) : (
			<FuturesBalance_v1 balance={record.balance} />
		);
	}
	return <span />;
};
const FuturesBalance_v1 = ({ balance }) => {
	const classes = useStyles();

	let balanceArray = balance.positions ? balance.positions : [];

	return (
		<Fragment>
			<Chip
				className={classes.balanceChip}
				// variant="outlined"
				color={"secondary"}
				label={"Total : $" + Number(balance.totalMarginBalance).toFixed(2)}
			/>
			<Chip
				className={classes.balanceChip}
				//  variant="outlined"
				color={"primary"}
				icon={
					<Avatar
						alt={"F"}
						src={"https://cdn.dealerify.io/asseticons/svg/color/usdt.svg"}
						// className={classes.assetAvatar}
						//style={{ width: parseInt(size, 10),backgroundColor:borderColor === 'transparent' ? 'lightgray' : borderColor, height: parseInt(size, 10),borderColor:borderColor,borderStyle:'solid',borderWidth:'1px' }}
						className={classes.balanceChipAvatar}
					/>
				}
				label={"Availabe : $" + Number(balance.availableBalance).toFixed(2)}
			/>
			{balanceArray.map((item) => (
				<Chip
					className={classes.balanceChip}
					icon={
						<Avatar
							alt={item.symbol}
							src={
								"https://cdn.dealerify.io/asseticons/svg/color/" +
								item.symbol.substring(0, item.symbol.length - 4).toLowerCase() +
								".svg"
							}
							// className={classes.assetAvatar}
							//style={{ width: parseInt(size, 10),backgroundColor:borderColor === 'transparent' ? 'lightgray' : borderColor, height: parseInt(size, 10),borderColor:borderColor,borderStyle:'solid',borderWidth:'1px' }}
							className={classes.balanceChipAvatar}
						/>
					}
					style={{
						backgroundColor: Number(item.notional) > 0 ? "#1D9B1D" : "#f50057",
						color: "#fff",
					}}
					//color={Number(item.notional) > 0 ? "success" : "secondary"}
					onDelete={() => true}
					deleteIcon={<span style={{ color: "#EDF169" }}>{item.leverage}x</span>}
					label={
						item.symbol +
						" : $" +
						Number(item.initialMargin).toFixed(2) +
						"(" +
						Number(item.unrealizedProfit).toFixed(2) +
						")"
					}
				/>
			))}
		</Fragment>
	);
};
const FuturesBalance_v2 = ({ balance }) => {
	const classes = useStyles();

	let balanceArray = balance.positions ? balance.positions : [];

	return (
		<Fragment>
			<Chip
				className={classes.balanceChip}
				// variant="outlined"
				color={"secondary"}
				label={"Total : $" + Number(balance.totalMarginBalance).toFixed(2)}
			/>
			<Chip
				className={classes.balanceChip}
				//  variant="outlined"
				color={"primary"}
				icon={
					<Avatar
						alt={"F"}
						src={"https://cdn.dealerify.io/asseticons/svg/color/usdt.svg"}
						// className={classes.assetAvatar}
						//style={{ width: parseInt(size, 10),backgroundColor:borderColor === 'transparent' ? 'lightgray' : borderColor, height: parseInt(size, 10),borderColor:borderColor,borderStyle:'solid',borderWidth:'1px' }}
						className={classes.balanceChipAvatar}
					/>
				}
				label={"Availabe : $" + Number(balance.availableBalance).toFixed(2)}
			/>
			{balanceArray.map((item) => (
				<Chip
					className={classes.balanceChip}
					icon={
						<Avatar
							alt={item.symbol}
							src={
								"https://cdn.dealerify.io/asseticons/svg/color/" +
								item.symbol.split("/")[0].toLowerCase() +
								".svg"
							}
							// className={classes.assetAvatar}
							//style={{ width: parseInt(size, 10),backgroundColor:borderColor === 'transparent' ? 'lightgray' : borderColor, height: parseInt(size, 10),borderColor:borderColor,borderStyle:'solid',borderWidth:'1px' }}
							className={classes.balanceChipAvatar}
						/>
					}
					style={{
						backgroundColor: item.side === "long" ? "#1D9B1D" : "#f50057",
						color: "#fff",
					}}
					//color={Number(item.notional) > 0 ? "success" : "secondary"}
					onDelete={() => true}
					deleteIcon={<span style={{ color: "#EDF169" }}>{item.leverage}x</span>}
					label={
						item.symbol +
						" : $" +
						Number(item.initialMargin).toFixed(2) +
						"(" +
						Number(item.unrealizedPnl).toFixed(2) +
						")"
					}
				/>
			))}
		</Fragment>
	);
};
const SpotBalance_V1 = ({ balance, prices }) => {
	const classes = useStyles();
	let balanceArray = Object.entries(balance).map(([asset, bal]) => {
		let assetPrice = 0;
		if (balance && prices) {
			assetPrice = prices.find((p) => p._symbol.startsWith(asset + "/USDT"));
			if (assetPrice) {
				assetPrice = Number(assetPrice.latestPrice * bal.available).toFixed(2);
			}
		}
		return {
			asset: asset,
			available: asset === "USDT" ? Number(bal.available).toFixed(2) : bal.available,
			worth: asset === "USDT" ? Number(bal.available).toFixed(2) : assetPrice,
		};
	});
	if (balanceArray && Array.isArray(balanceArray)) {
		balanceArray = balanceArray.filter((ba) => ba.available && Number(Number(ba.worth).toFixed()));
	}
	balanceArray.sort((a, b) => b.worth - a.worth);
	let total =
		balanceArray && Array.isArray(balanceArray) && balanceArray.length
			? balanceArray.map((b) => (b.worth ? Number(b.worth) : 0)).reduce((total, num) => total + num)
			: 0;
	return (
		<Fragment>
			<Chip
				className={classes.balanceChip}
				variant="outlined"
				color={"secondary"}
				label={"Total : $" + Number(total).toFixed(2)}
			/>
			{balanceArray.map((item) => (
				<Chip
					className={classes.balanceChip}
					variant="outlined"
					icon={
						<Avatar
							alt={item.asset}
							src={"https://cdn.dealerify.io/asseticons/svg/color/" + item.asset.toLowerCase() + ".svg"}
							// className={classes.assetAvatar}
							//style={{ width: parseInt(size, 10),backgroundColor:borderColor === 'transparent' ? 'lightgray' : borderColor, height: parseInt(size, 10),borderColor:borderColor,borderStyle:'solid',borderWidth:'1px' }}
							className={classes.balanceChipAvatar}
						/>
					}
					label={item.asset + " : " + Number(item.available) + (item.worth ? " = $" + item.worth : "")}
				/>
			))}
		</Fragment>
	);
};
const SpotBalance_V2 = ({ balance, prices }) => {
	const classes = useStyles();
	let balanceArray = Object.entries(balance).map(([asset, bal]) => {
		let assetPrice = 0;
		let worth = 0;
		if (balance && prices) {
			assetPrice = prices.find((p) => p._symbol.startsWith(asset + "/USDT"));
			if (assetPrice) {
				worth = asset === "USDT" ? bal.total.toFixed(2) : Number(assetPrice.latestPrice * bal.total).toFixed(2);
			} else {
				worth = 0.1;
			}
		}
		return {
			assetPrice: assetPrice && assetPrice.latestPrice ? assetPrice.latestPrice : bal.total,
			asset: asset,
			total: asset === "USDT" ? Number(bal.total).toFixed(2) : bal.total,
			free: asset === "USDT" ? Number(bal.free).toFixed(2) : bal.free,
			used: asset === "USDT" ? Number(bal.used).toFixed(2) : bal.used,
			worth: asset === "USDT" ? Number(bal.total).toFixed(2) : worth,
			onDateWorth: asset === "USDT" ? Number(bal.total).toFixed(2) : Number(bal.total * bal.price).toFixed(2),
		};
	});
	let total =
		balanceArray && Array.isArray(balanceArray) && balanceArray.length
			? balanceArray.map((b) => (b.worth ? Number(b.worth) : b.total)).reduce((total, num) => total + num)
			: 0;
	let totalOnDate =
		balanceArray && Array.isArray(balanceArray) && balanceArray.length
			? balanceArray
					.map((b) => (b.onDateWorth ? Number(b.onDateWorth) : b.total))
					.reduce((total, num) => total + num)
			: 0;
	if (balanceArray && Array.isArray(balanceArray)) {
		balanceArray = balanceArray.filter((ba) => ba.total && Number(Number(ba.worth).toFixed()));
	}
	balanceArray.sort((a, b) => b.worth - a.worth);

	return (
		<Box m={"3px"}>
			<Chip
				className={classes.balanceChip}
				variant="outlined"
				color={"secondary"}
				label={"Total Worth Now: $" + Number(total).toFixed(2)}
			/>
			<Chip
				className={classes.balanceChip}
				variant="outlined"
				color={"secondary"}
				label={"Worth on Refresh Time: $" + Number(totalOnDate).toFixed(2)}
			/>
			{balanceArray.map((item) => (
				<Chip
					className={classes.balanceChip}
					variant="outlined"
					icon={
						<Avatar
							alt={item.asset}
							src={"https://cdn.dealerify.io/asseticons/svg/color/" + item.asset.toLowerCase() + ".svg"}
							// className={classes.assetAvatar}
							//style={{ width: parseInt(size, 10),backgroundColor:borderColor === 'transparent' ? 'lightgray' : borderColor, height: parseInt(size, 10),borderColor:borderColor,borderStyle:'solid',borderWidth:'1px' }}
							className={classes.balanceChipAvatar}
						/>
					}
					label={item.asset + " : " + Number(item.total) + (item.worth ? " = $" + item.worth : "")}
				/>
			))}
		</Box>
	);
};
const SpotTotal = ({ balance, prices }) => {
	const classes = useStyles();
	let balanceArray = Object.entries(balance).map(([asset, bal]) => {
		let assetPrice = 0;
		let worth = 0;
		if (balance && prices) {
			assetPrice = prices.find((p) => p._symbol.startsWith(asset + "/USDT"));
			if (assetPrice) {
				worth = asset === "USDT" ? bal.total.toFixed(2) : Number(assetPrice.latestPrice * bal.total).toFixed(2);
			} else {
				worth = 0.1;
			}
		}
		return {
			assetPrice: assetPrice && assetPrice.latestPrice ? assetPrice.latestPrice : bal.total,
			asset: asset,
			total: asset === "USDT" ? Number(bal.total).toFixed(2) : bal.total,
			free: asset === "USDT" ? Number(bal.free).toFixed(2) : bal.free,
			used: asset === "USDT" ? Number(bal.used).toFixed(2) : bal.used,
			worth: asset === "USDT" ? Number(bal.total).toFixed(2) : worth,
			onDateWorth: asset === "USDT" ? Number(bal.total).toFixed(2) : Number(bal.total * bal.price).toFixed(2),
		};
	});
	let total =
		balanceArray && Array.isArray(balanceArray) && balanceArray.length
			? balanceArray.map((b) => (b.worth ? Number(b.worth) : b.total)).reduce((total, num) => total + num)
			: 0;
	let totalOnDate =
		balanceArray && Array.isArray(balanceArray) && balanceArray.length
			? balanceArray
					.map((b) => (b.onDateWorth ? Number(b.onDateWorth) : b.total))
					.reduce((total, num) => total + num)
			: 0;
	if (balanceArray && Array.isArray(balanceArray)) {
		balanceArray = balanceArray.filter((ba) => ba.total && Number(Number(ba.worth).toFixed()));
	}
	balanceArray.sort((a, b) => b.worth - a.worth);

	return (
		<Box m={"3px"}>
			<Typography variant={"h6"}>{"≈ " + Number(total).toFixed(2)}$</Typography>
		</Box>
	);
};
export default BalanceField;
