import React, {useState} from "react";
/*import { connect } from "react-redux";
import { change, submit, isSubmitting } from "redux-form";*/
import {useDataProvider, useNotify, useRefresh} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import {Button, Checkbox, DialogActions, FormControlLabel} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";

export const styles = (theme) => ( {
    profitRoot: {
       width:150,
        display:"inline-flex",
        textAlign: "center",
        fontSize:"1.1em"
    },
    profitZero: {
        fontWeight: "bold",
        fontSize: "1.1em",
        backgroundColor: "#1c1c1c",
        borderRadius: 24,
        padding: 12,
    },
    profitGreen: {
        fontWeight: "bold",
        fontSize: "1.1em",
        color: theme.palette.success.main,
        backgroundColor: "#1c1c1c",
        borderRadius: 24,
        padding: 12,
    },
    profitRed: {
        fontWeight: "bold",
        fontSize: "1.1em",
        color: theme.palette.warning.main,
        backgroundColor: "#1c1c1c",
        borderRadius: 24,
        padding: 12,
    },
});
const useStyles = makeStyles(styles);

const DeleteSignalBtn = (props) => {
    const classes = useStyles();
    const { signal, openDialog,setOpenDialog } = props;

    const {_id, positionType, closed, opened, tradetype, vendor,name } = signal ;

    const linkResource = !!vendor ? "vendorsignals|tradetype|" + tradetype    : "signalalerts|tradetype|" + tradetype  ;
    const [checked,setChecked] = useState(false)
    const [loading,setLoading] = useState(false)
    const handleDialogClose = () => setOpenDialog(false);

    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider()
    const handleDeleteSignal = async (values) => {

        setLoading(true);
        dataProvider
            .delete(linkResource, {
                id:_id,

            })
            .then(() => {

                refresh();
                notify("Signal deleted.")
            })
            .catch((error) => notify(`Error: ${error.message}`, "warning"))
            .finally(() => {
                setLoading(false)
                setOpenDialog(false)
            });
    }


    return (


            <Dialog fullWidth open={openDialog} onClose={handleDialogClose}  >
                <DialogTitle>{"You are about to Delete signal: " + name}</DialogTitle>
                <DialogContent>
                    You can Delete signals as long as they are not opened.<br/>

                    <Typography variant={"subtitle1"} color={"textSecondary"}>
                        Otherwise you have to Close them later
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox color={"primary"} checked={checked} onChange={(event)=>setChecked(event?.target?.checked)}   />}
                        label="I know what I am doing."
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteSignal} disabled={!checked || loading} color={"secondary"}>Delete the signal now</Button>
                    <Button onClick={()=>setOpenDialog(false)}  color={"default"}>Cancel</Button>
                </DialogActions>
            </Dialog>

    );
};

export default DeleteSignalBtn;
