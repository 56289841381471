import React from "react";
import {
	DateField,
	EditButton,
	Filter,
	List,
	NullableBooleanInput,
	NumberField,
	SearchInput,
	useLocale,
	useTranslate,
} from "react-admin";
import {makeStyles, Typography} from "@material-ui/core";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import CustomizableDatagrid from "ra-customizable-datagrid";
import {listRowStyle} from "../shared/DisabledBackgroundStyle";
import Box from "@material-ui/core/Box";
import Gravatar from "react-gravatar";

const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<SearchInput
				source="firstName.$search"
				alwaysOn
				placeholder={translate("resources.clients.firstName")}
				InputLabelProps={{ shrink: true }}
			/>
			<SearchInput
				source="lastName.$search"
				alwaysOn
				placeholder={translate("resources.clients.lastName")}
				InputLabelProps={{ shrink: true }}
			/>
			<SearchInput
				source="telegramId.$search"
				alwaysOn
				placeholder={translate("resources.clients.telegramId")}
				InputLabelProps={{ shrink: true }}
			/>

			<SearchInput
				source="phonenumber.$search"
				alwaysOn
				placeholder={translate("resources.clients.phonenumber")}
				InputLabelProps={{ shrink: true }}
			/>
			<SearchInput
				source="email.$search"
				alwaysOn
				placeholder={translate("resources.clients.email")}
				InputLabelProps={{ shrink: true }}
			/>
			<NullableBooleanInput source="disabled" alwaysOn placeholder={translate("resources.clients.disabled")} />
		</Filter>
	);
};
const useStyles = makeStyles((theme) => ({
	entryPriceRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	stopLossRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	positionTypeRoot: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	avatarRoot: {
		width: 30,
		height: 30,

		fontSize: "1.2em",
	},
	iconRoot: {
		fontSize: "1.1rem",
	},
	normalAvatar: {},
	entryAvatarTriggered: {
		backgroundColor: theme.palette.primary.main,
	},
	stopLossAvatarTriggered: {
		backgroundColor: theme.palette.error.main,
	},

	positionTypeAvatarError: {
		backgroundColor: theme.palette.error.main,
		borderRadius: 5,
	},
	positionTypeAvatarSuccess: {
		backgroundColor: theme.palette.success.main,
		borderRadius: 5,
	},
	profitGreen: {
		color: theme.palette.success.main,
	},
	profitRed: {
		color: theme.palette.error.main,
	},
	maxWidth100: {
		maxWidth: "200px",
	},
	minWidth100: {},
	maxWidth99P: {
		width: "99%",
	},
}));

function getDaysRemaining(expiresAt) {
	const _start = new Date();
	const _expire = new Date(expiresAt);

	const result = Math.round((_expire - _start) / (1000 * 60 * 60 * 24));
	return result > 0 ? result : 0;
}

const MyFragment = ({ children, style, ...props }) => {
	if (Array.isArray(children)) {
		const childrenWithProps = children.map((Child) => React.cloneElement(Child, { ...props }));
		return <span style={style}> {childrenWithProps} </span>;
	} else {
		return children ? (
			<span style={style}> {React.cloneElement(children, { ...props })}</span>
		) : (
			<span style={style} />
		);
	}
};
const DaysRemaining = ({ record }) => {
	if (record && record.expiresAt) {
		return (
			<Box marginLeft={2} color={"#f50057"}>
				{getDaysRemaining(record.expiresAt)}d
			</Box>
		);
	}
	return <span />;
};
const GF = ({ record }) => {
	if (!record || !record.email) {
		return <span />;
	}
	let ce = record.email.includes(".com") ? record.email : record.email + ".com";
	return <Gravatar email={ce} />;
};
const EmailField = ({ record }) =>
	record && record?.email ? (
		<Typography color={!!record?.emailVerified ? "primary" : "default"}>{record?.email}</Typography>
	) : (
		<span />
	);
const PhoneNumberField = ({ record }) =>
	record && record?.phonenumber ? (
		<Typography color={!!record?.phonenumberVerified ? "primary" : "default"}>{record?.phonenumber}</Typography>
	) : (
		<span />
	);
const ClientsList = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	const classes = useStyles();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			filterDefaultValues={{ disabled: false }}
			title={translate("resources.clients.listtitle")}
			perPage={25}
			exporter={false}
			bulkActionButtons={false}
			sort={{ field: "lastLogin", order: "DESC" }}
		>
			<CustomizableDatagrid rowStyle={listRowStyle}>
				<NameWithAvatarField label={"resources.clients.name"} />
				{/*	<GF />*/}

				<PhoneNumberField source="phonenumber" label={"resources.clients.phonenumber"} />

				<EmailField source="email" label={"resources.clients.email"} />
				<DateField source="lastLogin" label={"resources.clients.lastlogin"} locales={locale} showTime />
				<DateField source="createdAt" label={"resources.clients.createdat"} locales={locale} />
				<NumberField source={"signalPerformanceReport_1y.cum_pnl_spot"} label={"Spot PNL"} />

				<NumberField source={"signalPerformanceReport_1y.cum_pnl_futures"} label={"Futures PNL"} />
				<NumberField source={"signalPerformanceReport_1y.winrate_total"} label={"Win Rate"} />
				<NumberField source={"signalPerformanceReport_1y.count_total"} label={"Total Signals"} />
				<NumberField source="accounting.currentBalance" label={"resources.vendors.currentBalance"} />
				<NumberField source="accounting.totalIncome" label={"resources.vendors.totalIncome"} />
				<EditButton />
			</CustomizableDatagrid>
		</List>
	);
};

export { ClientsList, MyFragment };
