import * as React from "react";
import "./index.css";
import {widget} from "../../../charting_library/";

import KucoinDataFeed from "../../KucoinDataFeed/datafeed";
import KucoinFuturesDataFeed from "../../KucoinFuturesDataFeed/datafeed";
//TODO : tradingViewApi.activeChart().setVisibleRange({from:(new Date().getTime() / 1000) - 20000 ,to:new Date().getTime()/1000},{percentRightMargin:20})
const customDatafeeds = {
	kucoinspot: KucoinDataFeed,
	kucoinfutures: KucoinFuturesDataFeed,
};

function getLanguageFromURL() {
	const regex = new RegExp("[\\?&]lang=([^&#]*)");
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
}

class TVChartContainer extends React.Component {
	static defaultProps = {
		symbol: "BTCUSDT",
		auto_save_delay: 5,
		containerId: "tv_chart_container",
		datafeedUrl: "",
		libraryPath: "charting_library/",
		// chartsStorageUrl: "http://" + window.location.hostname + ":3030",
		chartsStorageUrl: "https://api1.dealerify.io",
		chartsStorageApiVersion: "tv",

		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
		theme: "light",
	};
	tvWidget = null;

	getInterval = () => {
		let intv = localStorage.getItem("intv_" + this.props.userId + "_" + this.props.clientId);
		if (intv) {
			return intv;
		}
		return "1D";
	};

	setInterval = (intval) => {
		if (intval?.slice(intval.length - 1) !== "S") {
			localStorage.setItem("intv_" + this.props.userId + "_" + this.props.clientId, intval);
		}
	};

	getTVWidget = () => {
		return this.tvWidget;
	};

	componentDidMount() {
		const datafeed = new window.Datafeeds.UDFCompatibleDatafeed(this.props.datafeedUrl, 10000);

		/*datafeed.prototype.calculateHistoryDepth = function (resolution, resolutionBack, intervalBack) {
            console.log(resolution);
            console.log(resolutionBack);
            console.log(intervalBack);
            return {
                resolutionBack: "D",
                intervalBack: 1,
            };
        };*/
		const widgetOptions = {
			symbol: this.props.symbol,
			load_last_chart: true,
			auto_save_delay: this.props.auto_save_delay,

			// BEWARE: no trailing slash is expected in feed URL
			datafeed: this.props.datafeedUrl && this.props.datafeedUrl.startsWith("http") ? datafeed : customDatafeeds[this.props.datafeedUrl],
			interval: this.getInterval(),
			container_id: this.props.containerId,
			library_path: this.props.libraryPath,
			locale: getLanguageFromURL() || "en",
			disabled_features: this.props.disabled_features,
			enabled_features: [
				"use_localstorage_for_settings",
				"header_in_fullscreen_mode",
				/*"custom_resolutions",*/
				"snapshot_trading_drawings",
				"context_menus",
				"side_toolbar_in_fullscreen_mode",
				//"no_min_chart_width",
				"chart_style_hilo",
				//	"pane_context_menu",
				"chart_crosshair_menu",
				//	"custom_items_in_context_menu",
			],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: this.props.fullscreen,
			autosize: this.props.autosize,
			studies_overrides: this.props.studiesOverrides,
			theme: this.props.theme,
			drawings_access: this.props.drawings_access,
			studies_access: this.props.studies_access,
			timezone: "exchange",
			timeframe: "1D",
			//debug: true,
			//preset: "mobile",
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		tvWidget.onChartReady(() => {
			/*tvWidget.getSavedCharts((data)=>{
                if(data && data.length > 0){
                    console.log(data[0])
                    tvWidget.activeChart().setResolution(data[0].interval,()=>{

                    })
                }
            })
*/
			tvWidget
				.activeChart()
				.onIntervalChanged()
				.subscribe(null, (interval, timeframeObj) => {
					//	console.log(interval - 1);
					//console.log(interval);
					//	console.log(timeframeObj);
					this.setInterval(interval);
					//	timeframeObj.timeframe = {value: "3D", type: "period-back"};
				});
			if (this.props.setWidgetLoaded) {
				this.props.setWidgetLoaded(true);
			}
		});
	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			if (this.props.setWidgetLoaded) {
				this.props.setWidgetLoaded(false);
			}
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {
		return <div id={this.props.containerId} style={this.props.chartStyle} className={"TVChartContainer"} />;
	}
}

export default TVChartContainer;
