import React, {useState} from "react";
/*import { connect } from "react-redux";
import { change, submit, isSubmitting } from "redux-form";*/
import {useDataProvider, useNotify, useRefresh} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import {Button, Checkbox, DialogActions, FormControlLabel} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles/index";
import ProfitField from "./ProfitField";
import Typography from "@material-ui/core/Typography";

export const styles = (theme) => ( {
    profitRoot: {
       width:150,
        display:"inline-flex",
        textAlign: "center",
        fontSize:"1.1em"
    },
    profitZero: {
        fontWeight: "bold",
        fontSize: "1.1em",
        backgroundColor: "#1c1c1c",
        borderRadius: 24,
        padding: 12,
    },
    profitGreen: {
        fontWeight: "bold",
        fontSize: "1.1em",
        color: theme.palette.success.main,
        backgroundColor: "#1c1c1c",
        borderRadius: 24,
        padding: 12,
    },
    profitRed: {
        fontWeight: "bold",
        fontSize: "1.1em",
        color: theme.palette.warning.main,
        backgroundColor: "#1c1c1c",
        borderRadius: 24,
        padding: 12,
    },
});
const useStyles = makeStyles(styles);

const CloseSignalDialog = (props) => {
    const classes = useStyles();
    const { signal, openDialog,setOpenDialog } = props;

    const {_id, positionType, closed, opened, tradetype, vendor,name } = signal ;

    const linkResource = !!vendor ? "vendorsignals|tradetype|" + tradetype    : "signalalerts|tradetype|" + tradetype  ;
    const [checked,setChecked] = useState(false)
    const [loading,setLoading] = useState(false)
    const handleDialogClose = () => setOpenDialog(false);

    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider()
    const handleCloseSignal = async (values) => {

        setLoading(true);
        dataProvider
            .update(linkResource, {
                id:_id,
                data: {
                    forceClosed:true
                },
            })
            .then(() => {

                refresh();
                notify("Signal closed. You can find it in history page")
            })
            .catch((error) => notify(`Error: ${error.message}`, "warning"))
            .finally(() => {
                setLoading(false)
                setOpenDialog(false)
            });
    }


    return (


            <Dialog fullWidth open={openDialog} onClose={handleDialogClose}  >
                <DialogTitle>{"You are about to close signal: " + name}</DialogTitle>
                <DialogContent>
                    Your estimated PnL is <ProfitField record={signal} classes={classes}  />
                    <Typography variant={"subtitle1"} color={"error"}>
                    Closing this signal will close all connected positions too!
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox color={"primary"} checked={checked} onChange={(event)=>setChecked(event?.target?.checked)}   />}
                        label="I know what I am doing."
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSignal} disabled={!checked || loading} color={"secondary"}>Close it now (market price)</Button>
                    <Button onClick={()=>setOpenDialog(false)}  color={"default"}>Cancel</Button>
                </DialogActions>
            </Dialog>

    );
};

export default CloseSignalDialog;
