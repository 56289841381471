import React from "react";

import {useTranslate} from "react-admin";

import {useForm, useFormState} from "react-final-form";
import {ThemedSlider} from "../../shared/ThemedSlider";
import {makeStyles} from "@material-ui/core/styles";
import {withStyles} from "@material-ui/core";
import {darken, lighten} from "@material-ui/core/styles/colorManipulator";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AlarmIcon from "@material-ui/icons/Alarm";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

const TargetPointSlider = withStyles((theme) => ({
	thumb: {
		backgroundColor: theme.palette.success.main,
		"&$disabled": {
			opacity: 0.5,
			height: 35,
			width: 35,
			marginLeft: -4,
			marginTop: -3,
			"&:hover": {
				boxShadow: "none",
			},
		},
	},
	track: {
		display: "block",
		position: "absolute",
		height: 2,
		borderRadius: 1,
		backgroundColor: theme.palette.success.main,
		"$vertical &": {
			width: 2,
		},
	},
	rail: {
		display: "block",
		position: "absolute",
		width: "100%",
		height: 2,
		borderRadius: 1,
		backgroundColor: theme.palette.success.main,
		opacity: 0.38,
		"$vertical &": {
			height: "100%",
			width: 2,
		},
	},
	/* Styles applied to the track element if `track={false}`. */
	trackFalse: {
		"& $track": {
			display: "none",
		},
	},
	/* Styles applied to the track element if `track="inverted"`. */
	trackInverted: {
		"& $track": {
			backgroundColor:
				// Same logic as the LinearProgress track color
				theme.palette.type === "light" ? lighten(theme.palette.success.main, 0.62) : darken(theme.palette.success.main, 0.5),
		},
		"& $rail": {
			opacity: 1,
		},
	},
}))(ThemedSlider);

const useStyles = makeStyles((theme) => ({
	formInput: {
		//    maxWidth:'100%'
		width: "100%",
		maxWidth: "500px",
		//  minWidth:'250px'
	},
	withSliderForm: {
		width: "100%",
		maxWidth: "500px",
		marginTop: 5,
	},
	margin: {
		height: theme.spacing(5),
	},
	seperate: {
		height: theme.spacing(2),
	},
	avatarSuccess: {
		backgroundColor: theme.palette.success.main,
	},
	avatarError: {
		backgroundColor: theme.palette.error.main,
	},
	avatarGrey: {
		backgroundColor: theme.palette.grey[500],
	},
	avatarPrimary: {
		backgroundColor: theme.palette.primary.main,
	},
	thumbContent: {
		color: "white",
		fontSize: "1em",
	},
	addTPButton: {
		margin: theme.spacing(2),
	},
}));
const PercentsEdit = ({ record, pairObject, ...props }) => {
	const translate = useTranslate();
	const classes = useStyles();

	const { values } = useFormState();
	const { targetPoints } = values;

	const form = useForm();

	const handleChangeSlider = (event, newValue) => {
		let lastPercents = getTargetPointsPercents();
		newValue = newValue.map((val, index) => {
			return targetPointCanEdit(targetPoints[index], record) ? val : lastPercents[index];
		});
		if (findDuplicates(newValue).length > 0) {
			return;
		}
		newValue.forEach((tpp, indx) => {
			form.change("targetPoints[" + indx + "].percent", getRealPercent(indx, newValue));
			/*if(targetPointCanEdit(targetPoints[indx],record)){

                }
*/
		});
		// console.log("222")
	};
	const handleChangeSliderCommited = (event, newValue) => {
		let lastPercents = getTargetPointsPercents();
		newValue = newValue.map((val, index) => {
			return targetPointCanEdit(targetPoints[index], record) ? val : lastPercents[index];
		});
		if (findDuplicates(newValue).length > 0) {
			return;
		}
		newValue.forEach((tpp, indx) => {
			form.change("targetPoints[" + indx + "].percent", getRealPercent(indx, newValue));
			/*if(targetPointCanEdit(targetPoints[indx],record)){

            }
*/
		});
		// console.log("1111")
	};
	const getRealPercent = (index, newValue) => {
		if (index === newValue.length - 1) {
			if (targetPoints.length > 1) {
				return (
					100 -
					targetPoints
						.map((tp) => tp.percent)
						.slice(0, index)
						.reduce((total, num) => total + num)
				);
			} else {
				return 100;
			}
		}
		return index === 0 ? newValue[0] : newValue[index] - newValue[index - 1];
	};
	const getRealPercentText = (index) => {
		return targetPoints[index].percent + "%";
	};
	const getTargetPointsPercents = () => {
		return targetPoints.map((tp, index) => {
			let result = 0;
			if (index === targetPoints.length - 1) {
				return 100;
			}
			if (tp) {
				result = targetPoints
					.map((tp) => tp.percent)
					.slice(0, index + 1)
					.reduce((total, num) => total + num);
			}

			return result;
		});
	};
	const getThumbContent = (index, record, classes) => {
		let targetPoint = targetPoints[index];

		let result = <span></span>;
		if (targetPoint.triggeredAt) {
			return <CheckIcon className={classes.thumbContent} />;
		} else if (!targetPoint.triggeredAt && !record.entryPrice.triggeredAt && !record.stopLoss.triggeredAt) {
			return result;
		} else if (!targetPoint.triggeredAt && record.stopLoss.triggeredAt) {
			return <CloseIcon className={classes.thumbContent} />;
		} else if (!targetPoint.triggeredAt && record.entryPrice.triggeredAt && !record.stopLoss.triggeredAt) {
			return <AlarmIcon className={classes.thumbContent} />;
		}
		return result;
	};
	const getExtractedTargetPoints = () => {
		let triggered = targetPoints.filter((tp) => tp.triggeredAt).map((tp) => tp.percent);
		return triggered && triggered.length > 0 ? triggered.reduce((total, num) => num + total) : 0;
	};

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<SaveAltIcon />
					</Avatar>
				}
				action={
					<IconButton aria-label="remove">
						<HelpIcon color={"action"} />
					</IconButton>
				}
				title={translate("resources.signalalerts.targetPointsExtraction")}
				subheader={translate("resources.signalalerts.extractedYet", { percent: getExtractedTargetPoints() })}
			/>
			<CardContent>
				<Box margin={2} marginBottom={4}>
					<TargetPointSlider
						value={getTargetPointsPercents()}
						onChange={handleChangeSlider}
						track={"false"}
						getAriaLabel={(index) => translate("resources.signalalerts.tp", { index: index + 1 })}
						step={0.5}
						min={0}
						max={100}
						getDisabled={(index) => {
							return !targetPointCanEdit(targetPoints[index], record);
						}}
						getContent={(index) => getThumbContent(index, record, classes)}
						valueLabelFormat={(val, index) => getRealPercentText(index)}
						// max={enterPriceEnd * 2}
						valueLabelDisplay="on"
						onChangeCommitted={handleChangeSliderCommited}

						//getAriaValueText={valuetext}
					/>
				</Box>
			</CardContent>
		</Card>
	);
};
const targetPointCanEdit = (targetPoint, record) => {
	return !targetPoint.triggeredAt && !record.stopLoss.triggeredAt;
};
const findDuplicates = (arr) => {
	let sorted_arr = arr.slice().sort(); // You can define the comparing function here.

	let results = [];
	for (let i = 0; i < sorted_arr.length - 1; i++) {
		if (sorted_arr[i + 1] == sorted_arr[i]) {
			results.push(sorted_arr[i]);
		}
	}
	return results;
};
var countDecimals = function (value) {
	let text = value.toString();
	// verify if number 0.000005 is represented as "5e-6"
	if (text.indexOf("e-") > -1) {
		let [base, trail] = text.split("e-");
		let deg = parseInt(trail, 10);
		return deg;
	}
	// count decimals for number in representation like "0.123456"
	if (Math.floor(value) !== value) {
		return value.toString().split(".")[1].length || 0;
	}
	return 0;
};
export default PercentsEdit;
