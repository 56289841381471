import React, {cloneElement} from "react";
import {
	AutocompleteInput,
	BulkActionsToolbar,
	Filter,
	List,
	ListBase,
	ListToolbar,
	Pagination,
	RadioButtonGroupInput,
	ReferenceInput,
	SearchInput,
	SelectInput,
	useLocale,
	useTranslate,
} from "react-admin";
import {BatchSignalsListGrid} from "./BatchSignalsListGrid";
import CreateWithMarketButton from "../shared/CreateWithMarketButton";
import Box from "@material-ui/core/Box";
import HistoryEmpty from "./HistoryEmpty";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";

const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<RadioButtonGroupInput
				source="relatedService"
				label={"Signal Creator"}
				choices={[
					{ id: "signalalerts", name: "Client Signals" },
					{ id: "vendorsignals", name: "Vendor Signals" },
				]}
				alwaysOn
			/>
			<ReferenceInput
				alwaysOn
				label={"resources.observes.pair"}
				source="pair"
				perPage={10}
				reference="pairs"
				allowEmpty={true}
				shouldRenderSuggestions={(value) => {
					return value && value.trim().length > 0;
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								symbol: { $search: searchText },
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={"symbol"}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.pairs.allpairs"}
					alwaysOn
				/>
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				label={"resources.signalalerts.client"}
				source="user"
				perPage={25}
				reference="users|usertype|CLIENT"
				allowEmpty={true}
				filter={{
					usertype: "CLIENT",
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
						  }
						: { fake: true }
				}
			>
				<AutocompleteInput
					optionText={(record) =>
						record && record.firstName
							? record.firstName + " " + record.lastName
							: translate("resources.observes.allusers")
					}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				emptyText={"resources.vendorsignals.allvendors"}
				label={"resources.vendorsignals.vendor"}
				source="vendor"
				reference="users|usertype|VENDOR"
				perPage={200}
			>
				<SelectInput optionText={<NickNameWithAvatarField />} name={"fakename"} />
			</ReferenceInput>
			<SearchInput
				source="name.$search"
				alwaysOn
				placeholder={translate("resources.vendorsignals.namehelper")}
				InputLabelProps={{ shrink: true }}
			/>
		</Filter>
	);
};
const PostPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
const ListActions = (props) => (
	<Box m={2}>
		<CreateWithMarketButton
			size={"medium"}
			label={"Create Signal"}
			variant={"contained"}
			basePath={props?.resource === "spot" ? "signalalerts|tradetype|0" : "signalalerts|tradetype|1"}
			tradetype={props?.resource === "spot" ? 0 : 1}
		/>
	</Box>
);
const BatchSignalsListHistory = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	const { resource } = props;

	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.vendors.listtitle")}
			perPage={10}
			filterDefaultValues={{ relatedService: "signalalerts" }}
			exporter={false}
			bulkActionButtons={false}
			sort={{ field: "updatedAt", order: "DESC" }}
			filter={{ closed: true }}
			empty={<HistoryEmpty />}
			//actions={<ListActions {...props} />}
		>
			<BatchSignalsListGrid groupMode={false} />
		</List>
	);
};

const ListStructure = ({ children, actions, bulkActionButtons, filters, title, ...props }) => (
	<ListBase {...props}>
		<ListToolbar filters={filters} actions={actions} />
		<Box>
			<BulkActionsToolbar>{bulkActionButtons}</BulkActionsToolbar>
			{cloneElement(children, {
				hasBulkActions: bulkActionButtons !== false,
			})}
		</Box>
	</ListBase>
);
export default BatchSignalsListHistory;
