import React from "react";
import {
	DateField,
	Filter,
	List,
	ReferenceField,
	ReferenceInput,
	SelectInput,
	ShowButton,
	TextField,
	useLocale,
	useTranslate,
} from "react-admin";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import CustomizableDatagrid from "ra-customizable-datagrid";
import Box from "@material-ui/core/Box";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import Typography from "@material-ui/core/Typography";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";

const StatusFieldColored = ({ record }) => (
	<TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);

const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<SelectInput
				alwaysOn
				allowEmpty={true}
				source="status"
				choices={[
					{ id: "created", name: "Created" },
					{ id: "waiting", name: "Waiting" },
					{ id: "paid", name: "Paid" },
					{ id: "canceled", name: "Canceled" },
					{ id: "partially", name: "Partially" },
					{ id: "failed", name: "Failed" },
					{ id: "failedCreating", name: "Failed Creating" },
					{ id: "expired", name: "Expired" },
				]}
			/>
			<SelectInput
				alwaysOn
				allowEmpty={true}
				source="invoicetype"
				choices={[
					{ id: "clients_subscribe_vendor_signal", name: "Subscribe vendor signal" },
					{ id: "clients_subscribe_vendor_autotrade", name: "Subscribe vendor autotrade" },
					{ id: "clients_subscribe_vendor_extend", name: "Extend Vendor Subscription" },
					{ id: "clients_subscribe_vendor_upgradetoautotrade", name: "Extend and upgrade to Autotrade" },
					{ id: "clients_app_subscribe", name: "Dealerify plan subscription" },
					{ id: "clients_app_subscribe_extend", name: "Dealerify plan extend" },
					{ id: "clients_app_subscribe_extend_upgrade", name: "Dealerify plan extend and upgrade" },
				]}
			/>
			<ReferenceInput
				alwaysOn
				emptyText={"resources.vendorsignals.allvendors"}
				label={"resources.vendorsignals.vendor"}
				source="vendor"
				reference="users|usertype|VENDOR"
				perPage={500}
			>
				<SelectInput optionText={<NickNameWithAvatarField />} name={"fakename"} />
			</ReferenceInput>
		</Filter>
	);
};
function getDaysRemaining(expiresAt) {
	const _start = new Date();
	const _expire = new Date(expiresAt);

	const result = Math.round((_expire - _start) / (1000 * 60 * 60 * 24));
	return result > 0 ? result : 0;
}

const DaysRemaining = ({ record }) => {
	if (record && record.expiresAt) {
		let remainingDays = getDaysRemaining(record.expiresAt);
		if (remainingDays) {
			return <Box marginLeft={2}>{remainingDays} Days remaining </Box>;
		}
		return (
			<Box marginLeft={2} color={"#f50057"}>
				Expired
			</Box>
		);
	}
	return <span />;
};

function capitalize(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

const rowStyle = (record) => {
	return {
		backgroundColor: record && record.disabled ? "lightgrey" : "transparent",
	};
};
const StatusFieldAvatar = ({ record }) => {
	if (!record || !record.paymentDetails) {
		return <span />;
	}
	switch (record.status) {
		case "created":
		case "waiting":
		case "partially":
			return <HourglassEmptyIcon color="primary" />;

		case "paid":
			return <CheckIcon style={{ color: "#71ac05" }} />;

		default:
			return <BlockIcon color="error" />;
	}
};
const StatusField = ({ record }) => {
	if (!record || !record.paymentDetails) {
		return <span />;
	}
	return (
		<Typography
			style={{
				display: "flex",
				alignItems: "center",
			}}
		>
			<StatusFieldAvatar record={record} />
			<Box m={0.5} />
			{capitalize(record.status)}
			<Box m={0.5} />
			<Typography component={"span"} variant={"caption"} color={"textSecondary"}>
				({capitalize(record.paymentDetails.payment_status)})
			</Typography>
		</Typography>
	);
};
const InvoiceNumberField = ({ record }) => {
	if (!record || !record.paymentDetails) {
		return <span />;
	}
	return <Typography>#{record.invoiceNumber}</Typography>;
};
const PeriodText = {
	MONTHLY: "30 days",
	QUARTERLY: "90 days",
	SEMIANNUAL: "180 days",
	ANNUALLY: "365 days",
};
const InvoiceDesc = ({ record }) => {
	if (!record || !record.paymentDetails) {
		return <span />;
	}

	switch (record.invoicetype) {
		case "clients_app_subscribe":
			return (
				<Typography>
					Subscribe to App{" "}
					<ReferenceField source="subscriptionplan" reference="subscriptionplans" link={false}>
						<TextField source={"name"} style={{ color: "#349821", fontSize: "1.1em" }} />
					</ReferenceField>{" "}
					Plan for {PeriodText[record?.period]}
				</Typography>
			);

			break;
		case "clients_app_subscribe_extend":
			return (
				<Typography>
					Extend subscription to App{" "}
					<ReferenceField source="subscriptionplan" reference="subscriptionplans" link={false}>
						<TextField source={"name"} style={{ color: "#349821", fontSize: "1.1em" }} />
					</ReferenceField>{" "}
					Plan for {PeriodText[record?.period]}
				</Typography>
			);

			break;
		case "clients_app_subscribe_extend_upgrade":
			return (
				<Typography>
					Extend & Upgrade to App{" "}
					<ReferenceField source="subscriptionplan" reference="subscriptionplans" link={false}>
						<TextField source={"name"} style={{ color: "#349821", fontSize: "1.1em" }} />
					</ReferenceField>{" "}
					Plan for {PeriodText[record?.period]}
				</Typography>
			);

			break;
		case "clients_subscribe_vendor_signal":
			return (
				<Typography>
					Subscribe to{" "}
					<ReferenceField
						source="vendor"
						label={"resources.vendorsubscriptions.vendor"}
						reference="users|usertype|VENDOR"
						link={false}
					>
						<TextField source={"nickName"} style={{ color: "#349821" }} />
					</ReferenceField>
					's Signals for {PeriodText[record?.period]}
				</Typography>
			);

			break;
		case "clients_subscribe_vendor_autotrade":
			return (
				<Typography>
					Subscribe to{" "}
					<ReferenceField
						source="vendor"
						label={"resources.vendorsubscriptions.vendor"}
						reference="users|usertype|VENDOR"
						link={false}
					>
						<TextField source={"nickName"} style={{ color: "#349821" }} />
					</ReferenceField>
					's Signals + AutoTrade for {PeriodText[record?.period]}
				</Typography>
			);
			break;
		case "clients_subscribe_vendor_extend":
			return (
				<Typography>
					Extend{" "}
					<ReferenceField
						source="vendor"
						label={"resources.vendorsubscriptions.vendor"}
						reference="users|usertype|VENDOR"
						link={false}
					>
						<TextField source={"nickName"} style={{ color: "#349821" }} />
					</ReferenceField>
					's Subscription for {PeriodText[record?.period]}
				</Typography>
			);
			break;
		case "clients_subscribe_vendor_upgradetoautotrade":
			return (
				<Typography>
					Upgrade to AutoTrade & Extend{" "}
					<ReferenceField
						source="vendor"
						label={"resources.vendorsubscriptions.vendor"}
						reference="users|usertype|VENDOR"
						link={false}
					>
						<TextField source={"nickName"} style={{ color: "#349821" }} />
					</ReferenceField>
					's Subscription for {PeriodText[record?.period]}
				</Typography>
			);
			break;
		default:
			return <span />;
	}
};
const PurchasableField = ({ record }) => {
	if (!record || !record.purchasable) {
		return <span />;
	}
	return (
		<Typography>
			{record.purchasable.toFixed(2)} USDT
			{!!record.vendorSubscriptionShare && (
				<Typography component={"span"} color={"primary"} variant={"caption"}>
					({(record.vendorAutoTradeShare + record.vendorSubscriptionShare).toFixed(2)})
				</Typography>
			)}
		</Typography>
	);
};
const InvoicesListItems = (props) => {
	const locale = useLocale();

	return (
		<CustomizableDatagrid {...props} rowStyle={rowStyle}>
			<InvoiceNumberField label={"resources.invoices.invoiceNumber"} />
			<ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
				<NameWithAvatarField />
			</ReferenceField>
			<StatusField label={"resources.invoices.status"} />
			<PurchasableField label={"resources.invoices.purchasable"} />
			<InvoiceDesc label={"resources.invoices.desc"} />
			<DateField source="createdAt" label="resources.invoices.createdAt" locales={locale} showTime />

			<ShowButton label={"Manage"} />
		</CustomizableDatagrid>
	);
};

const InvoicesList = (props) => {
	const translate = useTranslate();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.vendorsubscriptions.listtitle")}
			filterDefaultValues={{ status: "paid" }}
			perPage={10}
			filter={{ "paymentDetails.pay_address": { $ne: "sandBox_usdttrc20_address" } }}
			exporter={false}
			bulkActionButtons={false}
			sort={{ field: "createdAt", order: "DESC" }}
		>
			<InvoicesListItems />
		</List>
	);
};

export { InvoicesListItems, InvoicesList, InvoiceDesc, StatusField };
