import React, {useState} from "react";
import {useSelector} from "react-redux";
import SettingsIcon from "@material-ui/icons/Settings";
import {useMediaQuery} from "@material-ui/core";
import {DashboardMenuItem, MenuItemLink, usePermissions, useTranslate} from "react-admin";

import ADMINs from "../admins";
import clients from "../clients";
import vendors from "../vendors";
import markets from "../markets";
import pairs from "../pairs";
import candles from "../candles";
import observes from "../observes";
import alerts from "../alerts";
import sms from "../sms";
import signalalerts from "../signalalerts";
import vendorsignals from "../vendorsignals";
import vendorsubscriptions from "../vendorsubscriptions";
import appsubscriptions from "../appsubscriptions";
import exchangeaccounts from "../exchangeaccounts";
import atspositions from "../atspotpositions";
import atufuturespositions from "../atufuturespositions";
import invoices from "../invoices";
import referralcodes from "../referralcodes";
import subscriptionplans from "../subscriptionplans";
import clspositions from "../clspotpositions";
import clufuturespositions from "../clufuturespositions";
import SubMenu from "./SubMenu";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {makeStyles} from "@material-ui/core/styles";
import useChangeThemeColor from "../useChangeThemeColor";
import classnames from "classnames";
import TimelineIcon from "@material-ui/icons/Timeline";
import {FuturesIcon, SupportIcon} from "../shared/CustomIcons";
import HistoryIcon from "@material-ui/icons/History";
import CategoryIcon from "@material-ui/icons/Category";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(1),
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	open: {
		width: 235,
	},
	closed: {
		width: 55,
	},
}));
const Menu = (props) => {
	useChangeThemeColor();
	const { classes: classesOverride, className, dense, hasDashboard, onMenuClick, logout, ...rest } = props;
	const [state, setState] = useState({
		generalStuff: false,
		menuSales: false,
		menuCustomers: false,
		menuPersonalPositions: false,
		clientStuff: false,
		vendorStuff: false,
	});
	const translate = useTranslate();
	const classes = useStyles(props);
	const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	const open = useSelector((state) => state.admin.ui.sidebarOpen);
	useSelector((state) => state.theme); // force rerender on theme change

	const handleToggle = (menu) => {
		setState((state) => ({ ...state, [menu]: !state[menu] }));
	};

	const permissions = usePermissions();
	const permisLoaded = permissions.loaded;
	let isADMIN = false;
	let isSuperAdmin = false;
	if (permisLoaded && permissions.permissions) {
		isADMIN = permissions.permissions.usertype === "ADMIN";
		isSuperAdmin = permissions.permissions.accessType === 1;
	}

	if (!permisLoaded) {
		return <div />;
	}

	return (
		<div
			className={classnames(classes.root, {
				[classes.open]: open,
				[classes.closed]: !open,
			})}
		>
			{isADMIN && <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />}
			<MenuItemLink
				to={`/spot`}
				primaryText={"Spot"}
				leftIcon={<TimelineIcon />}
				dense={dense}
				sidebarIsOpen={open}
			/>
			<MenuItemLink
				to={`/futures`}
				primaryText={"Futures"}
				leftIcon={<FuturesIcon />}
				dense={dense}
				sidebarIsOpen={open}
			/>
			<MenuItemLink
				to={`/history`}
				primaryText={"History"}
				leftIcon={<HistoryIcon />}
				dense={dense}
				sidebarIsOpen={open}
			/>
			{isADMIN && isSuperAdmin && (
				<MenuItemLink
					to="/batchorders"
					primaryText={"Orders"}
					leftIcon={<FiberManualRecordIcon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
			)}
			<SubMenu
				handleToggle={() => handleToggle("clientStuff")}
				isOpen={state.clientStuff}
				hasPadding={false}
				isMain={true}
				name="Client Zone"
				icon={<ExpandLessIcon />}
				dense={!!dense}
			>
				{isADMIN && (
					<MenuItemLink
						to={`/users|usertype|CLIENT`}
						primaryText={translate(`resources.clients.menutitle`)}
						leftIcon={<clients.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && (
					<MenuItemLink
						to={`/alerts`}
						primaryText={translate(`resources.alerts.menutitle`)}
						leftIcon={<alerts.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && (
					<MenuItemLink
						to={`/vendorsubscriptions`}
						primaryText={translate(`resources.vendorsubscriptions.menutitle`)}
						leftIcon={<vendorsubscriptions.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && (
					<MenuItemLink
						to={`/appsubscriptions`}
						primaryText={translate(`resources.appsubscriptions.menutitle`)}
						leftIcon={<appsubscriptions.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && isSuperAdmin && (
					<MenuItemLink
						to="/exchangeaccounts"
						primaryText={translate("resources.exchangeaccounts.menutitle")}
						leftIcon={<exchangeaccounts.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				<MenuItemLink
					to={`/signalalerts|tradetype|0`}
					primaryText={translate(`resources.signalalerts.menutitleSpot`)}
					leftIcon={<signalalerts.icon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
				<MenuItemLink
					to={`/signalalerts|tradetype|1`}
					primaryText={translate(`resources.signalalerts.menutitleFutures`)}
					leftIcon={<signalalerts.icon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
				<MenuItemLink
					to="/clspotpositions"
					primaryText={translate("resources.clspositions.menutitle")}
					leftIcon={<clspositions.icon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
				<MenuItemLink
					to="/clufuturespositions"
					primaryText={translate("resources.clufuturespositions.menutitle")}
					leftIcon={<clufuturespositions.icon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
			</SubMenu>
			<SubMenu
				handleToggle={() => handleToggle("vendorStuff")}
				isOpen={state.vendorStuff}
				hasPadding={false}
				isMain={true}
				name="Vendor Zone"
				icon={<ExpandLessIcon />}
				dense={!!dense}
			>
				{isADMIN && (
					<MenuItemLink
						to={`/users|usertype|VENDOR`}
						primaryText={translate(`resources.vendors.menutitle`)}
						leftIcon={<vendors.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && (
					<MenuItemLink
						to={`/vendorsignals|tradetype|0`}
						primaryText={translate(`resources.vendorsignals.menutitleSpot`)}
						leftIcon={<vendorsignals.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && (
					<MenuItemLink
						to={`/vendorsignals|tradetype|1`}
						primaryText={translate(`resources.vendorsignals.menutitleFutures`)}
						leftIcon={<vendorsignals.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				<MenuItemLink
					to="/atspotpositions"
					primaryText={translate("resources.atspositions.menutitle")}
					leftIcon={<atspositions.icon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
				<MenuItemLink
					to="/atufuturespositions"
					primaryText={translate("resources.atufuturespositions.menutitle")}
					leftIcon={<atufuturespositions.icon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
			</SubMenu>
			<SubMenu
				handleToggle={() => handleToggle("generalStuff")}
				isOpen={state.generalStuff}
				hasPadding={false}
				isMain={true}
				name="General Zone"
				icon={<ExpandLessIcon />}
				dense={!!dense}
			>
				<MenuItemLink
					to={`/users|usertype|ADMIN`}
					primaryText={translate(`resources.admins.menutitle`)}
					leftIcon={<ADMINs.icon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
				{isADMIN && isSuperAdmin && (
					<MenuItemLink
						to={`/markets`}
						primaryText={translate(`resources.markets.menutitle`)}
						leftIcon={<markets.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && isSuperAdmin && (
					<MenuItemLink
						to={`/subscriptionplans`}
						primaryText={translate(`resources.subscriptionplans.menutitle`)}
						leftIcon={<subscriptionplans.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && isSuperAdmin && (
					<MenuItemLink
						to={`/pairs`}
						primaryText={translate(`resources.pairs.menutitle`)}
						leftIcon={<pairs.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && isSuperAdmin && (
					<MenuItemLink
						to={`/candles`}
						primaryText={translate(`resources.candles.menutitle`)}
						leftIcon={<candles.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}

				{isADMIN && isSuperAdmin && (
					<MenuItemLink
						to={`/observes`}
						primaryText={translate(`resources.observes.menutitle`)}
						leftIcon={<observes.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}

				{isADMIN && (
					<MenuItemLink
						to={`/sms`}
						primaryText={translate(`resources.sms.menutitle`)}
						leftIcon={<sms.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && (
					<MenuItemLink
						to={`/emails`}
						primaryText={translate(`resources.emails.menutitle`)}
						leftIcon={<sms.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && (
					<MenuItemLink
						to={`/giftcodes`}
						primaryText={"Gift codes"}
						leftIcon={<sms.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				{isADMIN && (
					<MenuItemLink
						to={`/links`}
						primaryText={"Links"}
						leftIcon={<sms.icon />}
						sidebarIsOpen={open}
						dense={dense}
					/>
				)}
				<MenuItemLink
					to="/invoices"
					primaryText={translate("resources.invoices.menutitle")}
					leftIcon={<invoices.icon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
				<MenuItemLink
					to="/accountingdocuments"
					primaryText={translate("resources.accountingdocuments.menutitle")}
					leftIcon={<invoices.icon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
				<MenuItemLink
					to="/referralcodes"
					primaryText={translate("resources.referralcodes.menutitle")}
					leftIcon={<referralcodes.icon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
				<MenuItemLink
					to="/stcategories"
					primaryText={"Support Categories"}
					leftIcon={<CategoryIcon />}
					sidebarIsOpen={open}
					dense={dense}
				/>
			</SubMenu>
			<MenuItemLink
				to="/supporttickets"
				primaryText={"Support Tickets"}
				leftIcon={<SupportIcon />}
				sidebarIsOpen={open}
				dense={dense}
			/>

			<MenuItemLink
				to="/configuration"
				primaryText={translate("pos.configuration")}
				leftIcon={<SettingsIcon />}
				sidebarIsOpen={open}
				dense={dense}
			/>

			{isXSmall && logout}
		</div>
	);
};

export default Menu;
