import Icon from "@material-ui/icons/Shop";
import AccountingDocsCreate from "./AccountingDocsCreate";
import {AccountingDocumentsList} from "./AccountingDocumentsList";
import MarketsEdit from "./MarketsEdit";

export default {
	create: AccountingDocsCreate,
	//edit: MarketsEdit,
	list: AccountingDocumentsList,
	icon: Icon,
};
