import React from "react";
import {
	BooleanField,
	BooleanInput,
	Datagrid,
	DateField,
	Filter,
	List,
	SearchInput,
	TextField,
	useLocale,
	useTranslate,
} from "react-admin";

const StatusFieldColored = ({ record }) => (
	<TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);
const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<SearchInput InputLabelProps={{ shrink: true }} source="to.$search" alwaysOn placeholder={"To"} />
			<SearchInput InputLabelProps={{ shrink: true }} source="from.$search" alwaysOn placeholder={"from"} />
			<SearchInput InputLabelProps={{ shrink: true }} source="subject.$search" alwaysOn placeholder={"subject"} />
			<BooleanInput source="success" />
		</Filter>
	);
};
const PhoneNumberCountField = ({ record }) => {
	if (!record || !record.phonenumber) {
		return <div />;
	}
	return <div>{Array.from(record.phonenumber.split(",")).length}</div>;
};

const EmailsListItems = (props) => {
	const locale = useLocale();
	return (
		<Datagrid {...props}>
			<TextField source={"from"} />
			<TextField source={"to"} />
			<TextField source={"subject"} />
			<TextField source={"template"} />
			<TextField source={"templateArgs"} />
			<BooleanField source="success" />
			<DateField source="createdAt" label="resources.sms.createdAt" locales={locale} showTime />
		</Datagrid>
	);
};
const EmailsList = (props) => {
	const translate = useTranslate();

	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.sms.listtitle")}
			perPage={10}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			bulkActionButtons={false}
		>
			<EmailsListItems />
		</List>
	);
};

export { EmailsListItems, EmailsList };
