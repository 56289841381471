import React, {Fragment} from "react";
import {
    BooleanInput,
    DateField,
    DateTimeInput,
    Edit,
    FormDataConsumer,
    Labeled,
    ReferenceField,
    ReferenceManyField,
    SelectInput,
    SimpleForm,
    SimpleList,
    useLocale,
    usePermissions,
    useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {ServiceTypes} from "./VendorSubscriptionsEnums.js";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import {InvoiceDesc, StatusField} from "../invoices/InvoicesList";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);
const Title = ({ record }) => {
	const translate = useTranslate();
	return <span> {translate("resources.vendorsubscriptions.edittitle")}</span>;
};

function getDaysRemaining(expiresAt) {
	const _start = new Date();
	const _expire = new Date(expiresAt);

	const result = Math.round((_expire - _start) / (1000 * 60 * 60 * 24));
	return result > 0 ? result : 0;
}

const DaysRemaining = ({ record }) => {
	if (record && record.expiresAt) {
		let remainingDays = getDaysRemaining(record.expiresAt);
		if (remainingDays) {
			return (
				<Box marginTop={1} marginBottom={1}>
					{remainingDays} Days remaining{" "}
				</Box>
			);
		}
		return (
			<Box marginTop={1} marginBottom={1} color={"#f50057"}>
				Expired
			</Box>
		);
	}
	return <span />;
};
const VendorSubscriptionsEdit = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const { permissions } = usePermissions();

	return (
		<Edit {...props} aside={<Aside />} title={<Title />}>
			<SimpleForm>
				<ReferenceField
					source="vendor"
					label={"resources.vendorsubscriptions.vendor"}
					reference="users|usertype|VENDOR"
				>
					<NickNameWithAvatarField />
				</ReferenceField>
				<ReferenceField
					source="user"
					label={"resources.vendorsubscriptions.client"}
					reference="users|usertype|CLIENT"
				>
					<NameWithAvatarField />
				</ReferenceField>
				<DateField source="startedAt" label="resources.vendorsubscriptions.startedAt" locales={locale} showTime />
				<DateField source="expiresAt" label="resources.vendorsubscriptions.expiresAt" locales={locale} showTime />
				{permissions?._id === "5fc928bde690dc5ffc675ef6" && (
					<DateTimeInput
						source="expiresAt"
						label="resources.vendorsubscriptions.expiresAt"
						locales={locale}
						showTime
					/>
				)}
				{permissions?._id === "5fc928bde690dc5ffc675ef6" && <BooleanInput source="expired" />}
				<Labeled label={"Days remaining"}>
					<DaysRemaining />
				</Labeled>
				<BooleanInput label="resources.vendorsubscriptions.disabledNotifications" source="disabledNotifications" />

				<FormDataConsumer>
					{({ formData, ...rest }) =>
						formData &&
						formData.servicetype === "AUTOTRADE" && (
							<BooleanInput label="resources.vendorsubscriptions.disabledTrades" source="disabledTrades" />
						)
					}
				</FormDataConsumer>
				<SelectInput
					label={"resources.vendorsubscriptions.serviceType"}
					source={"servicetype"}
					choices={ServiceTypes}
				/>
				<BooleanInput label="resources.vendorsubscriptions.disabled" source="disabled" />
			</SimpleForm>
		</Edit>
	);
};

const Aside = ({ record }) => {
	const locale = useLocale();
	if (!record) {
		return <span />;
	}
	return (
		<div style={{ width: 200, margin: "1em" }}>
			<Typography>Invoices</Typography>
			<ReferenceManyField label="Invoices" reference="invoices" target="vendorsubscription">
				<SimpleList
					primaryText={(record) => !!record && "#" + record.invoiceNumber + " : $" + record.purchasable}
					secondaryText={(record) =>
						!!record && (
							<Fragment>
								{" "}
								<InvoiceDesc record={record} /> <StatusField record={record} />
							</Fragment>
						)
					}
					tertiaryText={(record) =>
						!!record && (
							<DateField
								record={record}
								source="createdAt"
								label="resources.vendorsubscriptions.createdAt"
								locales={locale}
							/>
						)
					}
					linkType={"show"}
				/>
			</ReferenceManyField>
		</div>
	);
};

export default VendorSubscriptionsEdit;
