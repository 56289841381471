import React from "react";
import {
	Datagrid,
	DateField,
	EditButton,
	Filter,
	List,
	SearchInput,
	SelectField,
	TextField,
	useLocale,
	useTranslate,
} from "react-admin";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import {listRowStyle} from "../shared/DisabledBackgroundStyle";
import {usertypes} from "./AdminsEnum";

const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<SearchInput
				source="firstName.$search"
				alwaysOn
				placeholder={translate("resources.admins.firstName")}
				InputLabelProps={{ shrink: true }}
			/>
			<SearchInput
				source="lastName.$search"
				alwaysOn
				placeholder={translate("resources.admins.lastName")}
				InputLabelProps={{ shrink: true }}
			/>
			<SearchInput
				source="phonenumber.$search"
				alwaysOn
				placeholder={translate("resources.admins.phonenumber")}
				InputLabelProps={{ shrink: true }}
			/>
			<SearchInput
				source="email.$search"
				alwaysOn
				placeholder={translate("resources.admins.email")}
				InputLabelProps={{ shrink: true }}
			/>
		</Filter>
	);
};

const ADMINsList = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.admins.listtitle")}
			perPage={25}
			exporter={false}
			bulkActionButtons={false}
		>
			<Datagrid rowStyle={listRowStyle}>
				<NameWithAvatarField label={"resources.admins.name"} />

				<TextField source="phonenumber" label={"resources.admins.phonenumber"} />
				<TextField source="email" label={"resources.admins.email"} />
				<DateField source="lastLogin" label={"resources.admins.lastlogin"} locales={locale} showTime />
				<DateField source="createdAt" label={"resources.admins.createdat"} locales={locale} />
				<TextField source="desc" label={"resources.admins.desc"} />
				<SelectField label="resources.admins.acceessType" source="accessType" choices={usertypes} />
				{props &&
					props.permissions &&
					props.permissions.usertype === "ADMIN" &&
					props.permissions.accessType === 1 && <EditButton />}
			</Datagrid>
		</List>
	);
};

export default ADMINsList;
