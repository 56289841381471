import React from "react";
import {Sidebar} from "react-admin";
import useCurrentTheme from "../themes/useCurrentTheme";
import AppBar from "./AppBar";
import DealerifyLayout from "./DealerifyLayout";
import Menu from "./Menu";

const CustomSidebar = (props: any) => <Sidebar {...props} size={500} />;

const MyLayout = (props: any) => {
	const theme = useCurrentTheme();

	return <DealerifyLayout {...props} appBar={AppBar} sidebar={CustomSidebar} menu={Menu} theme={theme} />;
};

export default MyLayout;
