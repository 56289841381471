import Icon from "@material-ui/icons/AddAlert";
import SignalAlertsEdit from "./edit/SignalAlertsEdit";
import {SignalAlertsList} from "./SignalAlertsList";

export default {
	//create: SignalAlertsCreateWizard,
	/*edit : SignalAlertsEdit,*/
	edit: SignalAlertsEdit,
	list: SignalAlertsList,
	icon: Icon,
	options: { label: "signalalerts" },
};
