import Icon from "@material-ui/icons/MessageRounded";
import GiftCodesCreate from "./GiftCodesCreate";
import GiftCodesEdit from "./GiftCodesEdit";

import {GiftCodesList} from "./GiftCodesList";

export default {
	create: GiftCodesCreate,
	edit: GiftCodesEdit,
	list: GiftCodesList,
	icon: Icon,
};
