import React from 'react';
import {
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    DateField,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectField,
    SelectInput,
    TextField,
    useLocale,Datagrid,
    useTranslate
} from 'react-admin';
import NameWithAvatarField from '../shared/NameWithAvatarField';
import CustomizableDatagrid from 'ra-customizable-datagrid';
import {ConditionTypes} from "./ObservesEnum";
import {Box} from "@material-ui/core";
import {JsonField} from "@bb-tech/ra-components";

const StatusFieldColored = ({record}) => <TextField source="name" record={record} style={{color:record.color ? record.color : "unset"}}/>
const ListFilter = (props) => {
    const translate = useTranslate();
    return(<Filter {...props}>


        <ReferenceInput  alwaysOn label={"resources.observes.pair"} source="pair" perPage={10}
                         reference="pairs" allowEmpty={true}

                         filterToQuery={(searchText) => searchText.trim() !== '' ? ({
                             symbol:{$search:searchText}
                         }) : null}>
            <AutocompleteInput optionText={'symbol'} allowEmpty={true} optionValue={'_id'}   emptyText={"resources.pairs.allpairs"} alwaysOn
            />
        </ReferenceInput>
        <ReferenceInput  alwaysOn label={"resources.observes.client"} source="user" perPage={10}
                         reference="users|usertype|CLIENT" allowEmpty={true}

                         filterToQuery={(searchText) => searchText.trim() !== '' ? ({
                             name:{$search:searchText}
                         }) : null}>
            <AutocompleteInput optionText={'name'} allowEmpty={true} optionValue={'_id'}   emptyText={"resources.observes.allusers"} alwaysOn
            />
        </ReferenceInput>
        <SelectInput source="condition.conditionType" label="resources.observes.conditionType" choices={ConditionTypes} allowEmpty={true} nullable  />
        <TextField source="_id"  />
        <BooleanInput label="resources.observes.triggered" source="triggered" nullable />
        <BooleanInput  source="processed" nullable />
    </Filter>)
}


const PttChannelsListItems =(props) =>{
    const locale = useLocale();

    return(
        <Datagrid   {...props}   expand={({ id, record, resource }) => (
            <Box>
                <JsonField record={{ r: record }} source={"r"} />
            </Box>
        )}>



            <TextField source="_id"   />
            <TextField source="name"    source="actionType" label={"resources.observes.actionType"} />
            <ReferenceField   source="pair" label={"resources.pairs.market"} reference="pairs">
                <ReferenceField   source="market"  reference="markets">
                    <TextField source="name"   />
                </ReferenceField>
            </ReferenceField>
            <ReferenceField   source="pair" label={"resources.candles.pair"} reference="pairs">
                <TextField source="symbol"   />
            </ReferenceField>
            <ReferenceField   source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
                <NameWithAvatarField  />
            </ReferenceField>

            <SelectField source="condition.conditionType" label="resources.observes.conditionType" choices={ConditionTypes} />
            <TextField source="condition.args" label="resources.observes.conditionValue"   />

            <DateField source="createdAt" label="resources.observes.createdAt"  locales={locale} showTime/>
            <DateField source="triggeredAt" label="resources.observes.triggeredAt"  locales={locale} showTime/>

            <DateField source="processedAt"   locales={locale} showTime/>
            <DateField source="watchedAt"   locales={locale} showTime/>
            <BooleanField source="triggered" label="resources.observes.triggered" />
            <BooleanField source="processed"   />
            <BooleanField source="disabled" label="resources.observes.disabled" />
            <BooleanField source="deleted" label="resources.observes.deleted" />
            <BooleanField source="v3"   />



        </Datagrid>);

};
const ObservesList = (props) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            filters={<ListFilter/>}
            title={translate('resources.observes.listtitle')}
            perPage={10}
            exporter={false}
            sort={{ field: 'createdAt', order: 'DESC' }}
            bulkActionButtons={false}
        >

            <PttChannelsListItems/>
        </List>
    );
};


export  {PttChannelsListItems,ObservesList};
