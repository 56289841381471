import React from "react";
import {
    Create,
    email,
    maxValue,
    minLength,
    minValue,
    required,
    SimpleForm,
    TextInput,
    useLocale,
    useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const SubscriptionplansCreate = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	return (
		<Create {...props} label={"resources.subscriptionplans.createnew"}>
			<SimpleForm redirect={"list"}>
				<TextInput source="name" label="resources.subscriptionplans.name" validate={requiredValidate} />

				<TextInput source="desc" label="resources.subscriptionplans.desc" />
			</SimpleForm>
		</Create>
	);
};
const totValidate = [required(), minValue(10), maxValue(600)];
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];

const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default SubscriptionplansCreate;
