import React, {Fragment} from "react";
import {
    BooleanInput,
    Datagrid,
    DateField,
    DeleteButton,
    Edit,
    FormDataConsumer,
    FormTab,
    FunctionField,
    maxLength,
    maxValue,
    minLength,
    minValue,
    NumberInput,
    Pagination,
    ReferenceField,
    ReferenceManyField,
    required,
    SaveButton,
    TabbedForm,
    TextField,
    Toolbar,
    useLocale,
    useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
//import {DumbList} from '../shared/DumbList';
import NameWithAvatarField from "../shared/NameWithAvatarField";
import BalanceField from "./BalanceField";
import {JsonField} from "@bb-tech/ra-components";
import {ReloadBalanceBtn} from "./ExchangeAccountsList";
import InputAdornment from "@material-ui/core/InputAdornment";

export const styles = {
	formInput: { flexDirection: "column", display: "flex", maxWidth: 500 },
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	balanceChipAvatar: {
		width: 25,
		height: 25,
		fontSize: "0.9em",
	},
	balanceChip: {
		margin: 1,
	},
};
const useStyles = makeStyles(styles);
const Title = ({ record }) => {
	const translate = useTranslate();
	return <span> {translate("resources.alerts.edittitle")}</span>;
};
const PairField = ({ record }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const locale = useLocale();

	if (!record) {
		return <Fragment />;
	}
	let pair = record;
	let baseAsset,
		quoteAsset,
		latestPrice,
		latestPriceTime,
		symbol = "";

	baseAsset = pair.baseAsset;
	quoteAsset = pair.quoteAsset;
	latestPrice = pair.latestPrice;
	latestPriceTime = pair.latestPriceTime;
	symbol = pair.symbol;

	return (
		<Fragment>
			<Typography variant="subtitle1">{symbol}</Typography>
			<Typography variant="caption" color={"secondary"}>
				{translate("resources.alerts.wizard.pairpricetext", {
					baseAsset,
					quoteAsset,
					latestPrice,
				})}
				<DateField source="latestPriceTime" record={pair} locales={locale} showTime />
			</Typography>
		</Fragment>
	);
};
const FormToolbar = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	return (
		<Toolbar {...props} className={classes.toolbar}>
			{!props.record.triggered && <SaveButton disabled={props.record.triggered} />}
			{props.record.triggered && (
				<Typography variant="caption">{translate("resources.alerts.cantedittriggred")}</Typography>
			)}
			<DeleteButton />
		</Toolbar>
	);
};

const ExchangeAccountsEdit = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	return (
		<Edit {...props} title={<Title />}>
			<TabbedForm toolbar={<FormToolbar record={props.record} />}>
				<FormTab label="resources.exchangeaccounts.information">
					<ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
						<NameWithAvatarField />
					</ReferenceField>
					<TextField
						source="name"
						className={classes.formInput}
						// fullWidth={true}
						label={"resources.exchangeaccounts.accountname"}
					/>
					<ReferenceField source="market" label={"resources.alerts.market"} reference="markets" link={false}>
						<TextField source="name" />
					</ReferenceField>
					<TextField
						source="apikey"
						className={classes.formInput}
						validate={[required(), maxLength(1024), minLength(2)]}
						fullWidth={true}
						label={"resources.exchangeaccounts.apikey"}
					/>

					<BooleanInput source="copyTrade" />
					<FormDataConsumer>
						{({ formData, ...rest }) =>
							formData.copyTrade && (
								<NumberInput
									source={"autoTradeMinFundUsd"}
									label={"AutoTrade min fund"}
									validate={[required(), minValue(formData.tradetype === 1 ? 10 : 20)]}
									helperText={"Minimum fund for each VendorSignal"}
									defaultValue={formData.tradetype === 1 ? 10 : 20}
									InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
								/>
							)
						}
					</FormDataConsumer>
					<FormDataConsumer>
						{({ formData, ...rest }) =>
							formData.copyTrade && (
								<NumberInput
									source={"autoTradeMaxFundUsd"}
									label={"AutoTrade max fund"}
									validate={[required(), maxValue(10000)]}
									helperText={"Maximum fund for each VendorSignal"}
									defaultValue={1000}
									InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
								/>
							)
						}
					</FormDataConsumer>
				</FormTab>
				<FormTab label="resources.exchangeaccounts.balance">
					<ReloadBalanceBtn />
					<ReferenceManyField
						label="Account balances"
						reference="exchangebalances"
						target="exchangeAccount"
						elStyle={{ display: "block !important" }}
						pagination={<Pagination />}
					>
						<Datagrid>
							<DateField source={"createdAt"} showTime />
							<BalanceField label={"balance"} />
						</Datagrid>
					</ReferenceManyField>
				</FormTab>
				<FormTab label="Details">
					<FunctionField
						label={"details"}
						render={(record) => (
							<Box>
								<JsonField record={{ r: record }} source={"r"} />
							</Box>
						)}
					/>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};

export default ExchangeAccountsEdit;
