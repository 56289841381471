import React, {memo, useEffect, useMemo, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useQueryWithStore} from "react-admin";
import {useDebounce, useInterval} from "react-use";
import PairAvatar from "../../../batchsignals/PairAvatar";
import MarketAvatarField from "../../MarketAvatarField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import {useForm} from "react-final-form";
import {useLocation} from "react-router-dom";
import {parse} from "query-string";

const styles = (theme) => ({
	titleRoot: {
		margin: 0,
		padding: theme.spacing(2),
	},
	searchResultsRoot: {
		minHeight: "calc(100vh - 340px)",
		overflowY: "auto",
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	pairInfoPrice: {
		lineHeight: "1.25rem",
		fontWeight: 800,
		transition: theme.transitions.create("color", {
			duration: 200,
		}),
	},
	trendingToggle: {
		transition: theme.transitions.create("max-height", {
			delay: 500,
			duration: 300,
		}),
	},
	exchangeSelectFormControl: {
		flex: 1,
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.titleRoot} {...other}>
			<Typography variant="subtitle1">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		display: "flex",
		flexDirection: "column",
		transition: theme.transitions.create("background-color", {
			duration: 200,
		}),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

const pairFilterDefaultParts = {
	$limit: 25,
	//disabled: { $ne: true },
};
const SymbolSearch = (props) => {
	const { open, setOpen, classes } = props;

	const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	const [loading, setLoading] = useState(false);
	const [searching, setSearching] = useState(false);
	const [keyword, setKeyword] = useState("");
	const [pairFilter, setPairFilter] = useState(pairFilterDefaultParts);
	const [pairArray, setPairArray] = useState([]);
	const [marketsObject, setMarketsObject] = useState({});
	const [count, setCount] = useState(0);
	const [selectedMarkets, setSelectedMarkets] = useState([]);
	const [trendingSort, setTrendingSort] = useState("sc_1w");
	const form = useForm();
	const location = useLocation();
	const preFilledRecord = location?.state?.record || (parse(location?.search)?.source && JSON.parse(parse(location?.search)?.source));
	const marketsDataStore = useQueryWithStore({
		type: "getList",
		resource: "markets",
		payload: {
			pagination: { page: 0, perPage: 100 },
			sort: { field: "createdAt", order: "DESC" },
			filter: { $limit: 100, disabled: { $ne: true } },
		},
	});
	useEffect(() => {
		if (marketsDataStore?.data) {
			let _obj = {};
			marketsDataStore?.data?.forEach((market) => (_obj[market?._id] = market));

			setSelectedMarkets(preFilledRecord?.market ? [preFilledRecord?.market] : marketsDataStore?.data?.map((market) => market._id));
			setMarketsObject(_obj);
		}
	}, [marketsDataStore?.data]);
	const {
		data: pairStoreData,
		loading: pairStoreLoading,
		loaded: pairStoreLoaded,
	} = useQueryWithStore(
		{
			type: "getList",
			resource: "pairs",
			payload: {
				count,
				pagination: { page: 0, perPage: 25 },
				sort: { field: pairFilter?.$or ? "sc_total" : trendingSort, order: "DESC" },
				filter: { ...pairFilter, market: { $in: selectedMarkets } },
			},
		},
		{ enabled: open && marketsDataStore?.length, action: "CUSTOM_QUERY" }
	);
	useEffect(() => {
		if (!!pairStoreData) {
			//	let _sorted = pairDataStore?.data.sort((p1, p2) => p1?.sym);
			setSearching(false);
			setPairArray(pairStoreData);
		}
	}, [pairStoreData]);
	useEffect(() => {
		if (open) {
			document.getElementById("searchTextField")?.focus();
		}
	}, [open]);
	useInterval(
		() => {
			if (open && !pairStoreLoading) {
				setCount(count + 1);
			}
		},
		!pairStoreLoading && open ? 8500 : null
	);

	useDebounce(
		() => {
			setSearching(true);
			ChangePairFilter();
		},
		300,
		[keyword]
	);
	useEffect(() => {
		setSearching(true);
	}, [selectedMarkets]);
	const ChangePairFilter = () => {
		let pairFilter = { ...pairFilterDefaultParts };

		if (keyword?.trim()?.length) {
			let filter = {};
			let realKeyword = keyword?.trim()?.replaceAll(/ |-|/g, "").replaceAll("/", "");
			if (keyword?.trim()?.length < 4) {
				filter = {
					$or: [{ base: { $search: realKeyword } }],
				};
			} else {
				filter = {
					$or: [{ bq: { $search: realKeyword } }, { _symbol: { $search: realKeyword } }, { symbol: { $search: realKeyword } }],
				};
			}

			pairFilter = { ...pairFilter, ...filter };
		}

		setPairFilter(pairFilter);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleKeywordChange = (event) => {
		setKeyword(event.target.value);
	};
	const handleSelectPair = (pairObject) => {
		form.batch(() => {
			form.change("marketObject", marketsObject[pairObject?.market]);
			form.change("market", pairObject?.market);
			form.change("tradetype", marketsObject[pairObject?.market]?.tradetype);
			form.change("pairObject", pairObject);
			form.change("pair", pairObject._id);
		});

		handleClose();
	};

	return (
		<Dialog onClose={handleClose} open={open} maxWidth={"md"} fullWidth={true} fullScreen={isXSmall} keepMounted={true}>
			<DialogTitle onClose={handleClose}>Search</DialogTitle>

			<DialogContent dividers style={{ backgroundColor: keyword?.trim() !== "" ? "rgba(19,23,34,1)" : "rgba(19,23,34,0)" }}>
				<Box flexShrink={"0"} m={1} display={"flex"} alignItems={"end"}>
					<Box sx={{ maxWidth: "300px", flexGrow: 1 }}>
						<TextField
							id={"searchTextField"}
							autoFocus={true}
							label={"Symbol"}
							value={keyword}
							onChange={handleKeywordChange}
							fullWidth={true}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<CircularProgress size={20} variant={searching ? "indeterminate" : "determinate"} />
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box flexGrow={1} />
					<Box sx={{ maxWidth: "300px", flexGrow: 1, minWidth: 200 }}>
						{marketsDataStore?.total && (
							<FormControl className={classes.exchangeSelectFormControl} fullWidth={true}>
								<InputLabel id="demo-simple-select-label">Search in</InputLabel>
								<ExchangeSelect
									selectedMarkets={selectedMarkets}
									setSelectedMarkets={setSelectedMarkets}
									allMarkets={marketsDataStore?.data}
								/>
							</FormControl>
						)}
					</Box>
				</Box>
				<Box
					m={1}
					flexShrink={"0"}
					display={"flex"}
					alignItems={"center"}
					style={{ overflow: "hidden", height: "auto", maxHeight: keyword?.trim() !== "" ? "0" : "72px" }}
					className={classes.trendingToggle}
				>
					<ImportExportIcon />
					<Typography style={{ marginRight: isXSmall ? 2 : 10 }}>{isXSmall ? "Trending" : "Trending Pairs"}</Typography>
					<ToggleButtonGroup
						style={{ height: 32 }}
						size={"small"}
						exclusive={true}
						value={trendingSort}
						onChange={(event, newSort) => newSort && setTrendingSort(newSort)}
					>
						<ToggleButton value="sc_1d">
							<Typography variant={"caption"} style={{ fontSize: "0.75em" }} noWrap>
								Last 24H
							</Typography>
						</ToggleButton>
						<ToggleButton value="sc_1w">
							<Typography variant={"caption"} style={{ fontSize: "0.75em" }} noWrap>
								Last Week
							</Typography>
						</ToggleButton>
						<ToggleButton value="sc_1m">
							<Typography variant={"caption"} style={{ fontSize: "0.75em" }} noWrap>
								Last Month
							</Typography>
						</ToggleButton>
						<ToggleButton value="sc_1y">
							<Typography variant={"caption"} style={{ fontSize: "0.75em" }} noWrap>
								Last Year
							</Typography>
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>
				<Box className={classes.searchResultsRoot}>
					{pairStoreLoaded && !pairArray.length && !searching && <Typography>Nothing found</Typography>}
					{pairArray?.map((pair) => (
						<Box key={pair?._id}>
							<Button
								color={"primary"}
								focusRipple
								disabled={pair?.disabled || !pair?.status}
								fullWidth={true}
								onClick={() => handleSelectPair(pair)}
							>
								<PairInfoMemo pairObject={pair} market={marketsObject[pair?.market]} latestPrice={pair?.latestPrice} />
							</Button>
							<Divider />
						</Box>
					))}
				</Box>
			</DialogContent>
		</Dialog>
	);
};
const ExchangeSelect = ({ selectedMarkets, setSelectedMarkets, allMarkets = [], classes }) => {
	const AllMarketIds = useMemo(() => {
		return allMarkets?.length ? allMarkets?.map((ex) => ex?._id) : [];
	}, [allMarkets]);
	const SpotExchangesIds = useMemo(() => {
		return allMarkets?.length ? allMarkets?.filter((ex) => ex.tradetype === 0)?.map((ex) => ex?._id) : [];
	}, [allMarkets]);
	const FuturesExchangesIds = useMemo(() => {
		return allMarkets?.length ? allMarkets?.filter((ex) => ex.tradetype === 1)?.map((ex) => ex?._id) : [];
	}, [allMarkets]);
	const SpotExchanges = useMemo(() => {
		return allMarkets?.length ? allMarkets?.filter((ex) => ex.tradetype === 0) : [];
	}, [allMarkets]);
	const FuturesExchanges = useMemo(() => {
		return allMarkets?.length ? allMarkets?.filter((ex) => ex.tradetype === 1) : [];
	}, [allMarkets]);

	return (
		<Select
			labelId="demo-simple-select-label"
			id="demo-simple-select"
			value={JSON.stringify(selectedMarkets)}
			onChange={(event) => setSelectedMarkets(JSON.parse(event.target.value))}
		>
			<MenuItem value={JSON.stringify(AllMarketIds)}>All Exchanges</MenuItem>
			<MenuItem value={JSON.stringify(SpotExchangesIds)}>All Spot Exchanges</MenuItem>
			<MenuItem value={JSON.stringify(FuturesExchangesIds)}>All Futures Exchanges</MenuItem>
			<ListSubheader>Spot</ListSubheader>
			{SpotExchanges?.map((ex) => (
				<MenuItem value={JSON.stringify([ex._id])}>
					{" "}
					<MarketAvatarField size={15} addLabel={true} record={ex} />
				</MenuItem>
			))}

			<ListSubheader>Futures</ListSubheader>
			{FuturesExchanges?.map((ex) => (
				<MenuItem value={JSON.stringify([ex._id])}>
					<MarketAvatarField size={15} addLabel={true} record={ex} />
				</MenuItem>
			))}
		</Select>
	);
};
const PairInfo = ({ pairObject, latestPrice, market, classes }) => {
	const [localLastPrice, setLocalLastPrice] = useState(0);
	const [priceUp, setPriceUp] = useState(false);
	const [firstTime, setFirstTime] = useState(true);
	useEffect(() => {
		if (localLastPrice && latestPrice) {
			if (localLastPrice !== latestPrice) {
				setFirstTime(false);
			}
		}
		setLocalLastPrice((prevPrice) => {
			setPriceUp(prevPrice < latestPrice);

			return latestPrice;
		});
	}, [latestPrice]);

	return (
		<Box flexGrow={1} display={"flex"} alignItems={"center"}>
			<Box flexGrow={1}>
				<Box display={"flex"} alignItems={"flex-end"} flexGrow={1}>
					<Box marginRight={1}>
						<MarketAvatarField size={22} addLabel={false} record={market} />
					</Box>
					<Box>
						<PairAvatar size={35} pairObject={pairObject} textVariant={"h6"} />
					</Box>

					{!pairObject?._symbol?.includes("/") && (
						<Typography align={"left"} style={{ marginLeft: "10px" }} variant={"caption"} color={"textSecondary"} alignSelf={"flex-end"}>
							({pairObject?._symbol})
						</Typography>
					)}
					{(pairObject?.disabled || !pairObject?.status) && (
						<Typography style={{ marginLeft: "5px" }} color={"error"}>
							Not Trading
						</Typography>
					)}
				</Box>
				<Box display={"flex"}>
					<Typography align={"left"} style={{ marginLeft: "10px" }} variant={"caption"} color={"textSecondary"}>
						{market?.name}
					</Typography>
				</Box>
			</Box>
			<Typography variant={"h6"} color={!firstTime ? (priceUp ? "primary" : "error") : "textPrimary"} className={classes.pairInfoPrice}>
				{latestPrice}
			</Typography>
		</Box>
	);
};
const PairInfoMemo = memo(withStyles(styles, { name: "PairInfoInSearch" })(PairInfo));
export default memo(withStyles(styles, { name: "SymbolSearch" })(SymbolSearch));
