import React from "react";
import {
	BooleanInput,
	DateField,
	Edit,
	email,
	FormDataConsumer,
	FormTab,
	ImageField,
	ImageInput,
	maxLength,
	maxValue,
	minLength,
	minValue,
	NumberField,
	NumberInput,
	PasswordInput,
	ReferenceInput,
	regex,
	required,
	SelectInput,
	TabbedForm,
	TextInput,
	TopToolbar,
	useLocale,
	useRefresh,
	useTranslate,
} from "react-admin";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {validatePassword} from "../shared/ScorePassword";
import mobileValidate from "../shared/MobileValidate";
import NameWithAvatarField from "../shared/NameWithAvatarField";

import BulkEditBtn from "../shared/BulkEditBtn";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";
import SignalPerformanceWidget from "../shared/SignalPerformanceWidget";

const useStyles = makeStyles({
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
});

const VendorsEdit = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const locale = useLocale();
	const refresh = useRefresh();
	return (
		<Edit
			{...props}
			mutationMode="pessimistic"
			title={<NameWithAvatarField />}
			actions={
				props &&
				props.permissions &&
				props.permissions.usertype === "ADMIN" &&
				props.permissions.accessType === 1 ? (
					<EditActions />
				) : (
					false
				)
			}
			undoable={false}
		>
			<TabbedForm redirect={false}>
				<FormTab label="Information">
					<PerformanceBox />
					<TextInput
						source="userId"
						formClassName={classes.normalfield}
						validate={requiredValidate}
						label={"User Name"}
					/>
					<TextInput
						source="firstName"
						formClassName={classes.normalfield}
						validate={requiredValidate}
						label={"resources.vendors.firstName"}
					/>
					<TextInput
						source="lastName"
						formClassName={classes.normalfield}
						validate={requiredValidate}
						label={"resources.vendors.lastName"}
					/>
					<TextInput
						source="nickName"
						formClassName={classes.normalfield}
						validate={requiredValidate}
						label={"resources.vendors.nickName"}
					/>
					<TextInput
						source="phonenumber"
						label={"resources.vendors.phonenumber"}
						fullWidth={true}
						formClassName={classes.normalfield}
						validate={mobileValidatation}
					/>
					<TextInput
						label={"resources.vendors.email"}
						type="email"
						source="email"
						validation={{ email: true }}
						fullWidth={true}
						formClassName={classes.email}
						validate={emailValidate}
					/>

					<Separator />

					<Separator />
					<ImageInput
						source="avatar"
						label={"resources.vendors.avatar"}
						maxSize={1000000}
						accept=".jpg,.png,.jpeg"
						placeholder={<p>{translate("resources.vendors.dropimages")}</p>}
					>
						<ImageField source="src" title="title" />
					</ImageInput>
					<TextInput
						source="desc"
						formClassName={classes.address}
						multiline={true}
						fullWidth={true}
						label={"resources.vendors.desc"}
					/>
					<NumberInput source="referralPercent" validate={[minValue(1), maxValue(40)]} />
					<BooleanInput source="publicProfile" />
					<BooleanInput source="disabled" label={"resources.vendors.disabled"} />
					<BooleanInput source="allowedToWork" label={"resources.vendors.allowedToWork"} />
					<BooleanInput source="verified" label={"Verified"} />
					<BooleanInput source="active" label={"resources.vendors.active"} />
					<BooleanInput source="copyToMaster" />
					<BooleanInput source="emailVerified" />
					<BooleanInput source="phonenumberVerified" />
					<FormDataConsumer>
						{({ formData, ...rest }) => formData.copyToMaster && <WrappedVendorSelect {...rest} />}
					</FormDataConsumer>

					<DateField source="createdAt" locales={locale} />
					<DateField source="lastLogin" locales={locale} showTime />
					<DateField source="lastLogout" locales={locale} showTime />
				</FormTab>
				<FormTab label={"Prices & Percents"}>
					<NumberInput
						source="signalPrice.monthlyPrice"
						label={"resources.vendors.monthlyPrice"}
						validate={[minValue(0)]}
					/>
					<NumberInput
						source="signalPrice.discountPercent"
						label={"resources.vendors.discountPercent"}
						validate={[minValue(0), maxValue(100)]}
					/>
					<TextInput source="signalPrice.quarOnsaleText" label={"Quarterly on sale text"} />
					<NumberInput
						source="signalPrice.quarterlyPaidDiscountPercent"
						label={"resources.vendors.quarterlyPaidDiscountPercent"}
						validate={[minValue(0), maxValue(100)]}
					/>
					<TextInput source="signalPrice.semiOnsaleText" label={"Semi-Annual on sale text"} />
					<NumberInput
						source="signalPrice.semiAnnualPaidDiscountPercent"
						label={"resources.vendors.semiAnnualPaidDiscountPercent"}
						validate={[minValue(0), maxValue(100)]}
					/>
					<TextInput source="signalPrice.anuOnsaleText" label={"Annually on sale text"} />
					<NumberInput
						source="signalPrice.annuallyPaidDiscountPercent"
						label={"resources.vendors.annuallyPaidDiscountPercent"}
						validate={[minValue(0), maxValue(100)]}
					/>

					<NumberInput
						source="signalPrice.autoTradePrice"
						label={"resources.vendors.autoTradePrice"}
						validate={[minValue(10)]}
						initialValue={12}
					/>
					<NumberInput source="subscriptionSharePercent" initialValue={60} />
					<NumberInput source="autoTradeSharePercent" validate={[maxValue(30)]} initialValue={20} />
				</FormTab>

				<FormTab label={"Reports"}>
					<NumberField source={"signalPerformanceReport_1y.cum_pnl_spot"} label={"Spot PNL"} />

					<NumberField source={"signalPerformanceReport_1y.cum_pnl_futures"} label={"Futures PNL"} />
					<NumberField source={"signalPerformanceReport_1y.winrate_total"} label={"Win Rate"} />
					<NumberField source={"signalPerformanceReport_1y.count_total"} label={"Total Signals"} />
					<NumberField source="accounting.currentBalance" label={"resources.vendors.currentBalance"} />
					<NumberField source="accounting.totalIncome" label={"resources.vendors.totalIncome"} />
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
const PerformanceBox = ({ record }) => {
	return (
		<Box display={"flex"} alignItems={"center"}>
			<SignalPerformanceWidget user={record} tf={"1d"} />
			<SignalPerformanceWidget user={record} tf={"1w"} />
			<SignalPerformanceWidget user={record} tf={"1m"} />
			<SignalPerformanceWidget user={record} tf={"3m"} />
			<SignalPerformanceWidget user={record} tf={"1y"} />
		</Box>
	);
};
const WrappedVendorSelect = ({ record }) => {
	return (
		<ReferenceInput
			alwaysOn
			emptyText={"No Vendor"}
			allowEmpty={true}
			allowNull={true}
			filter={{ _id: { $ne: record?._id } }}
			source="master"
			reference="users|usertype|VENDOR"
			perPage={100}
		>
			<SelectInput optionText={<NickNameWithAvatarField />} name={"fakename"} />
		</ReferenceInput>
	);
};
const requiredValidate = [required()];
const mobileValidatation = [
	required(),
	mobileValidate,
	regex(/^([+]\d{1,3})?\d{10}$/, "Invalid mobile format"),
	minLength(11),
	maxLength(15),
];
const emailValidate = [required(), email()];
const passwordValidate = [required(), minLength(8), validatePassword];

const EditActions = ({ basePath, data, resource }) => (
	<TopToolbar>
		<BulkEditBtn
			data={data}
			resource={resource}
			field={"password"}
			label="resources.clients.changepassword"
			idField="_id"
			inputComponent={<PasswordInput source="password" validate={passwordValidate} />}
		/>
	</TopToolbar>
);
const Separator = () => <Box pt="1em" />;

export default VendorsEdit;
