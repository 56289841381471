const ListRowStyle = (record, index) => {
/*    const theme = useSelector((state) =>
        state.theme
    );
    if(theme === 'dark' ){
        return ({
            backgroundImage: record && record.disabled  ? 'repeating-linear-gradient(45deg, #4D4D4D , #4D4D4D 6px, transparent 6px, transparent 24px)' : 'transparent',
            borderLeft: record && record.disabled  ? "Solid 3px red" : record.triggered ? "Solid 3px green" : "unset"

        });
    }*/
    return ({
        backgroundImage: record && record.disabled  ? 'repeating-linear-gradient(45deg, #D6D6D6 , #D6D6D6 6px, transparent 6px, transparent 24px)' : 'transparent',
        borderLeft:record && record.copyTrade  ? "Solid 3px green" :  "Solid 3px red"

    });
}

export {ListRowStyle}