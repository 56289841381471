import React, {useEffect, useState} from 'react';

import {ArrayInput, maxValue, minValue, NumberInput, SimpleFormIterator, useTranslate} from "react-admin";

import {useForm, useFormState} from 'react-final-form';
import {ThemedSlider} from '../../shared/ThemedSlider';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, withStyles} from "@material-ui/core";
import {darken, lighten} from '@material-ui/core/styles/colorManipulator';
import {rawProfitInPercent} from '../SignalCalculations';

Array.min = function (array) {
    return Math.min.apply(Math, array);
};
Array.max = function (array) {
    return Math.max.apply(Math, array);
};

const TargetPointSlider = withStyles(theme => ({

    thumb: {

        backgroundColor: theme.palette.success.main

    },
    track: {
        display: 'block',
        position: 'absolute',
        height: 2,
        borderRadius: 1,
        backgroundColor: theme.palette.success.main,
        '$vertical &': {
            width: 2,
        },
    },
    rail: {
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: 2,
        borderRadius: 1,
        backgroundColor: theme.palette.success.main,
        opacity: 0.38,
        '$vertical &': {
            height: '100%',
            width: 2,
        },
    },
    /* Styles applied to the track element if `track={false}`. */
    trackFalse: {
        '& $track': {
            display: 'none',
        },
    },
    /* Styles applied to the track element if `track="inverted"`. */
    trackInverted: {
        '& $track': {
            backgroundColor:
            // Same logic as the LinearProgress track color
                theme.palette.type === 'light'
                    ? lighten(theme.palette.success.main, 0.62)
                    : darken(theme.palette.success.main, 0.5),
        },
        '& $rail': {
            opacity: 1,
        },
    },

}))(ThemedSlider);

const useStyles = makeStyles(theme => ({

    formInput: {
        //    maxWidth:'100%'
        width: '100%',
        maxWidth: "500px"
        //  minWidth:'250px'
    },
    withSliderForm: {

        width: '100%',
        maxWidth: "500px",
        marginTop: 5,


    },
    margin: {
        height: theme.spacing(5),
    },
    seperate: {
        height: theme.spacing(2),
    },
    profitPercent:{
        marginTop:theme.spacing(0.5),
        marginBottom:theme.spacing(0.5),
        color:theme.palette.success.light
    }

}));
const TargetPointsForm = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const {values} = useFormState();
    //   console.log(values)
    const form = useForm();
    const {pairObject, entryPrice, positionType, targetPoints,activeStep,markettradetype,leverage,entryReset,cloned} = values;
    const getTargetPoints = () => {
        return targetPoints ? targetPoints.map((tp) => {
            return tp ? Number(tp.valuenumber) : 0
        }) : []
    }
    const [localTargetPoints, setLocalTargetPoints] = useState(getTargetPoints());
    const [canInit, setCanBeInit] = useState(false);
    const getRealMin = () => {
        if (!canInit || !pairObject ) return 3;
        if (positionType === "sell") {
            return pairObject.tickSize

        }
        else {
            return Number((entryPrice.high + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
        }

    }
    const getRealMax = () => {
        if (!canInit || !pairObject ) return 99;
        if (positionType === "buy") {
            return pairObject.latestPrice * 1000;

        }
        else {
            return Number((entryPrice.low - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
        }
    }
    const getMin = () => {
        if (!canInit  || !pairObject) return 0;
        if (positionType === "sell") {
            const idealMin = pairObject.latestPrice - (pairObject.latestPrice * 0.2);
            const minTarget = Array.min(localTargetPoints);
            if (minTarget < idealMin + (idealMin * 0.1)) {
                return minTarget - (minTarget * 0.1);
            }
            return idealMin;

        }
        else {
            return getRealMin();
        }

    }
    const getMax = () => {
        if (!canInit  || !pairObject) return 100;
        if (positionType === "buy") {
            const idealMax = pairObject.latestPrice + (pairObject.latestPrice * 0.2);
            const maxTarget = Array.max(localTargetPoints)
            if (maxTarget > idealMax - (idealMax * 0.1)) {
                return maxTarget + (maxTarget * 0.1);
            }
            return idealMax;

        }
        else {
            return getRealMax();
        }
    }
    const getTargetPointDefaultInitVal = () => {
        if (!canInit || !targetPoints || targetPoints.length < 1  || !pairObject) return 85;
        let defaultTP = 0;
        if (positionType === "buy") {
            const maxTarget = Array.max(localTargetPoints);
            defaultTP = Number((maxTarget + (maxTarget * 0.02)).toFixed(countDecimals(pairObject.tickSize)));

        }
        else {
            const minTarget = Array.min(localTargetPoints);
            defaultTP = Number((minTarget - (minTarget * 0.02)).toFixed(countDecimals(pairObject.tickSize)));

        }
        return defaultTP;

    }

    const getMarks = () => {
        if (!canInit  || !pairObject) {
            return false;
        }
        return [{
            value: positionType === "sell" ? entryPrice.low : entryPrice.high,
            label: translate("resources.signalalerts.entry")
        }]
    }
     useEffect(() => {
         setTimeout(()=>{
             handleInputBlur()
           /*  getTargetPoints().forEach((val,index)=>{

                 form.change('targetPoints[' + index + '].valuenumber',val)
                 form.blur('targetPoints[' + index + '].valuenumber')
             })*/
         },100)

/*         setTimeout(()=>{
             window.swv && window.swv.updateHeight();
         },500)*/


     }, [localTargetPoints.length]);
    useEffect(() => {

        let filteredTargetPoints = getTargetPoints().map((tp) => {
            if (tp > getRealMax()) {
                return getRealMax();
            }
            if (tp < getRealMin()) {
                return getRealMin();
            }
            return tp;
        })
        setLocalTargetPoints(filteredTargetPoints);

    }, [targetPoints]);
    useEffect(() => {
        //  console.log('useeffect1')
        if (pairObject &&  entryPrice && positionType) {

            setCanBeInit(true);

        }
        else {
            setCanBeInit(false);
        }
    }, [pairObject, entryPrice, positionType,entryReset]);
    useEffect(()=>{

        /*
                setLocalTargetPoints([])
                form.change("targetPoints",[])*/
    },[positionType,pairObject]);
    useEffect(()=>{
        if(canInit && !!pairObject  ){

            if(cloned){
                setTimeout(()=>{
                    form.change('cloned',false);
                },5000)

                return;
            }
            let defaultTP = 0;
            if (positionType === "buy") {
                defaultTP = Number(((entryPrice.high + (entryPrice.low * 0.02)).toFixed(countDecimals(pairObject.tickSize))))

            }
            else {
                defaultTP = Number(((entryPrice.low - (entryPrice.high * 0.02)).toFixed(countDecimals(pairObject.tickSize))))

            }
            form.change('targetPoints', [{valuenumber: defaultTP,percent:100}])
            console.log(targetPoints)
        }
    },[positionType,pairObject,canInit,entryReset])
    /* const handleChangeSlider = (event, newValue) => {
         setLocalTargetPoints(newValue);

     };
     const handleChangeSliderCommited = (event, newValue) => {
        if(positionType === "buy"){

            newValue.forEach((val, indx) => {
                form.change('targetPoints[' + indx + '].valuenumber', val)
            })
        }
        else{
            newValue.reverse().forEach((val, indx) => {
                form.change('targetPoints[' + indx + '].valuenumber', val)
            })
        }


     };*/
    const handleInputBlur = ()=>{
        if(!canInit || !pairObject  ){
            return
        }
        if(targetPoints && targetPoints.length>0){
            let tps = Array.from(targetPoints);
            tps = tps.map((tp) => {
                if (tp.valuenumber > getRealMax()) {
                    tp.valuenumber = getRealMax();

                }
                if (tp.valuenumber < getRealMin()) {
                    tp.valuenumber = getRealMin();
                }
                return tp;
            })
            if(positionType === 'buy'){
                tps.sort((a, b) => parseFloat(a.valuenumber) - parseFloat(b.valuenumber));
            }
            else{
                tps.sort((a, b) => parseFloat(b.valuenumber) - parseFloat(a.valuenumber));
            }
            //     console.log(tps)
            form.change('targetPoints', tps);

            tps.forEach((tp,indx)=>{

                form.blur('targetPoints[' + indx + '].valuenumber');

            })

        }


    }
    const getDisableRemove = () => {
        return localTargetPoints && localTargetPoints.length < 2
    }
    const getDisableAdd = () => {
        return localTargetPoints && localTargetPoints.length > 9
    }
    const notEqualTP = (value, allValues) => {
        if (allValues && allValues.targetPoints && allValues.targetPoints.length > 1) {
            let duplicats = findDuplicates(localTargetPoints);

            if(duplicats.length > 0 && duplicats.indexOf(value) !== -1){
                return {message: 'Duplicated Target Point'};
            }

        }
    }
    const getProfitBasedOnEntryAvg = (tpIndex)=>{
        if(!canInit || !pairObject || !targetPoints || !targetPoints[tpIndex] ||  !targetPoints[tpIndex].valuenumber){
            return 0;
        }
        let avrg = Number(((entryPrice.low + entryPrice.high) / 2).toFixed(countDecimals(pairObject.tickSize)));
        let tpVal = targetPoints[tpIndex].valuenumber;

        let profitPercent = rawProfitInPercent(avrg,tpVal,positionType);
        return profitPercent;
    }
    const ProfitField = (props)=>{
        if(!props || !props.source){
            return <div/>
        }
        let {source} = props;
        let index = source.substring(13,source.length-1)
        return(<div className={classes.profitPercent} >
            {translate("resources.signalalerts.differenceBasedOnEntyAvg",{profit:getProfitBasedOnEntryAvg(index)})}
            {markettradetype === 1 &&
            <span>
                        <span>{"    x " + leverage  + " = " +(getProfitBasedOnEntryAvg(index)*leverage).toFixed(2) + "%"}</span>

                    </span>

            }
        </div>)
    }
    return (

        <div className={classes.withSliderForm} id={"targetPointsDiv"}  style={{display:'none'}}>
            <Typography id="enterPricesliderLabel" gutterBottom className={classes.formInput}>
                {translate('resources.signalalerts.targetPointsHint')}
            </Typography>
            <div className={classes.margin}/>
            {/*<TargetPointSlider
            value={localTargetPoints}
            onChange={handleChangeSlider}
            className={classes.formInput}
            track={"false"}
            //getAriaLabel={()=>translate("resources.signalalerts.stopLoss")}
            step={pairObject ? pairObject.tickSize : 1}
            min={getMin()}
            max={getMax()}

            marks={getMarks()}

            //getAriaValueText={getEnterPriceStopLossSliderText}

            // max={enterPriceEnd * 2}
            valueLabelDisplay="on"
            onChangeCommitted={handleChangeSliderCommited}

            //valueLabelFormat={getEnterPriceStopLossSliderText}


            //getAriaValueText={valuetext}
        />*/}
            <div className={classes.margin}/>
            <div className={classes.seperate}/>
            <ArrayInput source="targetPoints" {...props}>
                <SimpleFormIterator disableRemove={getDisableRemove()} disableAdd={getDisableAdd()}>

                    <NumberInput validate={[minValue(getRealMin()), maxValue(getRealMax()),notEqualTP]}
                                 onBlur={handleInputBlur}
                                 step={pairObject ? pairObject.tickSize.toFixed(10) : 1} source="valuenumber"
                                 label={"resources.signalalerts.targetprice"} initialValue={getTargetPointDefaultInitVal()}
                                 min={getRealMin()} max={getRealMax()}/>
                    <ProfitField/>


                </SimpleFormIterator>
            </ArrayInput>


        </div>

    )
}

var countDecimals = function (value) {
    let text = value.toString()
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
        let [base, trail] = text.split('e-');
        let deg = parseInt(trail, 10);
        return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
        return value.toString().split(".")[1].length || 0;
    }
    return 0;
}
const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort(); // You can define the comparing function here.

    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] == sorted_arr[i]) {
            results.push(sorted_arr[i]);
        }
    }
    return results;
}
export default TargetPointsForm;