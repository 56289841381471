import {
	CREATE,
	DELETE,
	DELETE_MANY,
	GET_LIST,
	GET_MANY,
	GET_MANY_REFERENCE,
	GET_ONE,
	UPDATE,
	UPDATE_MANY
} from "react-admin";

import diff from "object-diff";

const moment = require("moment");
const defaultIdKey = "id";
const queryOperators = ["$gt", "$gte", "$lt", "$lte", "$ne", "$sort", "$or", "$nin", "$in", "$search"];

function flatten(object, prefix = "", stopKeys = []) {
	return Object.keys(object).reduce((prev, element) => {
		const hasNextLevel =
			object[element] &&
			typeof object[element] === "object" &&
			!Array.isArray(object[element]) &&
			!Object.keys(object[element]).some((key) => stopKeys.includes(key));
		return hasNextLevel
			? { ...prev, ...flatten(object[element], `${prefix}${element}.`, stopKeys) }
			: { ...prev, ...{ [`${prefix}${element}`]: object[element] } };
	}, {});
}

function getIdKey({ resource, options }) {
	return (options[resource] && options[resource].id) || options.id || defaultIdKey;
}

function deleteProp(obj, prop) {
	const res = Object.assign({}, obj);
	delete res[prop];
	return res;
}

const restClient = (client, options = {}) => {
	const usePatch = !!options.usePatch;
	const mapRequest = (type, resource, params) => {
		const idKey = getIdKey({ resource, options });

		var service = client.service(resource);
		var query = {};
		if (resource === "vendors") {
			service = client.service("users");
			query["usertype"] = "VENDOR";
		}
		if (resource === "profile") {
			service = client.service("users");
			query["usertype"] = "CLIENT";
		}
		if (["spot", "futures", "history"].indexOf(resource) !== -1) {
			service = client.service("batchsignals");
		}

		let resourceParts = resource.split("|");
		if (resourceParts.length === 3) {
			service = client.service(resourceParts[0]);
			query[resourceParts[1]] = resourceParts[2];
		}
		switch (type) {
			case GET_MANY:
				const ids = params.ids || [];
				query[idKey] = { $in: ids };
				query.$limit = ids.length;
				return service.find({ query });
			case GET_MANY_REFERENCE:
				if (params.target && params.id) {
					query[params.target] = params.id;
				}
			case GET_LIST:
				const { page, perPage } = params.pagination || {};
				const { field, order } = params.sort || {};
				const additionalQueryOperators = Array.isArray(options.customQueryOperators) ? options.customQueryOperators : [];
				const allUniqueQueryOperators = [...new Set(queryOperators.concat(additionalQueryOperators))];

				if (perPage && page) {
					query.$limit = perPage;
					query.$skip = perPage * (page - 1);
				}
				if (order) {
					query.$sort = {
						[field === defaultIdKey ? idKey : field]: order === "DESC" ? -1 : 1,
					};
				}

				Object.assign(query, flatten(params.filter, "", allUniqueQueryOperators));

				return service.find({ query });
			case GET_ONE:
				//console.log(params)
				const restParams = deleteProp(params, defaultIdKey);
				return service.get(params.id, restParams);
			case UPDATE:
				if (usePatch) {
					const data = params.previousData ? diff(params.previousData, params.data) : params.data;
					var q = Object.assign(query, params.query);
					return service.patch(params.id, data, { query: q });
				}
				const data = idKey !== defaultIdKey ? deleteProp(params.data, defaultIdKey) : params.data;
				return service.update(params.id, data);

			case UPDATE_MANY:
				if (usePatch) {
					const dataPatch = params.previousData ? diff(params.previousData, params.data) : params.data;
					return Promise.all(params.ids.map((id) => service.patch(id, dataPatch)));
				}
				const dataUpdate = idKey !== defaultIdKey ? deleteProp(params.data, defaultIdKey) : params.data;
				return Promise.all(params.ids.map((id) => service.update(id, dataUpdate)));

			case CREATE:
				if (Array.isArray(params.data)) {
					return service.create(params.data);
				}
				return service.create(Object.assign(query, params.data));

			case DELETE:
				return service.remove(params.id);
			case DELETE_MANY:
				if (!!options.useMulti && service.options.multi) {
					return service.remove(null, {
						query: {
							[idKey]: {
								$in: params.ids,
							},
						},
					});
				}
				return Promise.all(params.ids.map((id) => service.remove(id)));
			default:
				return Promise.reject(`Unsupported FeathersJS restClient action type ${type}`);
		}
	};

	const mapResponse = (response, type, resource, params) => {
		const idKey = getIdKey({ resource, options });
		switch (type) {
			case GET_ONE:
				if (resource === "profile") {
					return { data: { ...response, id: params.id } };
				}
				return { data: { ...response, id: response[idKey] } };
			case UPDATE:
			case DELETE:
				return { data: { ...response, id: response[idKey] } };
			case UPDATE_MANY:
			case DELETE_MANY:
				return { data: response.map((record) => record[idKey]) };
			case CREATE:
				return { data: { ...params.data, ...response, id: response[idKey] } };
			case GET_MANY_REFERENCE: // fix GET_MANY_REFERENCE missing id
			case GET_MANY: // fix GET_MANY missing id
			case GET_LIST:
				let res;

				// support paginated and non paginated services
				if (!response.data) {
					response.total = response.length;
					res = response;
				} else {
					res = response.data;
				}

				/* if(params.filter && Object.keys(params.filter).length > 0){
        //  console.log("doing for " + JSON.stringify(params.filter))
          response.data = sortifHasSearch(response.data,params.filter)
        }*/

				response.data = res.map((_item) => {
					const item = _item;
					if (idKey !== defaultIdKey) {
						item.id = _item[idKey];
					}
					return _item;
				});
				return cacheResponse(response, type, resource, params);
			default:
				return response;
		}
	};
	const cacheResponse = (response, type, resource, params) => {
		const catchSettings = {
			alerts: 2,

			subscriptionplans: 30,
			markets: 30,
			stcategories: 30,

			"users|usertype|VENDOR": 30,
			referralcodes: 30,
		};
		if (catchSettings[resource]) {
			response.validUntil = moment(new Date()).add(catchSettings[resource], "minutes").toDate();
		}
		return response;
	};

	const resultFunc = (type, resource, params) =>
		mapRequest(type, resource, params).then((response) => mapResponse(response, type, resource, params));

	return (type, resource, params) => resultFunc(type, resource, params);
};
function sortifHasSearch(data, filter) {
	if (!filter || !data || filter === {} || data === [] || data === {}) {
		return data;
	}
	// console.log(data);
	//  console.log(filter)
	let hadSearch = false;
	let searchField = "";
	let keyword = "";
	for (let field in filter) {
		// console.log(field);
		//console.log(filter[field])
		if (filter[field] && filter[field].$search) {
			searchField = field;
			keyword = filter[field].$search;
			hadSearch = true;
			//  console.log("sorting " + searchField + " : " + keyword)
		}
	}
	if (hadSearch) {
		//  console.log(data);
		let res = _sortByTerm(data, searchField, keyword.toLowerCase());
		//  console.log(res)

		return res;
	}
	return data;
}
var _sortByTerm = function (data, key, term) {
	return data.sort(function (a, b) {
		return a[key].toLowerCase().indexOf(term) < b[key].toLowerCase().indexOf(term) ? -1 : 1;
	});
};
export default restClient;
