import React from "react";
import {
    AutocompleteInput,
    BooleanField,
    DateField,
    EditButton,
    Filter,
    List,
    NullableBooleanInput,
    ReferenceField,
    ReferenceInput,
    TextField,
    useLocale,
    useTranslate,
} from "react-admin";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import CustomizableDatagrid from "ra-customizable-datagrid";
import Box from "@material-ui/core/Box";

const StatusFieldColored = ({ record }) => (
	<TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);
const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<ReferenceInput
				alwaysOn
				label={"resources.signalalerts.client"}
				source="user"
				perPage={25}
				reference="users|usertype|CLIENT"
				allowEmpty={true}
				filter={{
					usertype: "CLIENT",
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
						  }
						: { fake: true }
				}
			>
				<AutocompleteInput
					optionText={(record) =>
						record && record.firstName
							? record.firstName + " " + record.lastName
							: translate("resources.observes.allusers")
					}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
			<NullableBooleanInput source={"expired"} alwaysOn />
		</Filter>
	);
};

function getDaysRemaining(expiresAt) {
	const _start = new Date();
	const _expire = new Date(expiresAt);

	const result = Math.round((_expire - _start) / (1000 * 60 * 60 * 24));
	return result > 0 ? result : 0;
}

const DaysRemaining = ({ record }) => {
	if (record && record.expiresAt) {
		let remainingDays = getDaysRemaining(record.expiresAt);
		if (remainingDays) {
			return <Box marginLeft={2}>{remainingDays} Days remaining </Box>;
		}
		return (
			<Box marginLeft={2} color={"#f50057"}>
				Expired
			</Box>
		);
	}

	return <span />;
};
const rowStyle = (record) => {
	return {
		backgroundColor: record && record.disabled ? "lightgrey" : "transparent",
	};
};

const AppSubscriptionsListItems = (props) => {
	const locale = useLocale();

	return (
		<CustomizableDatagrid {...props} rowStyle={rowStyle}>
			{/*   <DateField source="createdAt" label="resources.appsubscriptions.createdAt"  locales={locale} showTime/>*/}

			<ReferenceField source="user" label={"resources.appsubscriptions.client"} reference="users|usertype|CLIENT">
				<NameWithAvatarField />
			</ReferenceField>
			<ReferenceField source="subscriptionPlan" reference="subscriptionplans">
				<TextField source={"name"} />
			</ReferenceField>
			<DateField source="startedAt" label="resources.appsubscriptions.startedAt" locales={locale} showTime />
			<DateField source="expiresAt" label="resources.appsubscriptions.expiresAt" locales={locale} showTime />

			<DaysRemaining />
			<BooleanField label="resources.appsubscriptions.expired" source="expired" />

			<EditButton />
		</CustomizableDatagrid>
	);
};
const AppSubscriptionsList = (props) => {
	const translate = useTranslate();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.appsubscriptions.listtitle")}
			perPage={25}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			bulkActionButtons={false}
		>
			<AppSubscriptionsListItems />
		</List>
	);
};

export { AppSubscriptionsListItems, AppSubscriptionsList, DaysRemaining };
