import React from "react";
import {
	BooleanField,
	Datagrid,
	DateField,
	EditButton,
	Filter,
	List,
	SearchInput,
	SelectField,
	TextField,
	useLocale,
	useTranslate,
} from "react-admin";
import {MarketTypes} from "./MarketsEnum";

const StatusFieldColored = ({ record }) => (
	<TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);
const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<SearchInput
				source="name.$search"
				alwaysOn
				placeholder={translate("resources.markets.name")}
				InputLabelProps={{ shrink: true }}
			/>
		</Filter>
	);
};

const MarketsList = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.markets.listtitle")}
			perPage={10}
			exporter={false}
			bulkActionButtons={false}
		>
			<Datagrid>
				<TextField source="name" label="resources.markets.name" />

				<SelectField label="resources.markets.markettype" source="markettype" choices={MarketTypes} />

				<DateField source="createdAt" label="resources.markets.createdAt" locales={locale} />
				<DateField source="updatedAt" label="resources.markets.updatedAt" locales={locale} showTime />
				<BooleanField source={"wsConnected"} />

				<DateField
					source="priceTickerReceivedAt"
					label="resources.markets.priceTickerReceivedAt"
					locales={locale}
					showTime
				/>
				<DateField
					source="candlesReceivedAt"
					label="resources.markets.candlesReceivedAt"
					locales={locale}
					showTime
				/>
				{props &&
					props.permissions &&
					props.permissions.usertype === "ADMIN" &&
					props.permissions.accessType === 1 && <EditButton />}
			</Datagrid>
		</List>
	);
};

export { MarketsList };
