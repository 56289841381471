import React from "react";
import {
	BooleanField,
	Datagrid,
	DateField,
	Filter,
	List,
	SearchInput,
	TextField,
	useLocale,
	useTranslate,
} from "react-admin";

import Link from "@material-ui/core/Link";

const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<SearchInput InputLabelProps={{ shrink: true }} source="h.$search" alwaysOn placeholder={"Hash"} />
			<SearchInput InputLabelProps={{ shrink: true }} source="l.$search" alwaysOn placeholder={"Link"} />
			<SearchInput InputLabelProps={{ shrink: true }} source="m.$search" alwaysOn placeholder={"Desc"} />
		</Filter>
	);
};

const HashField = ({ record }) => {
	return (
		<Link target={"_blank"} href={"https://l.dealerify.io/" + record?.h}>
			{"https://l.dealerify.io/" + record?.h}
		</Link>
	);
};
const LinksListItems = (props) => {
	const locale = useLocale();
	return (
		<Datagrid {...props}>
			<HashField label={"Short Link"} />

			<TextField source={"l"} label={"Link"} />
			<TextField source={"c"} label={"Count"} />
			<TextField source={"m"} label={"Desc"} />

			<BooleanField source="disabled" />
			<DateField source="createdAt" label="resources.sms.createdAt" locales={locale} showTime />
		</Datagrid>
	);
};
const LinksList = (props) => {
	const translate = useTranslate();

	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.sms.listtitle")}
			perPage={10}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			bulkActionButtons={false}
		>
			<LinksListItems />
		</List>
	);
};

export { LinksListItems, LinksList };
