import React from "react";

import {
	AutocompleteInput,
	BooleanField,
	BooleanInput,
	Datagrid,
	DateField,
	EditButton,
	Filter,
	List,
	ReferenceField,
	ReferenceInput,
	SearchInput,
	TextField,
	useLocale,
	useTranslate,
} from "react-admin";

const StatusFieldColored = ({ record }) => (
	<TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);
const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<ReferenceInput
				alwaysOn
				label={"resources.pairs.market"}
				source="market"
				perPage={10}
				reference="markets"
				allowEmpty={true}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								name: { $search: searchText },
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={"name"}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.pairs.allmarkets"}
					alwaysOn
				/>
			</ReferenceInput>

			<SearchInput
				source="symbol.$search"
				alwaysOn
				placeholder={translate("resources.pairs.symbol")}
				InputLabelProps={{ shrink: true }}
			/>
			<SearchInput source="_symbol.$search" alwaysOn placeholder={"_symbol"} InputLabelProps={{ shrink: true }} />
			<SearchInput
				InputLabelProps={{ shrink: true }}
				source="baseAsset.$search"
				alwaysOn
				placeholder={translate("resources.pairs.baseAsset")}
			/>
			<SearchInput
				InputLabelProps={{ shrink: true }}
				source="quoteAsset.$search"
				alwaysOn
				placeholder={translate("resources.pairs.quoteAsset")}
			/>
			<BooleanInput label="resources.pairs.status" source="status" nullable />
		</Filter>
	);
};

const PairsList = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.pairs.listtitle")}
			perPage={10}
			exporter={false}
			bulkActionButtons={false}
			sort={{ field: "createdAt", order: "DESC" }}
		>
			<Datagrid>
				<ReferenceField source="market" label={"resources.pairs.market"} reference="markets">
					<TextField source="name" />
				</ReferenceField>
				<TextField source="symbol" label="resources.pairs.symbol" />
				<TextField source="_symbol" label="_symbol" />
				<TextField source="bq" label="bq" />
				<TextField source="baseAsset" />
				<TextField source="base" />
				<TextField source="quoteAsset" />
				<TextField source="quote" />
				<TextField source="latestPrice" label="resources.pairs.latestPrice" />
				<DateField source="latestPriceTime" label="resources.pairs.latestPriceTime" locales={locale} showTime />
				<BooleanField label="resources.pairs.status" source="status" />
				<BooleanField label="resources.pairs.removedfrommarket" source="removedfrommarket" />
				<BooleanField label="resources.pairs.disabled" source="disabled" />
				<DateField source="createdAt" label="resources.pairs.createdAt" locales={locale} showTime />
				<DateField source="updatedAt" label="resources.pairs.updatedAt" locales={locale} showTime />
				{props &&
					props.permissions &&
					props.permissions.usertype === "ADMIN" &&
					props.permissions.accessType === 1 && <EditButton />}
			</Datagrid>
		</List>
	);
};

export { PairsList };
