import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {Divider} from "@material-ui/core/";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import {Datagrid, DateField, FunctionField, useListContext, useLocale, useVersion} from "react-admin";
import SignalStatus from "../vendorsignals/SignalStatus";
import PairAvatar from "./PairAvatar";
import SignalActions from "./SignalActions";
import RowStyle from "./RowStyle";
import VendorOrUserAvatar from "./VendorOrUserAvatar";
import SignalInfoField from "./SignalInfoField";
import PositionsCards from "./PositionsCards";
import useOnScreen from "../shared/onScreen";
import ProfitField from "./ProfitField";
import {useDispatch} from "react-redux";
import {useMediaQuery} from "@material-ui/core";
import BatchSignalListMobileGridIterator from "./BatchSignalsListMobileGrid";
import HistoryEmpty from "./HistoryEmpty";

const useStyles = makeStyles((theme) => ({
	maxWidth100: {},
	minWidth100: {},
	maxWidth99P: {
		width: "99%",
	},
	profitRoot: {
		minWidth: 100,
		textAlign: "center",
	},
	profitZero: {
		fontWeight: "bold",
		fontSize: "1.1em",
		backgroundColor: "#1c1c1c",
		borderRadius: 24,
		padding: 12,
	},
	profitGreen: {
		fontWeight: "bold",
		fontSize: "1.1em",
		color: theme.palette.success.main,
		backgroundColor: "#1c1c1c",
		borderRadius: 24,
		padding: 12,
	},
	profitRed: {
		fontWeight: "bold",
		fontSize: "1.1em",
		color: theme.palette.warning.main,
		backgroundColor: "#1c1c1c",
		borderRadius: 24,
		padding: 12,
	},
}));
/*const SignalDataGridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />
const SignalDataGridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />*/

const SignalGroup = (props) => {
	const { total, resource, basePath } = useListContext();
	const { groupedData, index } = props;
	const locale = useLocale();

	const classes = useStyles();
	const [shouldRender, setShouldRender] = useState(index <= 4 || total < 15);
	const ref = useRef();
	const isVisible = useOnScreen(ref);
	const dispatch = useDispatch();
	useEffect(() => {
		if (isVisible && !shouldRender) {
			setShouldRender(true);
		}
	}, [isVisible]);
	/*useEffect(()=>{
		dispatch(toggleListItemExpand("spot", groupedData?.ids[0]));

	},[])*/
	return (
		<Box my={2} ref={ref} minHeight={150}>
			<Box width={80} m={1}>
				<PairAvatar pairObject={groupedData?.pairObject} textVariant={"h6"} />
			</Box>
			{shouldRender && <SignalDataGrid data={groupedData} />}
		</Box>
	);
};
const SignalDataGrid = (props = {}) => {
	const classes = useStyles();
	const { total, resource, basePath, loaded, filterValues, loading } = useListContext();
	const { data = {} } = props;
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const version = useVersion();
	return isSmall ? (
		<BatchSignalListMobileGridIterator data={data.signalsData} ids={data.ids} basePath={basePath} resource={resource} />
	) : (
		<Datagrid
			data={data.signalsData}
			ids={data.ids}
			basePath={basePath}
			resource={resource}
			optimized={true}
			loaded={loaded}
			expand={<PositionsCards />}
			rowStyle={RowStyle}
			empty={resource === "history" && <HistoryEmpty />}
		>
			<SignalInfoField label={"Signal"} />
			{resource === "history" && <FunctionField render={(record) => <PairAvatar pairObject={record?.pair} textVariant={"h6"} />} />}
			<ProfitField classes={classes} label={resource === "history" ? "PnL" : "Unrealized PnL"} cellClassName={classes.maxWidth100} />

			<VendorOrUserAvatar label={"Vendor"} />

			<SignalStatus label={"Status"} component="th" scope={"row"} cellClassName={classes.maxWidth99P} version={version} />
			{resource === "history" && <DateField source={"updatedAt"} />}
			<SignalActions label={"Actions"} />
		</Datagrid>
	);
};
const SignalGroupMemo = SignalGroup;
const createIdData = (array) => {
	let result = {};
	array.forEach((item) => (result[item._id] = item));
	return result;
};
const _BatchSignalsListGrid = (props) => {
	const { groupMode = true, empty } = props;
	//const version = useVersion();

	const {
		// fetched data
		total,
		data, // an id-based dictionary of the list data, e.g. { 123: { id: 123, title: 'hello world' }, 456: { ... } }
		ids, // an array listing the ids of the records in the list, e.g [123, 456, ...]
	} = useListContext();
	const mergedSignals = useCallback(() => {
		let dataArray = ids
			.map((id) => {
				return data[id];
			})
			.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
		const sortedData = {};
		const sortedIds = dataArray.map((data) => {
			sortedData[data?._id] = data;
			return data?._id;
		});
		return { signalsData: sortedData, ids: sortedIds };
	}, [data]);
	const createdGroupArray = useCallback(() => {
		let pairSet = new Set();
		let dataArray = ids.map((id) => {
			pairSet.add(data[id].pair?.bq);
			return data[id];
		});

		let groupedData = [];
		pairSet.forEach((pair_Symbol) => {
			let signals = dataArray
				.filter((sig) => sig.pair?.bq === pair_Symbol)
				.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
			groupedData.push({
				_symbol: pair_Symbol,
				pairObject: signals[0].pair,
				signalsData: createIdData(signals),
				lastSignalUpdatedAt: signals[0].updatedAt,
				ids: signals.map((s) => s._id),
			});
		});

		return groupedData.sort((a, b) => new Date(b.lastSignalUpdatedAt).getTime() - new Date(a.lastSignalUpdatedAt).getTime());
	}, [data]);
	const dataArray = groupMode ? createdGroupArray() : mergedSignals();

	return (
		<Box padding={1}>
			{!groupMode && <SignalDataGrid data={dataArray} />}
			{groupMode && (
				<Box container spacing={1}>
					{dataArray.map((sig, index) => (
						<Box key={index} item xs={12} sm={12} md={12} lg={12} textAlign={"center"}>
							<SignalGroupMemo groupedData={sig} index={index} />
						</Box>
					))}
				</Box>
			)}
		</Box>
	);
};

/*_BatchSignalsListGrid.defaultProps = {
	data: {},
	ids: [],
};*/
const BatchSignalsListGrid = _BatchSignalsListGrid;
export { BatchSignalsListGrid };
