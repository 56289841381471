import * as React from "react";
import {memo, useCallback} from "react";
import {Pie} from "react-chartjs-2";
import {ArcElement, Chart as ChartJS} from "chart.js";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import ExposureZeroIcon from "@material-ui/icons/ExposureZero";
import Tooltip from "@material-ui/core/Tooltip";
import {Typography} from "@material-ui/core";

ChartJS.register(ArcElement);

const useStyles = makeStyles((theme) => ({
	root: {
		width: 70,
		height: 70,
		position: "relative",
		display: "flex",
		zIndex: 999999,
		overflowX: "visible",
	},
	canvas: {
		backgroundSize: "50%",
		position: "relative",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		zIndex: 999999,
	},
	avatar: {
		backgroundColor: theme.palette.text.primary,
		width: 30,
		height: 30,
		position: "absolute",
		left: "calc(50% - 15px)",
		top: "calc(50% - 15px)",
		zIndex: 5,
	},
}));
const SignalPerformanceWidget = ({ user, tf }) => {
	const classes = useStyles();
	let reportData = {};

	if (!user || !user["signalPerformanceReport_" + tf]) {
	} else {
		reportData = user["signalPerformanceReport_" + tf];
	}
	const {
		count_spot,
		profit_count_spot,
		loos_count_spot,
		count_futures,
		profit_count_futures,
		loos_count_futures,
		count_total,
		cum_profit_spot,
		cum_loos_spot,
		cum_profit_futures,
		cum_loos_futures,
	} = reportData;

	/*var canvas = document.getElementById(record?._id);
	var ctx = canvas?.getContext("2d");

	// Create a linear gradient
	// The start gradient point is at x=20, y=0
	// The end gradient point is at x=220, y=0
	var gradient = ctx?.createLinearGradient(20, 0, 220, 0);

	// Add three color stops
	gradient?.addColorStop(0, "#7ed30f");
	gradient?.addColorStop(0.5, "#69a51a");
	gradient?.addColorStop(1, "#7ed30f");*/

	const piedata = {
		labels: ["Profitable Spot", "Loss-Making Spot", "Undetermined Spot", "Profitable Futures", "Loss-Making Futures", "Undetermined Futures"],
		datasets: [
			{
				backgroundColor: ["#7ed30f", "#ec0046", "#fff"],

				borderWidth: 0,
				radius: "50%",
				data: [profit_count_spot, loos_count_spot, count_spot - profit_count_spot - loos_count_spot],
				borderJoinStyle: "round",
			},
			{
				backgroundColor: ["#7ed30f", "#ec0046", "#fff"],

				borderJoinStyle: "bevel",
				rotation: 0,
				borderColor: "transparent",
				borderWidth: 0,
				radius: "45%",
				data: [profit_count_futures, loos_count_futures, count_futures - profit_count_futures - loos_count_futures],
			},
		],
	};
	const getTitle = () => {
		let pastDays = "";
		switch (tf) {
			case "1d":
				pastDays = "Last 24 hours";
				break;
			case "1w":
				pastDays = "Last 7 days";
				break;
			case "1m":
				pastDays = "Last 30 days";
				break;
			case "3m":
				pastDays = "Last 90 days";
				break;
			case "1y":
				pastDays = "Last 365 days";
				break;
		}
		if (!reportData?.count_total) {
			return <Typography>{"No signals published in " + pastDays}</Typography>;
		}

		return (
			<Box>
				<Typography>{"Total " + reportData?.count_total + " signals in " + pastDays}</Typography>
				<Typography color={"primary"}>{"Profitable Spot Signals: " + profit_count_spot}</Typography>
				<Typography color={"secondary"}>{"Loss-Making Spot Signals: " + loos_count_spot}</Typography>
				<Typography color={"textSecondary"}>{"Undetermined Spot Signals: " + (count_spot - profit_count_spot - loos_count_spot)}</Typography>
				<Typography color={"primary"}>{"Cumulative Profit Spot: " + cum_profit_spot.toFixed(2) + "%"}</Typography>
				<Typography color={"secondary"}>{"Cumulative Loss Spot: " + cum_loos_spot.toFixed(2) + "%"}</Typography>
				<Typography color={"primary"}>{"Profitable Futures Signals: " + profit_count_futures}</Typography>
				<Typography color={"secondary"}>{"Loss-Making Futures Signals: " + loos_count_futures}</Typography>
				<Typography color={"textSecondary"}>
					{"Undetermined Futures Signals: " + (count_futures - profit_count_futures - loos_count_futures)}
				</Typography>
				<Typography color={"primary"}>{"Cumulative Profit Futures: " + cum_profit_futures.toFixed(2) + "%"}</Typography>
				<Typography color={"secondary"}>{"Cumulative Loss Futures: " + cum_loos_futures.toFixed(2) + "%"}</Typography>
			</Box>
		);
	};
	const getT = useCallback(getTitle, [reportData, tf]);

	return (
		<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={2000} title={<Typography>{getT()}</Typography>}>
			<Box className={classes.root}>
				{!reportData?.count_total && (
					<Avatar className={classes.avatar}>
						<ExposureZeroIcon />
					</Avatar>
				)}
				{!!reportData?.count_total && (
					<Pie
						type={"pie"}
						data={piedata}
						options={{
							animations: false,
							responsive: true,
							cutout: 15,
						}}
						id={user?._id}
						className={classes.canvas}
					/>
				)}
			</Box>
		</Tooltip>
	);
};
export default memo(SignalPerformanceWidget);
