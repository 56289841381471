import Icon from '@material-ui/icons/AccountBalance';
import ExchangeAccountsCreateWizard from './ExchangeAccountsCreateWizard';

import ExchangeAccountsEdit from './ExchangeAccountsEdit';
import {ExchangeAccountsList} from './ExchangeAccountsList';

export default {
    create : ExchangeAccountsCreateWizard,
    edit : ExchangeAccountsEdit,
    list:ExchangeAccountsList,
    icon: Icon,
};
