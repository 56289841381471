import ClientIcon from "@material-ui/icons/PeopleAltTwoTone";
import ClientsCreate from "./ClientsCreate";
import ClientsEdit from "./ClientsEdit";
import {ClientsList} from "./ClientsList";

export default {
	edit: ClientsEdit,
	create: ClientsCreate,
	list: ClientsList,
	icon: ClientIcon,
};
