
const ServiceTypes =[
    {
        id:'SIGNALALERTS',
        name:"Signal Alerts"

    },
    {
        id:'AUTOTRADE',
        name:"Signal Alerts & Auto Trade"

    },
]
export {ServiceTypes}
