import React from "react";
import {
    AutocompleteInput,
    Create,
    DateInput,
    email,
    maxValue,
    minLength,
    minValue,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useLocale,
    useTranslate,
} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {AccountDocsSides, AccountDocsTypes} from "./AccountingDocsEnum";

export const styles = {
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);

const AccountingDocsCreate = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	const translate = useTranslate();
	return (
		<Create {...props} label={"resources.markets.createnew"}>
			<SimpleForm redirect={false}>
				<ReferenceInput
					alwaysOn
					validate={requiredValidate}
					source="user"
					perPage={25}
					reference="users"
					allowEmpty={true}
					filter={{
						usertype: { $ne: "ADMIN" },
					}}
					filterToQuery={(searchText) =>
						searchText.trim() !== ""
							? {
									$or: [
										{ firstName: { $search: searchText } },
										{ lastName: { $search: searchText } },
										{ nickName: { $search: searchText } },
										{ userName: { $search: searchText } },
									],
							  }
							: null
					}
				>
					<AutocompleteInput
						optionText={(record) =>
							record && record.firstName
								? record.firstName + " " + record.lastName + " " + (record.nickName || "")
								: translate("resources.observes.allusers")
						}
						allowEmpty={false}
						optionValue={"_id"}
					/>
				</ReferenceInput>

				<SelectInput
					label="resources.accountingdocuments.type"
					source="type"
					choices={AccountDocsTypes}
					validate={requiredValidate}
				/>
				<SelectInput
					label="resources.accountingdocuments.side"
					source="side"
					choices={AccountDocsSides}
					validate={requiredValidate}
				/>
				<NumberInput source={"amount"} validate={requiredValidate} />
				<DateInput source={"createdAt"} />
				<TextInput source={"desc"} />
			</SimpleForm>
		</Create>
	);
};
const totValidate = [required(), minValue(10), maxValue(600)];
const requiredValidate = [required()];
const nameValidate = [required(), minLength(3)];
const emailValidate = [email()];

const SectionTitle = (label) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom>
			{translate(label)}
		</Typography>
	);
};

const Separator = () => <Box pt="1em" />;

export default AccountingDocsCreate;
