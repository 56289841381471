import TimelineIcon from '@material-ui/icons/Timeline';

import {ATUFPositionsList} from './ATUFPositionsList';
import {ATUFPositionsShow} from './ATUFPositionsShow';

export default {

    show : ATUFPositionsShow,
    list:ATUFPositionsList,
    icon: TimelineIcon,
};
