import {TranslationMessages} from 'ra-core';
import englishMessages from "ra-language-english";
import defaultEnglish from './en'

const customFrenchMessages: TranslationMessages = {
    ...englishMessages

};

//export default customFrenchMessages;
export default defaultEnglish
