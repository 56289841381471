import React, {memo} from "react";
import {Box, Tooltip, useMediaQuery} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {ReferenceField, usePermissions} from "react-admin";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";
import NameWithAvatarField from "../shared/NameWithAvatarField";

const styles = (theme) => ({
	root: { minWidth: 75, textAlign: "center" },
});
const VendorOrUserAvatar = (props) => {
	const { classes, record } = props;
	const { loaded: permissionsLoaded, permissions } = usePermissions();
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	return (
		<Box className={classes.root}>
			{record?.vendor && (
				<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000}>
					<Box>
						<ReferenceField
							record={record}
							source="vendor"
							label={"resources.vendorsubscriptions.vendor"}
							reference="users|usertype|VENDOR"
							link={false}
						>
							<NickNameWithAvatarField size={isSmall ? 25 : 35} />
						</ReferenceField>
					</Box>
				</Tooltip>
			)}
			{record?.user && permissionsLoaded && (
				<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000}>
					<Box>
						<ReferenceField
							record={record}
							source="user"
							label={"resources.vendorsubscriptions.CLIENT"}
							reference="users|usertype|CLIENT"
							link={false}
						>
							<NameWithAvatarField />
						</ReferenceField>
					</Box>
				</Tooltip>
			)}
		</Box>
	);
};
export default memo(withStyles(styles, { name: "VendorOrUserAvatar" })(VendorOrUserAvatar));
