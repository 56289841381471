import Icon from '@material-ui/icons/Shop';
import MarketsCreate from './MarketsCreate';
import MarketsEdit from './MarketsEdit';
import {MarketsList} from './MarketsList';

export default {
    create : MarketsCreate,
    edit : MarketsEdit,
    list:MarketsList,
    icon: Icon,
};
