import Box from "@material-ui/core/Box";
import React, {memo} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import EmptyArtImage from "../assets/images/historyArt.svg";
import Typography from "@material-ui/core/Typography";
import {useListContext} from "react-admin";

const styles = (theme) => ({
	root: {
		display: "flex",
		maxWidth: 700,
		flexDirection: "column",
		marginLeft: "auto",
		marginRight: "auto",
		justifyContent: "center",
		alignItems: "center",
	},
	container: {
		padding: theme.spacing(10),
	},
});
const HistoryEmpty = ({ classes = {} }) => {
	const { filterValues } = useListContext();
	return (
		<Box className={classes.container}>
			<Box className={classes.root}>
				<Box width={"40%"} mx={"auto"} my={3}>
					<img src={EmptyArtImage} />
				</Box>
				<Typography gutterBottom>There are no closed Signals</Typography>

				{filterValues?.relatedService === "signalalerts" && (
					<Typography color={"textSecondary"}> You can find your self made signals here after they are closed</Typography>
				)}
				{filterValues?.relatedService === "vendorsignals" && (
					<Typography color={"textSecondary"}> You can find vendor signals here after they are closed</Typography>
				)}
			</Box>
		</Box>
	);
};
export default memo(withStyles(styles, { name: "HistoryEmpty" })(HistoryEmpty));
