import Box from "@material-ui/core/Box";
import React, {memo} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import EmptyArtImage from "../assets/images/futuresArt.svg";
import Typography from "@material-ui/core/Typography";
import CreateWithMarketButton from "../shared/CreateWithMarketButton";

const styles = (theme) => ({
	root: {
		display: "flex",
		maxWidth: 700,
		flexDirection: "column",
		marginLeft: "auto",
		marginRight: "auto",
		justifyContent: "center",
		alignItems: "center",
	},
	container: {
		padding: theme.spacing(10),
	},
});
const FuturesEmpty = ({ classes = {} }) => {
	return (
		<Box className={classes.container}>
			<Box className={classes.root}>
				<Box width={"40%"} mx={"auto"} my={3}>
					<img src={EmptyArtImage} />
				</Box>
				<Typography>You have no active Futures Signals</Typography>
				<Typography color={"textSecondary"}> Want to create one ?</Typography>
				<CreateWithMarketButton
					disableMobile={true}
					basePath={"signalalerts|tradetype|1"}
					tradetype={1}
					label={"Create Futures Signal"}
					fullWidth={true}
					size={"large"}
					icon={false}
				/>
			</Box>
		</Box>
	);
};
export default memo(withStyles(styles, { name: "FuturesEmpty" })(FuturesEmpty));
