import React, {Fragment, useState} from "react";
/*import { connect } from "react-redux";
import { change, submit, isSubmitting } from "redux-form";*/
import {
    AutocompleteArrayInput,
    Button,
    CREATE,
    ReferenceArrayInput,
    required,
    SaveButton,
    useNotify,
    useRedirect,
    useRefresh,
    useTranslate,
    useUnselectAll
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Form} from 'react-final-form'

import {Card, CardActions, CardContent} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles/index";

const styles = {
    suggestionsContainerOpen: {
        zIndex: 2100,
    },
};
const useStyles = makeStyles(styles);

const AddUsersToChannelByChannelBtn = (props) => {

    const [showDialog, setShowDialog] = useState(false);

    const classes = useStyles();
    const handleClick = () => setShowDialog(true);
    const handleDialogClose = () => setShowDialog(false);
    const redirect = useRedirect();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('pttusers');
    const handleSubmit = async values => {


            console.log(values)
        if (values.userIds === undefined || !Array.isArray(values.userIds) ||  values.userIds.length < 1 ) {
            notify("Please select channel", "error"); //todo :translate
            return;
        }
        let channelMapData = [];

        if (props && props.record ) {

            values.userIds.forEach((uid) => {
                const newData = {User_Id: uid, VChannel_Id: props.record.Id}
                channelMapData.push(newData);
            })

        }

        else {
            notify("Some Error", "error");  //todo :translate
            return;
        }


        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        return global.RC(CREATE, "pttchannelsmap", {data: channelMapData})
            .then(({data}) => {
                // Update the main react-admin form (in this case, the comments creation form)
                /* change(REDUX_FORM_NAME, "post_id", data.id);
                 this.setState({showDialog: false});*/
                console.log(data)

                redirect(props.basePath + '/' + props.record.Id + '/2');

                refresh();
                notify(
                    'resources.pttusers.notifications.addedtochannelsuccess',
                    'info',
                    {},
                    true
                );
                setShowDialog(false);
                return Promise.resolve()
            })
            .catch(error => {
                notify(error.message, "error");
            })

    };


    return (
        <Fragment>
            <Button onClick={handleClick} label="resources.pttchannels.adduserstochannelbtn">
                <IconContentAdd/>
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleDialogClose}
                aria-label="Add to channel"
            >
                <DialogTitle>{translate("resources.pttusers.adduserstochannel")}</DialogTitle>
                <DialogContent>
                    <Form
                        // We override the redux-form name to avoid collision with the react-admin main form
                        /* form="add-user-to-channel"
                          resource="pttusers"*/
                        // We override the redux-form onSubmit prop to handle the submission ourselves
                        onSubmit={handleSubmit}
                        /* redirect={false}
                         // We want no toolbar at all as we have our modal actions
                         toolbar={null}
                         undoable={false}
                         validate={false}*/
                        render={({handleSubmit, form, submitting, pristine, values}) =>

                            (<Card>
                                <form>
                                    <CardContent>
                                        <ReferenceArrayInput label="resources.pttchannels.users" perPage={10}
                                                             source="userIds" reference="pttusers" resource="pttusers"
                                                             allowEmpty={false} validate={[required()]}
                                                             filterToQuery={(searchText) => searchText.trim() !== '' ? ({
                                                                 Name:{$search:searchText}
                                                             }) : null}>
                                            <AutocompleteArrayInput suggestionLimit={10} fullWidth optionText="Name" optionValue="Id" classes={{
                                                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                            }}
                                             options={{
                                                 suggestionsContainerProps: {

                                                     zIndex:5000,
                                                     style:{ zIndex:5000}
                                                                        }
                                                   }}
                                            />

                                        </ReferenceArrayInput>

                                    </CardContent>
                                    <CardActions>
                                        <SaveButton type={"submit"} label="resources.pttusers.add"
                                                    disabled={submitting || pristine} saving={submitting}
                                                      handleSubmitWithRedirect={handleSubmit}/>
                                        <Button label="ra.action.cancel" onClick={handleDialogClose}>
                                            <IconCancel/>
                                        </Button>
                                    </CardActions>
                                </form>
                            </Card>)}


                    >


                    </Form>
                </DialogContent>

            </Dialog>
        </Fragment>
    );

}

/*
const mapStateToProps = state => ({
    isSubmitting: isSubmitting("add-user-to-channel")(state),
});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit,
};*/
export default AddUsersToChannelByChannelBtn
/*
export default connect(
    mapStateToProps,

)(AddUsersToChannelBtn);*/
