import React from "react";
import {
	Avatar,
	Box,
	Card,
	CardActions,
	CardHeader,
	CircularProgress,
	Divider,
	Typography,
	useMediaQuery
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import {ExchangePlugIcon} from "../shared/CustomIcons";
import {useReference, useTranslate} from "react-admin";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";
import PositionOrders from "./PositionOrders";
import LastPositionStatus from "./LastPositionStatus";
import PositionActions from "./PositionActions";

const styles = (theme) => ({
	iconButtonRoot: {},
	icon: {
		fontSize: "1.1em",
	},
	root: {
		display: "flex",
		flexDirection: "row",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			alignItems: "flex-start",
		},
		alignItems: "center",

		backgroundColor: "transparent",
	},
	divider: {
		width: 1,
		height: "auto",
		margin: theme.spacing(1),
		[theme.breakpoints.down("sm")]: {
			width: "auto",
			height: 1,
		},
	},
});
const PositionCard = (props) => {
	const { classes, signal = {}, record = {} } = props;
	const { positionType, tradetype, vendor, user, fundPercent, pair, leverage } = signal;
	const { exchangeAccount, opened, closed, stopLossed, canceled, status, minRequiredBalance, entryBalancePercent, fundAmountBase, fundAmountQuote } =
		record;
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const hasError = !status?.toString()?.endsWith("0");
	const translate = useTranslate();
	const {
		loading: xaLoading,
		loaded: xaLoaded,
		referenceRecord: exchangeAccountObject,
	} = useReference({ id: exchangeAccount, reference: "exchangeaccounts" });
	const getPairQuote = () => {
		if (pair?.quote === "USDT") {
			return "$";
		}
		return pair?.quote;
	};
	return (
		<Card className={classes.root} elevation={0} style={{ opacity: !signal?.closed && record?.closed ? 0.7 : 1 }}>
			<Tooltip enterDelay={0} enterTouchDelay={0} leaveTouchDelay={3000} title={<LastPositionStatus record={record} />}>
				<CardHeader
					style={{ minWidth: 200 }}
					action={isSmall && <PositionActions position={record} signal={signal} pair={pair} exchangeAccountObject={exchangeAccountObject} />}
					avatar={
						<Avatar aria-label="recipe" className={classes.avatar}>
							{xaLoading && <CircularProgress size={24} />}
							{xaLoaded && !xaLoading && !!hasError && <WarningTwoToneIcon color={"error"} />}
							{xaLoaded && !xaLoading && !hasError && <ExchangePlugIcon color={!!opened ? "primary" : "default"} />}
						</Avatar>
					}
					title={xaLoaded && exchangeAccountObject?.name}
					subheader={
						xaLoaded && (
							<Box>
								Fund:{" "}
								<Typography color={"textPrimary"} component={"span"} variant={"caption"}>
									{!!fundAmountQuote && (
										<span>
											{tradetype === 1 && (
												<span>{(fundAmountQuote / leverage).toFixed(5) + getPairQuote() + " x " + leverage + " = "}</span>
											)}
											{fundAmountQuote.toFixed(5) + getPairQuote()}
										</span>
									)}
									{!fundAmountQuote && (
										<span>{!!vendor ? (fundPercent ? fundPercent : exchangeAccountObject?.copyTradePercent) : entryBalancePercent}%</span>
									)}
								</Typography>
							</Box>
						)
					}
				/>
			</Tooltip>
			{!isSmall && <Divider orientation={isSmall ? "vertical" : "horizontal"} flexItem={true} className={classes.divider} />}
			{xaLoaded && <PositionOrders signal={signal} position={record} exchangeAccountObject={exchangeAccountObject} />}

			<Box flexGrow={1} height={isSmall && 2} />
			{isSmall && <Divider orientation={isSmall ? "vertical" : "horizontal"} flexItem={true} className={classes.divider} />}
			{!isSmall && (
				<CardActions>
					<PositionActions position={record} signal={signal} pair={pair} exchangeAccountObject={exchangeAccountObject} />
				</CardActions>
			)}
		</Card>
	);
};
export default withStyles(styles, { name: "SignalActions" })(PositionCard);
