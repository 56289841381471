import React from "react";
import {SelectInput, useTranslate} from "react-admin";
import {useDispatch, useSelector} from "react-redux";
import {AUTO_THEME_ID} from "../consts";
import themes from "../themes";

import {changeTheme} from "../actions";

const helpKey = "_help";

export const SelectTheme = (props) => {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const currentTheme = useSelector((state) => state.theme);
	const themeChoices = [
		{
			id: AUTO_THEME_ID,
			name: "Auto",
		},
	];
	themeChoices.push(
		...Object.keys(themes).map((key) => {
			return { id: key, name: themes[key].themeName };
		})
	);

	return (
		<SelectInput
			{...props}
			source="theme"
			label={translate("pos.theme.name")}
			defaultValue={currentTheme}
			translateChoice={false}
			choices={themeChoices}
			onChange={(event) => {
				dispatch(changeTheme(event.target.value));
			}}
		/>
	);
};
