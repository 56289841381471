import React, {Fragment, useEffect, useState} from 'react';

import {maxValue, minValue, useInput, useTranslate} from "react-admin";

import {useForm, useFormState} from 'react-final-form';
import {ThemedSlider} from '../../shared/ThemedSlider';
import {makeStyles} from '@material-ui/core/styles';
import TextFieldMui from '@material-ui/core/TextField';
import {Box, Typography, withStyles} from "@material-ui/core";
import {darken, lighten} from '@material-ui/core/styles/colorManipulator';
import {rawProfitInPercent} from '../SignalCalculations';

const StopLossSlider = withStyles(theme =>({

    thumb: {

        backgroundColor: theme.palette.error.main

    },
    track: {
        display: 'block',
        position: 'absolute',
        height: 2,
        borderRadius: 1,
        backgroundColor: theme.palette.error.main,
        '$vertical &': {
            width: 2,
        },
    },
    rail: {
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: 2,
        borderRadius: 1,
        backgroundColor: 'red',
        opacity: 0.38,
        '$vertical &': {
            height: '100%',
            width: 2,
        },
    },
    /* Styles applied to the track element if `track={false}`. */
    trackFalse: {
        '& $track': {
            display: 'none',
        },
    },
    /* Styles applied to the track element if `track="inverted"`. */
    trackInverted: {
        '& $track': {
            backgroundColor:
            // Same logic as the LinearProgress track color
                theme.palette.type === 'light'
                    ? lighten(theme.palette.error.main, 0.62)
                    : darken(theme.palette.error.main, 0.5),
        },
        '& $rail': {
            opacity: 1,
        },
    },

}))(ThemedSlider);

const useStyles = makeStyles(theme=>({

    formInput: {
        //    maxWidth:'100%'
        width: '100%',
        maxWidth: "500px"
        //  minWidth:'250px'
    },
    withSliderForm: {

        width: '100%',
        maxWidth: "500px",
        marginTop: 5,

    },
    margin: {
        height: theme.spacing(5),
    },
    seperate:{
        height: theme.spacing(2),
    },
    lossPercent :{
        marginTop:theme.spacing(3),
        color:theme.palette.error.light
    }

}));
const StopLossForm = (props) =>{
    const [stopLoss,setStopLoss] = useState(0);


    const [canInit,setCanBeInit] = useState(false);
    const [defualtSeted,setDefaultSeted] = useState(false);
    const translate = useTranslate();
    const {values} = useFormState();
    const form = useForm();
    const {pairObject,entryPrice,positionType,stopLoss : stopLossInForm,activeStep,markettradetype,leverage:leverageInForm,entryReset} = values;
    const [leverage,setLeverage] = useState(leverageInForm?leverageInForm : 3);
    const classes = useStyles();

    const getRealMin = ()=>{
        if(!canInit || !pairObject) return 0;
        if(positionType === "buy"){
            return pairObject.tickSize

        }
        else{
            return Number((entryPrice.high  + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
        }

    }
    const  getRealMax = ()=>{
        if(!canInit || !pairObject ) return 100;
        if(positionType === "sell"){
            return pairObject.latestPrice * 1000;

        }
        else{
            return Number((entryPrice.low  - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
        }
    }
    const getMarks = ()=>{
        if(!canInit  || !pairObject){
            return false;
        }
        return [{value:positionType === "buy" ? entryPrice.low : entryPrice.high,
            label: translate("resources.signalalerts.entry")}]
    }
    const liquidationValidation = (value, allValues) => {
        if (value && allValues && stopLoss && allValues.stopLoss && allValues.stopLoss.valuenumber !== undefined &&  markettradetype ===1) {
            if(((Math.abs(getLossBasedOnEntryHigh()*leverage) >= 100) || (Math.abs(getLossBasedOnEntryLow()*leverage) >= 100))){
                return  {message:'Liquidation Possibility'};
            }
        }

        return null;
    };
    let stopLossInput = useInput({name:"stopLoss.valuenumber",source:"stopLoss.valuenumber",validate:[ minValue(getRealMin()),maxValue(getRealMax()),liquidationValidation]});

    useEffect(()=>{
        if(stopLossInForm && stopLossInForm.valuenumber){

            setStopLoss(stopLossInForm.valuenumber)

        }
    },[stopLossInForm])
    useEffect(()=>{
        if(stopLoss && canInit && !!pairObject){
            setTimeout(()=>{
                form.resetFieldState("stopLoss.valuenumber");
                form.blur('stopLoss.valuenumber')

            },10)
        }
    },[stopLoss])

    useEffect(() => {
        //  console.log('useeffect1')
        if(canInit && !!pairObject){
            form.resetFieldState("stopLoss.valuenumber");
            form.blur("stopLoss.valuenumber");
            //setCanBeInit(true);


            let calculatedStopLossDefault = 0;
            if(positionType === "buy"){
                calculatedStopLossDefault = Number((entryPrice.low - (entryPrice.low  * 0.02)).toFixed(countDecimals(pairObject.tickSize)))

            }
            else{
                calculatedStopLossDefault = Number((entryPrice.high  + (entryPrice.high  * 0.02)).toFixed(countDecimals(pairObject.tickSize)))

            }
            setStopLoss(calculatedStopLossDefault);
            form.change("stopLoss.valuenumber",calculatedStopLossDefault)
            form.resetFieldState("stopLoss.valuenumber");
            form.blur("stopLoss.valuenumber");
            form.change('leverage',leverage)
            setDefaultSeted(true)

        }



    }, [positionType,pairObject,canInit,entryReset]);
    /*  useEffect(()=>{
         /!* setDefaultSeted(false)


          form.change("stopLoss",null)
          form.change("stopLoss.valuenumber",null)*!/
      },[positionType,pairObject])*/
    useEffect(()=>{
        if(pairObject && entryPrice && positionType){
            setCanBeInit(true);
        }
        else{
            setCanBeInit(false);
        }
    },[positionType,pairObject,entryPrice,entryReset])
    const handleFocus= (inputComponent)=>{

        inputComponent.input.onFocus()
    }
    const getMin = ()=>{
        if(!canInit  || !pairObject) return 0;
        if(positionType === "buy"){
            const idealMin = pairObject.latestPrice - (pairObject.latestPrice * 0.2);
            if(stopLoss < idealMin + (idealMin * 0.1)){
                return stopLoss - (stopLoss * 0.1);
            }
            return idealMin;

        }
        else{
            return getRealMin();
        }

    }
    const getMax = ()=>{
        if(!canInit || !pairObject) return 100;
        if(positionType === "sell"){
            const idealMax = pairObject.latestPrice +  (pairObject.latestPrice * 0.2) ;
            if(stopLoss > idealMax - (idealMax * 0.1)){
                return stopLoss + (stopLoss * 0.1);
            }
            return idealMax;

        }
        else{
            return getRealMax();
        }
    }
    const handleBlurStopLoss = ()=>{
        if(canInit && !!pairObject){
            const inputedVal = stopLoss;
            if(positionType === 'buy' && stopLoss >= entryPrice.low){
                setStopLoss(getRealMax());
                stopLossInput.input.onChange(Number(getRealMax()))

            }
            else if(positionType === 'sell' && stopLoss <= entryPrice.high){
                setStopLoss(getRealMin());
                stopLossInput.input.onChange(Number(getRealMin()))
            }

        }
        setTimeout(()=>{
            form.blur('stopLoss.valuenumber')
        },100)

    }
    const handleInputChangeStopLoss = (event) => {

        form.change('stopLoss.valuenumber',Number(event.target.value))
        stopLossInput.input.onChange(Number(event.target.value))
        setStopLoss(event.target.value === '' ? 0 : Number(event.target.value));


    };
    const handleChangeSlider = (event, newValue) => {
        setStopLoss(newValue);

    };

    const handleChangeSliderCommited = (event, newValue) => {
        setStopLoss(newValue);
        stopLossInput.input.onChange(newValue)

    };
    const handleChangeSliderLeverage = (event, newValue) => {
        setLeverage(newValue);

    };

    const handleChangeSliderCommitedLeverage = (event, newValue) => {
        setLeverage(newValue);
        form.change('leverage',newValue)

    };

    const getTrackType = ()=>{
        if(canInit && !!pairObject)
        {

            return positionType === "buy" ? "inverted": "normal"
        }
        return "normal"
    }
    const getLossBasedOnEntryAvg = ()=>{
        if(!canInit  || !pairObject){
            return 0;
        }
        let avrg = Number(((entryPrice.low + entryPrice.high) / 2).toFixed(countDecimals(pairObject.tickSize)));
        let lossPercent =  rawProfitInPercent(avrg,stopLoss,positionType)

        return lossPercent;
    }
    const getLossBasedOnEntryLow = ()=>{
        if(!canInit  || !pairObject){
            return 0;
        }
        let low = Number((entryPrice.low).toFixed(countDecimals(pairObject.tickSize)));
        let lossPercent =  rawProfitInPercent(low,stopLoss,positionType)

        return lossPercent;
    }
    const getLossBasedOnEntryHigh = ()=>{
        if(!canInit  || !pairObject){
            return 0;
        }
        let high = Number((entryPrice.high).toFixed(countDecimals(pairObject.tickSize)));
        let lossPercent =  rawProfitInPercent(high,stopLoss,positionType)

        return lossPercent;
    }
    return (

        <div className={"   no-cursor"}  >
            <div style={{display:'none'}} >
                <Typography id="enterPricesliderLabel" gutterBottom className={classes.formInput}>
                    {translate('resources.signalalerts.stopLossHint')}
                </Typography>
                <div className={classes.margin} />
                <StopLossSlider
                    value={stopLoss}
                    onChange={handleChangeSlider}
                    className={classes.formInput}
                    getAriaLabel={()=>translate("resources.signalalerts.stopLoss")}
                    step={pairObject ? pairObject.tickSize : 1}
                    min={getMin()}
                    max = {getMax()}
                    track={getTrackType()}
                    marks={getMarks()}
                    // marks={pairObject ? [{value:pairObject.latestPrice,label:pairObject.symbol + "\n" + pairObject.latestPrice}] : true}
                    //getAriaValueText={getEnterPriceStopLossSliderText}

                    // max={enterPriceEnd * 2}
                    valueLabelDisplay="on"
                    onChangeCommitted={handleChangeSliderCommited}

                    //valueLabelFormat={getEnterPriceStopLossSliderText}


                    //getAriaValueText={valuetext}
                />
                <div className={classes.margin} />
                <div className={classes.seperate} />
                <TextFieldMui
                    id="stopLoss.valuenumber"
                    label={translate("resources.signalalerts.stopLoss")}
                    type="number"

                    value={stopLoss}
                    variant="filled"
                    onChange={handleInputChangeStopLoss}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{ step: pairObject ? pairObject.tickSize.toFixed(10) : 1,
                        max:getRealMax(),min:getRealMin()
                    }}
                    onBlur={handleBlurStopLoss}
                    onFocus={()=>handleFocus(stopLossInput)}
                    className={classes.formInput}
                    name={"stopLoss.valuenumber"}
                    error={!!(stopLossInput.meta.touched && stopLossInput.meta.error)}
                    helperText={stopLossInput.meta.touched && stopLossInput.meta.error ? translate(stopLossInput.meta.error.message,stopLossInput.meta.error.args) :""}

                />
                <div className={classes.lossPercent} >
                    {translate("resources.vendorsignals.loss",{loss:"("+getLossBasedOnEntryLow() + " ~ " + getLossBasedOnEntryHigh()+")"})}
                    {markettradetype === 1 &&
                    <span>
                        <span>{"    x " + leverage  + " = " +(getLossBasedOnEntryLow()*leverage).toFixed(2) + "%" + " ~ " +(getLossBasedOnEntryHigh()*leverage).toFixed(2) + "%" }</span>

                    </span>

                    }

                </div>
            </div>
            {markettradetype ===1 &&
            <Fragment>

                <div className={classes.seperate} />

                <Box marginRight={2} marginLeft={2} marginBottom={1}>
                    <ThemedSlider
                        value={leverage}
                        onChange={handleChangeSliderLeverage}

                        getAriaLabel={()=>translate("resources.vendorsignals.leverage")}
                        step={1}
                        min={1}
                        max = {25}
                        //  marks={[{value:5,label:'5x'},{value:7,label:'7x'},{value:10,label:'10x'},{value:15,label:'15x'}]}
                        //   getAriaValueText={(val)=>val + "x"}

                        // max={enterPriceEnd * 2}
                        valueLabelDisplay="on"
                        onChangeCommitted={handleChangeSliderCommitedLeverage}

                        valueLabelFormat={(val)=>val + "x"}


                        //getAriaValueText={valuetext}
                    />
                </Box>
            </Fragment>}

        </div>

    )
}
var countDecimals = function(value) {
    let text = value.toString()
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
        let [base, trail] = text.split('e-');
        let deg = parseInt(trail, 10);
        return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
        return value.toString().split(".")[1].length || 0;
    }
    return 0;
}
export default StopLossForm;