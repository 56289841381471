import React from "react";
import {
    DeleteButton,
    Edit,
    FormTab,
    Loading,
    SaveButton,
    TabbedForm,
    TabbedFormTabs,
    Toolbar,
    useGetOne,
    useLocale,
    useNotify,
    useRedirect,
    useRefresh,
    useTranslate,
} from "react-admin";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import SignalTimeLine from "../SignalAlertTimeLine";

import EntryPriceEdit from "./EntryPriceEdit";
import StopLossEdit from "./StopLossEdit";
import TargetPointsEdit from "./TargetPointsEdit";
import PercentsEdit from "./PercentsEdit";
import SignalInformationEdit from "./SignalInformationEdit";

import Grid from "@material-ui/core/Grid";
import PositionsCards from "../../batchsignals/PositionsCards";

export const styles = {
	// formInput:{flexDirection:"column",display:"flex",maxWidth:500 },
	//normalfield: {width: 544},
	//fullWidth: {width: '100% !important'},
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	toolbar: { justifyContent: "space-between" },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};
const useStyles = makeStyles(styles);
const Title = ({ record }) => {
	const translate = useTranslate();
	return <span> {translate("resources.signalalerts.edittitle") + " : " + record.name}</span>;
};

const FormToolbar = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	return (
		<Toolbar {...props} className={classes.toolbar}>
			{!props.record.triggered && <SaveButton disabled={props.record.triggered} />}
			{props.record.triggered && <Typography variant="caption">{translate("resources.alerts.cantedittriggred")}</Typography>}
			<DeleteButton />
		</Toolbar>
	);
};
const GriddedComponents = ({ record, pairObject, ...other }) => {
	return (
		<Grid container spacing={1}>
			<Grid item sm={12} xs={12}>
				<SignalInformationEdit record={record} pairObject={pairObject} {...other} />
			</Grid>
			<Grid item sm={12} md={6} xs={12}>
				<EntryPriceEdit record={record} pairObject={pairObject} />
			</Grid>
			<Grid item sm={12} md={6} xs={12}>
				<StopLossEdit record={record} pairObject={pairObject} />
			</Grid>
			<Grid item sm={12} md={12} xs={12}>
				<TargetPointsEdit record={record} pairObject={pairObject} />
			</Grid>
			<Grid item sm={12} md={12} xs={12}>
				<PercentsEdit record={record} pairObject={pairObject} />
			</Grid>
		</Grid>
	);
};
const EditInnerForm = (props) => {
	const { record } = props;
	const { data, loading, error } = useGetOne("pairs", record.pair);
	let pairObject = data;

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <p>ERROR</p>;
	}
	return (
		<TabbedForm tabs={<TabbedFormTabs scrollable={false} />} {...props} redirect={false}>
			<FormTab label="main">
				<GriddedComponents pairObject={pairObject} />
			</FormTab>
			<FormTab label="resources.signalalerts.detailstimeline">
				<SignalTimeLine />
			</FormTab>
			<FormTab label="Positions">
				<PositionsCards />
			</FormTab>
		</TabbedForm>
	);
};
const SignalAlertsEdit = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	//console.log(record)
	const translate = useTranslate();
	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();

	const onFailure = (error) => {
		notify("resources.signalalerts.editErrorConflict", "warning", { message: error.message });

		refresh();
	};

	return (
		<Edit {...props} onFailure={onFailure} title={<Title />} undoable={false}>
			<EditInnerForm />
		</Edit>
	);
};

export default SignalAlertsEdit;
