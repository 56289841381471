import React from "react";
import {
	AutocompleteInput,
	BooleanField,
	BooleanInput,
	DateField,
	Filter,
	List,
	ReferenceField,
	ReferenceInput,
	SelectField,
	SelectInput,
	TextField,
	useLocale,
	useTranslate,
} from "react-admin";
import NameWithAvatarField from "../shared/NameWithAvatarField";
import CustomizableDatagrid from "ra-customizable-datagrid";
import {ConditionTypes} from "./AlertsEnum";
import MarketAvatarField from "../shared/MarketAvatarField";

const StatusFieldColored = ({ record }) => (
	<TextField source="name" record={record} style={{ color: record.color ? record.color : "unset" }} />
);
const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<ReferenceInput
				alwaysOn
				label={"resources.observes.pair"}
				source="pair"
				perPage={10}
				reference="pairs"
				allowEmpty={true}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								symbol: { $search: searchText },
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={"symbol"}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.pairs.allpairs"}
					alwaysOn
				/>
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				label={"resources.signalalerts.client"}
				source="user"
				perPage={10}
				reference="users"
				allowEmpty={true}
				filter={{
					usertype: { $ne: "ADMIN" },
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={(record) =>
						record && record.firstName
							? record.firstName + " " + record.lastName
							: translate("resources.observes.allusers")
					}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				label={"resources.observes.client"}
				source="user"
				perPage={10}
				reference="users"
				allowEmpty={true}
				filter={{ usertype: { $ne: "ADMIN" } }}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={"name"}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
			<SelectInput
				source="condition.conditionType"
				label="resources.observes.conditionType"
				choices={ConditionTypes}
				allowEmpty={true}
				nullable
			/>
			<BooleanInput label="resources.observes.triggered" source="triggered" nullable />
		</Filter>
	);
};
const SimpleArrayField = ({ record, source }) => (
	<div>
		{record[source].map((item, index) => (
			<span key={index}>{item}</span>
		))}
	</div>
);

const PttChannelsListItems = (props) => {
	const locale = useLocale();

	return (
		<CustomizableDatagrid {...props}>
			<BooleanField source="triggered" label="resources.observes.triggered" />

			<ReferenceField source="market" label={"resources.alerts.market"} reference="markets" link={false}>
				<MarketAvatarField />
			</ReferenceField>
			<ReferenceField source="pair" label={"resources.candles.pair"} reference="pairs" link={false}>
				<TextField source="symbol" />
			</ReferenceField>

			<ReferenceField source="user" label={"resources.observes.client"} reference="users|usertype|CLIENT">
				<NameWithAvatarField />
			</ReferenceField>

			<SelectField
				source="condition.conditionType"
				label="resources.observes.conditionType"
				choices={ConditionTypes}
			/>
			<TextField source="condition.args" label="resources.observes.conditionValue" />
			<SimpleArrayField source="notiftypes" link={false} />
			<TextField source="desc" label="resources.alerts.desc" />
			<DateField source="createdAt" label="resources.observes.createdAt" locales={locale} showTime />
			<DateField source="triggeredAt" label="resources.observes.triggeredAt" locales={locale} showTime />

			<BooleanField source="disabled" label="resources.observes.disabled" />
			<BooleanField source="deleted" label="resources.observes.deleted" />
			{/*<EditButton/>*/}
		</CustomizableDatagrid>
	);
};
const AlertsList = (props) => {
	const translate = useTranslate();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.observes.listtitle")}
			perPage={10}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			bulkActionButtons={false}
		>
			<PttChannelsListItems />
		</List>
	);
};

export { PttChannelsListItems, AlertsList };
