export default {
	themeName: "dealerifyDark",
	typography: {
		fontFamily: "'Nunito' ,system-ui, 'Helvetica Neue', Helvetica, Arial",
		/*h6: {
			fontSize: "0.9rem",
			fontWeight: "bold",
		},*/
	},
	palette: {
		primary: {
			light: "#31ffb5",
			dark: "#117c53",
			main: "#2ae8aa",
			contrastText: "#fff",
		},
		secondary: {
			light: "#7175d8",
			dark: "#b24800",
			main: "#f96115",
			contrastText: "#fff",
		},
		success: {
			main: "#7ed30f",
		},
		error: {
			main: "#ec0046",
		},
		warning: {
			main: "#ea5f00",
		},
		type: "dark",
	},
	overrides: {
		MuiCardHeader: {
			title: {
				fontSize: "1.05rem",
			},
		},
		MuiFormGroup: {
			root: {
				color: "white",
			},
		},
		MuiTypography: {
			button: {
				textTransform: "none",
			},
		},
		MuiButton: {
			root: {
				borderRadius: 22,
				textTransform: "none",
			},
		},
		NDLogin: {
			systemNameLink: {
				color: "#fff",
			},
			welcome: {
				color: "#eee",
			},
		},
		RaMenuItemLink: {
			active: {
				color: "#2ae8aa",
				fontWeight: "500",
			},
		},
		MuiListItemIcon: {
			root: {
				color: "inherit",
			},
		},

		RaDatagrid: {
			table: {
				backgroundColor: "#282828",
				borderColor: "transparent",
				borderRadius: 5,
			},
			tbody: { borderColor: "transparent" },
			rowCell: {
				borderBottom: "none",
			},
			expandIconCell: {
				borderBottom: "none",
				backgroundColor: "#232323",
			},
			expandIcon: {
				padding: 1,
			},
			row: {
				borderTop: "1px solid #232323",
			},
			headerCell: {
				lineHeight: 1,
				color: "#666666",
				backgroundColor: "#232323",
				borderBottom: "none",
				textAlign: "inherit",
				fontSize: ".8em",
			},
		},
		MuiCssBaseline: {
			"@global": {
				"*": {
					"scrollbar-width": "thin",
				},
				"*::-webkit-scrollbar": {
					width: "7px",
					height: "7px",
					background: "rgba(55,55,55,0.62)",
					borderRadius: 5,
				},
				"*::-webkit-scrollbar-thumb": {
					background: "rgba(167,167,167,0.7)",
					borderRadius: 5,
				},
				"*::-webkit-scrollbar-thumb:hover": {
					background: "#2ae8aa",
					borderRadius: 5,
				},
			},
		},
	},
};
