import React, {useState} from "react";
/*import { connect } from "react-redux";
import { change, submit, isSubmitting } from "redux-form";*/
import {useDataProvider, useNotify, useQueryWithStore, useRefresh} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import {Button, Checkbox, DialogActions, FormControlLabel} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles/index";
import {getUnrelizedProfit} from "./ProfitField";
import Typography from "@material-ui/core/Typography";
import PositionProfitField from "./PositionProfit";

export const styles = (theme) => ({
	profitRoot: {
		width: 150,
		display: "inline-flex",
		textAlign: "center",
		fontSize: "1.1em",
	},
	profitZero: {
		fontWeight: "bold",
		fontSize: "1.1em",
		backgroundColor: "#1c1c1c",
		borderRadius: 24,
		padding: 12,
	},
	profitGreen: {
		fontWeight: "bold",
		fontSize: "1.1em",
		color: theme.palette.success.main,
		backgroundColor: "#1c1c1c",
		borderRadius: 24,
		padding: 12,
	},
	profitRed: {
		fontWeight: "bold",
		fontSize: "1.1em",
		color: theme.palette.warning.main,
		backgroundColor: "#1c1c1c",
		borderRadius: 24,
		padding: 12,
	},
});
const useStyles = makeStyles(styles);

const ClosePositionDialog = (props) => {
	const classes = useStyles();
	const { signal, openDialog, setOpenDialog, pair = {}, position = {}, exchangeAccountObject = {} } = props;

	const { _id, positionType, closed, opened, tradetype, vendor, name, leverage } = signal;

	const [checked, setChecked] = useState(false);
	const [loading, setLoading] = useState(false);
	const handleDialogClose = () => setOpenDialog(false);

	const notify = useNotify();
	const refresh = useRefresh();
	const dataProvider = useDataProvider();
	const resourceLink = !!vendor ? (tradetype ? "atufuturesorders" : "atspotorders") : tradetype ? "clufuturesorders" : "clspotorders";
	const filterPositionService = !!vendor
		? tradetype
			? "atuFuturesPosition"
			: "atSpotPosition"
		: tradetype
		? "cluFuturesPosition"
		: "clSpotPosition";
	let filter = {};
	filter[filterPositionService] = position?._id;
	const { loaded, error, data, total, refetch } = useQueryWithStore({
		type: "getList",
		resource: resourceLink,
		payload: { pagination: { page: 0, perPage: 20 }, sort: { field: "createdAt", order: "DESC" }, filter: filter },
	});
	const getRealizedPnl = () => {
		const dataArray = data;

		if (!opened || !position?.opened) {
			return 0;
		}
		const entryOrder = dataArray?.find((order) => order.orderRole === "Entry");
		if (!entryOrder || !entryOrder?.orderId) {
			return 0;
		}

		let entryAmount = entryOrder?.quantity ? entryOrder?.quantity * entryOrder?.pairLatestPrice : entryOrder?.quoteOrderQty;
		if (tradetype === 1) {
			entryAmount /= leverage;
		}
		const placedTargetPoints = dataArray.filter((order) => order.orderId && order.orderRole === "TargetPoint");

		const slicedSignalTargetPoints = signal?.targetPoints?.slice(0, placedTargetPoints?.length);

		const replacedTargetPoints = slicedSignalTargetPoints.map((tp, index) => {
			return { ...tp, percent: placedTargetPoints[index].balancePercent };
		});
		let signalRecord = signal;
		if (replacedTargetPoints && replacedTargetPoints?.length) {
			signalRecord = { ...signal, targetPoints: replacedTargetPoints };
		}

		const unRealizedPnlPercent = getUnrelizedProfit(signalRecord);

		const pnl = entryAmount * (unRealizedPnlPercent / 100);
		return pnl.toFixed(2);
	};
	const handleCloseSignal = async () => {
		setLoading(true);
		return dataProvider
			.create("externaltools", {
				data: {
					action: "requestClosePosition",
					positionid: position?._id,
					servicename: filterPositionService.toLowerCase() + "s",
				},
			})
			.then(() => {
				refresh();
				notify("Position closed.");
			})
			.catch((error) => notify(`Error: ${error.message}`, "warning"))
			.finally(() => {
				setLoading(false);
				setOpenDialog(false);
			});
	};

	return (
		<Dialog fullWidth open={openDialog} onClose={handleDialogClose}>
			<DialogTitle>{"You are about to close position: " + exchangeAccountObject?.name + "@" + name}</DialogTitle>
			<DialogContent>
				Your estimated PnL is <PositionProfitField profit={getRealizedPnl()} pair={pair} />
				<Typography variant={"subtitle2"} color={"textSecondary"}>
					Closing this position will NOT close the related signal and other related positions!
				</Typography>
				<FormControlLabel
					control={<Checkbox color={"primary"} checked={checked} onChange={(event) => setChecked(event?.target?.checked)} />}
					label="I know what I am doing."
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseSignal} disabled={!checked || loading} color={"secondary"}>
					Close it now (market price)
				</Button>
				<Button onClick={() => setOpenDialog(false)} color={"default"}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ClosePositionDialog;
