import React, {Fragment, useState} from "react";
/*import { connect } from "react-redux";
import { change, submit, isSubmitting } from "redux-form";*/
import {Button, UPDATE, useNotify, useRedirect, useRefresh, useTranslate, useUnselectAll} from "react-admin";
import IconContentEdit from "@material-ui/icons/Edit";
import IconCancel from "@material-ui/icons/Cancel";
import IconSave from "@material-ui/icons/Save";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Form} from 'react-final-form'

import {Card, CardActions, CardContent} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles/index";

export const styles  = {
     btnRoot :{
         marginLeft:5
     }

};
const useStyles = makeStyles(styles);

const BulkEditBtn = (props)=> {
    const classes = useStyles();
    const {resource,field, label,inputComponent,idField} = props;

    const [showDialog,setShowDialog] = useState(false);


    const handleClick = () => setShowDialog(true);
    const handleDialogClose = () => setShowDialog(false);
    const redirect = useRedirect();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('pttusers');
    const handleSubmit = async  values => {
        let flattenValues = flatten(values);

        let val = flattenValues[field];
        if(val === undefined){
            notify("Please fill input", "error"); //todo :translate
            return;
        }
        if(!props.selectedIds && !props.data && !props.record){
            notify("Error", "error"); //todo :translate
            return;
        }
        let isList =  !!props.selectedIds;
        let target = isList ? props.selectedIds  : (props.data ?  props.data[idField] : props.record[idField])
        let _params = {};
        _params.id = isList ? null : target;
        //_params.data = {};
        _params.data = values;
        _params.query = {};
        if(isList){
            _params.query[idField] = target;
        }

      return  global.RC(UPDATE, resource,_params)
            .then(({data}) => {

           //    console.log(data)
                if(isList){
                    unselectAll();

                }

                refresh();
                notify(
                    'pos.actionDone',
                    'info',
                    {},
                    true
                );
                setShowDialog(false);
                if(props.afterSuccess){
                    props.afterSuccess();
                }
               return Promise.resolve()
            })
            .catch(error => {
              //  console.log(error)
                notify(error.message, "error");
            })

    };



        return (
            <Fragment>
                <Button className={classes.btnRoot} color="secondary"   variant={props.variant ? props.variant : "outlined"} onClick={handleClick} label={label}>
                    {!props.hideIcon &&
                    <IconContentEdit/>}
                </Button>
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={handleDialogClose}
                    aria-label={translate(label)}
                >
                    <DialogTitle>{translate(label)}</DialogTitle>
                    <DialogContent>
                        <Form

                            onSubmit={handleSubmit}

                            render={  ({handleSubmit, form, submitting, pristine, values }) =>

                                (<Card>
                                        <form>
                                            <CardContent>
                                                {inputComponent}
                                            </CardContent>
                                            <CardActions>

                                                <Button type={"submit"} label="pos.apply" disabled={submitting || pristine} saving={submitting} onClick={handleSubmit} >
                                                    <IconSave/>
                                                </Button>
                                                <Button label="ra.action.cancel" onClick={handleDialogClose} >
                                                    <IconCancel/>
                                                </Button>
                                            </CardActions>
                                        </form>
                                    </Card>)}


                        >


                        </Form>
                    </DialogContent>

                </Dialog>
            </Fragment>
        );

}
function flatten(object, prefix = '', stopKeys = []) {
    return Object.keys(object).reduce(
        (prev, element) => {
            const hasNextLevel = object[element]
                && typeof object[element] === 'object'
                && !Array.isArray(object[element])
                && !Object.keys(object[element]).some(key => stopKeys.includes(key));
            return hasNextLevel
                ? { ...prev, ...flatten(object[element], `${prefix}${element}.`, stopKeys) }
                : { ...prev, ...{ [`${prefix}${element}`]: object[element] } };
        },
        {},
    );
}
export default BulkEditBtn
