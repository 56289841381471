import React, {Fragment} from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    FunctionField,
    Labeled,
    Loading,
    NumberField,
    ReferenceField,
    ReferenceManyField,
    SelectField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    useGetOne,
    useLocale,
    useNotify,
    useRedirect,
    useRefresh,
    useTranslate,
} from "react-admin";
import {Box, Card, Typography, useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SignalTimeLine from "../vendorsignals/VendorSignalTimeLine";
import {spotOrderRoles} from "./ATSPositionsEnum";
import {JsonField} from "@bb-tech/ra-components";

import Grid from "@material-ui/core/Grid";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";
import {positionTypes} from "../signalalerts/SignalAlertsEnum";
import {PositionTypeRenderer} from "../signalalerts/wizard/SignalAlertsCreateWizard";

const useStyles = makeStyles((theme) => ({
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	toolbar: { justifyContent: "space-between" },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	unitTextBase: {
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.info.dark,
	},
	unitTextQuote: {
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.warning.dark,
	},
	percentText: {
		...theme.typography.caption,
	},
	positionTypeSelect: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	positionTypeavatarRoot: {
		width: 30,
		height: 30,
		marginRight: 5,
	},
	positionTypeAvatarError: {
		backgroundColor: theme.palette.error.main,
	},
	positionTypeAvatarSuccess: {
		backgroundColor: theme.palette.success.main,
	},
	positionStatusGood: {
		color: theme.palette.success.main,
	},
	positionStatusBad: {
		color: theme.palette.error.main,
	},
}));
const Title = ({ record }) => {
	const translate = useTranslate();
	return <span> {translate("resources.atspositions.showtitle")}</span>;
};

const GriddedComponents = ({ record, pairObject, ...other }) => {
	return (
		<Grid container spacing={1}>
			<Grid item sm={12} xs={12}>
				{/*<VendorSignalsSignalInformationShow record={record} pairObject={pairObject} {...other}/>*/}
			</Grid>
			<Grid item sm={12} xs={12}>
				<Card>
					<ReferenceField
						record={record}
						source="vendorSignal"
						label={"resources.atspositions.vendorSignal"}
						reference="vendorsignals"
						link={false}
						{...other}
					>
						<SignalTimeLine />
					</ReferenceField>
				</Card>
			</Grid>
		</Grid>
	);
};
const MinRequiredAsset = ({ record, pairObject }) => {
	const classes = useStyles();
	if (!record || !pairObject) {
		return <span />;
	}
	return (
		<Fragment>
			<NumberField
				source={record.byQuote ? "minRequiredQuoteQty" : "minRequiredQty"}
				record={record}
				options={{ maximumFractionDigits: 10 }}
			/>
			<span className={record.byQuote ? classes.unitTextQuote : classes.unitTextBase}>
				{" "}
				{record.byQuote ? pairObject.quoteAsset : pairObject.baseAsset}
			</span>
		</Fragment>
	);
};
const OrderAmount = ({ record, pairObject }) => {
	const classes = useStyles();
	if (!record || !pairObject) {
		return <span />;
	}
	return (
		<Fragment>
			<span className={classes.percentText}>({record.balancePercent}%) </span>
			<NumberField
				source={record.byQuote ? "quoteOrderQty" : "quantity"}
				record={record}
				options={{ maximumFractionDigits: 10 }}
			/>
			<span className={record.byQuote ? classes.unitTextQuote : classes.unitTextBase}>
				{" "}
				{record.byQuote ? pairObject.quoteAsset : pairObject.baseAsset}
			</span>
		</Fragment>
	);
};
const ExecutedQty = ({ record, pairObject }) => {
	const classes = useStyles();
	if (!record || !pairObject) {
		return <span />;
	}
	return (
		<Fragment>
			<NumberField source="executedQty" record={record} options={{ maximumFractionDigits: 10 }} />
			<span className={classes.unitTextBase}> {pairObject.baseAsset}</span>
		</Fragment>
	);
};
const CummulativeQuoteQty = ({ record, pairObject }) => {
	const classes = useStyles();
	if (!record || !pairObject) {
		return <span />;
	}
	return (
		<Fragment>
			<NumberField source="cummulativeQuoteQty" record={record} options={{ maximumFractionDigits: 10 }} />
			<span className={classes.unitTextQuote}> {pairObject.quoteAsset}</span>
		</Fragment>
	);
};

const AveragePrice = ({ record, pairObject }) => {
	const classes = useStyles();
	if (!record || !pairObject) {
		return <span />;
	}
	let obj = {};
	if (record.cummulativeQuoteQty && record.executedQty) {
		obj.avgPrice = record.cummulativeQuoteQty / record.executedQty;
	} else {
		obj.avgPrice = 0;
	}
	return (
		<Fragment>
			<NumberField source="avgPrice" record={obj} options={{ maximumFractionDigits: 10 }} />
			<span className={classes.unitTextQuote}> {pairObject.quoteAsset}</span>
		</Fragment>
	);
};

const LastStatus = ({ record }) => {
	const classes = useStyles();
	const translate = useTranslate();
	if (!record) {
		return <span />;
	}
	let statusStr = record.status.toString();
	if (statusStr.startsWith("3")) {
		let tpIndex = statusStr.charAt(1);

		return (
			<span className={statusStr.endsWith("0") ? classes.positionStatusGood : classes.positionStatusBad}>
				{" "}
				{translate("resources.atspositions.positionStatus.3i" + statusStr.charAt(statusStr.length - 1), {
					i: tpIndex,
				})}
			</span>
		);
	} else {
		return (
			<span className={statusStr.endsWith("0") ? classes.positionStatusGood : classes.positionStatusBad}>
				{translate("resources.atspositions.positionStatus." + record.status.toString())}
			</span>
		);
	}
};

const InnerShow = (props) => {
	const translate = useTranslate();
	const classes = useStyles();
	const locale = useLocale();
	const { record } = props;
	const { data, loading, error } = useGetOne("pairs", record.pair);
	let pairObject = data;
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <p>ERROR</p>;
	}
	return (
		<TabbedShowLayout {...props}>
			<Tab label="Information">
				<DateField source="createdAt" label="resources.alerts.createdAt" locales={locale} showTime />

				<ReferenceField source="market" label={"resources.alerts.market"} reference="markets" link={false}>
					<TextField source="name" />
				</ReferenceField>

				<ReferenceField source="pair" label={"resources.alerts.pair"} reference="pairs" link={false}>
					<TextField source="symbol" />
				</ReferenceField>
				<ReferenceField
					source="vendorSignal"
					label={"resources.atspositions.vendorSignal"}
					reference="vendorsignals"
					link={"show"}
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					source="vendorSignal"
					label={"resources.atspositions.vendorName"}
					reference="vendorsignals"
					link={false}
				>
					<ReferenceField
						source="vendor"
						label={"resources.vendorsubscriptions.vendor"}
						reference="users|usertype|VENDOR"
						link={false}
					>
						<NickNameWithAvatarField />
					</ReferenceField>
				</ReferenceField>
				<Labeled label={"resources.atspositions.lastStatus"}>
					<LastStatus />
				</Labeled>

				<ReferenceField
					source="exchangeAccount"
					label={"resources.atspositions.exchangeAccount"}
					reference="exchangeaccounts"
					link={false}
				>
					<TextField source="name" />
				</ReferenceField>

				<SelectField
					source="positionType"
					label="resources.signalalerts.positionType"
					choices={positionTypes}
					optionText={<PositionTypeRenderer classes={classes} />}
				/>
				<BooleanField source="opened" />
				<DateField source="openedAt" label="resources.atspositions.openedAt" locales={locale} showTime />

				<BooleanField source="closed" />
				<DateField source="closedAt" label="resources.atspositions.closedAt" locales={locale} showTime />

				<BooleanField source="stopLossed" label="resources.atspositions.stopLossed" />
				<DateField source="stopLossedAt" label="resources.atspositions.stopLossedAt" locales={locale} showTime />

				<BooleanField source="canceled" />
				<DateField source="canceledAt" locales={locale} showTime />

				<BooleanField source="fullTargeted" />
			</Tab>
			<Tab label="Orders">
				<ReferenceManyField reference="atspotorders" target="atSpotPosition" addLabel={false} perPage={100}>
					<Datagrid
						expand={({ id, record, resource }) => (
							<Box>
								<JsonField record={{ r: record }} source={"r"} />
							</Box>
						)}
					>
						<TextField source="orderId" />
						<DateField source="createdAt" showTime locales={locale} />
						<SelectField
							source="orderRole"
							label="resources.atspositions.orderRole"
							choices={spotOrderRoles}
							optionText={(rec) => <Box color={rec.color}>{rec.name}</Box>}
						/>

						<FunctionField
							label="resources.atspositions.side"
							render={(record) => (
								<Box style={{ textTransform: "capitalize" }} color={record.side === "buy" ? "green" : "red"}>
									{record.side}
								</Box>
							)}
						/>
						<NumberField
							source="baseAssetBalanceBefore"
							label={translate("resources.atspositions.baseAssetBalanceBefore", { base: pairObject.baseAsset })}
							options={{ maximumFractionDigits: 10 }}
						/>
						<NumberField
							source="quoteAssetBalanceBefore"
							label={translate("resources.atspositions.quoteAssetBalanceBefore", {
								quote: pairObject.quoteAsset,
							})}
							options={{ maximumFractionDigits: 10 }}
						/>
						<OrderAmount pairObject={pairObject} label={"resources.atspositions.orderAmount"} />

						<MinRequiredAsset pairObject={pairObject} label={"resources.atspositions.minRequiredAsset"} />

						<TextField source="status" />
						<ExecutedQty pairObject={pairObject} label={"resources.atspositions.executedQty"} />
						<CummulativeQuoteQty pairObject={pairObject} label={"resources.atspositions.cummulativeQuoteQty"} />
						<AveragePrice pairObject={pairObject} label={"resources.atspositions.averagePrice"} />

						<TextField source="orderType" />
					</Datagrid>
				</ReferenceManyField>
			</Tab>
			<Tab label="Details">
				<FunctionField
					label={"details"}
					render={(record) => (
						<Box>
							<JsonField record={{ r: record }} source={"r"} />
						</Box>
					)}
				/>
			</Tab>
			<Tab label="Signal TimeLine">
				<GriddedComponents pairObject={pairObject} {...props} />
			</Tab>
		</TabbedShowLayout>
	);
};
const ATSPositionsShow = (props) => {
	const classes = useStyles();
	const locale = useLocale();
	//console.log(record)
	const translate = useTranslate();
	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();

	return (
		<Show {...props} title={<Title />}>
			<InnerShow />
		</Show>
	);
};

const SectionTitle = ({ label }) => {
	const translate = useTranslate();

	return (
		<Typography variant="h6" gutterBottom gutterTop>
			{translate(label)}
		</Typography>
	);
};

export { ATSPositionsShow, LastStatus };
