import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import {
    BooleanField,
    Datagrid,
    DateField,
    ReferenceField,
    ReferenceManyField,
    ShowButton,
    TextField,
    useLocale,
    useTranslate,
} from "react-admin";

import {useMediaQuery} from "@material-ui/core";

import {LastStatus} from "../atspotpositions/ATSPositionsShow";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: "6px 16px",
	},
	paperSmall: {
		padding: "6px 6px",
	},

	secondaryTail: {
		backgroundColor: theme.palette.secondary.main,
	},
	topMarginDate: {
		marginTop: "10px",
		color: theme.palette.primary.light,
	},
	smallRoot: {
		padding: "6px 0px",
	},
	profitText: {
		color: theme.palette.success.light,
	},
	lossText: {
		color: theme.palette.error.light,
	},
	lossTextNotExtracted: {
		color: theme.palette.error.main,
	},
}));
const PositionsList = (props) => {
	const locale = useLocale();
	const classes = useStyles();

	return (
		<Datagrid optimized {...props}>
			<BooleanField source={"opened"} />
			<BooleanField source={"closed"} />
			<TextField source={"entryBalancePercent"} />
			<DateField source="createdAt" label="resources.alerts.createdAt" locales={locale} showTime />

			<LastStatus label={"resources.atspositions.lastStatus"} />

			<ReferenceField
				source="exchangeAccount"
				label={"resources.atspositions.exchangeAccount"}
				reference="exchangeaccounts"
			>
				<TextField source="name" />
			</ReferenceField>
			<TextField source={"error"} />
			<DateField source="updatedAt" showTime locales={locale} />

			<ShowButton label={"resources.alerts.manage"} />
		</Datagrid>
	);
};
const SignalPositionsExpand = ({ id, record, resource }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const locale = useLocale();
	const positionReference = record?.tradetype ? "clufuturespositions" : "clspotpositions";

	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

	return (
		<ReferenceManyField record={record} reference={positionReference} target="signalAlert">
			<PositionsList />
		</ReferenceManyField>
	);
};
export default SignalPositionsExpand;
