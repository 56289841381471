import {Collapse, List, ListItemIcon, MenuItem, Paper, Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import * as React from "react";
import {ReactElement, ReactNode} from "react";
import {ReduxState, useTranslate} from "react-admin";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
	containerChild: {
		border: "none",
		backgroundColor: "transparent",
	},
	containerMain: {
		marginBottom: theme.spacing(2),
		borderTop: "none",
		borderRight: "none",
		borderLeft: "none",
		marginTop: theme.spacing(1),
		backgroundColor: "rgba(100,100,100,.05)",
	},
	icon: { minWidth: theme.spacing(5) },
	sidebarIsOpen: {
		paddingLeft: theme.spacing(5),
		transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
	},
	sidebarIsClosed: {
		paddingLeft: 0,
		transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
	},
}));

interface Props {
	dense: boolean;
	handleToggle: () => void;
	icon: ReactElement;
	isOpen: boolean;
	name: string;
	children: ReactNode;
	hasPadding: boolean;
	isMain: boolean;
}

const SubMenu = (props: Props) => {
	const { handleToggle, isOpen, name, icon, children, dense, hasPadding, isMain } = props;
	const translate = useTranslate();
	const classes = useStyles();
	const sidebarIsOpen = useSelector<ReduxState, boolean>((state) => state.admin.ui.sidebarOpen);

	const header = (
		<MenuItem dense={dense} button onClick={handleToggle}>
			<ListItemIcon className={classes.icon}>
				{isOpen ? isMain ? <ExpandMore /> : <SubdirectoryArrowRightIcon /> : icon}
			</ListItemIcon>

			<Typography variant="inherit" color="textSecondary">
				{translate(name)}
			</Typography>
		</MenuItem>
	);

	return (
		<Paper className={isMain ? classes.containerMain : classes.containerChild} square>
			{sidebarIsOpen || isOpen ? (
				header
			) : (
				<Tooltip title={translate(name)} placement="right">
					{header}
				</Tooltip>
			)}
			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<List
					dense={dense}
					component="div"
					disablePadding
					className={hasPadding ? (sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed) : undefined}
				>
					{children}
				</List>
			</Collapse>
		</Paper>
	);
};

export default SubMenu;
