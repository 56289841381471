const usertypes = [
    {
        id : 1,
        name : "Super Admin"
    },
    {
        id : 0,
        name : "Normal Admin"
    }
];
export  {usertypes}
