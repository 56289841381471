import React from "react";
import {Create, ImageField, ImageInput, SaveButton, SimpleForm, Toolbar, useNotify, useTranslate} from "react-admin";
import {SendTwoTone} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {useForm} from "react-final-form";

const useStyles = makeStyles((theme) => ({}));

const FormToolbar = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	return (
		<Toolbar {...props} className={classes.toolbar}>
			<SaveButton icon={<SendTwoTone />} disabled={props.pristine} label={"Send"} />
		</Toolbar>
	);
};
const CDNCreate = (props) => {
	const translate = useTranslate();

	return (
		<Create {...props}>
			<SimpleForm redirect={false} enctype="multipart/form-data" toolbar={<FormToolbar />}>
				<FormContent />
			</SimpleForm>
		</Create>
	);
};
const FormContent = (props) => {
	const translate = useTranslate();
	const notify = useNotify();
	const form = useForm();
	const onFileRejected = (props) => {
		form.change("fileToUpload", undefined);
		notify("Invalid file selected or file exceed maximum size", "error");
	};
	return (
		<ImageInput
			autoFocus={true}
			placeholder={<p>{translate("resources.cdn.dropimages")}</p>}
			source="fileToUpload"
			minSize={512}
			maxSize={5 * 1024 * 1024}
			accept=".jpg,.png,.jpeg,.gif,.bmp,.webp,.mov,.mp4,.avi,.jfif,.pdf,.xls,.xlsx,.doc,.docx"
			options={{ onDropRejected: onFileRejected }}
			{...props}
		>
			<ImageField source="src" title="title" />
		</ImageInput>
	);
};
export default CDNCreate;
