import React from "react";
import {BooleanField, EditButton, Filter, List, NumberField, SearchInput, useLocale, useTranslate} from "react-admin";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";
import CustomizableDatagrid from "ra-customizable-datagrid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<SearchInput
				InputLabelProps={{ shrink: true }}
				source="firstName.$search"
				alwaysOn
				placeholder={translate("resources.vendors.firstName")}
			/>
			<SearchInput
				InputLabelProps={{ shrink: true }}
				source="lastName.$search"
				alwaysOn
				placeholder={translate("resources.vendors.lastName")}
			/>
			<SearchInput
				InputLabelProps={{ shrink: true }}
				source="nickName.$search"
				alwaysOn
				placeholder={translate("resources.vendors.nickName")}
			/>

			<SearchInput
				InputLabelProps={{ shrink: true }}
				source="phonenumber.$search"
				alwaysOn
				placeholder={translate("resources.clients.phonenumber")}
			/>
			<SearchInput
				InputLabelProps={{ shrink: true }}
				source="email.$search"
				alwaysOn
				placeholder={translate("resources.clients.email")}
			/>
		</Filter>
	);
};
const ServiceStatus = (record) => {
	const translate = useTranslate();
	if (record && record.service) {
		switch (record.service.status) {
			case -1:
				return translate("resources.clients.serviceNotInitiated");
			case 0:
				return translate("resources.clients.serviceExpired");
			case 1:
				return (
					` ${record.service.quantity} ` +
					translate("resources.clients.people") +
					" / " +
					getDaysRemaining(record.service.expiresAt) +
					" " +
					translate("resources.clients.remainingDays")
				);
		}
	}
	return null;
};

function getDaysRemaining(expiresAt) {
	const _start = new Date();
	const _expire = new Date(expiresAt);

	const result = Math.round((_expire - _start) / (1000 * 60 * 60 * 24));
	return result > 0 ? result : 0;
}

const listRowStyle = (record, index) => {
	if (!record) {
		return null;
	}
	return {
		backgroundImage: record.disabled
			? "repeating-linear-gradient(45deg, #EEEEEE,#EEEEEE 10px, #ffffff 10px, #ffffff 20px)"
			: "transparent",
		borderWidth: 2,
		// borderRightWidth:2,

		borderLeftStyle: "solid",
		borderRightStyle: "solid",
		borderLeftColor: record.allowedToWork ? "green" : "red",
		borderRightColor: record.active ? "blue" : "red",
	};
};
const NickNameWithToolTip = ({ record }) => {
	if (!record) {
		return <div />;
	}
	return (
		<Tooltip
			interactive
			title={
				<Typography variant={"caption"}>
					{record.firstName + " " + record.lastName + " " + record.phonenumber + " " + record.email}
				</Typography>
			}
		>
			<div>
				<NickNameWithAvatarField record={record} label={"resources.vendors.nickName"} />
			</div>
		</Tooltip>
	);
};

const VendorsList = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	return (
		<List
			{...props}
			filters={<ListFilter />}
			title={translate("resources.vendors.listtitle")}
			perPage={25}
			exporter={false}
			bulkActionButtons={false}
		>
			<CustomizableDatagrid optimized rowStyle={listRowStyle}>
				<NickNameWithToolTip />

				<NumberField source="totalSpot" label={"resources.vendors.totalSpotSignals"} />

				<NumberField source="lastWeekProfit" label={"resources.vendors.lastWeekProfitSpot"} />
				<NumberField source="lastMonthProfit" label={"resources.vendors.lastMonthProfitSpot"} />
				<NumberField source="totalProfit" label={"resources.vendors.totalProfitSpot"} />

				<NumberField source="totalFutures" label={"resources.vendors.totalFuturesSignals"} />

				<NumberField source="lastWeekProfitFutures" label={"resources.vendors.lastWeekProfitFutures"} />
				<NumberField source="lastMonthProfitFutures" label={"resources.vendors.lastMonthProfitFutures"} />
				<NumberField source="accounting.currentBalance" label={"resources.vendors.currentBalance"} />
				<NumberField source="accounting.totalIncome" label={"resources.vendors.totalIncome"} />
				<BooleanField source={"verified"} />
				<BooleanField source={"allowedToWork"} />
				<BooleanField source={"active"} />
				<EditButton />
			</CustomizableDatagrid>
		</List>
	);
};

export default VendorsList;
