import {makeApiRequest} from "./helpers.js";

const lastBarsCache = new Map();

const resMap = {
	1: 1,

	5: 5,
	15: 15,
	30: 30,
	60: 60,
	120: 120,
	240: 240,
	480: 480,
	720: 720,
	"1D": "D",
	"1W": "W",
};
const configurationData = {
	supports_marks: false,
	supports_time: true,
	supports_search: true,
	supports_group_request: false,
	supported_resolutions: ["1", "5", "15", "30", "60", "120", "240", "480", "720", "1D", "1W"],
	exchanges: [
		{
			value: "Kucoin Futures",
			name: "Kucoin Futures",
			desc: "Kucoin Futures",
		},
	],
	symbols_types: [
		{
			name: "crypto",
			value: "crypto",
		},
	],
};
let cachedSymbols = false;
function convertExponentialToDecimal(exponentialNumber) {
	// sanity check - is it exponential number
	const str = exponentialNumber.toString();
	if (str.indexOf("e") !== -1) {
		const exponent = parseInt(str.split("-")[1], 10);
		// Unfortunately I can not return 1e-8 as 0.00000001, because even if I call parseFloat() on it,
		// it will still return the exponential representation
		// So I have to use .toFixed()
		return exponentialNumber.toFixed(exponent);
	} else {
		return exponentialNumber;
	}
}
const countDecimals = function (value) {
	let _val = value < 1 ? convertExponentialToDecimal(value) : value;
	if (_val.toString().split(".").length < 2) {
		return 1;
	}
	if (Math.floor(_val) !== _val) return _val.toString().split(".")[1].length || 0;
	return 0;
};
async function getAllSymbols() {
	if (cachedSymbols) {
		return cachedSymbols;
	}
	const data = await makeApiRequest("/api/v1/contracts/active");
	let allSymbols = [];

	for (const exchange of configurationData.exchanges) {
		const pairs = data.data;
		allSymbols = pairs.map((pair) => {
			return {
				symbol: pair.symbol,
				full_name: pair.baseCurrency + pair.quoteCurrency,
				description:
					pair.baseCurrency === "XBT" ? pair.baseCurrency + "(BTC) / " + pair.quoteCurrency : pair.baseCurrency + " / " + pair.quoteCurrency,
				exchange: "Kucoin Futures",
				type: "crypto",
				baseCurrency: pair.baseCurrency,
				settleCurrency: pair.settleCurrency,
				quoteCurrency: pair.quoteCurrency,
				priceIncrement: Math.pow(10, countDecimals(!!pair.indexPriceTickSize ? pair.indexPriceTickSize : 0.001)),
			};
		});
		if (allSymbols && allSymbols.length) {
			allSymbols = allSymbols.filter((sym) => sym.settleCurrency === sym.quoteCurrency);
		}
	}
	cachedSymbols = allSymbols;
	return allSymbols;
}
const getBars = async (symbolInfo, resolution, periodParams, onResult, onError, firstDataRequest) => {
	const urlParameters = {
		symbol: symbolInfo.ticker,

		resolution: resMap[resolution],
	};
	if (!!periodParams?.from) {
		urlParameters.from = periodParams?.from;
	}
	if (!!periodParams?.to) {
		urlParameters.to = periodParams?.to;
	}

	const query = Object.keys(urlParameters)
		.map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
		.join("&");
	let data = false;
	try {
		data = await makeApiRequest(`https://futures.kucoin.com/_api/kumex-kline/history?${query}`);
		/*if ((data.Response && data.Response === "Error") || data.data.length === 0) {
            // "noData" should be set if there is no data in the requested period.
            onResult([], {
                noData: true,
            });
            return;
        }*/
	} catch (error) {
		console.log("[getBars]: Get error", error);
		onError(error);
	}
	if (!!data) {
		let bars = [];

		var nodata = !data.s || data.s !== "ok" || !data.c || !data.c.length;
		var barsCount = nodata ? 0 : data.c.length;
		console.log(barsCount);
		console.log(nodata);
		console.log(data);
		for (var i = 0; i < barsCount; i++) {
			var barValue = {
				time: data["t"][i] * 1000,
				open: data["o"][i],
				high: data["h"][i],
				low: data["l"][i],
				close: data["c"][i],

				volume: data["v"][i],
			};

			bars.push(barValue);
		}

		if (firstDataRequest) {
			lastBarsCache.set(symbolInfo.full_name, {
				...bars[bars.length - 1],
			});
		}
		if (onResult) {
			onResult(bars, {
				noData: nodata,
			});
		}

		return { bars: bars, noData: nodata };
	}
};
var DataPulseProvider = (function () {
	function DataPulseProvider(updateFrequency) {
		this._subscribers = {};
		this._requestsPending = 0;

		setInterval(this._updateData.bind(this), 10000);
	}
	DataPulseProvider.prototype.subscribeBars = function (symbolInfo, resolution, newDataCallback, listenerGuid) {
		if (this._subscribers.hasOwnProperty(listenerGuid)) {
			//console.log("DataPulseProvider: already has subscriber with id=" + listenerGuid);
			return;
		}
		this._subscribers[listenerGuid] = {
			lastBarTime: null,
			listener: newDataCallback,
			resolution: resolution,
			symbolInfo: symbolInfo,
		};
		//console.log("DataPulseProvider: subscribed for #" + listenerGuid + " - {" + symbolInfo.name + ", " + resolution + "}");
	};
	DataPulseProvider.prototype.unsubscribeBars = function (listenerGuid) {
		delete this._subscribers[listenerGuid];
		//console.log("DataPulseProvider: unsubscribed for #" + listenerGuid);
	};
	DataPulseProvider.prototype._updateData = function () {
		var _this = this;
		if (this._requestsPending > 0) {
			return;
		}
		this._requestsPending = 0;
		var _loop_1 = function (listenerGuid) {
			this_1._requestsPending += 1;
			this_1
				._updateDataForSubscriber(listenerGuid)
				.then(function () {
					_this._requestsPending -= 1;
					//	console.log("DataPulseProvider: data for #" + listenerGuid + " updated successfully, pending=" + _this._requestsPending);
				})
				.catch(function (reason) {
					_this._requestsPending -= 1;
					/*console.log(
						"DataPulseProvider: data for #" + listenerGuid + " updated with error=" + reason.message + ", pending=" + _this._requestsPending
					);*/
				});
		};
		var this_1 = this;
		for (var listenerGuid in this._subscribers) {
			_loop_1(listenerGuid);
		}
	};
	DataPulseProvider.prototype._updateDataForSubscriber = function (listenerGuid) {
		var _this = this;
		var subscriptionRecord = this._subscribers[listenerGuid];
		var rangeEndTime = parseInt((Date.now() / 1000).toString());
		// BEWARE: please note we really need 2 bars, not the only last one
		// see the explanation below. `10` is the `large enough` value to work around holidays
		var rangeStartTime = rangeEndTime - periodLengthSeconds(subscriptionRecord.resolution, 10);

		return getBars(subscriptionRecord.symbolInfo, subscriptionRecord.resolution, { from: rangeStartTime, to: rangeEndTime }).then(function (
			result
		) {
			_this._onSubscriberDataReceived(listenerGuid, result);
		});
	};
	DataPulseProvider.prototype._onSubscriberDataReceived = function (listenerGuid, result) {
		// means the subscription was cancelled while waiting for data
		if (!this._subscribers.hasOwnProperty(listenerGuid)) {
			//console.log("DataPulseProvider: Data comes for already unsubscribed subscription #" + listenerGuid);
			return;
		}
		var bars = result.bars;
		if (bars.length === 0) {
			return;
		}
		var lastBar = bars[bars.length - 1];
		var subscriptionRecord = this._subscribers[listenerGuid];
		if (subscriptionRecord.lastBarTime !== null && lastBar.time < subscriptionRecord.lastBarTime) {
			return;
		}
		var isNewBar = subscriptionRecord.lastBarTime !== null && lastBar.time > subscriptionRecord.lastBarTime;
		// Pulse updating may miss some trades data (ie, if pulse period = 10 secods and new bar is started 5 seconds later after the last update, the
		// old bar's last 5 seconds trades will be lost). Thus, at fist we should broadcast old bar updates when it's ready.
		if (isNewBar) {
			if (bars.length < 2) {
				throw new Error("Not enough bars in history for proper pulse update. Need at least 2.");
			}
			var previousBar = bars[bars.length - 2];
			subscriptionRecord.listener(previousBar);
		}
		subscriptionRecord.lastBarTime = lastBar.time;
		subscriptionRecord.listener(lastBar);
	};
	return DataPulseProvider;
})();
let newPulseProvider = new DataPulseProvider(15000);

export default {
	searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
		//console.log("[searchSymbols]: Method call");
		const symbols = await getAllSymbols();
		const newSymbols = symbols.filter((symbol) => {
			const isFullSymbolContainsInput =
				symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1 ||
				symbol.description.toLowerCase().indexOf(userInput.toLowerCase()) !== -1 ||
				symbol.symbol.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
			return isFullSymbolContainsInput;
		});
		onResultReadyCallback(newSymbols);
	},

	resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
		const symbols = await getAllSymbols();
		const symbolItem = symbols.find((item) => item.full_name === symbolName || item.symbol === symbolName);

		if (!symbolItem) {
			//console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
			onResolveErrorCallback("cannot resolve symbol");
			return;
		}
		const symbolInfo = {
			ticker: symbolItem.symbol,
			name: symbolItem.full_name,
			description: symbolItem.description,
			type: symbolItem.type,
			session: "24x7",
			timezone: "UTC",
			exchange: symbolItem.exchange,
			minmov: 1,
			pricescale: symbolItem.priceIncrement,
			has_intraday: true,
			has_daily: true,
			has_no_volume: false,
			has_weekly_and_monthly: false,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: "streaming",
			force_session_rebuild: true,
			pro_name: symbolItem.full_name,
			has_empty_bars: false,
		};

		onSymbolResolvedCallback(symbolInfo);
	},

	getBars: getBars,

	subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
		newPulseProvider.subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID);
	},

	unsubscribeBars: (subscriberUID) => {
		newPulseProvider.unsubscribeBars(subscriberUID);
	},
	onReady: (callback) => {
		setTimeout(() => callback(configurationData));
	},
};
function periodLengthSeconds(resolution, requiredPeriodsCount) {
	var daysCount = 0;
	if (resolution === "D" || resolution === "1D") {
		daysCount = requiredPeriodsCount;
	} else if (resolution === "M" || resolution === "1M") {
		daysCount = 31 * requiredPeriodsCount;
	} else if (resolution === "W" || resolution === "1W") {
		daysCount = 7 * requiredPeriodsCount;
	} else {
		daysCount = (requiredPeriodsCount * parseInt(resolution)) / (24 * 60);
	}
	return daysCount * 24 * 60 * 60;
}
