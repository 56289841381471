import React from "react";
import {
	BooleanField,
	BooleanInput,
	Datagrid,
	DateField,
	Edit,
	EditButton,
	email,
	FormTab,
	FunctionField,
	maxLength,
	maxValue,
	minLength,
	minValue,
	NumberField,
	NumberInput,
	Pagination,
	PasswordInput,
	ReferenceField,
	ReferenceManyField,
	regex,
	required,
	SelectField,
	ShowButton,
	SingleFieldList,
	TabbedForm,
	TextField,
	TextInput,
	TopToolbar,
	useLocale,
	useTranslate,
} from "react-admin";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import NameWithAvatarField from "../shared/NameWithAvatarField";

import BulkEditBtn from "../shared/BulkEditBtn";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";
import {ServiceTypes} from "../vendorsubscriptions/VendorSubscriptionsEnums";
import {BalanceField, ReloadBalanceBtn} from "../exchangeaccounts/ExchangeAccountsList";
import {MyFragment} from "./ClientsList";
import mobileValidate from "../shared/MobileValidate";
import {validatePassword} from "../shared/ScorePassword";
import MarketAvatarField from "../shared/MarketAvatarField";
import {InvoiceDesc, StatusField} from "../invoices/InvoicesList";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Link as RouterLink} from "react-router-dom";
import SignalPerformanceWidget from "../shared/SignalPerformanceWidget";

const useStyles = makeStyles({
	normalfield: { width: 544 },
	fullWidth: { width: "100% !important" },
	email: { width: 544 },
	address: { maxWidth: 544 },
	zipcode: { display: "inline-block" },
	city: { display: "inline-block", marginLeft: 32 },
	comment: {
		maxWidth: "20em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
});

function getDaysRemaining(expiresAt) {
	const _start = new Date();
	const _expire = new Date(expiresAt);

	// @ts-ignore
	const result = Math.round((_expire - _start) / (1000 * 60 * 60 * 24));
	return result > 0 ? result : 0;
}

const DaysRemaining = ({ record }) => {
	if (record && record.expiresAt) {
		let remainingDays = getDaysRemaining(record.expiresAt);
		if (remainingDays) {
			return <Box>{remainingDays} Days remaining </Box>;
		}
		return <Box color={"#f50057"}>Expired</Box>;
	}
	return <span />;
};
const PerformanceBox = ({ record }) => {
	return (
		<Box display={"flex"} alignItems={"center"}>
			<SignalPerformanceWidget user={record} tf={"1d"} />
			<SignalPerformanceWidget user={record} tf={"1w"} />
			<SignalPerformanceWidget user={record} tf={"1m"} />
			<SignalPerformanceWidget user={record} tf={"3m"} />
			<SignalPerformanceWidget user={record} tf={"1y"} />
		</Box>
	);
};
const ClientsEdit = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const locale = useLocale();
	return (
		<Edit {...props} title={<NameWithAvatarField />} actions={<EditActions />} undoable={false}>
			<TabbedForm redirect={false}>
				<FormTab label="Information">
					<PerformanceBox />
					<TextInput
						source="userId"
						formClassName={classes.normalfield}
						validate={requiredValidate}
						label={"User Name"}
					/>
					<TextInput
						source="firstName"
						formClassName={classes.normalfield}
						validate={requiredValidate}
						label={"resources.clients.firstName"}
					/>
					<TextInput
						source="lastName"
						formClassName={classes.normalfield}
						validate={requiredValidate}
						label={"resources.clients.lastName"}
					/>
					<TextInput
						source="telegramId"
						label={"resources.clients.telegramId"}
						fullWidth={true}
						formClassName={classes.normalfield}
					/>

					<TextInput
						source="phonenumber"
						label={"resources.clients.phonenumber"}
						fullWidth={true}
						formClassName={classes.normalfield}
						validate={mobileValidatation}
					/>
					<TextInput
						label={"resources.clients.email"}
						type="email"
						source="email"
						validation={{ email: true }}
						fullWidth={true}
						formClassName={classes.email}
						validate={emailValidate}
					/>

					{/*<Separator />
                <ImageInput  source="avatar"  label={"resources.vendors.avatar"}  maxSize={1000000}  accept=".jpg,.png,.jpeg" placeholder={<p>{translate("resources.vendors.dropimages")}</p>}>
                    <ImageField source="src" title="title"/>
                </ImageInput >*/}
					<TextInput
						source="desc"
						formClassName={classes.address}
						multiline={true}
						fullWidth={true}
						label={"resources.clients.desc"}
					/>
					<NumberInput source="referralPercent" validate={[minValue(1), maxValue(40)]} />
					<TextField source={"lastIP"} />
					<ReferenceField label={"Invited By"} source="registeredWithReferralCode" reference="referralcodes">
						<ReferenceField source="owner" reference="users">
							<NameWithAvatarField />
						</ReferenceField>
					</ReferenceField>
					<ReferenceField source="appsubscription" reference="appsubscriptions" link={false}>
						<MyFragment>
							<ReferenceField source="subscriptionPlan" reference="subscriptionplans" link={false}>
								<TextField source={"name"} />
							</ReferenceField>

							<DaysRemaining />
						</MyFragment>
					</ReferenceField>

					<DateField source="createdAt" locales={locale} />
					<DateField source="lastLogin" label={"resources.clients.lastlogin"} locales={locale} showTime />
					<DateField source="lastLogout" locales={locale} showTime />
					<BooleanInput source="publicProfile" />
					<BooleanInput source="disabled" label={"resources.admins.disabled"} />
					<BooleanInput source="emailVerified" />
					<BooleanInput source="phonenumberVerified" />
				</FormTab>
				<FormTab label="Vendor Subscriptions">
					<ReferenceManyField
						label="resources.clients.vendorsubscriptions"
						reference="vendorsubscriptions"
						target="user"
						sortable={false}
					>
						<Datagrid>
							<BooleanField source={"expired"} />
							<ReferenceField
								source="vendor"
								label={"resources.vendorsubscriptions.vendor"}
								reference="users|usertype|VENDOR"
							>
								<NickNameWithAvatarField />
							</ReferenceField>

							<SelectField
								label={"resources.vendorsubscriptions.serviceType"}
								source={"servicetype"}
								choices={ServiceTypes}
							/>
							<DateField
								source="startedAt"
								label="resources.vendorsubscriptions.startedAt"
								locales={locale}
								showTime
							/>
							<DateField
								source="expiresAt"
								label="resources.vendorsubscriptions.expiresAt"
								locales={locale}
								showTime
							/>
							<FunctionField
								label="resources.vendorsubscriptions.notifications"
								render={(record) => {
									record.notification = !record.disabledNotifications;
									return <BooleanField record={record} source="notification" />;
								}}
							/>
							<FunctionField
								label="resources.vendorsubscriptions.autoTrade"
								render={(record) => {
									record.trades = record.servicetype === "AUTOTRADE" && !record.disabledTrades;
									return <BooleanField record={record} source="trades" />;
								}}
							/>

							<DaysRemaining />
							<EditButton />
						</Datagrid>
					</ReferenceManyField>
				</FormTab>
				<FormTab label="Exchange Accounts">
					<ReferenceManyField
						label={false}
						reference="exchangeaccounts"
						target="user"
						sortable={false}
						filter={{ deleted: { $ne: true } }}
						pagination={<Pagination />}
					>
						<Datagrid>
							<ReferenceField source="market" label={"Exchange"} reference="markets" link={false}>
								<MarketAvatarField />
							</ReferenceField>

							<TextField source="name" label={"resources.exchangeaccounts.accountname"} />
							<BooleanField source={"isValid"} />

							<NumberField source={"copyTradePercent"} />
							<ReloadBalanceBtn />
							<ReferenceManyField
								reference="exchangebalances"
								target="exchangeAccount"
								sort={{ field: "createdAt", order: "DESC" }}
								filter={{ $limit: 1 }}
								label={"Balance"}
							>
								<SingleFieldList>
									<BalanceField />
								</SingleFieldList>
							</ReferenceManyField>
							<EditButton label={"resources.alerts.manage"} />
						</Datagrid>
					</ReferenceManyField>
				</FormTab>
				<FormTab label={"Invoices"}>
					<ReferenceManyField label={false} reference="invoices" target="user" sortable={false}>
						<Datagrid>
							<InvoiceNumberField label={"resources.invoices.invoiceNumber"} />

							<StatusField label={"resources.invoices.status"} />
							<PurchasableField label={"resources.invoices.purchasable"} />
							<InvoiceDesc label={"resources.invoices.desc"} />
							<DateField source="createdAt" label="resources.invoices.createdAt" locales={locale} showTime />

							<ShowButton label={"Manage"} />
						</Datagrid>
					</ReferenceManyField>
				</FormTab>
				<FormTab label={"User Data"}>
					<NumberField source={"signalPerformanceReport_1y.cum_pnl_spot"} label={"Spot PNL"} />

					<NumberField source={"signalPerformanceReport_1y.cum_pnl_futures"} label={"Futures PNL"} />
					<NumberField source={"signalPerformanceReport_1y.winrate_total"} label={"Win Rate"} />
					<NumberField source={"signalPerformanceReport_1y.count_total"} label={"Total Signals"} />
					<NumberField source="accounting.currentBalance" label={"resources.vendors.currentBalance"} />
					<NumberField source="accounting.totalIncome" label={"resources.vendors.totalIncome"} />
					<UserDataSection />
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
const UserDataSection = ({ record }) => {
	return (
		<Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
			<Button
				component={RouterLink}
				color="primary"
				to={{
					pathname: "/spot",
					search: `displayedFilters={}&filter=${JSON.stringify({ user: record?.id })}`,
				}}
			>
				Spot Signals
			</Button>
			<Button
				component={RouterLink}
				color="primary"
				to={{
					pathname: "/futures",
					search: `displayedFilters={}&filter=${JSON.stringify({ user: record?.id })}`,
				}}
			>
				Futures Signals
			</Button>
			<Button
				component={RouterLink}
				color="primary"
				to={{
					pathname: "/history",
					search: `displayedFilters={}&filter=${JSON.stringify({ user: record?.id })}`,
				}}
			>
				Signal History
			</Button>
			<Button
				component={RouterLink}
				color="primary"
				to={{
					pathname: "/atspotpositions",
					search: `displayedFilters={}&filter=${JSON.stringify({ user: record?.id })}`,
				}}
			>
				Auto Trade Spot Positions
			</Button>
			<Button
				component={RouterLink}
				color="primary"
				to={{
					pathname: "/atufuturespositions",
					search: `displayedFilters={}&filter=${JSON.stringify({ user: record?.id })}`,
				}}
			>
				Auto Trade Futures Positions
			</Button>
			<Button
				component={RouterLink}
				color="primary"
				to={{
					pathname: "/clspotpositions",
					search: `displayedFilters={}&filter=${JSON.stringify({ user: record?.id })}`,
				}}
			>
				Personal Spot Positions
			</Button>
			<Button
				component={RouterLink}
				color="primary"
				to={{
					pathname: "/clufuturespositions",
					search: `displayedFilters={}&filter=${JSON.stringify({ user: record?.id })}`,
				}}
			>
				Personal Futures Positions
			</Button>
			<Button
				component={RouterLink}
				color="primary"
				to={{
					pathname: "/referralcodes",
					search: `displayedFilters={}&filter=${JSON.stringify({ owner: record?.id })}`,
				}}
			>
				Referral Codes
			</Button>
		</Box>
	);
};
const InvoiceNumberField = ({ record }) => {
	if (!record || !record.paymentDetails) {
		return <span />;
	}
	return <Typography>#{record.invoiceNumber}</Typography>;
};
const PurchasableField = ({ record }) => {
	if (!record || !record.purchasable) {
		return <span />;
	}
	return (
		<Typography>
			{record.purchasable.toFixed(2)} USDT
			{!!record.vendorSubscriptionShare && (
				<Typography component={"span"} color={"primary"} variant={"caption"}>
					({(record.vendorAutoTradeShare + record.vendorSubscriptionShare).toFixed(2)})
				</Typography>
			)}
		</Typography>
	);
};
const requiredValidate = [required()];
const mobileValidatation = [
	required(),
	mobileValidate,
	regex(/^([+]\d{1,3})?\d{10}$/, "Invalid mobile format"),
	minLength(11),
	maxLength(15),
];
const emailValidate = [required(), email()];
const passwordValidate = [required(), minLength(8), validatePassword];

const EditActions = ({ basePath, data, resource }) => (
	<TopToolbar>
		<BulkEditBtn
			data={data}
			resource={resource}
			field={"password"}
			label="resources.clients.changepassword"
			idField="_id"
			inputComponent={<PasswordInput source="password" validate={passwordValidate} />}
		/>
	</TopToolbar>
);
const Separator = () => <Box pt="1em" />;
export default ClientsEdit;
