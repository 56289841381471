import React, {useState} from "react";

import {Confirm, DateField, maxValue, minValue, NumberInput, useLocale, useTranslate} from "react-admin";

import {useField, useForm, useFormState} from "react-final-form";
import {ThemedSlider} from "../../shared/ThemedSlider";
import {makeStyles} from "@material-ui/core/styles";
import {withStyles} from "@material-ui/core";
import {darken, lighten} from "@material-ui/core/styles/colorManipulator";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import AlarmIcon from "@material-ui/icons/Alarm";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AddIcon from "@material-ui/icons/AddCircle";
import Grid from "@material-ui/core/Grid";

const TargetPointSlider = withStyles((theme) => ({
	thumb: {
		backgroundColor: theme.palette.success.main,
		"&$disabled": {
			opacity: 0.5,
			height: 35,
			width: 35,
			marginLeft: -4,
			marginTop: -3,
			"&:hover": {
				boxShadow: "none",
			},
		},
	},
	track: {
		display: "block",
		position: "absolute",
		height: 2,
		borderRadius: 1,
		backgroundColor: theme.palette.success.main,
		"$vertical &": {
			width: 2,
		},
	},
	rail: {
		display: "block",
		position: "absolute",
		width: "100%",
		height: 2,
		borderRadius: 1,
		backgroundColor: theme.palette.success.main,
		opacity: 0.38,
		"$vertical &": {
			height: "100%",
			width: 2,
		},
	},
	/* Styles applied to the track element if `track={false}`. */
	trackFalse: {
		"& $track": {
			display: "none",
		},
	},
	/* Styles applied to the track element if `track="inverted"`. */
	trackInverted: {
		"& $track": {
			backgroundColor:
				// Same logic as the LinearProgress track color
				theme.palette.type === "light"
					? lighten(theme.palette.success.main, 0.62)
					: darken(theme.palette.success.main, 0.5),
		},
		"& $rail": {
			opacity: 1,
		},
	},
}))(ThemedSlider);

const useStyles = makeStyles((theme) => ({
	formInput: {
		//    maxWidth:'100%'
		width: "100%",
		maxWidth: "500px",
		//  minWidth:'250px'
	},
	withSliderForm: {
		width: "100%",
		maxWidth: "500px",
		marginTop: 5,
	},
	margin: {
		height: theme.spacing(5),
	},
	seperate: {
		height: theme.spacing(2),
	},
	avatarSuccess: {
		backgroundColor: theme.palette.success.main,
	},
	avatarError: {
		backgroundColor: theme.palette.error.main,
	},
	avatarGrey: {
		backgroundColor: theme.palette.grey[500],
	},
	avatarPrimary: {
		backgroundColor: theme.palette.primary.main,
	},
	thumbContent: {
		color: "white",
		fontSize: "1em",
	},
	addTPButton: {
		margin: theme.spacing(2),
	},
	noBorder: {
		border: "none",
	},
}));
const VendorSignalsTargetPointsEdit = ({ record, pairObject, ...props }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const [removeTPDialogOpen, setRemoveTPDialogOpen] = useState(-1);
	const [formTPCountChanged, setTPCountChange] = useState(new Date());
	const { values } = useFormState();

	const { entryPrice, stopLoss, targetPoints } = values;

	const form = useForm();
	const { positionType, entryAverage } = record;

	const tpCountChangedAtInput = useField("tpCountChangedAt");
	let that = this;
	const getMarks = () => {
		let result = [
			{
				value: entryAverage ? entryAverage : positionType === "sell" ? entryPrice.low : entryPrice.high,
				label:
					positionType === "sell"
						? translate("resources.signalalerts.entry") + "\n" + (entryAverage ? entryAverage : entryPrice.low)
						: translate("resources.signalalerts.entry") + "\n" + (entryAverage ? entryAverage : entryPrice.high),
			},
		];
		/*  if(pairObject.latestPrice >= getMin() && pairObject.latestPrice <= getMax()){
              result.push({value:pairObject.latestPrice,label:pairObject.symbol + "\n" + pairObject.latestPrice })
          }*/
		return result;
	};
	const getRealMin = () => {
		if (positionType === "sell") {
			return pairObject.tickSize;
		} else {
			return Number((entryPrice.high + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		}
	};
	const getRealMax = () => {
		if (positionType === "buy") {
			return pairObject.latestPrice * 1000;
		} else {
			return Number((entryPrice.low - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		}
	};
	const getMin = () => {
		if (positionType === "sell") {
			const idealMin = pairObject.latestPrice - pairObject.latestPrice * 0.2;
			const minTarget = Array.min(getTargetPoints());
			if (minTarget < idealMin + idealMin * 0.1) {
				return minTarget - minTarget * 0.1;
			}
			return idealMin;
		} else {
			return getRealMin();
		}
	};
	const getMax = () => {
		if (positionType === "buy") {
			const idealMax = pairObject.latestPrice + pairObject.latestPrice * 0.2;
			const maxTarget = Array.max(getTargetPoints());
			if (maxTarget > idealMax - idealMax * 0.1) {
				return maxTarget + maxTarget * 0.1;
			}
			return idealMax;
		} else {
			return getRealMax();
		}
	};
	const handleChangeSlider = (event, newValue) => {
		if (record.stopLoss.triggeredAt) {
			return;
		}
		form.pauseValidation();
		if (positionType === "buy") {
			form.batch(() => {
				newValue.forEach((val, indx) => {
					if (!targetPoints[indx].triggeredAt) {
						form.change("targetPoints[" + indx + "].valuenumber", val);
					}
				});
			});
		} else {
			form.batch(() => {
				newValue.reverse().forEach((val, indx) => {
					if (!targetPoints[indx].triggeredAt) {
						form.change("targetPoints[" + indx + "].valuenumber", val);
					}
				});
			});
		}
		form.resumeValidation();
	};
	const handleChangeSliderCommited = () => {
		afterFormEdit(targetPoints, form);
	};
	const getTargetPoints = () => {
		return targetPoints.map((tp) => {
			return tp ? Number(tp.valuenumber) : 0;
		});
	};
	const getThumbContent = (index, record, classes) => {
		let targetPoint = targetPoints[index];

		let result = <span></span>;
		if (targetPoint.triggeredAt) {
			return <CheckIcon className={classes.thumbContent} />;
		} else if (!targetPoint.triggeredAt && !entryPrice.triggeredAt && !record.stopLoss.triggeredAt) {
			return result;
		} else if (!targetPoint.triggeredAt && record.stopLoss.triggeredAt) {
			return <CloseIcon className={classes.thumbContent} />;
		} else if (!targetPoint.triggeredAt && entryPrice.triggeredAt && !record.stopLoss.triggeredAt) {
			return <AlarmIcon className={classes.thumbContent} />;
		}
		return result;
	};

	const onRemoveTPDialogConfirmed = () => {
		let _targetPoints = targetPoints;
		if (removeTPDialogOpen > -1) {
			let removingTP = _targetPoints[removeTPDialogOpen];

			_targetPoints.splice(removeTPDialogOpen, 1);
			let firstNotTriggeredTP = _targetPoints.findIndex((tp) => !tp.triggeredAt);
			if (firstNotTriggeredTP >= 0) {
				_targetPoints[firstNotTriggeredTP].percent += removingTP.percent;
			}
			form.change("targetPoints", _targetPoints);
			makePercentsCorrect();
			setTPCountChange(new Date());
			form.change("tpCountChangedAt", new Date());
		}
		setRemoveTPDialogOpen(-1);
	};
	const addTPButtonDisabled = () => {
		if ((targetPoints && targetPoints.length > 9) || record.stopLoss.triggeredAt) {
			return true;
		} else {
			let remainingTPs = targetPoints.filter((tp) => {
				return !tp.triggeredAt;
			});
			return !remainingTPs || remainingTPs.length === 0;
		}
	};
	const makePercentsCorrect = () => {
		let percents = targetPoints.map((tp) => tp.percent);
		let percentsCount = percents.reduce((total, num) => total + num);
		let lastTPINDX = targetPoints.length - 1;
		if (percentsCount !== 100) {
			let lastTP = targetPoints[lastTPINDX];
			if (!lastTP.triggeredAt) {
				lastTP.percent += 100 - percentsCount;

				form.change("targetPoints[" + lastTPINDX + "].percent", lastTP.percent);
			}
		}
	};
	const getCardSubHeader = () => {
		return targetPoints.filter((tp) => tp.triggeredAt).length + "/" + targetPoints.length;
	};
	const getCardSubHeaderText = () => {
		return translate("resources.signalalerts.reachedTargetPoints", {
			r: targetPoints.filter((tp) => tp.triggeredAt).length,
			a: targetPoints.length,
		});
	};
	const handleAddTargetPoint = () => {
		if (addTPButtonDisabled()) {
			return;
		}

		let lastTargetVal = positionType === "buy" ? Array.max(getTargetPoints()) : Array.min(getTargetPoints());
		let newTargetVal =
			positionType === "buy" ? lastTargetVal + lastTargetVal * 0.02 : lastTargetVal - lastTargetVal * 0.02;
		newTargetVal = Number(newTargetVal.toFixed(countDecimals(pairObject.tickSize)));
		let newPercent = targetPoints[targetPoints.length - 1].percent / 2;
		let shouldCurrectPercent = false;
		if (newPercent >= 1 && countDecimals(newPercent) > 1) {
			shouldCurrectPercent = true;
		}
		let newTargetPoints = targetPoints;
		newTargetPoints[newTargetPoints.length - 1].percent = shouldCurrectPercent ? Math.floor(newPercent) : newPercent;
		newTargetPoints.push({
			valuenumber: newTargetVal,
			percent: shouldCurrectPercent ? Math.ceil(newPercent) : newPercent,
		});

		form.change("targetPoints", newTargetPoints);

		form.change("tpCountChangedAt", new Date());
		makePercentsCorrect();
		setTPCountChange(new Date());
	};
	return (
		<Card>
			<CardHeader
				avatar={<Avatar style={{ fontSize: "0.9rem" }}>{getCardSubHeader()}</Avatar>}
				action={
					<IconButton aria-label="remove">
						<HelpIcon color={"action"} />
					</IconButton>
				}
				title={translate("resources.signalalerts.targetPoints")}
				subheader={getCardSubHeaderText()}
			/>
			<CardContent>
				<Box margin={2} marginBottom={9}>
					<TargetPointSlider
						value={getTargetPoints()}
						onChange={handleChangeSlider}
						//className={classes.formInput}
						track={"false"}
						getAriaLabel={(index) =>
							translate("resources.signalalerts.tp", {
								index: positionType === "buy" ? index + 1 : targetPoints.length - index,
							})
						}
						step={pairObject.tickSize}
						min={getMin()}
						max={getMax()}
						getDisabled={(index) => {
							return !targetPointCanEdit(
								targetPoints[positionType === "buy" ? index : targetPoints.length - 1 - index],
								record
							);
						}}
						marks={getMarks()}
						getContent={(index) =>
							getThumbContent(positionType === "buy" ? index : targetPoints.length - 1 - index, record, classes)
						}
						//getAriaValueText={getEnterPriceStopLossSliderText}

						// max={enterPriceEnd * 2}
						valueLabelDisplay="on"
						onChangeCommitted={handleChangeSliderCommited}

						//valueLabelFormat={getEnterPriceStopLossSliderText}

						//getAriaValueText={valuetext}
					/>
				</Box>
				<Grid container spacing={2} justify={"center"}>
					{targetPoints.map(
						(tp, index) =>
							tp && (
								<Grid item xs={12} md={7}>
									<TargetPointItem
										record={record}
										setRemoveTPDialogOpen={setRemoveTPDialogOpen}
										targetPoint={tp}
										pairObject={pairObject}
										positionType={positionType}
										entryPrice={entryPrice}
										targetPoints={targetPoints}
										index={index}
									/>
								</Grid>
							)
					)}
				</Grid>

				<Confirm
					isOpen={removeTPDialogOpen !== -1}
					title={"resources.signalalerts.removeTargetPoint"}
					content={translate("resources.signalalerts.areyousuretoRemoveTP", {
						index: removeTPDialogOpen >= 0 ? removeTPDialogOpen + 1 : "",
					})}
					onConfirm={onRemoveTPDialogConfirmed}
					onClose={() => setRemoveTPDialogOpen(-1)}
				/>
			</CardContent>
			<CardActions disableSpacing>
				<Grid container justify={"center"}>
					<Button
						variant="contained"
						color="primary"
						className={classes.addTPButton}
						startIcon={<AddIcon />}
						disabled={addTPButtonDisabled()}
						onClick={handleAddTargetPoint}
					>
						{translate("resources.signalalerts.addTP")}
					</Button>
				</Grid>
			</CardActions>
		</Card>
	);
};
const targetPointCanEdit = (targetPoint, record) => {
	return !targetPoint.triggeredAt && !record.stopLoss.triggeredAt;
};

const getTargetPointStatusText = (targetPoint, record, translate, locale) => {
	if (targetPoint.triggeredAt) {
		return (
			<>
				{" "}
				{translate("resources.signalalerts.reachedAt")}{" "}
				<DateField source={"triggeredAt"} record={targetPoint} locales={locale} showTime />{" "}
			</>
		);
	} else if (!targetPoint.triggeredAt && !record.entryPrice.triggeredAt && !record.stopLoss.triggeredAt) {
		return translate("resources.signalalerts.waitingForEntry");
	} else if (!targetPoint.triggeredAt && record.stopLoss.triggeredAt) {
		return translate("resources.signalalerts.willnotReachDuetoStopLoss");
	} else if (!targetPoint.triggeredAt && record.entryPrice.triggeredAt && !record.stopLoss.triggeredAt) {
		return translate("resources.signalalerts.notReachedYet");
	}
};
const getAvatarClassName = (targetPoint, record, classes) => {
	if (targetPoint.triggeredAt) {
		return classes.avatarSuccess;
	} else if (!targetPoint.triggeredAt && !record.entryPrice.triggeredAt && !record.stopLoss.triggeredAt) {
		return classes.avatarGrey;
	} else if (!targetPoint.triggeredAt && record.stopLoss.triggeredAt) {
		return classes.avatarError;
	} else if (!targetPoint.triggeredAt && record.entryPrice.triggeredAt && !record.stopLoss.triggeredAt) {
		return classes.avatarPrimary;
	}
};
const afterFormEdit = (targetPoints, form) => {
	targetPoints.forEach((tp, inx) => {
		form.resetFieldState("targetPoints[" + inx + "].valuenumber");
		form.blur("targetPoints[" + inx + "].valuenumber");
	});
};
const TargetPointItem = ({
	targetPoint,
	targetPoints,
	pairObject,
	positionType,
	record,
	index,
	entryPrice,
	setRemoveTPDialogOpen,
	...props
}) => {
	const classes = useStyles();
	const translate = useTranslate();
	const locale = useLocale();
	const form = useForm();

	const getTpMin = () => {
		if (positionType === "buy") {
			if (index === 0) {
				return Number((entryPrice.high + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			} else {
				return Number(
					(targetPoints[index - 1].valuenumber + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize))
				);
			}
		} else {
			Number(pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize));
			/*if(index === targetPoints.length-1){

            }
            else{
                return Number((targetPoints[index-1].valuenumber + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
            }*/
		}
	};
	const getTpMax = () => {
		if (positionType === "sell") {
			if (index === 0) {
				return Number((entryPrice.low + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			} else {
				return Number(
					(targetPoints[index - 1].valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize))
				);
			}
		} else {
			return pairObject.latestPrice * 1000;
			/*if(index === targetPoints.length-1){

            }
            else{
                return Number((targetPoints[index+1].valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
            }*/
		}
	};
	const targetPointCanRemoved = () => {
		return (
			targetPointCanEdit(targetPoint, record) &&
			targetPoints.length !== 1 &&
			targetPoints.filter((tp) => !tp.triggeredAt).length > 1
		);
	};
	return (
		<Card elevation={2} className={classes.noBorder}>
			<CardHeader
				avatar={
					<Avatar backgroundColor={"error"} className={getAvatarClassName(targetPoint, record, classes)}>
						{targetPoint.triggeredAt && <CheckIcon />}
						{!targetPoint.triggeredAt && !entryPrice.triggeredAt && !record.stopLoss.triggeredAt && (
							<MoreHorizIcon />
						)}
						{!targetPoint.triggeredAt && record.stopLoss.triggeredAt && <CloseIcon />}
						{!targetPoint.triggeredAt && entryPrice.triggeredAt && !record.stopLoss.triggeredAt && <AlarmIcon />}
					</Avatar>
				}
				action={
					<IconButton
						aria-label="remove"
						disabled={!targetPointCanRemoved()}
						onClick={() => setRemoveTPDialogOpen(index)}
					>
						<RemoveCircleOutlineIcon color={targetPointCanRemoved() ? "error" : "grey"} />
					</IconButton>
				}
				title={
					translate("resources.signalalerts.targetPoint", { index: index + 1 }) +
					" - " +
					translate("resources.signalalerts.extraction") +
					": " +
					targetPoint.percent +
					"%"
				}
				subheader={getTargetPointStatusText(targetPoint, record, translate, locale)}
			/>
			<CardContent style={{ justifyContent: "Center", display: "flex", paddingBottom: 0, paddingTop: 0 }}>
				<NumberInput
					style={{ minWidth: 200 }}
					step={pairObject.tickSize}
					onBlur={() => afterFormEdit(targetPoints, form)}
					validate={[maxValue(getTpMax()), minValue(getTpMin())]}
					label={translate("resources.signalalerts.targetPoint", { index: index + 1 })}
					source={"targetPoints[" + index + "].valuenumber"}
					disabled={!targetPointCanEdit(targetPoint, record)}
				/>
			</CardContent>
		</Card>
	);
};
var countDecimals = function (value) {
	let text = value.toString();
	// verify if number 0.000005 is represented as "5e-6"
	if (text.indexOf("e-") > -1) {
		let [base, trail] = text.split("e-");
		let deg = parseInt(trail, 10);
		return deg;
	}
	// count decimals for number in representation like "0.123456"
	if (Math.floor(value) !== value) {
		return value.toString().split(".")[1].length || 0;
	}
	return 0;
};
export default VendorSignalsTargetPointsEdit;
