import React, {cloneElement, Fragment, useEffect} from "react";
import {
	AutocompleteInput,
	BulkActionsToolbar,
	Filter,
	ListBase,
	ListToolbar,
	Pagination,
	ReferenceInput,
	SearchInput,
	SelectInput,
	TopToolbar,
	useAuthState,
	useListContext,
	useLocale,
	useRefresh,
	useTranslate,
} from "react-admin";
import {BatchSignalsListGrid} from "./BatchSignalsListGrid";
import Box from "@material-ui/core/Box";
import SpotEmpty from "./SpotEmpty";
import FuturesEmpty from "./FuturesEmpty";
import NickNameWithAvatarField from "../shared/NickNameWithAvatarField";

const ListFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props} style={{ alignItems: "center", justifyContent: "center" }}>
			<ReferenceInput
				alwaysOn
				label={"resources.observes.pair"}
				source="pair"
				perPage={10}
				reference="pairs"
				allowEmpty={true}
				shouldRenderSuggestions={(value) => {
					return value && value.trim().length > 0;
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								symbol: { $search: searchText },
						  }
						: null
				}
			>
				<AutocompleteInput
					optionText={"symbol"}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.pairs.allpairs"}
					alwaysOn
				/>
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				label={"resources.signalalerts.client"}
				source="user"
				perPage={25}
				reference="users|usertype|CLIENT"
				allowEmpty={true}
				filter={{
					usertype: "CLIENT",
				}}
				filterToQuery={(searchText) =>
					searchText.trim() !== ""
						? {
								$or: [{ firstName: { $search: searchText } }, { lastName: { $search: searchText } }],
						  }
						: { fake: true }
				}
			>
				<AutocompleteInput
					optionText={(record) =>
						record && record.firstName
							? record.firstName + " " + record.lastName
							: translate("resources.observes.allusers")
					}
					allowEmpty={true}
					optionValue={"_id"}
					emptyText={"resources.observes.allusers"}
					alwaysOn
				/>
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				emptyText={"resources.vendorsignals.allvendors"}
				label={"resources.vendorsignals.vendor"}
				source="vendor"
				reference="users|usertype|VENDOR"
				perPage={200}
			>
				<SelectInput optionText={<NickNameWithAvatarField />} name={"fakename"} />
			</ReferenceInput>
			<SearchInput
				source="name.$search"
				alwaysOn
				placeholder={translate("resources.vendorsignals.namehelper")}
				InputLabelProps={{ shrink: true }}
			/>
		</Filter>
	);
};
const PostPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
const ListActions = (props) => (
	<TopToolbar style={{ alignItems: "center" }}>{cloneElement(props.filters, { context: "button" })}</TopToolbar>
);
let refreshInterVal = null;
const BatchSignalsList = (props) => {
	const translate = useTranslate();
	const locale = useLocale();
	const refresh = useRefresh();
	const { resource } = props;
	const { loading, authenticated } = useAuthState();
	useEffect(() => {
		if (refreshInterVal) {
			clearInterval(refreshInterVal);
		}
		if (authenticated) {
			refreshInterVal = setInterval(() => {
				refresh();
			}, 20000);
		}

		return function cleanup() {
			if (refreshInterVal) {
				clearInterval(refreshInterVal);
			}
		};
	}, []);
	return (
		<ListStructure
			{...props}
			filters={<ListFilter />}
			title={translate("resources.vendors.listtitle")}
			perPage={200}
			filter={{ tradetype: resource === "spot" ? 0 : 1, closed: { $ne: true } }}
			exporter={false}
			bulkActionButtons={false}
			pagination={false}
			actions={<ListActions {...props} />}
			empty={resource === "spot" ? <SpotEmpty /> : <FuturesEmpty />}
		>
			<BatchSignalsListGrid />
		</ListStructure>
	);
};

const ListStructure = (props) => {
	const { empty, children, actions, bulkActionButtons, filters, title, ...rest } = props;
	return (
		<ListBase {...rest}>
			<ListBaseContent {...props} />
		</ListBase>
	);
};
const ListBaseContent = (props) => {
	const listContext = useListContext(props);
	const { empty, children, actions, bulkActionButtons, filters, title, ...rest } = props;
	const { defaultTitle, total, loaded, loading, filterValues, selectedIds } = listContext;
	const shouldRenderEmptyPage = loaded && !loading && total === 0 && !Object.keys(filterValues).length;
	/*if (loading) { //todo!
		return <span />;
	}*/
	if (!shouldRenderEmptyPage) {
		return (
			<Fragment>
				<ListToolbar filters={filters} actions={actions} />
				<Box>
					<BulkActionsToolbar>{bulkActionButtons}</BulkActionsToolbar>

					{cloneElement(children, {
						hasBulkActions: bulkActionButtons !== false,
					})}
				</Box>
			</Fragment>
		);
	}
	return cloneElement(empty, listContext);
};
export default BatchSignalsList;
