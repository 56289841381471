import React from "react";
import {Avatar, Box, Card, CardHeader, Divider, Typography, useMediaQuery} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import PositionOrders from "./PositionOrders";

import InsertDriveFileTwoToneIcon from "@material-ui/icons/InsertDriveFileTwoTone";

const styles = (theme) => ({
	iconButtonRoot: {},
	icon: {
		fontSize: "1.1em",
	},
	root: {
		display: "flex",
		flexDirection: "row",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			alignItems: "flex-start",
		},
		alignItems: "center",

		backgroundColor: "transparent",
	},
	divider: {
		width: 1,
		height: "auto",
		margin: theme.spacing(1),
		[theme.breakpoints.down("sm")]: {
			width: "auto",
			height: 1,
		},
	},
});
const PaperPositionCard = (props) => {
	const { classes, signal = {}, record } = props;
	const { positionType, tradetype, vendor, user, pair } = signal;
	const { exchangeAccount, opened, closed, stopLossed, canceled, status, minRequiredBalance, entryBalancePercent } = record;
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	return (
		<Card className={classes.root} elevation={0}>
			<CardHeader
				style={{ minWidth: 200 }}
				avatar={
					<Avatar aria-label="recipe" className={classes.avatar}>
						<InsertDriveFileTwoToneIcon color={!!opened ? "primary" : "default"} />
					</Avatar>
				}
				title={"Paper Trade"}
				subheader={
					<Box>
						Fund:{" "}
						<Typography color={"textPrimary"} component={"span"}>
							{entryBalancePercent}%
						</Typography>
					</Box>
				}
			/>
			{!isSmall && <Divider orientation={isSmall ? "vertical" : "horizontal"} flexItem={true} className={classes.divider} />}

			<PositionOrders
				signal={signal}
				position={record}
				exchangeAccountObject={{
					balance: [{ balance: { availableBalance: 100, [pair?.quote]: { free: 100 } } }],
					tradetype: tradetype,
					copyTradePercent: 100,
				}}
			/>
			{/*{xaLoaded && }*/}
			<Box flexGrow={1} height={isSmall && 2} />
			{isSmall && <Divider orientation={isSmall ? "vertical" : "horizontal"} flexItem={true} className={classes.divider} />}
		</Card>
	);
};
export default withStyles(styles, { name: "SignalActions" })(PaperPositionCard);
