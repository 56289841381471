/* tslint:disable */
import {ThemeOptions} from "@material-ui/core";
import {createStyles, withStyles} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import classnames from "classnames";
import compose from "lodash/flowRight";
import {SnackbarProvider} from "notistack";
import PropTypes from "prop-types";
import {ComponentPropType, CoreLayoutProps} from "ra-core";
import React, {
	Component,
	ComponentType,
	createElement,
	ErrorInfo,
	HtmlHTMLAttributes,
	ReactElement,
	useEffect,
	useRef,
	useState,
} from "react";

import {AppBarProps, createMuiTheme, Error, MenuProps, Notification, SkipNavigationButton} from "react-admin";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import CustomNotifications from "./CustomNotifications";
import LoadingIndicator from "./LoadingIndicator";

const styles = (theme) =>
	createStyles({
		root: {
			display: "flex",
			flexDirection: "column",
			zIndex: 1,
			minHeight: "100vh",
			backgroundColor: theme.palette.background.default,
			position: "relative",
			minWidth: "fit-content",
			width: "100%",
			color: theme.palette.getContrastText(theme.palette.background.default),
		},
		appFrame: {
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			[theme.breakpoints.up("xs")]: {
				marginTop: theme.spacing(6),
			},
			[theme.breakpoints.down("xs")]: {
				marginTop: theme.spacing(7),
			},
		},
		contentWithSidebar: {
			display: "flex",
			flexGrow: 1,
		},
		content: {
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			flexBasis: 0,
			padding: theme.spacing(3),
			paddingTop: theme.spacing(1),
			paddingLeft: 0,
			[theme.breakpoints.up("xs")]: {
				paddingLeft: 5,
			},
			[theme.breakpoints.down("sm")]: {
				padding: 0,
			},
		},
	});

class LayoutWithoutTheme extends Component<LayoutWithoutThemeProps, LayoutState> {
	static propTypes = {
		appBar: ComponentPropType,
		children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
		classes: PropTypes.object,
		className: PropTypes.string,
		dashboard: ComponentPropType,
		error: ComponentPropType,
		history: PropTypes.object.isRequired,
		logout: PropTypes.element,
		menu: ComponentPropType,
		notification: ComponentPropType,
		open: PropTypes.bool,
		sidebar: ComponentPropType,
		title: PropTypes.node.isRequired,
	};
	static defaultProps = {
		error: Error,

		notification: Notification,
	};
	state: LayoutState = { hasError: false, error: null, errorInfo: null };

	constructor(props) {
		super(props);
		/**
		 * Reset the error state upon navigation
		 *
		 *
		 * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
		 */
		props.history.listen(() => {
			if (this.state.hasError) {
				this.setState({ hasError: false });
			}
		});
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({ hasError: true, error, errorInfo });
	}

	render() {
		const {
			appBar,
			children,
			classes,
			className,
			error: ErrorComponent,
			dashboard,
			logout,
			menu,
			notification,
			open,
			sidebar,
			title,
			// sanitize react-router props
			match,
			location,
			history,
			staticContext,
			...props
		} = this.props;
		const { hasError, error, errorInfo } = this.state;
		// @ts-ignore

		return (
			<SnackbarProvider maxSnack={10} autoHideDuration={null}>
				<CustomNotifications />
				<LoadingIndicator />
				<div className={classnames("layout", classes.root, className)} {...props}>
					<SkipNavigationButton />
					<div className={classes.appFrame}>
						{createElement(appBar, { title, open, logout })}
						<main className={classes.contentWithSidebar}>
							{createElement(sidebar, {
								children: createElement(menu, {
									logout,
									hasDashboard: !!dashboard,
								}),
							})}
							<div id="main-content" className={classes.content}>
								{hasError ? <Error error={error} errorInfo={errorInfo} title={title as any} /> : children}
							</div>
						</main>
					</div>
				</div>
				{createElement(notification)}
			</SnackbarProvider>
		);
	}
}

export interface LayoutProps extends CoreLayoutProps, Omit<HtmlHTMLAttributes<HTMLDivElement>, "title"> {
	appBar?: ComponentType<AppBarProps>;
	classes?: any;
	className?: string;
	error?: ComponentType<{
		error?: Error;
		errorInfo?: ErrorInfo;
		title?: string | ReactElement<any>;
	}>;
	menu?: ComponentType<MenuProps>;
	notification?: ComponentType;
	sidebar?: ComponentType<{ children: JSX.Element }>;
	theme?: ThemeOptions;
}

export interface LayoutState {
	hasError: boolean;
	error?: Error;
	errorInfo?: ErrorInfo;
}

interface LayoutWithoutThemeProps extends RouteComponentProps, Omit<LayoutProps, "theme"> {
	open?: boolean;
}

const mapStateToProps = (state) => ({
	open: state.admin.ui.sidebarOpen,
});

const EnhancedLayout = compose(
	connect(
		mapStateToProps,
		{} // Avoid connect passing dispatch in props
	),
	withRouter,
	withStyles(styles, { name: "RaLayout" })
)(LayoutWithoutTheme);

const Layout = ({ theme: themeOverride, ...props }: LayoutProps): JSX.Element => {
	const themeProp = useRef(themeOverride);
	const [theme, setTheme] = useState(() => createMuiTheme(themeOverride));

	useEffect(() => {
		if (themeProp.current !== themeOverride) {
			themeProp.current = themeOverride;
			setTheme(createMuiTheme(themeOverride));
		}
	}, [themeOverride, themeProp, theme, setTheme]);

	return (
		<ThemeProvider theme={theme}>
			<EnhancedLayout {...props} />
		</ThemeProvider>
	);
};

Layout.propTypes = {
	theme: PropTypes.object,
};

export default Layout;
