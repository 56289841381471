import {Box, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import {
    Create,
    email,
    maxLength,
    minLength,
    PasswordInput,
    regex,
    required,
    SimpleForm,
    TextInput,
    useTranslate
} from 'react-admin';
import mobileValidate from "../shared/MobileValidate";
import {validatePassword} from "../shared/ScorePassword";


const useStyles = makeStyles({
    normalfield :{ width: 544},
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
});

export const validatePasswords = ({
    password,
    confirm_password,
}: {
    password: string;
    confirm_password: string;
}) => {
    const errors = {} as any;

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            'resources.customers.errors.password_mismatch',
        ];
    }

    return errors;
};

const ClientsCreate = (props: any) => {
    const classes = useStyles();

    return (
        <Create {...props} title={"resources.clients.createnew"}>
            <SimpleForm  redirect={"list"} >

                <TextInput
                    autoFocus
                    source="firstName"
                    formClassName={classes.normalfield}
                    validate={requiredValidate}
                    label={"resources.clients.firstName"}
                />
                <TextInput

                    source="lastName"
                    formClassName={classes.normalfield}
                    validate={requiredValidate}
                    label={"resources.clients.lastName"}
                />
                <TextInput
                    source="telegramId"
                    label={"resources.clients.telegramId"}
                    fullWidth={true}
                    formClassName={classes.normalfield}
                    validate={requiredValidate}

                />
                <TextInput
                    source="phonenumber"
                    label={"resources.clients.phonenumber"}
                    fullWidth={true}
                    formClassName={classes.normalfield}
                    validate={mobileValidatation}
                />
                <TextInput
                    label={"resources.clients.email"}
                    type="email"
                    source="email"
                    validation={{ email: true }}
                    fullWidth={true}
                    formClassName={classes.email}
                    validate={emailValidate}
                />

                <Separator />

                <TextInput
                    source="desc"
                    formClassName={classes.address}
                    multiline={true}
                    fullWidth={true}
                    label={"resources.clients.desc"}
                />


                <Separator />

                <PasswordInput
                    source="password"
                    formClassName={classes.password}
                    validate={passwordValidate}
                    label={"resources.clients.password"}
                />

            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];
const mobileValidatation = [required(),mobileValidate ,regex(/^([+]\d{1,3})?\d{10}$/,"Invalid mobile format") , minLength(11), maxLength(15)]
const emailValidate = [required(),email()]
const prefixValidate = [required(),minLength(3),maxLength(7),regex(/^[a-zA-Z0-9]+$/, 'resources.clients.invalid_char')];
const passwordValidate = [required(),minLength(8),validatePassword]
const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default ClientsCreate;
