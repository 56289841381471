export default {
	themeName: "Extra Dark",
	palette: {
		background: {
			paper: "#000000",
			default: "#000000",
		},
		primary: {
			light: "#2ae8aa",
			dark: "#001064",
			main: "#2ae8aa",
			contrastText: "#fff",
		},
		secondary: {
			light: "#7175d8",
			dark: "#001064",
			main: "#7175d8",
			contrastText: "#fff",
		},
		type: "dark",
	},
	overrides: {
		MuiFormGroup: {
			root: {
				color: "white",
			},
		},
		NDLogin: {
			systemNameLink: {
				color: "#fff",
			},
			welcome: {
				color: "#eee",
			},
		},
	},
};
