import * as React from "react";
import {Show, SimpleShowLayout, TextField, DateField, RichTextField, FunctionField} from 'react-admin';
import {Box} from "@material-ui/core";
import {JsonField} from "@bb-tech/ra-components";

export const JSONShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <FunctionField
                label={"details"}
                render={(record) => (
                    <Box>
                        <JsonField record={{ r: record }} source={"r"} />
                    </Box>
                )}
            />
        </SimpleShowLayout>
    </Show>
);