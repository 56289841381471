import TimelineIcon from '@material-ui/icons/Timeline';

import {ATSPositionsList} from './ATSPositionsList';
import {ATSPositionsShow} from './ATSPositionsShow';

export default {

    show : ATSPositionsShow,
    list:ATSPositionsList,
    icon: TimelineIcon,
};
