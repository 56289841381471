import React from 'react';

import {DateField, maxValue, minValue, NumberInput, useLocale, useTranslate} from "react-admin";

import {useForm, useFormState} from 'react-final-form';
import {ThemedSlider} from '../../shared/ThemedSlider';
import {makeStyles} from '@material-ui/core/styles';
import {withStyles} from "@material-ui/core";
import {darken, lighten} from '@material-ui/core/styles/colorManipulator';
import CheckIcon from '@material-ui/icons/Check';
import AlarmIcon from '@material-ui/icons/Alarm';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined'
import IconButton from '@material-ui/core/IconButton';

const StopLossSlider = withStyles(theme =>({

    thumb: {

        backgroundColor: theme.palette.error.main

    },
    track: {
        display: 'block',
        position: 'absolute',
        height: 2,
        borderRadius: 1,
        backgroundColor: theme.palette.error.main,
        '$vertical &': {
            width: 2,
        },
    },
    rail: {
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: 2,
        borderRadius: 1,
        backgroundColor: 'red',
        opacity: 0.38,
        '$vertical &': {
            height: '100%',
            width: 2,
        },
    },
    /* Styles applied to the track element if `track={false}`. */
    trackFalse: {
        '& $track': {
            display: 'none',
        },
    },
    /* Styles applied to the track element if `track="inverted"`. */
    trackInverted: {
        '& $track': {
            backgroundColor:
            // Same logic as the LinearProgress track color
                theme.palette.type === 'light'
                    ? lighten(theme.palette.error.main, 0.62)
                    : darken(theme.palette.error.main, 0.5),
        },
        '& $rail': {
            opacity: 1,
        },
    },

}))(ThemedSlider);

const useStyles = makeStyles(theme=>({

    formInput: {
        //    maxWidth:'100%'
        width: '100%',
        maxWidth: "500px"
        //  minWidth:'250px'
    },
    withSliderForm: {

        width: '100%',
        maxWidth: "500px",
        marginTop: 5,

    },
    margin: {
        height: theme.spacing(5),
    },
    seperate:{
        height: theme.spacing(2),
    },
    thumbContent:{
        color:  "white",
        fontSize:"1em"
    },
    iconStyle:{
        color:  "white"
    },
    cardContent:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between"
    },
    avatarClass:{
        backgroundColor:theme.palette.error.main
    }

}));
const VendorSignalsStopLossEdit = ({record,pairObject,...props}) =>{
    const translate = useTranslate();
    const classes = useStyles();
    const {values} = useFormState();
    const {entryPrice,stopLoss,targetPoints} = values;
    const form = useForm();
    const {positionType,entryAverage} = record;
    const locale = useLocale()
    const getMarks = ()=>{

        let result = [{value: entryAverage ? entryAverage : positionType === "buy" ? entryPrice.low : entryPrice.high,
            label:positionType === "buy" ? translate("resources.signalalerts.entry") + '\n' + (entryAverage ? entryAverage : entryPrice.low) : translate("resources.signalalerts.entry") + '\n' + (entryAverage ? entryAverage : entryPrice.high)}]
        /*if(pairObject.latestPrice >= getMin() && pairObject.latestPrice <= getMax()){
            result.push({value:pairObject.latestPrice,label:pairObject.symbol + "\n" + pairObject.latestPrice })
        }*/
        return result;
    }
    const getRealMin = ()=>{

        if(positionType === "buy"){
            return pairObject.tickSize

        }
        else{
            if(!entryPrice.triggeredAt){
                return  Number((entryPrice.high  + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
            }
            else{
                let notTriggeredTargetPoints = targetPoints.filter((tp)=>!tp.triggeredAt);
                if(notTriggeredTargetPoints.length === 0){
                    return stopLoss.valuenumber - (stopLoss.valuenumber * 0.1);
                }
                else{
                    let res = Number(Array.max(notTriggeredTargetPoints.map(tp=>tp.valuenumber)));

                    return  Number((res  + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
                }
            }

        }

    }
    const  getRealMax = ()=>{

        if(positionType === "sell"){
            return pairObject.latestPrice * 1000;

        }
        else{
            if(!entryPrice.triggeredAt){
                return Number((entryPrice.low  - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
            }
            else{
                let notTriggeredTargetPoints = targetPoints.filter((tp)=>!tp.triggeredAt);
                if(notTriggeredTargetPoints.length === 0){
                    return stopLoss.valuenumber + (stopLoss.valuenumber * 0.1);
                }
                else{
                    let res = Number(Array.min(notTriggeredTargetPoints.map(tp=>tp.valuenumber)));

                    return  Number((res  - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
                }
            }

        }
    }
    const getMin = ()=>{

        if(positionType === "buy"){
            const idealMin = pairObject.latestPrice - (pairObject.latestPrice * 0.2);
            if(stopLoss.valuenumber < idealMin + (idealMin * 0.1)){
                return stopLoss.valuenumber - (stopLoss.valuenumber * 0.1);
            }
            return idealMin;

        }
        else{
            return getRealMin();
        }

    }
    const getMax = ()=>{

        if(positionType === "sell"){
            const idealMax = pairObject.latestPrice +  (pairObject.latestPrice * 0.2) ;
            if(stopLoss.valuenumber > idealMax - (idealMax * 0.1)){
                return stopLoss.valuenumber + (stopLoss.valuenumber * 0.1);
            }
            return idealMax;

        }
        else{
            return getRealMax();
        }
    }
    const handleChangeSlider = (event, newValue) => {
        if(!canEdit()){
            return;
        }
        form.change('stopLoss.valuenumber',newValue);

    };
    const getThumbContent = () =>{
        if(!entryPrice.triggeredAt){
            return <span></span>;
        }
        if(stopLoss.triggeredAt ){
            return  <CheckIcon className={classes.thumbContent}/>;
        }

        else {
            return  <AlarmIcon className={classes.thumbContent}/>
        }

    }
    const canEdit = ()=>{
      return  targetPoints && targetPoints.filter( tp=>!tp.triggeredAt).length > 0 && !stopLoss.triggeredAt
    }
    const getStatusText = ()=>{
        if(!entryPrice.triggeredAt ){
            return translate("resources.signalalerts.waitingForEntry")

        }
        else if(stopLoss.triggeredAt)  {
            return <> {translate("resources.signalalerts.reachedAt")}  <DateField source={"triggeredAt"} record={stopLoss} locales={locale} showTime/> </>

        }
        else{
            return translate("resources.signalalerts.notReachedYet")
        }

    }
    return (







            <Card  >
                <CardHeader
                    action={
                        <IconButton aria-label="remove"  >
                            <HelpIcon color={"action"} />
                        </IconButton>
                    }
                    avatar={
                        <Avatar aria-label="recipe"   className={classes.avatarClass}>
                            {stopLoss.triggeredAt &&
                            <CheckIcon className={classes.iconStyle}/>
                            }

                            {!stopLoss.triggeredAt &&
                            <AlarmIcon className={classes.iconStyle}/>
                            }
                        </Avatar>
                    }

                    title={translate("resources.signalalerts.stopLoss") }
                    subheader= {getStatusText()}
                />
                <CardContent  >
                    <Box margin={2} marginBottom={9}  >
                    <StopLossSlider
                        value={stopLoss.valuenumber}
                        onChange={handleChangeSlider}
                       // className={classes.formInput}
                        getAriaLabel={()=>translate("resources.signalalerts.stopLoss")}
                        step={pairObject.tickSize}
                        min={getMin()}
                        max = {getMax()}
                        track={positionType === "buy" ? "inverted": "normal"}
                        marks={getMarks()}
                        getDisabled={()=> !canEdit() }
                        getContent={()=>getThumbContent()}
                        // marks={pairObject ? [{value:pairObject.latestPrice,label:pairObject.symbol + "\n" + pairObject.latestPrice}] : true}
                        //getAriaValueText={getEnterPriceStopLossSliderText}

                        // max={enterPriceEnd * 2}
                        valueLabelDisplay="on"
                        // onChangeCommitted={handleChangeSliderCommited}

                        //valueLabelFormat={getEnterPriceStopLossSliderText}


                        //getAriaValueText={valuetext}
                    />
                    </Box>
                    <Grid   container   justify={"center"}>
                    <NumberInput label={"resources.signalalerts.stopLoss"} source="stopLoss.valuenumber" validate={[maxValue(getRealMax()),minValue(getRealMin())]}  disabled={!canEdit()} />
                    </Grid>
                </CardContent>

            </Card>






    )
}
var countDecimals = function(value) {
    let text = value.toString()
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
        let [base, trail] = text.split('e-');
        let deg = parseInt(trail, 10);
        return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
        return value.toString().split(".")[1].length || 0;
    }
    return 0;
}
export default VendorSignalsStopLossEdit;