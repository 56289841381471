import React, {Fragment} from "react";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SwipeableViews from "../shared/SwipeableViews";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {FormWithRedirect, useTranslate} from "react-admin";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Toolbar, useMediaQuery,} from "@material-ui/core";
import {FormSpy} from "react-final-form";
import Draggable from "react-draggable";
import lodashGet from "lodash/get";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: "100vw",
		position: "relative",
	},
	button: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	xSmallTitle: {
		marginTop: 5,
	},
	completedStep: {
		backgroundColor: theme.palette.success.main,
	},
}));

//let formState = false;
const Wizard = (props) => {
	const { basePath, record, save, saving, version } = props;
	const { steps, UseDialog, dialogTitle } = props;
	const { FixedComponent, fixedComponentVisibleSteps, hasFixedComponent, hideStepper, panelClassName } = props;
	const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	/*const validate = values => {
        console.log('validating')
        const errors = {};
        errors.market = "hi";
        return errors
        const activePage = React.Children.toArray(props.children)[
            activeStep
            ]
        return activePage.props.validate ? activePage.props.validate(values) : {}
    }*/
	const onSubmit = (values) => {
		//  console.log('handleSubmit')
		return false;
		const { children, onSubmit } = props;

		const isLastPage = activeStep === steps.length - 1;
		if (isLastPage) {
			//     return onSubmit(values)
			//    console.log("dastan")
		} else {
			handleNext(values);
		}
	};

	const [activeStep, setActiveStep] = React.useState(0);
	const [stepHasError, setStepHasError] = React.useState(false);
	const [hidePanel, setHidePanel] = React.useState(false);

	const theme = useTheme();
	const translate = useTranslate();

	const handleNext = (handleSubmit, props) => {
		//  handleSubmit();
		const isLastPage = activeStep === steps.length - 1;

		if (isLastPage) {
			handleSubmit();
		} else {
			setActiveStep((prevActiveStep) => {
				props.form.change("activeStep", prevActiveStep + 1);
				return prevActiveStep + 1;
			});
		}

		// checkhasStepErrors();
	};

	const checkhasStepErrors = (activePage, formState) => {
		const currentPage = steps[activePage];
		let foundError = false;

		if (formState && formState.errors) {
			for (let field of currentPage.fields) {
				if (lodashGet(formState.errors, field) !== undefined) {
					foundError = true;

					break;
				}
			}
		}
		//  console.log(formState)
		if (formState && formState.values && !formState.values.activeStep) {
			setActiveStep(0);
		}
		return foundError;
		//  setStepHasError(foundError);
	};

	const handleBack = (props) => {
		setActiveStep((prevActiveStep) => {
			props.form.change("activeStep", prevActiveStep - 1);
			return prevActiveStep - 1;
		});
	};

	const handleReset = () => {
		setActiveStep(0);
	};
	const classes = useStyles();
	return (
		<FormWithRedirect
			{...props}
			render={(formProps) => (
				<Box className={classes.root}>
					<form>
						<Box>
							{!hideStepper && (
								<Stepper activeStep={activeStep} alternativeLabel>
									{steps.map((step, index) => {
										const stepProps = {};
										const labelProps = {};
										return (
											<Step key={step + index} {...stepProps}>
												<StepLabel {...labelProps}>{!isXsmall ? translate(step.title) : ""}</StepLabel>
											</Step>
										);
									})}
								</Stepper>
							)}
							{isXsmall && !hideStepper && (
								<Typography variant="h6" gutterBottom className={classes.xSmallTitle} align={"center"}>
									{translate(steps[activeStep].title)}
								</Typography>
							)}
							{!!hasFixedComponent && fixedComponentVisibleSteps.indexOf(activeStep) !== -1 && (
								<FixedComponent />
							)}
							{!UseDialog && (
								<FormSpy subscription={{ errors: true, values: true }}>
									{(spyProps) => (
										<Draggable
											//  defaultPosition={{x: "50%", y: 0}}
											//  positionOffset={{ y: 0,x:"-50%"}}
											cancel=".no-cursor"
											disabled={isXsmall}
											bounds={"." + classes.root}
										>
											<div className={["box", panelClassName]}>
												<SwipeableViews
													axis={theme.direction === "rtl" ? "x-reverse" : "x"}
													index={activeStep}
													disabled={true}
													animateHeight={true}
													ref={(ref) => {
														window.swv = ref;
													}}
													/*onChangeIndex={handleChangeIndex}*/
												>
													{props.children}
												</SwipeableViews>

												<Toolbar className={"no-cursor"}>
													<Box display="flex" justifyContent="space-around" width={"100%"}>
														<Fragment>
															<Button
																disabled={activeStep === 0}
																onClick={() => handleBack(spyProps)}
																className={classes.button}
															>
																Back
															</Button>

															<Button
																variant="contained"
																color="primary"
																onClick={() => handleNext(formProps.handleSubmitWithRedirect, spyProps)}
																//   type="submit"
																className={classes.button}
																disabled={checkhasStepErrors(activeStep, spyProps) || formProps.saving}
																saving={formProps.saving}
																// handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
															>
																{activeStep === steps.length - 1 ? "Finish" : "Next"}
															</Button>
														</Fragment>
													</Box>
												</Toolbar>
											</div>
										</Draggable>
									)}
								</FormSpy>
							)}

							<Box>
								<FormSpy subscription={{ errors: true, values: true }}>
									{(spyProps) => (
										<Dialog
											maxWidth={false}
											open={UseDialog && !spyProps?.values?.hidePanel}
											fullScreen={isXsmall}
											keepMounted={true}
											style={{ opacity: isXsmall && 0.95 }}
											onClose={() => {
												formProps?.form?.change("hidePanel", true);
											}}
										>
											<DialogTitle>
												<Box style={{ display: "flex", flexDirection: "row", flex: 1, width: "100%" }}>
													{dialogTitle}
													<Box flexGrow={1} />
													<IconButton
														aria-label="close"
														onClick={() => {
															formProps?.form?.change("hidePanel", true);
														}}
													>
														<CloseIcon />
													</IconButton>
												</Box>
											</DialogTitle>

											<DialogContent>
												<SwipeableViews
													axis={theme.direction === "rtl" ? "x-reverse" : "x"}
													index={activeStep}
													disabled={true}
													animateHeight={true}
													ref={(ref) => {
														window.swv = ref;
													}}
													/*onChangeIndex={handleChangeIndex}*/
												>
													{props.children}
												</SwipeableViews>
											</DialogContent>

											<DialogActions>
												<Box display="flex" justifyContent="space-around" width={"100%"}>
													<Fragment>
														{activeStep === 0 && (
															<Button
																onClick={() => {
																	formProps?.form?.change("hidePanel", true);
																}}
																className={classes.button}
															>
																Cancel
															</Button>
														)}
														{activeStep !== 0 && (
															<Button
																disabled={activeStep === 0}
																onClick={() => handleBack(spyProps)}
																className={classes.button}
															>
																Back
															</Button>
														)}

														<Button
															variant="contained"
															color="primary"
															onClick={() => handleNext(formProps.handleSubmitWithRedirect, spyProps)}
															//   type="submit"
															className={classes.button}
															disabled={checkhasStepErrors(activeStep, spyProps) || formProps.saving}
															saving={formProps.saving}
															// handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
														>
															{activeStep === steps.length - 1 ? "Finish" : "Next"}
														</Button>
													</Fragment>
												</Box>
											</DialogActions>
										</Dialog>
									)}
								</FormSpy>
							</Box>
						</Box>
					</form>
				</Box>
			)}
		/>
	);
};

export default Wizard;
