import * as React from "react";
import {memo} from "react";
import PropTypes from "prop-types";
import {Fab, useMediaQuery} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import ContentAdd from "@material-ui/icons/Add";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {Button as RaButton, useGetList, useTranslate} from "react-admin";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Box from "@material-ui/core/Box";

import Zoom from "@material-ui/core/Zoom";
import Avatar from "@material-ui/core/Avatar";

var __rest =
	(this && this.__rest) ||
	function (s, e) {
		var t = {};
		for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
		if (s != null && typeof Object.getOwnPropertySymbols === "function")
			for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
				if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
			}
		return t;
	};
const sanitizeButtonRestProps = function (_a) {
	var basePath = _a.basePath,
		handleSubmit = _a.handleSubmit,
		handleSubmitWithRedirect = _a.handleSubmitWithRedirect,
		invalid = _a.invalid,
		onSave = _a.onSave,
		pristine = _a.pristine,
		record = _a.record,
		redirect = _a.redirect,
		resource = _a.resource,
		saving = _a.saving,
		submitOnEnter = _a.submitOnEnter,
		undoable = _a.undoable,
		rest = __rest(_a, [
			"basePath",
			"handleSubmit",
			"handleSubmitWithRedirect",
			"invalid",
			"onSave",
			"pristine",
			"record",
			"redirect",
			"resource",
			"saving",
			"submitOnEnter",
			"undoable",
		]);
	return rest;
};
const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: props && props.isFab ? "top" : "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: props && props.isFab ? "bottom" : "top",
			horizontal: "center",
		}}
		TransitionComponent={Zoom}
		TransitionProps={{ style: { transitionDelay: 100 } }}
		{...props}
	/>
));
const StyledMenuItem = withStyles((theme) => ({
	root: {
		"&:focus": {
			backgroundColor: theme.palette.primary.main,
			"& .MuiListItemIcon-root, & .MuiListItemText-primary": {
				color: theme.palette.common.white,
			},
		},
	},
}))(MenuItem);
const CreateWithMarketButton = (props) => {
	const {
		basePath = "",
		className,
		classes: classesOverride,
		icon = defaultIcon,
		label = "ra.action.create",
		scrollToTop = true,
		variant,
		tradetype,
		...rest
	} = props;
	const {
		data: marketObject,
		ids: marketIds,
		total,
		loading,
		loaded,
		error,
	} = useGetList(
		"markets",
		{
			page: 1,
			perPage: 100,
		},
		{ field: "createdAt", order: "DESC" },
		{ tradetype: tradetype, disabled: { $ne: true } }
	);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const classes = useStyles(props);
	const translate = useTranslate();
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	return (
		<Box>
			{isSmall && (
				<Fab
					onClick={handleClick}
					color="primary"
					className={classnames(classes.floating, className)}
					aria-label={label && translate(label)}
					{...sanitizeButtonRestProps(rest)}
				>
					{icon}
				</Fab>
			)}
			{!isSmall && (
				<RaButton onClick={handleClick} label={label} variant={variant}>
					{icon}
				</RaButton>
			)}
			<StyledMenu
				id="customized-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				isFab={isSmall}
			>
				{!!loaded &&
					!!marketObject &&
					!!marketIds &&
					marketIds.map((marketId) => (
						<StyledMenuItem
							key={marketId}
							component={Link}
							to={{
								pathname: basePath + "/create",
								//state: { marketId: marketId, _scrollToTop: true },
								search: `?source=${JSON.stringify({ market: marketId })}`,
							}}
						>
							<ListItemIcon>
								<Avatar
									variant={"rounded"}
									style={{ width: 30, height: 30, padding: 5 }}
									alt={marketObject[marketId] ? marketObject[marketId].name : ""}
									src={
										"https://cdn.dealerify.io/asseticons/svg/color/" +
										(marketObject[marketId] ? marketObject[marketId].ccxtId.toLowerCase() : "") +
										"icon.svg"
									}
								/>
							</ListItemIcon>
							<ListItemText primary={marketObject[marketId] ? marketObject[marketId].name : ""} />
						</StyledMenuItem>
					))}
			</StyledMenu>
		</Box>
	);
};

const defaultIcon = <ContentAdd />;

const useStyles = makeStyles(
	(theme) => ({
		floating: {
			color: theme.palette.getContrastText(theme.palette.primary.main),
			margin: 0,
			top: "auto",
			right: 20,
			bottom: 60,
			left: "auto",
			position: "fixed",
			zIndex: 1000,
		},
	}),
	{ name: "RaCreateButton" }
);

CreateWithMarketButton.propTypes = {
	basePath: PropTypes.string,
	classes: PropTypes.object,
	className: PropTypes.string,
	icon: PropTypes.element,
	label: PropTypes.string,
};

export default memo(CreateWithMarketButton, (prevProps, nextProps) => {
	return (
		prevProps.basePath === nextProps.basePath &&
		prevProps.label === nextProps.label &&
		prevProps.translate === nextProps.translate &&
		prevProps.to === nextProps.to &&
		prevProps.disabled === nextProps.disabled
	);
});
