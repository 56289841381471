import TimelineIcon from "@material-ui/icons/Timeline";

import {CLSPositionsList} from "./CLSPositionsList";
import {CLSPositionsShow} from "./CLSPositionsShow";

export default {
  show: CLSPositionsShow,
  list: CLSPositionsList,
  icon: TimelineIcon,
};
