import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '../shared/MyTimeLineDot';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {DateField, Loading, useGetOne, useLocale, useTranslate} from "react-admin";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close'
import AlarmIcon from '@material-ui/icons/Alarm';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {useMediaQuery} from "@material-ui/core";
import {rawProfitInPercent} from './SignalCalculations';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    paperSmall:{
        padding: '6px 6px',
    },

    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    topMarginDate:{
        marginTop:"10px",
        color:theme.palette.primary.light
    },
    smallRoot:{
        padding:"6px 0px"
    },
    profitText:{
        color:theme.palette.success.light
    },
    lossText:{
        color:theme.palette.error.light
    },
    lossTextNotExtracted:{
        color:theme.palette.error.main
    }

}));
const getNotExtractedLoss =(record) =>{
    const {stopLoss,entryPrice,targetPoints} = record;
    if(!stopLoss.triggeredAt){
        return 0;
    }
    const notExtractedTargetPoints = targetPoints.filter((tp)=>!tp.triggeredAt);
    let notExtractedPercent = 0;
    if(notExtractedTargetPoints.length > 0 ){
        notExtractedPercent = notExtractedTargetPoints.map((tp)=>tp.percent).reduce((total,num)=>total+num)
    }
    let lossPercent = getLossBasedOnEntryAvg(record);
    let  NotExtractedLossPercent = Number((lossPercent*notExtractedPercent/100).toFixed(3));
    return NotExtractedLossPercent;
}
const getLossBasedOnEntryAvg = (record)=>{
    const {stopLoss,entryPrice,positionType,entryAverage,leverage} = record;
    let avrg =entryAverage ? entryAverage :  Number(((entryPrice.low + entryPrice.high) / 2));
    let lossPercent = rawProfitInPercent(avrg,stopLoss.valuenumber,positionType);
    if(leverage){
        lossPercent *= leverage
        lossPercent = Number(lossPercent.toFixed(3))
    }
    return lossPercent;
}
const getProfitBasedOnEntryAvg = (tp,record)=>{
    const {entryPrice,positionType,entryAverage,leverage} = record;

    let avrg =entryAverage ? entryAverage :  Number(((entryPrice.low + entryPrice.high) / 2));
    let tpVal = tp.valuenumber;

    let profitPercent  = rawProfitInPercent(avrg,tpVal,positionType);
    if(leverage){
        profitPercent *= leverage
        profitPercent = Number(profitPercent.toFixed(3))
    }
    return profitPercent;
}
const getProfitWithAppliedExtraction = (tp,record) => {
    if(!tp || !tp.percent || !tp.valuenumber){
        return 0;
    }
    return Number((getProfitBasedOnEntryAvg(tp,record) * (tp.percent  / 100)).toFixed(3));
}
function getTimelineArray(record,pair) {
    let tlArray = [];
    tlArray.push({price:record.stopLoss.valuenumber,color:"error",name:"stopLoss",NotExtractedLossPercent:getNotExtractedLoss(record),
        LossBasedOnEntryAvg:getLossBasedOnEntryAvg(record),...record.stopLoss});
    tlArray.push({price:record.entryPrice.low,color:"primary",name:"enterPriceStart",...record.entryPrice});
    tlArray.push({price:record.entryPrice.high,color:"primary",name:"enterPriceEnd",...record.entryPrice});
    tlArray.push({price:pair.latestPrice,color:"grey",name:"latestPrice",...pair});
    record.targetPoints.forEach((tp,index)=>{
        tlArray.push({price:tp.valuenumber,color:"success",name:"targetPoint",differencefromAvg:getProfitBasedOnEntryAvg(tp,record)
            ,profitWithExtraction:getProfitWithAppliedExtraction(tp,record),index,...tp});
    });
    //todo add current price;

    tlArray.sort((a,b)=>b.price- a.price);
    return tlArray;
}
function getTitleForDate (item,translate){

    if(["enterPriceStart","enterPriceEnd"].indexOf(item.name) !== -1) {
        return translate("resources.signalalerts.entryrange" )
    }
    if(item.name ==="latestPrice"){
        return  translate("resources.signalalerts.pairpriceUpdatedAt" )
    }
    else{
        return getTitle(item,translate)
    }
}
function getTitle(item,translate){

    if(item.name ===  "targetPoint") {
        return translate("resources.signalalerts." + item.name,{index:item.index+1})
    }
    else if(item.name ==="latestPrice") {
        return item.symbol;
    }
    else {
        return translate("resources.signalalerts." + item.name)
    }
}
function getTimeLineItem(item,classes,record,translate,locale,isSmall) {

    return(
        <TimelineItem>
            <TimelineOppositeContent>
                <Paper elevation={1} className={isSmall ? classes.paperSmall : classes.paper}>
                    <Typography variant="h6" noWrap>
                        {getTitle(item,translate)}
                    </Typography>
                    <Typography  >{translate("resources.signalalerts.price") + ": " + item.price}</Typography>
                    {item.name === "targetPoint" &&
                    <Fragment>
                        <Typography variant="subtitle2"  color={'textSecondary'}>{translate("resources.signalalerts.extraction") + ": " + item.percent + "%"}</Typography>
                        <Typography variant="subtitle2"  color={'textSecondary'}>{translate("resources.signalalerts.differenceBasedOnEntyAvg",{profit:item.differencefromAvg})}</Typography>
                        <Typography variant="subtitle2"  className={classes.profitText} >{translate("resources.signalalerts.profitWithAppliedExtraction",{profit:item.profitWithExtraction})}</Typography>
                    </Fragment>
                    }
                    {item.name === "stopLoss" &&
                    <Fragment>

                        <Typography variant="subtitle2" className={classes.lossText} >{translate("resources.signalalerts.differenceBasedOnEntyAvg",{profit:item.LossBasedOnEntryAvg})}</Typography>
                        {item.triggeredAt &&
                        <Typography variant="subtitle2" className={classes.lossTextNotExtracted}>{translate("resources.signalalerts.notextractedLoss",{loss:item.NotExtractedLossPercent})}</Typography>
                        }

                    </Fragment>
                    }
                </Paper>
            </TimelineOppositeContent>

            <TimelineSeparator>
                <TimelineDot variant={item.triggeredAt ? "default" : "outlined"} color={item.color}>
                    {item.name === "latestPrice" &&
                    <AttachMoneyIcon/>
                    }

                    {item.triggeredAt &&
                    <CheckIcon/>
                    }

                    {!item.triggeredAt &&["enterPriceEnd","enterPriceStart"].indexOf(item.name) !==-1 &&
                    <AlarmIcon/>
                    }
                    {!item.triggeredAt && item.name === "stopLoss" && item.disabled &&
                    <MoreHorizIcon/>
                    }
                    {!item.triggeredAt && item.name === "stopLoss" && !item.disabled &&
                    <AlarmIcon/>
                    }
                    {!item.triggeredAt && item.name === "targetPoint" &&  item.disabled && !record.stopLoss.triggeredAt &&
                    <MoreHorizIcon/>
                    }
                    {!item.triggeredAt && item.name === "targetPoint" &&   item.disabled && record.stopLoss.triggeredAt &&
                    <CloseIcon/>
                    }
                    {!item.triggeredAt && item.name === "targetPoint" &&   !item.disabled && !record.stopLoss.triggeredAt &&
                    <AlarmIcon/>
                    }
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            {item.triggeredAt  &&
            <TimelineContent>
                <Typography className={classes.topMarginDate}>{!isSmall ? getTitleForDate(item,translate) + " " + translate("resources.signalalerts.reachedAt") : translate("resources.signalalerts.reachedAt")}
                    <DateField source={"triggeredAt"} record={item} locales={locale} showTime/></Typography>

            </TimelineContent>}
            {!item.triggeredAt  && item.name !== "latestPrice"  &&
            <TimelineContent>
                <Typography className={classes.topMarginDate}>{translate("resources.signalalerts.notReached")}</Typography>


            </TimelineContent>}
            {item.name === "latestPrice"  &&
            <TimelineContent>
                <Typography className={classes.topMarginDate}>{getTitleForDate(item,translate)}
                    <DateField source={"latestPriceTime"} record={item} locales={locale} showTime/></Typography>

            </TimelineContent>}
        </TimelineItem>
    )
}
const  SignalTimeLine = ({ id, record, resource})=> {
    const classes = useStyles();
    const translate = useTranslate();
    const locale = useLocale();
    const { data, loading, error } = useGetOne('pairs', record.pair);
    const isXsmall = useMediaQuery(theme =>
        theme.breakpoints.down('xs')
    );
    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <Timeline  className={isXsmall ? classes.smallRoot : undefined}>
            {getTimelineArray(record,data).map((item)=>getTimeLineItem(item,classes,record,translate,locale,isXsmall))}
        </Timeline>
    );
}
export default SignalTimeLine