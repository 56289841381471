import React, {Fragment, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TVChartContainer from "./TvWidget";
import {useFormState} from "react-final-form";
import {useDataProvider, usePermissions} from "react-admin";
import {useSelector} from "react-redux";
import ChartOrderModifier from "./ChartOrderModifier";
import SymbolSearch from "./SymbolSearch";
import ReactDom from "react-dom";

const useStyles = makeStyles((theme) => ({
	avatar: {
		marginRight: theme.spacing(1),
	},
}));
//let tvWidget = null;
let stopLossOrderLine = false;
let liquidLine = false;
let entryPriceLowLine = false;
let entryPriceHighLine = false;
let targetPointsLines = [];

let buttonPosition = false;
let buttonExchange = false;

const SignalCreateChart = ({ form, record, pairObject: pairInitObject }) => {
	const styles = useStyles();
	const dataProvider = useDataProvider();
	const [widgetLoaded, setWidgetLoaded] = useState(false);
	const [tvDataURL, setTVDataURL] = useState(false);
	const [orderModifierOpen, setOrderModifierOpen] = useState(false);

	const [orderModifierProps, setOrderModifierProps] = useState({});
	const theme = useSelector((state) => state.theme);
	//const [pairObject , setPairObject] = useState(pairInitObject)
	const chartRef = useRef(null);
	const { values } = useFormState();

	const { closed, positionType } = record;
	const {
		entryPrice,
		stopLoss,
		targetPoints,
		activeStep,
		leverage,
		name,
		tradetype,
		entryReset,
		marketObject,
		pairObject,
		hideOrders,
		symbolSearchOpen,
	} = values;
	const { symbol } = pairObject;
	const { permissions } = usePermissions();
	useEffect(() => {
		if (buttonExchange && tradetype === 1) {
			ReactDom.render(exchangeBtnReact(), buttonExchange);
		}

		if (liquidLine && tradetype === 1) {
			liquidLine?.setPrice(getLiquidPrice());
			liquidLine?.setQuantity(getLiquidPrice());
		}
	}, [leverage]);
	useEffect(() => {
		if (buttonPosition) {
			buttonPosition.style.display = tradetype === 1 ? "flex" : "none";
			buttonPosition.innerHTML = positionType === "buy" ? "Position: Buy/Long" : "Position: Sell/Short";
			buttonPosition.style.backgroundColor = positionType === "buy" ? "green" : "red";
			buttonPosition.onclick = () => {
				if (tradetype === 1) {
					form?.change("positionType", positionType === "buy" ? "sell" : "buy");
				}
			};
		}

		if (liquidLine && tradetype === 1) {
			liquidLine?.setPrice(getLiquidPrice());
			liquidLine?.setQuantity(getLiquidPrice());
		}
	}, [positionType, tradetype]);
	const setSymbolSearchOpen = (value) => {
		form?.change("symbolSearchOpen", value);
	};
	const getLiquidPrice = () => {
		const { entryPrice, stopLoss, targetPoints, positionType, leverage } = form.getState().values;
		if (tradetype === 1 && leverage) {
			let _entryPrice = positionType === "buy" ? entryPrice.high : entryPrice.low;
			if (positionType === "buy") {
				return ((_entryPrice * leverage) / (leverage + 1)).toFixed(countDecimals(pairObject.tickSize));
			} else {
				return ((_entryPrice * leverage) / (leverage - 1)).toFixed(countDecimals(pairObject.tickSize));
			}
		}
		return 0;
	};
	const getEmoji = (obj, closed) => {
		return "⏰";
	};
	const getStopLossMin = () => {
		const { entryPrice, stopLoss, targetPoints, positionType } = form.getState().values;
		if (positionType === "buy") {
			return 0;
		} else {
			if (!entryPrice.triggeredAt) {
				return Number((entryPrice.high + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			} else {
				let notTriggeredTargetPoints = targetPoints.filter((tp) => !tp.triggeredAt);
				if (notTriggeredTargetPoints.length === 0) {
					return stopLoss.valuenumber - stopLoss.valuenumber * 0.1;
				} else {
					let res = Number(Array.max(notTriggeredTargetPoints.map((tp) => tp.valuenumber)));

					return Number((res + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
				}
			}
		}
	};
	const getStopLossMax = () => {
		const { entryPrice, stopLoss, targetPoints, positionType } = form.getState().values;
		if (positionType === "sell") {
			return pairObject.latestPrice * 1000;
		} else {
			if (!entryPrice.triggeredAt) {
				//	console.log(entryPrice);
				return Number((entryPrice.low - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			} else {
				let notTriggeredTargetPoints = targetPoints.filter((tp) => !tp.triggeredAt);
				if (notTriggeredTargetPoints.length === 0) {
					return stopLoss.valuenumber + stopLoss.valuenumber * 0.1;
				} else {
					let res = Number(Array.min(notTriggeredTargetPoints.map((tp) => tp.valuenumber)));

					return Number((res - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
				}
			}
		}
	};
	const onStopLossMoved = () => {
		let stopLossPrice = stopLossOrderLine.getPrice();
		if (stopLossPrice > getStopLossMax()) {
			stopLossPrice = form.getState().values.stopLoss.valuenumber;
			stopLossOrderLine?.setPrice(stopLossPrice);
			stopLossOrderLine?.setQuantity(stopLossPrice);
		} else if (stopLossPrice < getStopLossMin()) {
			stopLossPrice = form.getState().values.stopLoss.valuenumber;
			stopLossOrderLine?.setPrice(stopLossPrice);
			stopLossOrderLine?.setQuantity(stopLossPrice);
		}

		form.change("stopLoss.valuenumber", stopLossPrice);
	};

	const addStopLoss = (tvWidget) => {
		const { hideOrders } = form.getState().values;
		if (stopLoss && stopLoss.valuenumber && !hideOrders) {
			//	console.log("adding stoploss");
			let that = this;
			stopLossOrderLine = tvWidget.activeChart().createOrderLine();
			stopLossOrderLine
				.setTooltip("Stop Loss")
				.setModifyTooltip("Modify Price")

				.onMove(() => {
					onStopLossMoved();
				})

				.setText(getEmoji(stopLoss, closed) + " Stop Loss")
				.setPrice(stopLoss.valuenumber)
				.setQuantity(stopLoss.valuenumber)

				.setEditable(!stopLoss.triggeredAt && !closed)
				.setLineColor("#FF0000")
				.setBodyBorderColor("#FF0000")
				.setBodyTextColor("#FF0000")
				.setQuantityBackgroundColor("#FF0000")
				.setQuantityBorderColor("#FF0000")
				.setLineLength(25)
				.setLineStyle(2)
				.setLineWidth(1.3)
				.onModify("onModify called", function () {
					const { stopLoss } = form.getState().values;
					setOrderModifierProps({
						point: stopLoss,

						pointType: "StopLoss",
						min: getStopLossMin(),
						max: getStopLossMax(),
					});
					setOrderModifierOpen(true);
				});
		}
	};
	const addLiquidLine = (tvWidget) => {
		const { hideOrders } = form.getState().values;
		if (stopLoss && stopLoss.valuenumber && !hideOrders && tradetype === 1) {
			//	console.log("adding liquid line");
			let that = this;
			liquidLine = tvWidget.activeChart().createOrderLine();
			liquidLine
				.setTooltip("Liquidation Zone")

				.setText("Liquidation Zone")
				.setPrice(getLiquidPrice())
				.setQuantity(getLiquidPrice())

				.setEditable(false)
				.setLineColor("#500740")
				.setBodyBorderColor("#500740")
				.setBodyTextColor("#500740")
				.setQuantityBackgroundColor("#500740")
				.setQuantityBorderColor("#500740")
				.setLineLength(25)
				.setLineStyle(2)
				.setLineWidth(1.3);
		}
	};

	const getTargetPointMin = (index) => {
		const { entryPrice, stopLoss, targetPoints, positionType } = form.getState().values;
		if (positionType === "buy") {
			if (index === 0) {
				return Number((entryPrice.high + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			} else {
				return Number((targetPoints[index - 1].valuenumber + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			}
		} else {
			if (index === targetPoints.length - 1 || targetPoints.length === 1) {
				return Number(pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize));
			}
			return Number((targetPoints[index + 1].valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		}
	};
	const getEntryLowMin = () => {
		const { entryPrice, stopLoss, targetPoints, positionType } = form.getState().values;
		if (positionType === "buy") {
			if (!stopLoss || !stopLoss.valuenumber) {
				return 0;
			}
			return Number((stopLoss.valuenumber + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		} else {
			if (!targetPoints || !targetPoints[0]) {
				return 0;
			}
			return Number((targetPoints[0].valuenumber + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		}
	};
	const getEntryLowMax = () => {
		const { entryPrice, stopLoss, targetPoints } = form.getState().values;
		return Number((entryPrice.high - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
	};
	const getEntryHighMin = () => {
		const { entryPrice, stopLoss, targetPoints } = form.getState().values;
		return Number((entryPrice.low + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
	};
	const getEntryHighMax = () => {
		const { entryPrice, stopLoss, targetPoints, positionType } = form.getState().values;
		if (positionType === "buy") {
			if (!targetPoints || !targetPoints[0]) {
				return Number.MAX_SAFE_INTEGER;
			}
			return Number((targetPoints[0].valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		} else {
			if (!stopLoss || !stopLoss.valuenumber) {
				return Number.MAX_SAFE_INTEGER;
			}
			return Number((stopLoss.valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		}
	};
	const getTargetPointMax = (index) => {
		const { entryPrice, stopLoss, targetPoints, positionType } = form.getState().values;
		if (positionType === "sell") {
			if (index === 0) {
				return Number((entryPrice.low + pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			} else {
				return Number((targetPoints[index - 1].valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
			}
		} else {
			if (index === targetPoints.length - 1 || targetPoints.length === 1) {
				return Number.MAX_SAFE_INTEGER;
			}
			return Number((targetPoints[index + 1].valuenumber - pairObject.tickSize).toFixed(countDecimals(pairObject.tickSize)));
		}
	};

	const addTargetPoints = (tvWidget) => {
		const { hideOrders, targetPoints } = form.getState().values;
		if (targetPoints && targetPoints.length > 0 && !hideOrders) {
			targetPoints.forEach((tp, index) => {
				if (!tp || !tp.valuenumber) {
					return;
				}

				targetPointsLines[index] = tvWidget
					.activeChart()
					.createOrderLine({ disableUndo: true })
					.setModifyTooltip("Modify Price")
					.setCancelTooltip("Remove Target Point")
					.onCancel(
						index,
						targetPoints?.length < 2
							? null
							: function (indx) {
								const { hideOrders, targetPoints } = form.getState().values;
								tvWidget.showConfirmDialog({
									title: "Confirm",
									body: "Are you sure to remove TP?",
									callback: (result) => {
										if (targetPoints?.length > 1 && result) {
											let tempTP = JSON.parse(JSON.stringify(targetPoints));
											tempTP.splice(index, 1);
											form.change("targetPoints", tempTP);
										}
									},
								});
							}
					)
					.onMove(function () {
						let tpPrice = this.getPrice();
						if (tpPrice > getTargetPointMax(index)) {
							tpPrice = form.getState().values.targetPoints[index].valuenumber;
							targetPointsLines[index]?.setPrice(tpPrice);
						} else if (tpPrice < getTargetPointMin(index)) {
							tpPrice = form.getState().values.targetPoints[index].valuenumber;
							targetPointsLines[index]?.setPrice(tpPrice);
						}
						this.setQuantity(tpPrice);
						form.change("targetPoints[" + index + "].valuenumber", tpPrice);
					})

					.setText(
						getEmoji(targetPoints[index], closed) +
						" TP" +
						(index + 1) +
						(targetPoints[index].percent !== 100 ? (targetPoints[index].percent ? " : " + targetPoints[index].percent + "%" : "") : " : All")
					)
					.setPrice(targetPoints[index].valuenumber)
					.setQuantity(targetPoints[index].valuenumber)
					.setEditable(!targetPoints[index].triggeredAt && !closed)
					.setLineColor("#00bc00")
					.setBodyBorderColor("#00bc00")
					.setBodyTextColor("#00bc00")
					.setQuantityBackgroundColor("#00bc00")
					.setQuantityBorderColor("#00bc00")
					.setLineLength(20 - (20 / targetPoints.length) * index)
					.setLineStyle(2)
					.onModify("onModify called", function () {
						const { targetPoints } = form.getState().values;
						setOrderModifierProps({
							point: targetPoints[index],
							tpIndex: index,
							pointType: "TargetPoint",
							min: getTargetPointMin(index),
							max: getTargetPointMax(index),
						});
						setOrderModifierOpen(true);
					});
			});
		}
	};
	const addEntryHigh = (tvWidget) => {
		const { hideOrders } = form.getState().values;
		if (entryPrice.high && !hideOrders) {
			entryPriceHighLine = tvWidget?.activeChart()?.createOrderLine({ disableUndo: true });
			entryPriceHighLine
				.setTooltip("Entry Price High")

				.onMove(function () {
					let entryHighPrice = this.getPrice();
					if (entryHighPrice > getEntryHighMax()) {
						entryHighPrice = form.getState().values.entryPrice.high;
						entryPriceHighLine?.setPrice(entryHighPrice);
						entryPriceHighLine?.setQuantity(entryHighPrice);
					} else if (entryHighPrice < getEntryHighMin()) {
						entryHighPrice = form.getState().values.entryPrice.high;
						entryPriceHighLine?.setPrice(entryHighPrice);
						entryPriceHighLine?.setQuantity(entryHighPrice);
					}
					form.change("entryPrice.high", entryHighPrice);
				})

				.setText(getEmoji(entryPrice, closed) + " Entry High")
				.setPrice(entryPrice.high)
				.setQuantity(entryPrice.high)
				.setEditable(!entryPrice.triggeredAt && !closed)
				.setLineLength(35)
				.setLineStyle(2)
				.setLineWidth(1.4)
				.onModify("onModify called", function () {
					const { entryPrice } = form.getState().values;
					setOrderModifierProps({
						point: { valuenumber: entryPrice.high },

						pointType: "Entry Price High",
						min: getEntryHighMin(),
						max: getEntryHighMax(),
					});
					setOrderModifierOpen(true);
				});
		}
	};
	const addEntryLow = (tvWidget) => {
		const { hideOrders } = form.getState().values;
		if (entryPrice.low && !hideOrders) {
			entryPriceLowLine = tvWidget?.activeChart()?.createOrderLine({ disableUndo: true });
			entryPriceLowLine
				.setTooltip("Entry Price Low")

				.onMove(function () {
					let entryLowPrice = this.getPrice();
					if (entryLowPrice > getEntryLowMax()) {
						entryLowPrice = form.getState().values.entryPrice.low;
						entryPriceLowLine?.setPrice(entryLowPrice);
						entryPriceLowLine?.setQuantity(entryLowPrice);
					} else if (entryLowPrice < getEntryLowMin()) {
						entryLowPrice = form.getState().values.entryPrice.low;
						entryPriceLowLine?.setPrice(entryLowPrice);
						entryPriceLowLine?.setQuantity(entryLowPrice);
					}
					form.change("entryPrice.low", entryLowPrice);
				})

				.setText(getEmoji(entryPrice, closed) + " Entry Low")
				.setPrice(entryPrice.low)
				.setQuantity(entryPrice.low)
				.setEditable(!entryPrice.triggeredAt && !closed)
				.setLineLength(35)
				.setLineStyle(2)
				.setLineWidth(1.4)
				.onModify("onModify called", function () {
					const { entryPrice } = form.getState().values;
					setOrderModifierProps({
						point: { valuenumber: entryPrice.low },

						pointType: "Entry Price Low",
						min: getEntryLowMin(),
						max: getEntryLowMax(),
					});
					setOrderModifierOpen(true);
				});
		}
	};
	useEffect(() => {
		const { hideOrders } = form.getState().values;
		if (hideOrders) {
			return;
		}
		if (widgetLoaded && chartRef) {
			if (stopLossOrderLine) {
				stopLossOrderLine.setPrice(stopLoss.valuenumber);
				stopLossOrderLine.setQuantity(stopLoss.valuenumber);
				stopLossOrderLine.setText(getEmoji(stopLoss, closed) + " Stop Loss");
				stopLossOrderLine.setEditable(!stopLoss.triggeredAt && !closed);
			} else {
				const tvWidget = chartRef.current.getTVWidget();
				addStopLoss(tvWidget);
			}

			// const tvWidget = chartRef.current.getTVWidget();
			// addStopLoss(tvWidget)
		}
	}, [stopLoss]);
	const resetAllTargetPointLines = () => {
		const { hideOrders } = form.getState().values;
		if (hideOrders) {
			return;
		}
		if (widgetLoaded && chartRef) {
			const tvWidget = chartRef.current.getTVWidget();
			targetPointsLines.forEach((tpLine) => {
				tpLine.remove(tvWidget);
			});
			targetPointsLines = [];
			addTargetPoints(tvWidget);
			// const tvWidget = chartRef.current.getTVWidget();
			// addStopLoss(tvWidget)
		}
	};

	useEffect(() => {
		const { hideOrders } = form.getState().values;
		if (hideOrders) {
			return;
		}
		if (widgetLoaded && chartRef) {
			if (targetPoints.length > 0 && targetPoints.length === targetPointsLines.length) {
				targetPoints.forEach((tp, index) => {
					if (!tp || !tp.valuenumber || !targetPointsLines[index]) {
						resetAllTargetPointLines();
						return;
					}

					targetPointsLines[index].setPrice(targetPoints[index].valuenumber);
					targetPointsLines[index].setQuantity(targetPoints[index].valuenumber);
					targetPointsLines[index].setText(
						getEmoji(targetPoints[index], closed) +
						" TP" +
						(index + 1) +
						(targetPoints[index].percent ? " : " + targetPoints[index].percent + "%" : "")
					);
					targetPointsLines[index].setEditable(!targetPoints[index].triggeredAt && !closed);
				});
			} else {
				resetAllTargetPointLines();
			}

			// const tvWidget = chartRef.current.getTVWidget();
			// addStopLoss(tvWidget)
		}
	}, [targetPoints]);
	useEffect(() => {
		const { hideOrders } = form.getState().values;
		if (hideOrders) {
			return;
		}
		if (widgetLoaded && chartRef && entryPriceHighLine) {
			entryPriceHighLine?.setPrice(entryPrice.high);
			entryPriceHighLine?.setQuantity(entryPrice.high);
			entryPriceHighLine?.setText(getEmoji(entryPrice, closed) + " Entry High");
			entryPriceHighLine?.setEditable(!entryPrice.triggeredAt && !closed);
			entryPriceLowLine?.setPrice(entryPrice.low);
			entryPriceLowLine?.setQuantity(entryPrice.low);
			entryPriceLowLine?.setText(getEmoji(entryPrice, closed) + " Entry Low");
			entryPriceLowLine?.setEditable(!entryPrice.triggeredAt && !closed);
			if (liquidLine && tradetype === 1) {
				liquidLine?.setPrice(getLiquidPrice());
				liquidLine?.setQuantity(getLiquidPrice());
			}

			// const tvWidget = chartRef.current.getTVWidget();
			// addStopLoss(tvWidget)
		}
	}, [entryPrice]);

	useEffect(() => {
		if (widgetLoaded && chartRef) {
			const tvWidget = chartRef.current.getTVWidget();
			if (stopLossOrderLine) {
				stopLossOrderLine.remove();
				stopLossOrderLine = false;
			}
			if (liquidLine) {
				liquidLine?.remove();
				liquidLine = false;
			}
			if (entryPriceLowLine) {
				entryPriceLowLine?.remove();
				entryPriceLowLine = false;
			}
			if (entryPriceHighLine) {
				entryPriceHighLine?.remove();
				entryPriceHighLine = false;
			}
			if (targetPoints && targetPoints.length > 0) {
				targetPointsLines?.forEach((tpLine) => {
					tpLine?.remove(tvWidget);
				});
				targetPointsLines = [];
			}

			if (!!hideOrders) {
				return;
			}
			if (stopLoss) {
				addStopLoss(tvWidget);
			}
			if (entryPrice) {
				addEntryHigh(tvWidget);
				addEntryLow(tvWidget);
			}
			if (targetPoints && targetPoints.length > 0) {
				resetAllTargetPointLines();
			}
			if (entryPrice && tradetype === 1) {
				addLiquidLine(tvWidget);
			}
		}
	}, [entryReset, hideOrders]);
	const handleAddTargetPoint = (price) => {
		const { entryPrice, stopLoss, targetPoints, positionType } = form.getState().values;
		let newTargetVal = Number(price.toFixed(countDecimals(pairObject.tickSize)));
		let newPercent = targetPoints[targetPoints.length - 1].percent / 2;
		let shouldCurrectPercent = false;
		if (newPercent >= 1 && countDecimals(newPercent) > 1) {
			shouldCurrectPercent = true;
		}
		let newTargetPoints = Array.from(targetPoints);
		newTargetPoints[newTargetPoints.length - 1].percent = shouldCurrectPercent ? Math.floor(newPercent) : newPercent;
		newTargetPoints.push({
			valuenumber: newTargetVal,
			percent: shouldCurrectPercent ? Math.ceil(newPercent) : newPercent,
		});

		form.change("hideOrders", false);
		form.change("targetPoints", newTargetPoints);

		form.change("tpCountChangedAt", new Date());
		/* makePercentsCorrect();
         setTPCountChange(new Date());*/
	};
	const exchangeBtnReact = () => {
		return (
			<div
				style={{
					fontSize: "1em",
					fontWeight: 600,
					justifyContent: "center",
					width: "100%",
					height: "18px",
					position: "absolute",
					bottom: 0,
					alignItems: "end",
					display: "flex",
					backgroundColor: "rgba(0,0,0,0.6)",
				}}
			>
				{pairObject?.symbol}
				{tradetype === 1 && (
					<span style={{ paddingLeft: "5px" }}>
						<span
							style={{
								fontSize: "1.1em",
								padding: "2px",
								backgroundColor: "orange",
								color: "#fff",
							}}
						>
							{" " + leverage}x
						</span>
					</span>
				)}
			</div>
		);
	};
	useEffect(() => {
		if (widgetLoaded && chartRef) {
			const tvWidget = chartRef?.current?.getTVWidget();
			tvWidget?.activeChart()?.setSymbol(symbol + "@" + marketObject?.ccxtId);
			if (!!buttonExchange) {
				ReactDom.render(exchangeBtnReact(), buttonExchange);
				buttonExchange?.setAttribute("title", marketObject.name);
				buttonExchange.style.backgroundImage = "url(https://cdn.dealerify.io/asseticons/svg/color/" + marketObject.ccxtId + "logo.svg)";
			}
		}
	}, [symbol, marketObject?._id]);
	useEffect(() => {
		if (widgetLoaded && chartRef) {
			const { pairObject } = form.getState().values;
			const { symbol } = pairObject;
			//initial stopLoss and ...
			//set listeners
			const tvWidget = chartRef.current.getTVWidget();
			tvWidget.onChartReady(() => {
				tvWidget?.activeChart()?.setSymbol(symbol + "@" + marketObject?.ccxtId);
				tvWidget.onContextMenu(function (unixtime, price) {
					return [
						{
							position: "top",
							text: "Add Target Point (Stop Market)",
							click: function () {
								handleAddTargetPoint(price);
							},
						},
					];
				});
				if (stopLoss?.valuenumber) {
					addStopLoss(tvWidget);
				}

				if (entryPrice) {
					addEntryHigh(tvWidget);
					addEntryLow(tvWidget);
				}
				if (targetPoints && targetPoints.length > 0) {
					addTargetPoints(tvWidget);
				}
				if (tradetype === 1) {
					addLiquidLine(tvWidget);
				}
				tvWidget.saveChartToServer(null, null, {
					chartName: tvWidget.activeChart().symbol() + "_chart",
				});

				tvWidget.subscribe("onAutoSaveNeeded", () => {
					tvWidget.saveChartToServer(null, null, {
						chartName: tvWidget.activeChart().symbol() + "_chart",
					});
				});
				tvWidget.subscribe("onPlusClick", (params) => {
					handleAddTargetPoint(params?.price);
				});
				tvWidget
					.activeChart()
					.onSymbolChanged()
					.subscribe(null, () => {
						const { pairObject } = form.getState().values;

						localStorage.setItem(tradetype + "lastPair", tvWidget.activeChart().symbol());
						localStorage.setItem("lastPairId", pairObject?._id);
						localStorage.setItem("lastPairId-" + pairObject?.market, pairObject?._id);
						tvWidget.saveChartToServer(null, null, {
							chartName: tvWidget.activeChart().symbol() + "_chart",
						});
					});
				tvWidget.headerReady().then(() => {
					const { marketObject } = form.getState().values;

					buttonExchange = tvWidget.createButton();

					buttonExchange.setAttribute("title", marketObject.name);

					buttonExchange.style.backgroundImage = "url(https://cdn.dealerify.io/asseticons/svg/color/" + marketObject.ccxtId + "logo.svg)";
					buttonExchange.style.backgroundSize = "90% 80%";
					buttonExchange.style.backgroundRepeat = "no-repeat";
					buttonExchange.style.backgroundPosition = "center 1px";

					buttonExchange.style.height = "100%";
					buttonExchange.style.width = "120px";
					buttonExchange.style.position = "relative";
					buttonExchange.style.padding = "0";
					buttonExchange.style.cursor = "pointer";
					ReactDom.render(exchangeBtnReact(), buttonExchange);
					buttonExchange.onclick = () => {
						setSymbolSearchOpen(true);
					};

					buttonPosition = tvWidget.createButton({ align: "left" });
					buttonPosition.setAttribute("title", "Click to show change position side");
					buttonPosition.innerHTML = positionType === "buy" ? "Position: Buy/Long" : "Position: Sell/Short";
					buttonPosition.style.backgroundColor = positionType === "buy" ? "green" : "red";
					buttonPosition.style.color = "#fff";
					buttonPosition.style.height = "100%";
					buttonPosition.style.display = tradetype === 1 ? "flex" : "none";
					buttonPosition.style.alignItems = "center";
					buttonPosition.onclick = () => {
						if (tradetype === 1) {
							form?.change("positionType", positionType === "buy" ? "sell" : "buy");
						}
					};
				});
			});
		}
	}, [widgetLoaded]);

	return (
		<Fragment>
			{marketObject?.tvDataURL && permissions && (
				<TVChartContainer
					chartStyle={{ flex: 1 }}
					//  userId={symbol + market}
					disabled_features={[
						"header_symbol_search",
						"symbol_search_hot_key",
						"header_saveload",
						"header_undo_redo",
						"border_around_the_chart",
						"caption_buttons_text_if_possible",
						"disable_resolution_rebuild",
					]}
					userId={permissions._id}
					clientId={permissions._id}
					datafeedUrl={"https://udf2.dealerify.io/udf"}
					/*datafeedUrl={"http://localhost:3030/udf"}*/
					ref={chartRef}
					symbol={symbol + "@" + marketObject?.ccxtId}
					theme={theme && theme.includes("Dark") ? "dark" : "light"}
					setWidgetLoaded={setWidgetLoaded}
				/>
			)}
			<ChartOrderModifier open={orderModifierOpen} setOpen={setOrderModifierOpen} order={orderModifierProps} pairObject={pairObject} />
			<SymbolSearch open={symbolSearchOpen} setOpen={setSymbolSearchOpen} />
		</Fragment>
	);
};

var countDecimals = function (value) {
	let text = value.toString();
	// verify if number 0.000005 is represented as "5e-6"
	if (text.indexOf("e-") > -1) {
		let [base, trail] = text.split("e-");
		let deg = parseInt(trail, 10);
		return deg;
	}
	// count decimals for number in representation like "0.123456"
	if (Math.floor(value) !== value) {
		return value.toString().split(".")[1].length || 0;
	}
	return 0;
};
export default SignalCreateChart;
