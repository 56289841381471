import React, {useEffect, useState} from 'react';

import {required, useInput, useTranslate} from "react-admin";

import {useForm, useFormState} from 'react-final-form';
import {ThemedSlider} from '../../shared/ThemedSlider';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import TextFieldMui from '@material-ui/core/TextField';

const useStyles = makeStyles(theme=>({
    formInputSlider:{
        paddingLeft:"5px",
        paddingRight:"5px",
        width: '100%',
        maxWidth: "500px",
    },
    formInput: {
        //    maxWidth:'100%'
        width: '100%',
        maxWidth: "500px"
        //  minWidth:'250px'
    },
    withSliderForm: {
        width: '100%',
        maxWidth: "500px",
        marginTop: 5,

    },
    margin: {
        height: theme.spacing(5),
    },
    seperate:{
        height: theme.spacing(2),
    }

}));
const EnterPriceForm = (props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const {values} = useFormState();
    const {pairObject,activeStep,entryPrice,pair,cloned} = values;
    const [enterPriceStart,setEnterPriceStart] = useState(entryPrice && entryPrice.low ?  entryPrice.low : 10);
    const [enterPriceEnd,setEnterPriceEnd] =useState(entryPrice && entryPrice.high ?  entryPrice.high : 15);
    const entryLowValidate = [required(),notEqualHighLow];
    const entryHighValidate = [required(), notEqualHighLow];
    const form = useForm();
    //const {input: {onChange},meta: {touched, error}} = useField("enterPriceStartvaluenumber");
    const enterPriceStartInput = useInput({name:"entryPrice.low",source:"entryPrice.low",validate:entryLowValidate});
    const enterPriceEndInput = useInput({name:"entryPrice.high",source:"entryPrice.high",validate:entryHighValidate});
    const getDefaultEntryStart = (pair) =>{

        return pair ?  Number((pair.latestPrice - (pair.latestPrice * 0.01)).toFixed(countDecimals(pair.tickSize))) : 10;

    }
    const getDefaultEntryEnd = (pair) =>{
        return pair ?  Number((pair.latestPrice + (pair.latestPrice * 0.01)).toFixed(countDecimals(pair.tickSize))) : 20;

    }
    useEffect(() => {
        if(!pairObject){
            return;
        }
        if(cloned){
            return;
        }
         console.log('EFFECTED ' + pairObject.symbol)

        const defaultLow = getDefaultEntryStart(pairObject);
        const defaultHigh = getDefaultEntryEnd(pairObject)

        form.change('entryPrice.low',defaultLow)
        form.change('entryPrice.high',defaultHigh)
        enterPriceStartInput.input.onChange(defaultLow)
        enterPriceEndInput.input.onChange(defaultHigh)
        setEnterPriceStart(defaultLow);
        setEnterPriceEnd(defaultHigh);
        setTimeout(()=>{
            form.change('entryReset',new Date())
        },500)

    }, [pairObject]);


    useEffect(()=>{
        if(entryPrice && entryPrice.low && entryPrice.high){
            setEnterPriceStart(entryPrice.low);
            setEnterPriceEnd(entryPrice.high);
        }

    },[entryPrice])



    const handleChangeSlider = (event, newValue) => {
        setEnterPriceStart(newValue[0]);
        setEnterPriceEnd(newValue[1]);


        //enterPriceStartInput.input.onChange(newValue)
        //form.change('enterPriceStartvaluenumber', newValue)
    };

    const handleChangeSliderCommited = (event, newValue) => {
        setEnterPriceStart(newValue[0]);
        setEnterPriceEnd(newValue[1]);

        enterPriceStartInput.input.onChange(newValue[0])
        enterPriceEndInput.input.onChange(newValue[1])

        //handleBlur();
        //enterPriceStartInput.input.onChange(newValue)
        //form.change('enterPriceStartvaluenumber', newValue)
    };
    const handleInputChangeEnterPriceStart = (event) => {

        enterPriceStartInput.input.onChange(Number(event.target.value))
        setEnterPriceStart(event.target.value === '' ? 0 : Number(event.target.value));
    };
    const handleInputChangeEnterPriceEnd = (event) => {
        enterPriceEndInput.input.onChange(Number(event.target.value))
        setEnterPriceEnd(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleBlurLow= ()=>{
        let _entryLow = enterPriceStart;
        let _entryHigh = enterPriceEnd;
        if(enterPriceStart >= enterPriceEnd){
            setEnterPriceEnd(_entryLow + (pairObject.tickSize*100))
            enterPriceEndInput.input.onChange(Number(_entryLow + (pairObject.tickSize*100)))
        }

        enterPriceStartInput.input.onBlur()
        enterPriceEndInput.input.onBlur()
    }
    const handleBlurHigh= ()=>{
        let _entryLow = enterPriceStart;
        let _entryHigh = enterPriceEnd;
        if(enterPriceStart > enterPriceEnd){
            setEnterPriceEnd(_entryLow)
            setEnterPriceStart(_entryHigh);
            enterPriceEndInput.input.onChange(Number(_entryLow))
            enterPriceStartInput.input.onChange(Number(_entryHigh))
        }
        else if(enterPriceStart === enterPriceEnd){
            setEnterPriceEnd(_entryLow + (pairObject.tickSize*100))
            enterPriceEndInput.input.onChange(Number(_entryLow + (pairObject.tickSize*100)))
        }
        enterPriceStartInput.input.onBlur()
        enterPriceEndInput.input.onBlur()
    }
    const handleFocus= (inputComponent)=>{

        inputComponent.input.onFocus()
    }
    const getMin = ()=>{
        if(pairObject){
            const result = enterPriceStart - (enterPriceStart * 0.075);

            return result < pairObject.latestPrice ? result : pairObject.latestPrice;
        }
        return 0
    }
    const getMax = ()=>{
        if(pairObject) {
            const result = enterPriceEnd + (enterPriceEnd * 0.075);

            return result > pairObject.latestPrice ? result : pairObject.latestPrice;
        }
        return  100;
    }
   const getMarks = ()=>{
        return false
        if(!pairObject){
            return true;
        }
       let result = [{value:pairObject.latestPrice,label:pairObject.symbol + "\n" + pairObject.latestPrice}];
        let avrg = Number(((enterPriceStart + enterPriceEnd) / 2).toFixed(countDecimals(pairObject.tickSize)));
        if(pairObject.latestPrice !== avrg) {
            result.push({value:avrg,label:translate("resources.signalalerts.entryAverage")+ "\n"  + avrg})
        }
        return result
    }
    // console.log(enterPriceStartInput);
    return (

        <div className={classes.withSliderForm} style={{display:'none'}}>
            <Typography id="enterPricesliderLabel" gutterBottom className={classes.formInput}>
                {translate('resources.signalalerts.entryrange')}
            </Typography>
            <div className={classes.margin} />
            <ThemedSlider
                value={[enterPriceStart,enterPriceEnd] }
                onChange={handleChangeSlider}
                className={classes.formInput}
                getAriaLabel={(index)=>translate(getPriceStopLossSliderThumbLabel(index))}
                step={pairObject ? pairObject.tickSize : 1}
                min={getMin()}
                max = {getMax()}
                marks={getMarks()}
                //getAriaValueText={getEnterPriceStopLossSliderText}

                // max={enterPriceEnd * 2}
                valueLabelDisplay="on"
                onChangeCommitted={handleChangeSliderCommited}

                //valueLabelFormat={getEnterPriceStopLossSliderText}


                //getAriaValueText={valuetext}
            />
            <div className={classes.margin} />
            <div className={classes.margin} />
            <div className={classes.seperate} />
            <TextFieldMui
                id="entryPrice.low"
                label={translate("resources.signalalerts.enterPriceStart")}
                type="number"

                value={enterPriceStart}
                variant="filled"
                onChange={handleInputChangeEnterPriceStart}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{ step: pairObject ? pairObject.tickSize.toFixed(10) : 1,
                    max:enterPriceEnd
                }}
                onBlur={()=>handleBlurLow(enterPriceStartInput)}
                onFocus={()=>handleFocus(enterPriceStartInput)}
                className={classes.formInput}
                name={"entryPrice.low"}
                error={!!(enterPriceStartInput.meta.touched && enterPriceStartInput.meta.error)}
                helperText={enterPriceStartInput.meta.touched && enterPriceStartInput.meta.error ? translate(enterPriceStartInput.meta.error.message,enterPriceStartInput.meta.error.args) :""}

            />
            <div className={classes.seperate} />
            <TextFieldMui
                id="entryPrice.high"
                variant="filled"
                type="number"
                inputProps={{ step: pairObject ? pairObject.tickSize.toFixed(10) : 1,
                    min:enterPriceStart
                }}

                label={translate("resources.signalalerts.enterPriceEnd")}
                value={enterPriceEnd}
                onChange={handleInputChangeEnterPriceEnd}
                InputLabelProps={{
                    shrink: true,
                }}

                onBlur={()=>handleBlurHigh(enterPriceEndInput)}
                onFocus={()=>handleFocus(enterPriceEndInput)}
                className={classes.formInput}
                name={"entryPrice.high"}
                error={!!(enterPriceEndInput.meta.touched && enterPriceEndInput.meta.error)}
                helperText={enterPriceEndInput.meta.touched && enterPriceEndInput.meta.error ? translate(enterPriceEndInput.meta.error.message,enterPriceEndInput.meta.error.args) :""}

            />



        </div>

    )
}
var countDecimals = function(value) {
    let text = value.toString()
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
        let [base, trail] = text.split('e-');
        let deg = parseInt(trail, 10);
        return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
        return value.toString().split(".")[1].length || 0;
    }
    return 0;
}
const notEqualHighLow = (value, allValues) => {
    if (allValues && allValues.enterPriceStart && allValues.entryPrice.low !== undefined && allValues.enterPriceEnd && allValues.entryPrice.high  !== undefined ) {
        if(allValues.entryPrice.high === allValues.entryPrice.low){
            return  {message:'shoud not be equal'};
        }
    }

    return null;
};
function getPriceStopLossSliderThumbLabel(index) {
    switch (index){
        case 0:
            return "resources.signalalerts.low"
        case 1:
            return "resources.signalalerts.high"

    }
}
export default EnterPriceForm;